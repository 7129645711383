import React, {Component} from 'react';
import descStyles from '../Description.module.css'
import ButtonBase from '@material-ui/core/ButtonBase';
import {Star} from '@material-ui/icons';
import RouteNames from '../../../routes/Route.name';
import styles from './AboutDriver.module.css';

class AboutDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this._handleProfile = this._handleProfile.bind(this);
    }

    _handleProfile() {
        const {data, vehicle} = this.props;
        this.props.history.push(RouteNames.driver_profile+'/'+data.id, {
            data: data
        });
    }

    render() {
        const {data, vehicle,tour} = this.props;
        if (data) {
            return (
                <div>
                    <div className={descStyles.box} onClick={this._handleProfile}>
                        <div className={descStyles.cont} style={{display: 'flex'}}>
                            <div style={{padding: '7px', position: 'relative'}}>
                                <img className={styles.driverImage} src={data.image}/>
                                <div>
                                    <ButtonBase classes={{root: descStyles.btnCol}} onClick={this._handleProfile}>
                                        <span style={{
                                            fontSize: '10px',
                                            color: 'white',
                                            fontWeight: '500'
                                        }}>View Profile</span>
                                    </ButtonBase>
                                </div>
                            </div>
                            <div>
                                <div style={{padding: '10px'}}>
                                    <div className={descStyles.icons}>
                                        <Star fontSize="small" className={descStyles.fontSizeSmall}/>
                                        <span style={{color: 'white'}}>4.6</span>
                                    </div>
                                    <div className={descStyles.text}>{data.name}</div>
                                    <div style={{fontSize: '14px'}}>{data.first_name} can speak {data.languages}</div>
                                    <span style={{fontSize: '14px'}}>
Vehlicle:
                    <span style={{fontWeight: 'bold'}}> {tour.type_category}</span>
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }
}

export default AboutDriver
