/**
 * Created by charnjeetelectrovese@gmail.com on 4/9/2019.
 */

import { postRequest, getRequest, formDataRequest } from '../libs/AxiosService.util';

export async function serviceRequestOtp(params) {
    return await postRequest('/auth/request/otp', params);
}

export async function serviceRequestEmailOtp(params) {
    return await postRequest('requestemailotp', params);
}

export async function serviceVerifyOTP(params) {
    return await postRequest('/auth/verify/otp', params);
}
export async function serviceLoginUser(params) {
    return await postRequest('/auth/login', params);
}
export async function serviceSignupUser(params) {
    return await postRequest('/auth/signup', params);
}


export async function serviceSaveUser(params) {
    return await postRequest('/auth/store/user', params);
}

export async function serviceVerifyEmail(params) {
    return await postRequest('verifyemail', params);
}

export async function serviceCaptureLead(params) {
    return await formDataRequest('capturelead', params);
}

export async function serviceHomeData(params) {
    return await getRequest('homedata', params);
}
export async function serviceCaptureQuery(params) {
    return await postRequest('capturequery', params);
}
export async function serviceUploadImage(params) {
    return await formDataRequest('updateprofileimage', params);
}
export async function serviceCaptureComplaint(params) {
    return await postRequest('capture/complaint', params);
}

export async function serviceGetUserData (params) {
    return await getRequest('/user/data', params);
}

export async function serviceGetResendEmailVerification(params) {
    return await getRequest('/resend/emailverification', params);
}

export async function serviceCallReferer(params) {
    return await postRequest('setreferer', params);
}
