/**
 * Created by charanjeetelectrovese@gmail.com on system AakritiS. on 22/03/19.
 */
import {serviceGetCountryCode, serviceGetFilterList} from "../services/Common.service";
import {actionUpdateFilter} from './Filter.action';

export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const SELECT_INSURANCE = 'SELECT_INSURANCE';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const UPDATE_BOARD_TYPE = 'UPDATE_BOARD_TYPE';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const FILTER_LIST_INIT = 'FILTER_LIST_INIT';
export const FILTER_LIST_DONE = 'FILTER_LIST_DONE';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_LOCATION = 'SET_LOCATION';
export const CLEAR_LOCATION = 'CLEAR_LOCATION';
export const UPDATE_DATETIME = 'UPDATE_DATETIME';
export const ADD_WISHLIST = 'ADD_WISHLIST';
export const REMOVE_WISHLIST = 'REMOVE_WISHLIST';

export const actionUpdateLocation = location => (dispatch) => {
    dispatch(actionUpdateFilter({city: location.name}));
    dispatch({type: LOCATION_UPDATE, payload: location});
};

export const actionTourLaterUpdateTime = (date, time) => (dispatch) => {
    dispatch({type: UPDATE_DATETIME, payload: { date, time } })
}

export const actionSelectInsurance = selectedInsurance => (dispatch) => {
    dispatch({type: SELECT_INSURANCE, payload: selectedInsurance});
};

export const actionUpdateRoute = route => (dispatch) => {
    dispatch({type: UPDATE_ROUTE, payload: route});
};


export const actionUpdateBoardType = type => (dispatch) => {
    dispatch({type: UPDATE_BOARD_TYPE, payload: type});
};

export const actionUpdateCategory = type => (dispatch) => {
    dispatch({type: UPDATE_CATEGORY, payload: type});
};

export function actionGetCountryCode() {
    const request = serviceGetCountryCode();
    return (dispatch) => {
        request.then((data) => {
            if (!data.error) {
                const tempData = data.data;
                dispatch({type: SET_COUNTRY_CODE, payload: tempData.country_code});
            }
        });
    };
}

export function actionGetFilterLists() {
    const request = serviceGetFilterList({list: ['LANGUAGES', 'TOURTYPE', 'TYPECATEGORY', 'CATEGORY']});
    return (dispatch) => {
        dispatch({type: FILTER_LIST_INIT, payload: null});
        request.then((data) => {
            if (!data.error) {
                dispatch({type: FILTER_LIST_DONE, payload: data.data})
            }
        })
    }
}


export function actionSetFromLocation(location) {
    return (dispatch) => {
        dispatch({ type: SET_LOCATION, payload: location  });
    }
}


export function actionClearFromLocation() {
    return (dispatch) => {
        dispatch({ type: CLEAR_LOCATION, payload: null });
    }
}

export function addToWishlist(data) {
    return (dispatch) => {
        dispatch({
            type: ADD_WISHLIST,
            payload: data
        })
    }
}

export function removeFromWishlist() {
    return (dispatch) => {
        dispatch({
            type: REMOVE_WISHLIST
        })
    }
}