/**
 * Created by charnjeetelectrovese@gmail.com on 4/10/2019.
 */
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionUpdateRoute } from '../actions/Common.action';

class PrivateRouter extends React.PureComponent {
  render() {
    const { component: Component, ...rest } = this.props;
    console.log(this.props);
    if (!this.props.is_authenticated) {
      this.props.actionUpdateRoute(this.props.location);
    }
    return (<Route
      {...rest}
      render={props => (
        this.props.is_authenticated
          ? <Component {...props} />
          : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location },
            }}
            />
          )
      )}
    />
    );
  }
}

function mapStateToProps(state) {
  return {
    is_authenticated: state.auth.is_authenticated,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    actionUpdateRoute,
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouter);
