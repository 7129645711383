/**
 * Created by charnjeetelectrovese@gmail.com on 10/11/2019.
 */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {
    Menu as MenuIcon,
    ExpandMore as SelectIcon,
    KeyboardArrowRight as RightArrow,
    Edit as EditIcon
} from '@material-ui/icons';
import {ButtonBase} from '@material-ui/core';
import styles from './Style.module.css';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actionLogoutUser} from "../../actions/Auth.action";
// import SignupDialog from "../../views/SignUp/SignupDialog.view";
import CustomDropdown from "../CustomDropdown/CustomDropdown.component";
import EventEmitter from "../../libs/Events.utils";
import MediaQuery from 'react-responsive';
import data from '../../data';

const tempstyles = {
    list: {
        width: 'calc(70vw)',
        maxWidth: '290px'
    },
    fullList: {
        width: 'auto',
    },
};

class NavComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            right: false,
            open: false,
            dialog_open: false,
        };
        this._handleMenu = this._handleMenu.bind(this);
        this._handleClose = this._handleClose.bind(this)
        this._handleUser = this._handleUser.bind(this);
        this._handleDialog = this._handleDialog.bind(this);
        this._handleProfileClick = this._handleProfileClick.bind(this);
    }

    componentDidMount() {
        EventEmitter.subscribe(EventEmitter.OPEN_LOGIN_DIALOG, this._handleDialog);
    }

    componentWillUnmount() {
        EventEmitter.unsubscribe(EventEmitter.OPEN_LOGIN_DIALOG);
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            right: open,
        });
    };

    _handleMenu() {
        this.setState({
            right: true,
        });
    }

    _handleClose() {
        this.setState({open: !this.state.open});
    }

    _handleClick(val) {
        if (val == 'logout') {
            // this.props.actionLogoutUser();
        } else {
            this.props.history.push(val)
        }
    }

    _handleDialog() {
        const {is_authenticated} = this.props;
        // this.setState({
        //     dialog_open: !this.state.dialog_open
        // })
    }


    _handleUser() {
        this.setState({
            right: false,
        });
        this.props.history.push('/user/profile');
    }

    _handleProfileClick(path) {
        if (path == 'logout') {
            this.props.actionLogoutUser();
        } else {
            this.props.history.push(path);
        }
    }

    _renderProfile() {
        const {user_details} = this.props;
        if (user_details.name) {
            return (
                <div style={{
                    // backgroundImage: 'url(' + require('../../assets/img/drawer/ic_rectangle.png') + ')',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'left'
                }}
                     className={styles.drawerContainer}>
                    <div>
                        <img src={user_details.user_image}
                             style={{height: '70px', width: '70px', borderRadius: '50%'}}/>
                        {/*<EditIcon className={styles.imgEditBtn} onClick={this._handleUser}/>*/}
                    </div>
                    <div className={styles.profile}>
                        <div>Welcome</div>
                        <div style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {user_details.name}
                        </div>
                    </div>
                </div>
            );
        }
        return (<div className={styles.drawerContainer}>
            <div>
                <img src={require('../../assets/img/ic_profile.png')}
                     style={{height: '70px', width: '70px', borderRadius: '50%'}}/>
            </div>
            <div className={styles.profile}>
                <div>Welcome</div>
                <div style={{fontWeight: 'bold', fontSize: '16px'}}>
                    {/*{user_details.name}*/}User
                </div>
            </div>
        </div>);
    }

    activeRoute(routeName) {
        // return false;
        if (routeName == '/') {
            return this.props.location.pathname == '/';
        } else {
            return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
        }
    }

    _renderMenu() {
        const temp = [];
            data.drawer.forEach((val) => {
               if(this.props.is_authenticated){
                   temp.push((
                       <ButtonBase  className={styles.drawerList} onClick={this._handleClick.bind(this, val.path)}>
                           <div style={{display: 'flex', alignItems: 'center'}}>
                               <div>
                                   <img src={val.icon} className={styles.icon}/>

                               </div>
                               <div className={styles.text}>
                                   {/*<ButtonBase>*/}
                                       {val.d1}
                                   {/*</ButtonBase>*/}
                               </div>
                           </div>
                       </ButtonBase>
                   ))
               }
            });
            return temp
    }

    render() {
        const {classes, user_details, injectCss} = this.props;
        return (
            <div className={styles.topBar} style={injectCss}>
            <div className={classNames('ncontainer', styles.navContainer)}>
                <div className={styles.imageFlex}>
                    <Link to="/">
                        <img className={styles.logo} src={require('../../assets/img/factory/logo_new.png')} alt="logo"/>
                    </Link>
                </div>

                <MediaQuery minDeviceWidth={768}>
                <div className={styles.innerFlex}>
                    <ul className={styles.navList}>
                        <li style={{overflow: 'hidden'}}>
                            <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/how')}>
                                <div className={styles.iconsFlex}>
                                    {/*<img*/}
                                        {/*src={this.activeRoute('/') ? require('../../assets/img/navbar/ic_home_selected@2x.png') : require('../../assets/img/navbar/ic_home_unselected@2x.png')}*/}
                                        {/*className={styles.navIcon}/>*/}
                                    <span
                                        className={this.activeRoute('/how') ? styles.navText : 'inherit'}>How It Works</span>
                                </div>
                            </ButtonBase>
                        </li>
                    </ul>
                    <ul className={styles.navList}>
                        <li style={{overflow: 'hidden'}}>
                            <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/a')}>
                                <div className={styles.iconsFlex}>
                                    {/*<img*/}
                                        {/*src={this.activeRoute('/cities') ? require('../../assets/img/navbar/ic_cities_selected.png') : require('../../assets/img/navbar/ic_cities_unselected.png')}*/}
                                        {/*className={styles.navIcon}/>*/}
                                    <span
                                        className={this.activeRoute('/a') ? styles.navText : 'inherit'}>Marketer</span>
                                </div>
                            </ButtonBase>
                        </li>
                    </ul>
                    <ul className={styles.navList}>
                        <li style={{overflow: 'hidden'}}>
                            <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/b')}>
                                <div className={styles.iconsFlex}>
                                    {/*<img*/}
                                        {/*src={this.activeRoute('/blogs') ? require('../../assets/img/navbar/ic_blogs_selected.png') : require('../../assets/img/navbar/ic_blogs_unselected.png')}*/}
                                        {/*className={styles.navIcon}/>*/}
                                    <span
                                        className={this.activeRoute('/b') ? styles.navText : 'inherit'}>Manufacturer</span>
                                </div>
                            </ButtonBase>
                        </li>
                    </ul>
                    <ul className={styles.navList}>
                        <li style={{overflow: 'hidden'}}>
                            <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/contact')}>
                                <div className={styles.iconsFlex}>
                                    {/*<img*/}
                                        {/*src={this.activeRoute('/wishlist') ? require('../../assets/img/navbar/ic_wishlist_selected@2x.png') : require('../../assets/img/navbar/ic_wishlist_unselected@2x.png')}*/}
                                        {/*className={styles.navIcon}/>*/}
                                    <span
                                        className={this.activeRoute('/contact') ? styles.navText : 'inherit'}>Contact</span>
                                </div>
                            </ButtonBase>
                        </li>
                    </ul>

                    <ul className={styles.navList}>
                        <li style={{overflow: 'hidden'}}>
                            <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/login')}>
                                <div className={styles.iconsFlex}>
                                    {/*<img*/}
                                    {/*src={this.activeRoute('/wishlist') ? require('../../assets/img/navbar/ic_wishlist_selected@2x.png') : require('../../assets/img/navbar/ic_wishlist_unselected@2x.png')}*/}
                                    {/*className={styles.navIcon}/>*/}
                                    <span
                                        className={this.activeRoute('/login') ? styles.navText : 'inherit'}>Login</span>
                                </div>
                            </ButtonBase>
                        </li>
                    </ul>
                </div>
                {/*this.props.is_authenticated ? (<CustomDropdown handleClick={this._handleProfileClick} />) :*/}
                    <ButtonBase className={styles.loginButton} onClick={this._handleClick.bind(this, '/signup')}>
                        Signup
                    </ButtonBase>

                {/*<SignupDialog*/}
                    {/*open={this.state.dialog_open}*/}
                    {/*handleClose={this._handleDialog}/>*/}
                </MediaQuery>

                <MediaQuery maxDeviceWidth={768}>
                    <div className={styles.innerFlex}>
                        <ul className={styles.navList}>
                            <li style={{overflow: 'hidden'}}>
                                <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/')}>
                                    <div className={styles.iconsFlex}>
                                        <img
                                            src={this.activeRoute('/') ? require('../../assets/img/navbar/ic_home_selected@2x.png') : require('../../assets/img/navbar/ic_home_unselected@2x.png')}
                                            className={styles.navIcon}/>
                                    </div>
                                </ButtonBase>
                            </li>
                        </ul>

                        <ul className={styles.navList}>
                            <li style={{overflow: 'hidden'}}>
                                <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/wishlist')}>
                                    <div className={styles.iconsFlex}>
                                        <img
                                            src={this.activeRoute('/wishlist') ? require('../../assets/img/navbar/ic_wishlist_selected@2x.png') : require('../../assets/img/navbar/ic_wishlist_unselected@2x.png')}
                                            className={styles.navIcon}/>
                                    </div>
                                </ButtonBase>
                            </li>
                        </ul>

                        <ul className={styles.navList}>
                            <li style={{overflow: 'hidden'}}>
                                <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/bookings')}>
                                    <div className={styles.iconsFlex}>
                                        <img
                                            src={this.activeRoute('/bookings') ? require('../../assets/img/navbar/ic_profile@2x.png') : require('../../assets/img/navbar/ic_profile_black@2x.png')}
                                            className={styles.navIcon}/>
                                    </div>
                                </ButtonBase>
                            </li>
                        </ul>

                        <ul className={styles.navList}>
                            <li style={{overflow: 'hidden'}}>
                                <ButtonBase className={styles.buttonBase} onClick={this._handleMenu.bind(this, '/b')}>
                                    <div className={styles.iconsFlex}>
                                        <img
                                            src={ require('../../assets/img/navbar/ic_menu_bar@2x.png')}
                                            // src={this.activeRoute('/') ? require('../../assets/img/navbar/ic_menu_bar_green@2x.png') : require('../../assets/img/navbar/ic_menu_bar@2x.png')}
                                            className={styles.navIcon}/>
                                    </div>
                                </ButtonBase>
                            </li>
                        </ul>
                        <Drawer
                            classes={{
                                paper: this.props.classes.list
                            }}
                            anchor="right"
                            open={this.state.right}
                            onClose={this.toggleDrawer('right', false)}>
                                {this._renderProfile()}

                            <div
                                tabIndex={0}
                                role="button"
                                onClick={this.toggleDrawer('right', false)}
                                onKeyDown={this.toggleDrawer('right', false)}
                            >
                                {this._renderMenu()}
                            </div>
                        </Drawer>
                        {/*<SignupDialog*/}
                            {/*open={this.state.dialog_open}*/}
                            {/*handleClose={this._handleDialog}/>*/}
                    </div>
                </MediaQuery>
            </div>
            </div>
        );
    }
}

NavComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        user_details: state.auth.user_details
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLogoutUser: actionLogoutUser
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(tempstyles)(NavComponent))
