import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ReviewsP from "../ReviewsP/ReviewsP.component";
import TabsDetails from "./TabsDetails/TabsDetails.view";
// import MyReviews from './ReviewsP/ReviewsP.component'

function TabContainer({ children, dir, otherStyles={padding: '24px'} }) {
    return (
        <Typography component="div" dir={dir} style={{ ...otherStyles}}>
            {children}
        </Typography>
    );
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow:'none'

    },

    indicator: {
        backgroundColor:'#2196F3'
    },
    // box: {
    //
    // }
});

class FullTabs extends React.Component{
    state={
        value:0
    }
    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render(){

        const{classes,theme, info} = this.props;
        return(
            <div className={classes.root}>
                {/*<AppBar position={'static'} color={'default'}>*/}
                <Tabs value={this.state.value}
                      onChange={this.handleChange}
                     classes={{indicator: classes.indicator, root:classes.root}}

                      textColor="primary"
                      variant="fullWidth"
                        centered={true}>

                    <Tab  style={{color:'black'}} label={"About"}/>
                    <Tab  style={{color:'black'}} label={"Reviews"}/>
                </Tabs>
                {/*</AppBar>*/}
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                >

                    <TabContainer dir={theme.direction}>


                    <h5 style={{marginTop:'5px',marginBottom:'5px'}}>Details</h5>
                        <TabsDetails info={info}/>
                    </TabContainer>
                <TabContainer dir={theme.direction} otherStyles={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', }}>
                    <ReviewsP/>
                    {/*<img src={require('../../../assets/img/Group.png')} style={{width:'100%'}}/>*/}
                </TabContainer>

                </SwipeableViews>
            </div>
        )
    }
}
export default withStyles(styles, { withTheme: true })(FullTabs);
