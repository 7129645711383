import React, {Component} from 'react';
import styles from './ReadsCard.module.css';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import ButtonBase from "@material-ui/core/ButtonBase";
import history from '../../../libs/history.util';
import RouteNames from "../../../routes/Route.name";
import data from '../../../data'

class ReadsCard extends Component{
    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick () {
        history.push(RouteNames.blog_detail);
    }

    _renderTopCards(){
        return (
            <div className={styles.mainContainer} onClick={this._handleClick}>
                        <div className={styles.indusCont}>
                            <div style={{position:'relative'}}>
                            <img src={require('../../../assets/img/factory/1.png')} className={styles.industryIcons}/>
                                <div className={styles.date}>
                                    <div className={styles.number}>10</div>
                                    <div className={styles.mon}>APR</div>
                                </div>
                            </div>
                            <div className={styles.min}>
                                Lorem ipsum is simply dummy text of <br/> the the printing and industry
                            </div>
                            <div className={styles.supp}>
                                <div>2 mins read</div>
                                <div>
                                    <b>Pharma</b>
                                </div>
                            </div>
                        </div>

            </div>
        )
    }

    render(){
        return(
            <div className={classNames(styles.wrapper,styles.prodFlex)} onClick={this._handleClick}>
                {this._renderTopCards()}
            </div>
        )
    }
}

export default ReadsCard
