import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store, {persistor} from './store';
import * as serviceWorker from './serviceWorker';
import {setAuthorizationToken, setAxiosTimezone} from './libs/set_auth_token.utils';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { actionGetFilterLists, actionGetCountryCode } from './actions/Common.action';
import {AUTH_USER} from "./actions/Auth.action";
import { initializeFirebase, askForPermissioToReceiveNotifications } from './libs/push_notification';
import {connectToSocket} from "./libs/socket.utils";
// import { registerServiceWorker } from "./libs/register-sw";


setAxiosTimezone();
if (localStorage.jwt_token) {
  setAuthorizationToken(localStorage.jwt_token);
    if (localStorage.user) {
        store.dispatch({
            type: AUTH_USER,
            payload: {...(JSON.parse(localStorage.user)), token: localStorage.jwt_token}
        });
    }
    connectToSocket(localStorage.jwt_token);
} else {
    connectToSocket();
}
// registerServiceWorker();
store.dispatch(actionGetFilterLists());
store.dispatch(actionGetCountryCode());
ReactDOM.render(
  <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
    <App />
      </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
initializeFirebase();
askForPermissioToReceiveNotifications();
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener("message", (message) => {
        /*
        data:
    firebaseMessaging:
    payload:
    collapse_key: "do_not_collapse"
    from: "1015129279552"
    notification:
    body: "dsd"
    title: "Working Good"
         */

        console.log('messageSend', message)
    });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
