/**
 * Created by charnjeetelectrovese@gmail.com on 3/25/2019.
 */
import { AUTH_USER, LOGOUT_USER, UPDATE_USER, UPDATE_FAMILY, SET_ACTIVE, SET_REFERER, CALL_REFERER,STORE_CONTACT } from '../actions/Auth.action';

const initialState = { error: false,
    message: '',
    user_details: {
        is_active: false,
        name: '',
        email: '',
        address: '',
        country: '',
        contact: '',
        country_code: 'IN',
        is_permanent_address: false,
        gender: 'MALE',
        via: 'NUMBER',
        dob: '',
        age: 0

    },
    is_authenticated: false,
    is_referer: false,
    referer: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_USER: {
            return { ...state, is_authenticated: true, user_details: { ...state.user_details, ...action.payload } };
        }
        case LOGOUT_USER: {
            return { ...state, ...initialState };
        }
        case UPDATE_USER: {
            const newUserState = {...state.user_details, ...(action.payload) };
            localStorage.setItem('user', JSON.stringify({ ...newUserState }));
            return { ...state, user_details: newUserState };
        }
        case SET_ACTIVE: {
            localStorage.setItem('user', JSON.stringify({ ...state.user_details, is_active: action.payload }));
            return { ...state, user_details: { ...state.user_details, is_active: action.payload } };
        }
        case SET_REFERER: {
            return { ...state, referer: action.payload };
        }
        case CALL_REFERER: {
            return { ...state, is_referer: true }
        }
        default: {
            return state;
        }
    }
}
