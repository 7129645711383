import React, {Component} from 'react';
import styles from './TourCard.module.css'
import {FavoriteBorder as Favorite, Favorite as FavoriteFilled,Update as Duration} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';

class TourCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false
        };
        this._handleClick = this._handleClick.bind(this)

    }

    _handleClick(e) {
        e.stopPropagation();
        this.setState({
            is_filled: !this.state.is_filled
        })
    }

    _renderFavourite() {
        if (this.state.is_filled)
            return (
                <FavoriteFilled fontSize={'2rem'} className={styles.icons}/>
            );
        else
            return <Favorite fontSize={'2rem'} className={styles.fontIcon}/>

    }

    render(){
        return(
            <div className={styles.wrapper} >
                <div className={styles.cardContainer}>
                    <div className={styles.backgroundImage} style={{
                        backgroundImage: 'url(' + require('../../../assets/img/tuxi/tourA.png') + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition:'center'
                    }}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div></div>
                            <div className={styles.favorite} onClick={this._handleClick}>
                                {this._renderFavourite()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomText}>
                    <div>
                    <div className={styles.name}>
                       Eiffel Tower Guided Summit or<br/>
                        2nd Floor Direct Access Tour
                    </div>
                    <div className={styles.rating}>
                        <Rating className={styles.ratingColor} value={4} readOnly />
                    </div>
                        <div className={styles.duration}>
                            <Duration fontSize={'0.5rem'}/> <span className={styles.timePeriod}>Duration: 2 hours</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <img src={require('../../../assets/img/tuxi/ic_eco5.png')} className={styles.ecoIcon}/>
                        </div>
                        <div>
                            <div className={styles.fromText}>
                            From
                            </div>
                            <div className={styles.price}>
                            $40
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TourCard