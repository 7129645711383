import React, {Component} from 'react'
import classStyles from '../Detail/Description.module.css';
import styles from './Payment.module.css'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from 'moment';
import {Star, FavoriteBorder as Favorite, KeyboardArrowDown, Add, Remove} from '@material-ui/icons'
import PickupDialog from './PickpointDialog/PickupDialog.component';
import ButtonBase from '@material-ui/core/ButtonBase';
import RouteNames from '../../routes/Route.name';
import { actionCartUpdate } from '../../actions/Cart.action';


class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seats: 1,
            checked: false,
            selected_point: null,
            pickup_dialog: false,
            price: 0,
        }
        this._handleAddition = this._handleAddition.bind(this);
        this._handleSubtraction = this._handleSubtraction.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._handlePickup = this._handlePickup.bind(this);
    }

    componentDidMount() {
        const {selected_tour} = this.props;
        if (selected_tour) {
            this.props.actionCartUpdate({
                selected_point: selected_tour.routes.length > 0 ? selected_tour.routes[0] : null,
                price: selected_tour.price,
                tax: selected_tour.tax,
                seats:1
            })
        } else {
            this.props.history.push(RouteNames.index);
        }
    }

    _handleAddition() {
        const {selected_tour, cart} = this.props;
        const newSeat = cart.seats + 1;
        const tempPrice = selected_tour.price_type == 'PER_PERSON' ? (newSeat * selected_tour.price) : (selected_tour.price);
        if (selected_tour.vehicle.capacity >= newSeat) {
            this.props.actionCartUpdate({
                seats: newSeat,
                price: tempPrice
            })
        }
    }

    _handleSubtraction() {
        const {selected_tour, cart} = this.props;
        if (cart.seats > 1) {
            const newSeat = cart.seats - 1;
            const tempPrice = selected_tour.price_type == 'PER_PERSON' ? (newSeat * selected_tour.price) : (selected_tour.price);
            this.props.actionCartUpdate({
                seats: newSeat,
                price: tempPrice
            })
        }
    }

    _handleClick() {
        const { selected_tour } = this.props;
        this.props.actionCartUpdate({
            tour_id: selected_tour.id,
            tour_name: selected_tour.name,
        });
        this.props.history.push(RouteNames.cart)
    }

    _handlePickup(selectedPickupPoint = null) {
        if (selectedPickupPoint) {
            this.props.actionCartUpdate({
                selected_point: selectedPickupPoint,
            })
        }
        this.setState({
            pickup_dialog: !this.state.pickup_dialog,
        });
    }

    _renderLater() {
        if (this.props.board_type == 'board_later') {
            const { date, time } = this.props;
            const dateString = moment(new Date(date)).format('MMM, DD YYYY');
            return (
                <div className={styles.pickup} style={{marginBottom: '35px'}}>
                    <div>
                        <img src={require('../../assets/img/ic_time.png')} className={styles.image}/>
                    </div>
                    <div className={styles.main}>
                        <div style={{color: 'grey'}}>Date and Time</div>
                        <div style={{color: 'grey', fontWeight: '600'}}>{dateString} {time}</div>
                    </div>
                </div>
            )
        }

        return null;
    }

    render() {
        const {selected_tour, cart} = this.props;
        return (
            <div>
                <div style={{height: '65px'}}>
                </div>
                <div style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                    backgroundColor: '#d3eafc',
                    padding: '7px',
                    borderRadius: '5px'
                }}>
                    <div className={classStyles.innerFlex}>
                        <div className={classStyles.category}>{selected_tour.category}</div>
                        <div className={classStyles.icon}>
                            <Star fontSize={'small'} className={classStyles.fontSizeSmall}/>
                            <span>4.6</span>
                        </div>
                    </div>
                    <div className={classStyles.display}>
                        <h3 className={classStyles.title}>{selected_tour.name}</h3>
                    </div>
                    <small className={classStyles.desc}>
                        {selected_tour.overview}
                    </small>
                </div>
                <div style={{paddingTop: '15px'}}>
                    <hr/>
                </div>

                <br/>
                {this._renderLater()}
                <div className={styles.pickup}>

                    <div>
                        <img src={require('../../assets/img/ic_pickup.png')} className={styles.image}/>
                    </div>
                    <div className={styles.main}>
                        <span className={styles.heading}>Meetup Point</span>
                    </div>
                </div>

                <div className={styles.location}>
                    <div className={styles.title}>
                        {cart.selected_point ? cart.selected_point.name : 'N/A'}
                    </div>
                    <div style={{marginTop: '4px'}}>
                        <ButtonBase onClick={() => {
                            this._handlePickup();
                        }}>
                            <KeyboardArrowDown fontSize={'large'} className={styles.arrow}/>
                        </ButtonBase>
                    </div>
                </div>

                <div className={styles.scheduled}>
                    Pickup is on 11:00 PM
                </div>

                <br/>
                <br/>
                <div className={styles.pickup}>

                    <div>
                        <img src={require('../../assets/img/ic_seat@3x.png')} className={styles.image}/>
                    </div>
                    <div className={styles.main}>
                        <span className={styles.heading}>Select Number Of Seats</span>
                    </div>
                </div>

                <div className={styles.loc} style={{justifyContent: 'space-between'}}>

                    <ButtonBase onClick={this._handleSubtraction}>
                        <div className={styles.btn}>
                            <Remove/>
                        </div>
                    </ButtonBase>
                    <div className={styles.title}>
                        {cart.seats} {cart.seats == 1 ? 'Seat' : ' Seats'}
                    </div>
                    <ButtonBase onClick={this._handleAddition} style={this.state.checked ? {color: '#2196F3'} : null}>
                        <div className={styles.btn}>
                            <Add/>
                        </div>
                    </ButtonBase>
                </div>

                {/*<div className={styles.scheduled}>*/}
                {/*1 Left*/}
                {/*</div>*/}

                <div className={styles.bottomButton}>
                    <div style={{flex: '1'}}>
                        <ButtonBase classes={{root: styles.bookbtn}}>
                            <span style={{fontSize: '18px', color: 'black', fontWeight: '500'}}>
                                {cart.price}
                                <div style={{fontSize: '12px', color: 'lightgray', fontWeight: '600'}}></div>
                            </span>
                        </ButtonBase>
                    </div>

                    <div className={styles.innerFlex}>
                        <ButtonBase classes={{root: styles.bookbtns}} onClick={this._handleClick}>
                            <span style={{fontSize: '18px', color: 'white', fontWeight: '500'}}>Proceed</span>
                        </ButtonBase>
                    </div>
                </div>

                <PickupDialog
                    routes={selected_tour.routes}
                    open={this.state.pickup_dialog}
                    handleClick={this._handlePickup}
                />
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionCartUpdate: actionCartUpdate
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        board_type: state.common.board_type,
        selected_tour: state.tours.selected_tour,
        cart: state.cart,
        date: state.common.date,
        time: state.common.time,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Payment);

