/**
 * Created by charnjeetelectrovese@gmail.com on 10/11/2019.
 */
import React, {Component} from 'react';
import {ButtonBase} from '@material-ui/core';
import styles from './style.module.css';
import Slider from "rc-slider";
import EventEmitter from "../../../libs/Events.utils";
// import data from '../../../data';
import {getRandomSubarray} from "../../../libs/general.utils";

class Chip extends Component{
    constructor(props){
        super(props);
        this.state={
        };
        this._handleClick = this._handleClick.bind(this)
    }
    _handleClick(){
        this.props.handleClick(this.props.data.value);
    }

    render(){
        const { data } = this.props;
        return(
                <div className={styles.vehicles} style={this.state.checked ? {color: '#2196F3'}: {}} onClick={this._handleClick}>
                            <input type="checkbox" name={"v1"} value={"v1"} checked={this.props.checked} style={this.props.checked ? {color: '#2196F3'}: {}}  />
                            <span className={styles.cars}>
                                {data.title}
                        </span>
                </div>

        )
    }
}

class VehicleFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        // this.data = data.filters.vehicle.data;
        this._handleClick = this._handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    _handleClick (value) {
        const index  = this.state.data.indexOf(value);
        const tempVehicles = this.state.data;

        if (index >= 0) {
            tempVehicles.splice(index, 1);
        } else {
            tempVehicles.push(value);
        }

        this.setState({
            data: tempVehicles,
        });

    }
    _renderList() {
        const { list_data } = this.props;
        return list_data.slice(0, 5).map((val) => {
            const isThere =  this.state.data.indexOf(val.value) >= 0;
            return <Chip
                checked={isThere}
                data={val}
                handleClick={this._handleClick}
            />
        })
    }
    render() {
        return (
            <div>
                <div className={styles.outerFlex}>
                    <div className={styles.innFlex}>
                        <div style={{}}>
                            Select Vehicle Category
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <ButtonBase onClick={() => {  this.props.handleClear('type_category'); }}>
                          <span className={styles.rightBtn}>
                          Clear
                              </span>
                        </ButtonBase>
                        <div className={styles.rightBtn} style={{ padding: '5px 0px' }}>
                            |
                        </div>
                        <ButtonBase onClick={(e) => {
                            this.props.handleChange('type_category', this.state.data);
                        }}>
                          <span className={styles.rightBtn}>
                          Apply
                              </span>
                        </ButtonBase>
                    </div>
                </div>
                {/*Filter Content*/}
                <div className={styles.contentContainer}>
                {this._renderList()}
                </div>
            </div>
        );
    }
}

export default VehicleFilter;
