import React, {Component} from 'react';
import styles from './AttractionCard.module.css'

class AttractionCard extends Component{
    render(){
        return(
            <div>
            <div className={styles.cardContainer}>
                <div className={styles.backgroundImage} style={{
                    backgroundImage: 'url(' + require('../../../assets/img/tuxi/lisbon.png') + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center'
                }}>

                </div>
            </div>

                <div className={styles.bottomText}>
                    <div className={styles.name}>
                    Lisbon
                    </div>
                    <div className={styles.totalCount}>
                        1900 Tours and Activities
                    </div>
                </div>
            </div>
        )
    }
}

export default AttractionCard