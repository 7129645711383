/**
 * Created by charnjeetelectrovese@gmail.com on 1/29/2020.
 */
import {serviceApplyCoupon} from "../services/Common.service";
import store from '../store';
import {serviceBookTour} from "../services/Booking.service";
import history from '../libs/history.util';
import RouteNames from "../routes/Route.name";
import EventEmitter from "../libs/Events.utils";
import DateUtils from '../libs/DateUtils.lib';

export const CART_RESET = 'CART_RESET';
export const CART_UPDATE = 'CART_UPDATE';
export const APPLY_COUPON = 'APPLY_COUPON';
export const APPLYING_COUPON = 'APPLYING_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const CHECKOUT_INIT = 'CHECKOUT_INIT';
export const CHECKOUT_DONE = 'CHECKOUT_DONE';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';

export function actionCartUpdate(params) {
    return (dispatch) => {
        dispatch({ type: REMOVE_COUPON, payload: null });
        dispatch({ type: CART_UPDATE, payload: params });
    };
}



export function actionCartReset(data) {
    return (dispatch) => {
        dispatch({ type: CART_RESET, payload: data });

    }
}

export function actionApplyCoupon(couponName) {
    const tempState = store.getState().cart;
    const request = serviceApplyCoupon({
        coupon: couponName,
        tour_id: tempState.tour_id,
        amount: tempState.price,
    });
    return (dispatch) => {
        dispatch({ type: APPLYING_COUPON, payload: null });
        request.then((data) => {
            if (!data.error) {
                dispatch({ type: APPLY_COUPON, payload: data.data });
            } else {
                EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                    error: 'Invalid Coupon',
                    type: 'error'
                });
                dispatch({ type: REMOVE_COUPON, payload: null });
            }
        })

    }
}

export function actionRemoveCoupon() {
    return (dispatch) => {
        dispatch({ type: REMOVE_COUPON, payload: null });
    }
}


export function actionCheckout() {
    const tempState = store.getState().cart;
    const commonParams = store.getState().common
    let seats = 0;
    tempState.passengerPrices.forEach((val) => {
        seats += val.number;
    });
    const req = serviceBookTour({
        tour_id: tempState.tour_id,
        // selected_point: tempState.selected_point,
        selected_point: tempState.selected_point,
        seats: seats,
        passengers: tempState.passengerPrices,
        coupon_name: tempState.coupon.name ? tempState.coupon.name : null,
        date: tempState.selectedDate,
        tour_date: tempState.tour_date,
        time: tempState.time_slot,
        language: tempState.language,
        user_timezone: DateUtils.getLocalTimeZone(),
    });
    return (dispatch) => {
        dispatch({ type: CHECKOUT_INIT, payload: null });
        req.then((res) => {
            if (!res.error) {
                const data = res.data;
                dispatch({ type: CHECKOUT_DONE, payload: data });
                history.push(RouteNames.thank_you);
            } else {
                dispatch({ type: CHECKOUT_ERROR, payload: res.message });
                EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                    error: res.message,
                    type: 'error'
                });
            }
        })
    }
}
