/**
 * Created by charnjeetelectrovese@gmail.com on 9/28/2017.
 */
export function roundUnit(unit) {
    if(unit >= 1000) {
        return `${unit / 1000} Kg`;
    } else {
        return `${unit} Gm`;
    }
}

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export function getRandomSubarray(arr, size) {
    var shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
}

export function convertDurationToString(duration) {
    if (duration >= 60) {
        const hours = (duration / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        if (rminutes == 0) {
            return `${rhours} hrs`
        } else {
            return `${rhours}:${rminutes} hrs`
        }
    } return `${duration} min`;
}

// export const updateTitle = (title) => {
//     document.title = Constants.APP_NAME + ' - ' + title;
// }

