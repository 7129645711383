import React, {Component} from 'react';
import styles from './Privacy.module.css';

class Terms extends Component {
    render() {
        return (
            <div className={'container'}>
                <div style={{marginTop:'70px'}}></div>
                <div><h1>General Terms and Conditions</h1></div>
                <hr/>
                <p className={styles.description}>
                    These General Terms and Conditions are subdivided into:
                    I. Lets Tuxi Terms of Use

                    II. General Terms and Conditions of Lets Tuxi Deutschland GmbH, Berlin (intermediary)

                    III. General Terms and Conditions of the Supplier of tours and other tourist services (referred company)

                    Within the scope of an intermediary service, Lets Tuxi Deutschland GmbH shall provide the visitors and Suppliers of tours or tourist services with this booking Platform.
                    Furthermore, Lets Tuxi Deutschland GmbH shall also provide this booking Platform to certain cooperating partners (sub-agent/ distribution partner) for the booking of the Supplier’s tourist services. The use of this Platform by sub-agents or distribution partner requires a separate contractual agreement and is not permitted without the prior written authorization of Lets Tuxi Deutschland GmbH.
                    Contracts for tourist services are to be concluded directly between the users of this booking Platform and the Suppliers of tours or other tourist services. If users book services via a linked partner Platform or if a sub-intermediary makes a booking on behalf of a user of a connected partner Platform, a corresponding contract shall be concluded directly between this user and the Supplier. Lets Tuxi Deutschland GmbH is not a contract party with regards to the tourist services offered on this booking Platform.
                    I. Lets Tuxi Terms of Use

                </p>
                <h1>I. Lets Tuxi Terms of Use</h1>

                <h2>1. Who operates this website?</h2>
                <hr/>
                <p className={styles.description}>
                    This website (including sub-sites and including text, images, videos, software, products, services and other information contained in or presented on the website; all together the "Website") is provided by Lets Tuxi Deutschland GmbH, Sonnenburger Strasse 73, 10437 Berlin, Germany. You can contact us by email (https://www.Lets Tuxi.com/contact) or by phone using these numbers:
                    USA: +1 844-326-5840
                    Canada: +1 844-326-5840
                    Australia: +61 2 4708 9546
                    UK: +44 (0) 20 3962 0237
                    Germany: +49 (0) 30 56839445
                    Switzerland: +41 (0) 43 505 14 95
                    Italy: +39 (0) 6 9480 0677
                    Spain: +34 911 23 56 12
                    France: +33 (0) 1 75 85 97 22
                    International: +49 (0) 30 56839445
                    Bookings through the Platform are subject to the General Terms and Conditions of Lets Tuxi Deutschland GmbH as well as the General Terms and Conditions of the Supplier of tours and other tourist.
                </p>

                <h2>2. Application of these Terms of Use</h2>
                <hr/>
                <p className={styles.description}>
                    These terms of use (the "Terms of Use"), together with our privacy policy (the "Privacy Policy"), apply to any use of the Website. Visitors of the Website ("User" or "you") may use the Website only on condition that they accept the Terms of Use and read and understand the Privacy Policy. Any further use of the Website or any part of it means you have read and understood the Terms of Use and the Privacy Policy, and agree to be bound by all parts of the Terms of Use.
                </p>

                <h2>3. No offer</h2>
                <hr/>
                <p className={styles.description}>
                    The information on this Website is for general informational purposes only. Information on this Website does not constitute an offer binding to us. Binding agreements with suppliers of activities available on the Website require a booking request through the Lets Tuxi Platform and the supplier's acceptance of the booking request according to the General Terms and Conditions of Lets Tuxi Deutschland GmbH.
                </p>

                <h2>4. No warranty</h2>
                <hr/>
                <p className={styles.description}>
                    While Lets Tuxi Deutschland GmbH tries to ensure that the information in or through the Website is accurate, it does not provide any warranties, express or implied, in relation to its correctness, completeness, current, reliability, suitability for any purpose or otherwise (including for any information provided by third parties). Lets Tuxi Deutschland GmbH may change, add or remove information on the Website and its structure and functions at any time at its sole discretion, without specifically informing of any such change, and without removing outdated information or characterizing it as such. Lets Tuxi Deutschland GmbH may also block Users from accessing the Website or parts of it, or requires certain conditions to be fulfilled for such access. Lets Tuxi Deutschland GmbH does not provide any warranties, express or implied, in relation to the availability of the Website or its functions, that the Website is free from defects, or that the Website and the infrastructure on which it runs is free from viruses and other harmful software. Moreover, Lets Tuxi Deutschland GmbH does not guarantee that information available on the Website has not been altered through technical defects or by unauthorized third parties.
                </p>

                <h2>5. Limitation of liability</h2>
                <hr/>
                <p className={styles.description}>
                    Lets Tuxi Deutschland GmbH excludes its liability, and that of its agents and independent contractors, and its and their employees and officers, and its sub-agents or distribution partners for damages relating to your access to (or inability to access) the Website, or to any errors or omissions, or the results obtained from the use, of the Website, whatever the legal basis of such liability would be, except liability for damages caused willfully or through gross negligence, and only to the extent permitted by applicable law. Restrictions of liability do not apply within the scope of guarantees issued, in the event of an injury to life, limb or health or for claims based on product liability regulation.
                </p>

                <h2>6. Third party content, links to other websites</h2>
                <hr/>
                <p className={styles.description}>
                    Lets Tuxi Deutschland GmbH does not assume any responsibility for third party content (including any activities available for booking requests, and information relating to such activities) that may be available through the Website, and for content linked to the Website or which are linked to from it or referred to. Lets Tuxi Deutschland GmbH does not recommend or endorse such content, and will not have any liability relating to it. Where Lets Tuxi Deutschland GmbH links to third party websites, you use such websites at your own risk. We recommend reading the policies of these websites and review how these websites may process personally identifiable data relating to you. If you think we have illicit content on the Website, please send an email to support@Lets Tuxi.com
                </p>

                <h2>7. Limited Lets Tuxi App license</h2>
                <hr/>
                <p className={styles.description}>
                    Lets Tuxi Deutschland GmbH hereby grants to you a non-exclusive, non-transferable, non-sublicensable, revocable, royalty-free, worldwide right to use the Lets Tuxi App according to these Terms of Use and provided that you are and will always be in compliance with these Terms of Use. You may (i) only use the Lets Tuxi App in object code form and for your personal purposes (if you are a consumer) or for your internal business purposes (if you are a business); (ii) only use such number of copies of the Lets Tuxi App and make such number of backup copies of the Lets Tuxi App as may be necessary for its lawful use; (iii) not nor permit any third party to copy, adapt, reverse engineer, decompile, disassemble, modify, adapt or make error corrections to the Lets Tuxi App in whole or in part; (iv) not rent, lease, sub-license, loan, translate, merge, adapt or modify the Lets Tuxi App or any associated documentation; (v) not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the Lets Tuxi App nor attempt to do any such things.
                </p>

                <h2>8. Intellectual property rights</h2>
                <hr/>
                <p className={styles.description}>
                    As between you and Lets Tuxi Deutschland GmbH, the Website is and remains protected by copyright and/or any other intellectual property rights (including protection granted through unfair competition law). You acquire no rights in the Website, and in any names, trade names, and distinctive signs of any nature (including trademarks) published on the Website. You may access and view the Website, but not incorporate it into other websites, and not copy, present, license, publish, download, upload, send or make it perceptible in any other way without our prior written consent.
                </p>

                <h2>9. Amendments to the Terms of Usee</h2>
                <hr/>
                <p className={styles.description}>
                    Lets Tuxi Deutschland GmbH may amend these Terms of Use at any time and with immediate effect. If we make amendments, they apply as of the date of their publication on the Website. Lets Tuxi Deutschland GmbH expects you to regularly refer to this section to make sure you are familiar with the applicable Terms of Use. Any further use of the Website following such amendments means you consent to the amendment
                </p>

                <h2>10. Applicable law and jurisdiction</h2>
                <hr/>
                <p className={styles.description}>
                    The law of the Federal Republic of Germany shall apply, excluding the UN Convention on Contracts for the International Sale of Goods. If you have placed the order, as the consumer, and at the time of the order your normal place of residence is in another country, the application of the mandatory legal provisions of this country remain unaffected by the choice of law in clause 1. The exclusive place of jurisdiction is Berlin, provided the user is a tradesperson as per the German Commercial Code or does not have a permanent place of residence in Germany when the suit is filed. The legally-binding places of jurisdiction remain unaffected by this.
                </p>

                <h1>II. Lets Tuxi Deutschland GmbH – User Terms and Conditions for the online offer of Lets Tuxi Platform</h1>
                <h2>1. Introduction</h2>
                <hr/>
                <p className={styles.description}>
                    1.1 Lets Tuxi Deutschland GmbH, Sonnenburger Strasse 73, 10437 Berlin, Germany runs an intermediary Platform for tourist offers in the form of an online offer via the internet and apps (hereinafter jointly referred to as the “Lets Tuxi Platform”). On the Lets Tuxi Platform users have the chance to find and book tours, activities and experiences all over the world. The online offer comprises guided tours, cooking courses, sightseeing tours by bus, river barge trips, tickets for entry to sightseeing locations and other services. The offers are placed online by a range of local Suppliers around the world (hereinafter referred to as “Suppliers”) with whom the respective contract for tourist services is concluded (“service agreement”). The Suppliers’ services can be accessed via the Lets Tuxi Platform and bookings can be made.
                    1.2 Lets Tuxi Deutschland GmbH approaches both, consumers and companies. For the purposes of these General Terms and Conditions, the following applies:
                    a. A “consumer” is any individual person who has concluded a contract for purposes which cannot be predominantly assigned to commercial or independent work activities (Section 13 German Civil Code – “BGB”).
                    b. A “company” is a natural person or legal entity, or a partnership, which is performing its commercial or independent activities by concluding this contract (Section 14, paragraph 1, BGB).
                    c. The “user” denotes a natural person, unless this person has been explicitly registered with Lets Tuxi Deutschland GmbH as a legal entity. The actions and omissions that take place during the registration of a legal entity are allocated to a natural individual, unless they are carried out within the framework of their power of attorney for the legal entity. The “user” subsequently also includes users referred by sub-agent or distribution partner
                </p>

                <h2>2. Subject matter of part II of these General Terms and Conditions</h2>
                <hr/>
                <p className={styles.description}>
                    These General Terms and Conditions apply to any use of the Lets Tuxi Platform, such as via the internet and apps. The contractual relationship between the user and Lets Tuxi Deutschland GmbH generally only comprises the proper referral of users to the respective Supplier, or the transfer of data via users who are provided by a sub-agent . Lets Tuxi Deutschland GmbH does not appear itself as the organizer, lessor, seller or other contract partner with regards to the tourist service agreement with the user. Consequently, part II of the General Terms and Conditions of Lets Tuxi Deutschland GmbH shall apply to offers on the Lets Tuxi Platform and the provision of users to the Supplier. Part II of the General Terms and Conditions applies to the use of Lets Tuxi Platform by cooperating partners (sub-agents or distribution partners), subject to special contractual agreements concluded with the respective cooperating partner. The contractual conditions of the Supplier, as per part III of these General Terms and Conditions, apply to the respective tourist service agreement between the user and the Supplier
                </p>

                <h2>3. Registration</h2>
                <hr/>
                <p className={styles.description}>
                    3.1 The use of Lets Tuxi Platform’s offer can generally be done anonymously.
                    3.2 Certain types of use of Lets Tuxi Platform, such as the making of bookings, require registration. During registration, the user sends an electronic registration form and consents to the General Terms and Conditions. The registration with Lets Tuxi Deutschland GmbH is only concluded once a confirmation is sent to the e-mail address specified by the user. Natural individuals must be over the age of 18 in order to register. The user has to keep the password he/she sets secret and take suitable precautions to prevent third parties becoming aware of it.
                    3.3 The creation of more than one user account for the same natural individual or legal entity is not permitted. The user account cannot be transferred.
                </p>

                <h2>4. Services of Lets Tuxi Deutschland GmbH/Conclusion of contract</h2>
                <hr/>
                <p className={styles.description}>
                    4.1 Once the user has entered his/her desired service on the Lets Tuxi Platform (e.g. travel destination, type of tour, start time, number of participants & price options), Lets Tuxi Deutschland GmbH shall show the user the information about the Suppliers’ services (“service information“). Based on this information, the user can, where applicable after checking availability, make a contractual offer, by placing an offer, to be sent to the respective Supplier, whereby the sub-intermediates act on behalf of the referred user. This happens, after the selection and transfer to the shopping basket, by clicking on the button “confirm and book”.
                    4.2 The user is bound to his/her binding offer for five working days. For more information see Section 5 of the General Terms and Conditions of the Supplier of tours and other tourist services in Part III.
                    4.3 Lets Tuxi Deutschland GmbH shall notify the user of the conditions of transport and business of the Supplier for their contractual relationship with the user and for their services. They can be found in the respective tenders. The user is responsible for meeting and complying with these conditions. The Supplier reserves the right to not allow the user to undertake an action, or to exclude it, if they do not meet the conditions. In this case the paid price shall not be refunded.
                    4.4 Lets Tuxi Deutschland GmbH shall provide the user with a booking confirmation issued in the name of, and on behalf of, the Supplier, as well as a payment confirmation. The use of the Lets Tuxi Platform itself is essentially free of charge for the user. The costs for the technical access to the Lets Tuxi Platform (e.g. internet access) are to be borne by the user. Lets Tuxi Deutschland GmbH is permitted to collect the invoiced amounts in the name of, and behalf of the Supplier.
                    4.5 Lets Tuxi Deutschland GmbH shall forward the user any data for the use of a Supplier’s service according to the applicable conditions (such as ticket data), once they have been received by the Supplier.
                    4.6 Lets Tuxi Deutschland GmbH assumes no guarantee for the accuracy of forwarded data, or for the performance of services by the Supplier, as all the information indicated and forwarded is based on data from Suppliers or third parties, which Lets Tuxi Deutschland GmbH cannot check in detail.
                </p>

                <h2>5. Payments on Lets Tuxi</h2>
                <hr/>
                <p className={styles.description}>
                    5.1 The service agreement concluded with the Supplier shall apply to the fees to be paid by the user for the services of the Supplier.
                    5.2 Lets Tuxi Deutschland GmbH is permitted to collect the invoiced amounts in the name of and on behalf of the Supplier, provided nothing else has been explicitly stated in the Supplier’s invoice. If claims have to be paid by the user in a different currency than its national currency (claims for payment in foreign currencies), Lets Tuxi Deutschland GmbH can demand payment in the national currency of the user and the foreign currency claim can be converted based on the current exchange rate at the time the contract is concluded. Lets Tuxi Deutschland GmbH can charge the user a suitable conversion charge for this.
                    5.3 The contact partner, and contract partner, of the user in connection with the service agreement and its payment, is the respective Supplier. The user can only assert the repayment of a payment to the respective Supplier. A refund granted by the Supplier can also be processed by the Supplier via Lets Tuxi Deutschland GmbH. To simplify the process for the user, communication via Lets Tuxi Platform is recommended.
                    5.4 To use the payment functions of Lets Tuxi Deutschland GmbH, the user has to register. The user has to enter correct payment information and update the details immediately in the event of changes. Lets Tuxi Deutschland GmbH can reject the payment method specified by the user. The user will be notified of the payment methods permitted for the respective service during the order process.
                    5.5 Upon the authorization of the payment, the user consents to his/her payment information being used for the collection of payments by the creditor. Lets Tuxi Deutschland GmbH reserves the right to make the use of the payment function of Lets Tuxi Deutschland GmbH dependent on a check of the credit rating of the user.
                    5.6 If the user uses a credit card issued in the US for the payment transaction, the payment is processed by Lets Tuxi Operations Inc., a subsidiary of Lets Tuxi Deutschland GmbH. US users can get in touch with the Lets Tuxi customer support for the US as set out in the contact area of the Lets Tuxi website.
                    5.7 If the user uses a credit card issued in Australia for the payment transaction, the payment is processed by Lets Tuxi Australia Pty. Ltd., a subsidiary of Lets Tuxi Deutschland GmbH. Australian users can get in touch with the Lets Tuxi customer support for Australia as set out in the contact area of the Lets Tuxi website.
                </p>

                <h2>6. Pricing of Lets Tuxi Deutschland GmbH</h2>
                <hr/>
                <p className={styles.description}>
                    6.1 All prices on Lets Tuxi are quoted per person and include VAT and all other taxes. Local taxes may be charged on site.
                    6.2 These prices specified by the Suppliers may be subjected to special conditions, for example with regards to cancellation and the refunding of payments made. Before making the booking, please check precisely whether the respective service agreement is subject to separate conditions.
                </p>

                <h2>7. The best price guarantee of Lets Tuxi Deutschland GmbH</h2>
                <hr/>
                <p className={styles.description}>
                    7.1 Lets Tuxi Deutschland GmbH would like the user to pay the lowest possible price for the respective service. If the users find the offer they have booked via Lets Tuxi Deutschland GmbH with the same conditions (town/city, number of people, availability) and at a lower price online, Lets Tuxi Deutschland GmbH shall pay the user, on a voluntary basis, the difference between the price paid and the lower price found on the internet.
                    7.2 All special offers and promotional campaigns are marked as such.
                </p>

                <h2>8. Duties and obligations of the user</h2>
                <hr/>
                <p className={styles.description}>
                    8.1 The user shall keep the registration data (user login and password) secret and not allow third parties access to Lets Tuxi Portal using his/her registration data. The user shall be accountable for all use of his/her user account on Lets Tuxi Portal.
                    8.2 After receiving the service information, the user can send any orders to Lets Tuxi Deutschland GmbH for forwarding to the Supplier.
                    8.3 The user shall exempt Lets Tuxi Deutschland GmbH from third-party claims based on his/her use of the Lets Tuxi Platform, unless they are the fault of Lets Tuxi Deutschland GmbH.
                </p>

                <h2>9. Availability and warranty</h2>
                <hr/>
                <p className={styles.description}>
                    9.1 There is no claim for availability, quality or service features, or technical support for the Lets Tuxi Platform. Lets Tuxi Deutschland GmbH can redesign, reduce or suspend their online portal Lets Tuxi at any time, at its discretion. Existing agreements of the user with a Supplier, as well as the execution of these agreements, remain unaffected by these changes.
                    9.2 Lets Tuxi Deutschland GmbH makes no guarantee or warranty for the accuracy or completeness of data provided by third parties (such as Suppliers).
                    9.3 Lets Tuxi Deutschland GmbH makes no guarantee or warranty for the services provided by the Suppliers. The contact partner of the user in the event of questions and claims in connection with a service agreement and its execution is the respective Supplier.
                    9.4 Provided Lets Tuxi Deutschland GmbH does not have any obligation to the user, Lets Tuxi Deutschland GmbH also provides no guarantee
                </p>

                <h2>10. Liability of Lets Tuxi Deutschland GmbH</h2>
                <hr/>
                <p className={styles.description}>
                    10.1 If Lets Tuxi Deutschland GmbH has not assumed a corresponding contractual obligation by means of an explicit agreement with the user, it is not liable for the realization of corresponding agreements with Suppliers in line with the booking request of the user.
                    10.2 Without explicit agreement or an assurance of this kind, Lets Tuxi Deutschland GmbH is not liable for defects in the performance of the service and personal or material damage incurred by the user in connection with the travel service provided, concerning the services provided. If several tourist services are provided (in accordance with the legal term “package holidays”) this does not apply, unless Lets Tuxi Deutschland GmbH gives the impression, as per Section 651a, paragraph 2, BGB that it performs the intended travel services under its own responsibility.
                    10.3 Any liability of Lets Tuxi Deutschland GmbH due to the culpable violation of obligations in the brokering of contracts remains unaffected by the aforementioned conditions.
                    10.4 The liability of Lets Tuxi Deutschland GmbH for contractual claims of the user is limited to three-times the price of the tourist services procured, except for

                    any violation of a key obligation, which needs to be fulfilled in order to allow the proper execution of the brokering agreement or the violation of which endangers the fulfilment of the contractual purpose

                    liability for damage incurred by the user due to the injury to life, limb or health, which is based on a negligent breach of duty by Lets Tuxi Deutschland GmbH or a vicarious agent of Lets Tuxi Deutschland GmbH

                    liability of Lets Tuxi Deutschland GmbH for other damage incurred by the user due to a grossly-negligent breach of duty by Lets Tuxi Deutschland GmbH or a willful or grossly-negligent breach of duty by a legal representative or vicarious agent of Lets Tuxi Deutschland GmbH.


                    10.5 For ordinary negligence, Lets Tuxi Deutschland GmbH is only liable for the violation of a key contractual obligation and only for foreseeable and typical damage. Key contractual obligations are obligations which need to be fulfilled to make the proper execution of the contract possible and whereby the user can regularly rely on them being met.
                    10.6 Restrictions of liability do not apply within the scope of guarantees issued, in the event of an injury to life, limb or health or for claims based on the product liability law.
                </p>

                <h2>11. Termination</h2>
                <hr/>
                <p className={styles.description}>
                    Users can cancel their registration on Lets Tuxi Portal at any time by blocking their user account. Lets Tuxi Deutschland GmbH can cancel a registration unilaterally with one week’s notice. Claims which have arisen before this is done, remain unaffected. The right to extraordinary cancellation remains unaffected.
                </p>

                <h2>12. Evaluation function of Lets Tuxi Deutschland GmbH</h2>
                <hr/>
                <p className={styles.description}>
                    12.1 Users have the opportunity to have personal influence on the content of Lets Tuxi Platform by writing travel reports in the form of evaluations, or uploading pictures (“user content”). Users are fully responsible for the user content they provide. They ensure that the content is correct and guarantee that the content does not contain any misleading or illegal statements or details. Furthermore, the users guarantee that the content does not violate third-party rights. Under no circumstance and in no way does Lets Tuxi Deutschland GmbH make user content its own, it merely provides a Platform.
                    12.2 Lets Tuxi Deutschland GmbH can use the user content in various ways. This includes displaying it on the website, reformatting, editing for more clarity or better grammar, incorporation into adverts or other work.
                    12.3 Lets Tuxi Deutschland GmbH can remove or report user content where necessary and at its own discretion. For example, Lets Tuxi Deutschland GmbH can remove user content if it violates the Lets Tuxi Deutschland GmbH principles for content, in the opinion of Lets Tuxi Deutschland GmbH. Lets Tuxi Deutschland GmbH is not obliged to store copies of user content or provide copies thereof. Lets Tuxi Deutschland GmbH does not guarantee the confidentiality of user content.
                    12.4 Lets Tuxi Deutschland GmbH and its distribution partners or sub-agents can display adverts and other information together or next to the user content on the website and other media. Users have no claim to remuneration for these adverts. Subject to changes regarding the type and scope of these advertising measures. The user does not need to be especially notified of this.
                    12.5 The users shall fully indemnify Lets Tuxi Deutschland GmbH and its distribution partner or sub-agents from all third-party claims (incl. suitable costs for legal prosecution and defense) at first request, which these third parties assert against Lets Tuxi Deutschland GmbH concerning the user content provided by the user. This also applies if the contested content can no longer be accessed on Lets Tuxi Platform. This does not apply if Lets Tuxi Deutschland GmbH is responsible for the legal violation. In the event of recourse by third parties, users have to immediately, truthfully and completely provide Lets Tuxi Deutschland GmbH with all the information necessary to verify the claims and defend them.
                </p>


                <h2>13. Data protection</h2>
                <hr/>
                <p className={styles.description}>
                    13.1 Lets Tuxi Deutschland GmbH collects and uses personal data of users to the extent that is necessary for the creation, design of content or modification of the contractual conditions for Lets Tuxi Deutschland GmbH between the user and Lets Tuxi Deutschland GmbH.
                    13.2 If Lets Tuxi Deutschland GmbH is involved in the communication for a service agreement between the user and the respective Supplier, it shall transfer the data required for this agreement to the respective Supplier. This Supplier processes and uses the data to initiate, conclude and execute the contract on its own responsibility. The identity of the respective Supplier can be taken from the booking dialogue.
                    13.3 Further information can be found in the data protection conditions of Lets Tuxi Deutschland GmbH at https://www.Lets Tuxi.com/privacy_policy.
                </p>


                <h2>14. Changes to these General Terms and Conditions</h2>
                <hr/>
                <p className={styles.description}>
                    14.1 Lets Tuxi Deutschland GmbH reserves the right to change these General Terms and Conditions at any time and without stating the reasons. Lets Tuxi Deutschland GmbH will only make changes affecting the user, which the user must agree to in consideration of mutual interests. This concerns, for example, cases where the equivalence principle has been disturbed, as well as loopholes and changes in legislation. The amended conditions will be sent to the users by e-mail at the latest two weeks before they come into effect. If a user does not reject the validity of the new General Terms and Conditions within two weeks of the receipt of the e-mail, the amended General Terms and Conditions are deemed to have been accepted. Lets Tuxi Deutschland GmbH will notify the user, in the e-mail containing the amended conditions, of the importance of this two-week deadline.
                    14.2 These Terms and Conditions can be amended at any time and without notice for future intermediation of contracts with Supplier. The respective conditions valid for each individual contract conclusion shall apply
                </p>


                <h2>15. Other provisions</h2>
                <hr/>
                <p className={styles.description}>
                    15.1 These General Terms and Conditions represent the whole agreement between Lets Tuxi Deutschland GmbH and the user. No additional agreements exist.
                    15.2 The law of the Federal Republic of Germany shall apply, excluding the UN Convention on Contracts for the International Sale of Goods. If you have placed the order, as the consumer, and at the time of the order your normal place of residence is in another country, the application of the mandatory legal provisions of this country remain unaffected by the choice of law in clause 1. The exclusive place of jurisdiction is Berlin, provided the user is a tradesperson as per the German Commercial Code or does not have a permanent place of residence in Germany when the suit is filed. The legally-binding places of jurisdiction remain unaffected by this.
                    15.3 If provisions of these General Terms and Conditions are or become invalid, this shall not affect the validity of the rest of the provisions.
                    Lets Tuxi Deutschland GmbH
                    Sonnenburger Strasse 73
                    10437 Berlin
                    Germany
                </p>

                <h1>III. General Terms and Conditions of the Supplier of tours and other tourist services</h1>
                <h2>1. Introduction</h2>
                <hr/>
                <p className={styles.description}>
                    Part III of these General Terms and Conditions applies to all contracts concluded between the Suppliers and the users via the Lets Tuxi Platform. This also applies, if access to the Lets Tuxi Platform is provided via a cooperating partner (sub- agent, distribution partner).
                </p>

                <h2>2. Truthful details</h2>
                <hr/>
                <p className={styles.description}>
                    The details transferred via the Lets Tuxi Platform must be truthful. The Supplier reserves the right to withdraw from the contract or to exclude users from participating in the service and to demand compensation amounting to the cancellation costs as per Section 11 of part III of the General Terms and Conditions of Supplier of tours and other tourist services, if defective details are provided by the user.
                </p>


                <h2>3. Arrival at meeting point and compliance with conditions</h2>
                <hr/>
                <p className={styles.description}>
                    You are responsible for arriving on time at the notified meeting point. If you are travelling to an activity from abroad, you are responsible for having the necessary travel documents (passport etc.), and for observing the health regulations etc
                </p>


                <h2>4. Additional terms and conditions</h2>
                <hr/>
                <p className={styles.description}>
                    If Supplier includes in addition to these General Terms and Conditions of Supplier of tours and other tourist services of Part III further participation or transport or other terms in a contract with you, you are responsible for observing these additional terms. The Supplier reserves the right to exclude participants from the activity if they do not meet these conditions.
                </p>


                <h2>5. Binding nature of the request</h2>
                <hr/>
                <p className={styles.description}>
                    The contract between you or the user you have referred, and the Supplier comes into effect upon the acceptance of your binding request by the Supplier. When you book a tour or another activity, you make the Supplier a binding offer which you, or the user you have referred, are bound to for five working days. If the Supplier accepts your offer within this period of time, the contract comes into binding effect. You, or the user referred by you, receives notification of this by e-mail.
                </p>

                <h2>6. Payment</h2>
                <hr/>
                <p className={styles.description}>
                    The agreed total price for the service we have provided is due upon the conclusion of the contract.
                </p>

                <h2>7. Notes on the right of revocation</h2>
                <hr/>
                <p className={styles.description}>
                    The Supplier points out that according to legal provisions (section 312, paragraph (2), no. 4, 312g, paragraph 2, clause 1, no. 9, BGB), contracts for tourism services which are concluded as remote selling (i.e. via the Lets Tuxi Platform) do not have a right of revocation, but merely the legal rights to withdraw and cancel the contract.
                </p>

                <h2>8. Self-responsibility for insurance</h2>
                <hr/>
                <p className={styles.description}>
                    The price of the services does not include insurance. You are responsible for providing sufficient insurance protection. The requirement for insurance depends on the activity booked.
                </p>

                <h2>9. Unpaid services</h2>
                <hr/>
                <p className={styles.description}>
                    If you or the user you have referred do not pay for the booked service according to the agreed due date of payment, although the Supplier is prepared, and able, to perform the contractual service and there is no legal or contractual right of retention for you or the user you have provided, the Supplier is permitted to withdraw from the contract and charge you, or the user you have referred, for the withdrawal costs as per point 11.
                </p>

                <h2>10. Exchange of messages</h2>
                <hr/>
                <p className={styles.description}>
                    All messages between you, or the user you have referred, and the Supplier shall be done via the Lets Tuxi Platform. In the interest of quick processing, it is recommended to exclusively use the forms provided by Lets Tuxi Deutschland GmbH.
                </p>

                <h2>11. Cancellation guidelines</h2>
                <hr/>
                <p className={styles.description}>
                    1.1 If you reject (cancel) the activity, the cancellation conditions stated in the product description as well as on your voucher apply. Lets Tuxi Deutschland GmbH advises the user to carefully read the information in the product description.
                    11.2 Provided there are no deviating cancellation conditions in the product description of your Supplier, the following cancellation fees will be charged by the Supplier of the tourist services:
                    a. Up to 24 hours before the start of the activity: full refund
                    b. Less than 24 hours before the start of the activity or in the event of a no-show: no refund
                    11.3 You, or the user you have referred, are free to prove to the Supplier that they have not incurred any damage, or substantially less damage than the fee charged by the Supplier.
                    11.4 The Supplier reserves the right to request higher, specific compensation instead of the aforementioned flat-rate fees, if the Supplier can prove that it has incurred much higher expenses than the respectively applicable flat-rate fee. In this case the Supplier is obliged to provide specific figures and evidence of the compensation requested, taking into account the saved expenses and any other use of the services.
                    11.5 The refund is done using the same payment method. In the event of credit cards which are charged monthly, the amount shall be credited at the end of the current invoicing period. The exact time of the refund depends on the user’s credit card agreement. The refund by bank transfer shall be done within seven bank working days
                </p>

                <h2>12. Extraordinary cancellation</h2>
                <hr/>
                <p className={styles.description}>
                    The Supplier can cancel the activity on the agreed date without observing a period of notice, if weather conditions, official measures, strikes or other unforeseeable or unavoidable external conditions (in particular force majeure) make the execution of the activity impossible, make it considerably more difficult or endanger it. In this case the paid price is refunded.
                </p>

                <h2>13. Exclusion of participation</h2>
                <hr/>
                <p className={styles.description}>
                    The Supplier is permitted not to allow you to join an activity, or to exclude you from one, if you do not meet the personal participation requirements, your participation would endanger you or someone else or in any other way make the activity impossible in the long-term. This applies accordingly to a user you have referred. In these cases, the paid price cannot be refunded.
                </p>

                <h2>14. Changes to the program</h2>
                <hr/>
                <p className={styles.description}>
                    Furthermore, the Supplier reserves the right to make non-essential changes to the program, if this is necessary due to unforeseeable or unavoidable conditions.
                </p>


                <h2>15. Further conditions</h2>
                <hr/>
                <p className={styles.description}>
                    Further conditions or deviating conditions can be found in the respective tenders.
                </p>


                <h2>16. Time zone</h2>
                <hr/>
                <p className={styles.description}>
                    For calculations of the time and deadlines, the time zone of the Supplier is authoritative.
                </p>

                <h2>17. Liability of the Supplier of the tour or the tourism service</h2>
                <hr/>
                <p className={styles.description}>
                    The Supplier is liable in accordance with the applicable law for its services, whereby the liability, if permitted according to the applicable law, is excluded.
                </p>


                <h2>18. Change to these General Terms and Conditionse</h2>
                <hr/>
                <p className={styles.description}>
                    These Terms and Conditions of the Supplier of tours and other tourist services of Part III can be amended at any time and without notice for future bookings. The respective conditions valid for each individual booking shall apply. The user has no claim for future bookings based on the existing conditions..
                </p>

            </div>
        )
    }
}

export default Terms