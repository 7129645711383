import React,{Component} from 'react'
import styles from './Rfq.module.css';
import {ButtonBase} from '@material-ui/core'

class Rfq extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div className={styles.marketbg}>
                <h2>Global Sourcing Marketplace</h2>
                <div className={styles.avgflex}>
                    <div className={styles.supply}>
                        <div className={styles.numbers}>1668000+</div>
                        <div className={styles.title}>RFQs</div>
                    </div>
                    <div className={styles.supply}>
                        <div className={styles.numbers}>{'<21h'}</div>
                        <div className={styles.title}>Avg Quotation Duration</div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className={styles.avgflex}>
                    <div className={styles.supply}>
                        <div className={styles.numbers}>129000+</div>
                        <div className={styles.title}>Active Suppliers</div>
                    </div>
                    <div className={styles.supply} style={{marginRight:'16%'}}>
                        <div className={styles.numbers}>4449</div>
                        <div className={styles.title}>Industries</div>
                    </div>
                </div>
                <ButtonBase className={styles.learn}>
                    learn more
                </ButtonBase>
            </div>
        )
    }
}

export default Rfq