/**
 * Created by charnjeetelectrovese@gmail.com on 5/29/2020.
 */
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {actionSelectTour} from "../../actions/Tours.action";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {serviceGetTourDetail} from "../../services/Tours.service";
import RouteNames from "../../routes/Route.name";
import DesktopView from './components/Desktop/DesktopTourDetail.view';
import WaitingComponent from "../../components/Waiting.component";
import {actionCartReset} from "../../actions/Cart.action";

class TourDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_calling: true,
        };
        this._handleBookNow = this._handleBookNow.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {id} = this.props.match.params;
        const request = serviceGetTourDetail({id: id});
        request.then((data) => {
            if (!data.error) {
                this.props.actionSelectTour(data.data);
                this.setState({
                    is_calling: false,
                })
            }
        });
    }


    _handleBookNow() {
        const { tour, cart } = this.props;
        this.props.actionCleanCart();
        this.props.history.push(RouteNames.cart);

    }

    _handleReviews() {
        this.props.history.push(RouteNames.all_reviews);
    }


    render() {
        const {is_calling, } = this.state;
        const { tour } = this.props;
        if (is_calling) {
            return (
                <WaitingComponent/>
            );
        }
        return (
            <DesktopView
                data={tour}
                handleBookNow={this._handleBookNow}
            />
        );
    }

}


const themestyles = theme => ({
    fontSizeSmall: {
        fontSize: '15px !important',
    },
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionCleanCart: actionCartReset,
        actionSelectTour: actionSelectTour
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        tour: state.tours.selected_tour,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themestyles)(TourDetails));
