import React, {Component} from 'react'
import {Room as MapIcon, Timer as ClockIcon} from '@material-ui/icons'
import styles from './Route.module.css'

const tempRoutes = [
    {id: 1, title: 'Your Location', sub: 'Avendia da Libredada, 78', ride: '15', stop: 0},
    {id: 2, title: 'Calouste Gulbenkain Foundation', sub: 'Avendia da Libredada, 5', ride: '15', stop: 10},
    {id: 3, title: 'Monstero Park', sub: 'Avendia da Libredada, 5', ride: '15', stop: 10},
    {id: 4, title: 'Saint George Castle', sub: 'Castlo de Jeonre', ride: 15, stop: 10},
    {id: 5, title: 'Alvito View Point', sub: 'Castlo de Jeonre', ride: '15', stop: 10},
    {id: 6, title: 'Esterilia Basilica', sub: 'Castlo de Jeonre', ride: 0, stop: 0},
]

class RouteCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li className={styles.timelineItem}>
                <div className={styles.timelineIcon}>
                    <MapIcon/>
                </div>
                <div className={styles.timelineBody}>
                    <div className={styles.timelineLead}>
                        <h3 className={styles.timelineTitle}>{this.props.title}</h3>
                        <span className={styles.timelineSub}>{this.props.sub}</span>
                    </div>
                    <div className={styles.timelineTime}>
                        <div className={styles.timeCard}>
                            {this.props.ride != 0 ? <ClockIcon style={{ fontSize: '14px', marginTop: '1px', marginRight: '2px' }}/> : '' }
                            <div className={styles.timeContainer}>
                                {this.props.ride != 0 ?<div className={styles.timeText}>{this.props.ride} minutes ride</div> : ''}
                                {this.props.stop != 0 ? (<div className={styles.stopText}>{this.props.stop} minutes stop</div>) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

}

class Route extends Component {
    constructor(props) {
        super(props);
    }

    _renderRoute() {
        const { routes } = this.props;
        return routes.map((val, index) => {
            let title = `Beginning (${val.title})`;
            if (index > 0) {
                title = `${index}. Stop - ${val.title}`;
            }
            if(index+1 == tempRoutes.length) {
                title = `${index}. and last Stop - ${val.title}`;
            }
            return (
                <RouteCard
                    title={title}
                    sub={val.sub}
                    ride={val.ride}
                    stop={val.stop}
                />
            )
        })
    }

    render() {
        return (
            <div className={styles.routeContainer}>
                <ul className={styles.timeline}>
                    {this._renderRoute()}
                </ul>
            </div>
        )
    }
}

export default Route;
