import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { ButtonBase, withStyles } from '@material-ui/core'
import styles from './Footer.module.css'
import Language from "./Language/Language.view";
import FooterCollapse from '../../views/Footer/Mobile/FooterCollapse.view'
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

class FooterView extends Component{

    render(){
        const { classes } = this.props;
        return(
            <div className={classNames(styles.footerContainer, classes.footer)}>
                <div className={styles.mainContainer}>
                <div className={styles.footerFlex}>
                    {/*<div className={styles.innerFlex}>*/}
                        {/*<ul className={styles.footerList}>*/}
                            {/*<li>*/}
                                {/*<a href=""><Language/> </a>*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                    {/*</div>*/}

                    <MediaQuery minDeviceWidth={768}>
                    <div className={styles.innerFlex}>
                        <div className={styles.heading}>About Us</div>
                        <ul className={styles.footerList}>
                            <li>
                                <div className={styles.pharmaText}>
                                    Pharma needs engage in manufacturing<br/>
                                    sourcing,marketing and distribution of <br/>
                                    high-quality pharmaceutical products and<br/>
                                    raw material
                                </div>
                            </li>
                            <li>
                                <div className={styles.iconFlex}>
                                    <img src={require('../../assets/img/factory/fb.png')} height={34}/>
                                    <img src={require('../../assets/img/factory/insta.png')} className={styles.socialIcons}/>
                                    <img src={require('../../assets/img/factory/linkdin.png')} className={styles.socialIcons}/>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.innerFlex}>
                        <div className={styles.heading}>Quick Links</div>
                        <ul className={styles.footerList}>
                            <li>
                                <Link to ='/'>Customer Support </Link>
                            </li>
                            <li>
                                <a href="">Careers</a>
                            </li>
                            <li>
                                <a href="">Blogs</a>
                            </li>
                            <li>
                                <a href="">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.innerFlex}>
                        <div className={styles.heading}>Important Links</div>
                        <ul className={styles.footerList}>
                            <li>
                                <a href="">Manufacturer</a>
                            </li>
                            <li>
                                <a href="">Marketers</a>
                            </li>
                            <li>
                                <a href="">Services Categories</a>
                            </li>
                            <li>
                                <a href="">Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>
                    </MediaQuery>
                </div>

                    {/*<MediaQuery maxDeviceWidth={768}>*/}
                        {/*<FooterCollapse/>*/}
                    {/*</MediaQuery>*/}


                {/*<hr className={styles.horizontalLine}/>*/}
            </div>
                <img src={require('../../assets/img/factory/madeinindiabg.png')} className={styles.bottomIcon}/>
            </div>

        )
    }
}

const s = theme => ({
    footer: {
        backgroundColor: '#333333'
    },
    selectField: {

    },
});


export default withStyles(s, { withTheme: true })(FooterView);
