import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import styles from './Card.module.css';
import Watch from '@material-ui/icons/WatchLater';
import {Star, FavoriteBorder as Favorite, Favorite as FavoriteFilled, People} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import Account from '@material-ui/icons/AccountBalanceWallet';
import classnames from 'classnames';
import {withRouter} from 'react-router-dom';
import RouteNames from '../../routes/Route.name';
import {actionSelectTour} from "../../actions/Tours.action";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';

const themestyles = theme => ({
    fontSizeSmall: {
        fontSize: '15px !important'
    }
});


class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false
        };
        this._handleClick = this._handleClick.bind(this)
        this._handleCardClick = this._handleCardClick.bind(this);
    }

    _handleClick(e) {
        e.stopPropagation();
        this.setState({
            is_filled: !this.state.is_filled
        })
    }

    _handleCardClick() {
        const { data } = this.props;
        // this.props.actionSelectTour(this.props.data);
        this.props.history.push(RouteNames.details+'/'+data.id);
    }

    _renderFavourite() {
        if (this.state.is_filled)
            return (
                <FavoriteFilled fontSize={'1.5rem'} className={styles.icons}/>
            );
        else
            return <Favorite fontSize={'1.5rem'} className={styles.fontIcon}/>

    }

    render() {
        const {data} = this.props;
        console.log('Card Data', data);
        if (data) {
            const image = data.images.length > 0 ? (data.thumbnail_index <= data.images.length ? data.images[data.thumbnail_index] : data.images[0]) : '';
            return (
                <div className={classnames(styles.card, this.props.className)} onClick={this._handleCardClick}>
                    <Paper elevation={2} className={styles.offerSliderBase}>
                        <div className={styles.cardCon} style={{
                            backgroundImage: 'url(' + image + ')',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <img className={styles.profile} src={data.driver.image}/>
                                <div className={styles.favorite} onClick={this._handleClick}>
                                    {this._renderFavourite()}
                                </div>
                            </div>
                            <div className={styles.icon}>
                                <Star fontSize={'small'} className={styles.fontSizeSmall}/>
                                <span>4.6</span>
                            </div>

                        </div>

                        {/* <div style={{backgroundImage: 'url(' + this.props.val.icon + ')',backgroundRepeat: 'no-repeat'}}></div> */}
                        <div className={styles.container}>
                            <span className={styles.heading}>{data.category}</span>
                            <div className={styles.title}>{data.name}</div>
                            <div
                                style={{display: 'flex', marginTop: '4px', alignItems: 'center', paddingBottom: '6px'}}>
                                <div className={styles.time}><Account className={styles.fontSizeIcon}
                                                                      fontSize={'small'}/><span
                                    style={{marginLeft: '8px'}}>{data.price}</span></div>
                                <div className={styles.time} style={{paddingLeft: '25px'}}>
                                    <People className={styles.fontSizeIcons} fontSize={'1.5rem'}/>
                                    <span style={{marginLeft: '8px'}}>{data.vehicle.capacity}</span>
                                </div>
                                <div className={styles.time} style={{paddingLeft: '25px'}}>
                                    <Watch className={styles.fontSizeIcon} fontSize={'small'}/>
                                    <span style={{marginLeft: '8px'}}>{data.duration}</span>
                                </div>
                                <div className={styles.time} style={{paddingLeft: '25px'}}>
                                    <div style={{display: 'flex'}}>
                                        {<img src={require('../../assets/img/speak.png')}
                                              style={{height: '17px'}}/>}</div>
                                    <span style={{marginLeft: '8px'}}>
                            {data.driver.languages_shortcode}
                            </span></div>
                            </div>
                        </div>

                    </Paper>
                </div>
            )
        } else {
            return null;
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionSelectTour: actionSelectTour
    }, dispatch);
}

function mapStateToProps(state) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themestyles)(withRouter(Card)));
