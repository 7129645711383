import React, {Component} from 'react';
import styles from './Inclusion.module.css';

class Inclusion extends Component {
    constructor(props) {
        super(props);

    }

    _renderList() {
        const {data, included} = this.props;
        const tempReturn = [];
        data.forEach((val, index) => {
            if (val.is_included == (included ? true : false)) {
                tempReturn.push((<li key={'included_'+index} className={styles.listItem}>
                    {val.title}
                </li>));
            }
        });
       if(tempReturn.length > 0) {
           return tempReturn
       }
       else {
           return (<li className={styles.listItem} style={{fontWeight:'bold'}}>{'Not Provided'}</li>)
       }
    }

    render() {
        return (
            <div>
                <div className={styles.outerFlex}>
                    <div className={styles.heading}>{this.props.title}</div>
                    <div className={styles.list}>
                        <ul>
                            {this._renderList()}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Inclusion
