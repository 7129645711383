/**
 * Created by charnjeetelectrovese@gmail.com on 2/17/2020.
 */
import {getRequest, postRequest} from "../libs/AxiosService.util";

export async function serviceBookTour(params) {
    return await postRequest('booking/tour', params);
}

export async function serviceGetTicketDetail(params) {
    return await postRequest('booking/details', params);
}


export async function serviceGetMyBookings(params = {}) {
    return await getRequest('bookings', params);
}
