import React, {Component} from 'react';
import styles from './BulkCard.module.css';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import ButtonBase from "@material-ui/core/ButtonBase";
import history from '../../../libs/history.util';
import RouteNames from "../../../routes/Route.name";
import data from '../../../data'

class BulkCard extends Component{
    constructor(props) {
        super(props);
        // this._handleClick = this._handleClick.bind(this);
    }

    // _handleClick () {
    //     history.push(RouteNames.blog_detail);
    // }

    _renderTopBulk(){
        return (
            data.bulk.map((val)=> {
                return (
                    <div className={styles.mainContainer}>
                        <div className={styles.indusCont}><img src={val.image} className={styles.industryIcons}/></div>
                        <div className={styles.bulkcont}>
                        <div className={styles.title}>{val.title}</div>
                            <div className={styles.priceFlex}>
                                <div> ₹{val.price} </div>
                                <div className={styles.perPrice}>per Piece</div>
                            </div>
                            <div className={styles.min}>(Min order {val.min}pc)</div>
                        </div>
                    </div>
                )
            })
        )
    }

    render(){
        return(
            <div className={classNames(styles.wrapper,styles.prodFlex)} onClick={this._handleClick}>
                {this._renderTopBulk()}
            </div>
        )
    }
}

export default BulkCard
