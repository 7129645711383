/*  */
/**
 * Created by charnjeetelectrovese@gmail.com on 12/5/2018.
 */
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PrivateRoute from '../libs/PrivateRouter.util';
import CustomRouter from '../libs/CustomRouter.utils';
import HeaderLayout from '../layouts/Header/Header.layout';
import Index from '../views/index/Index.component'

const RouteComponent = (props) => {
    return (
        <Switch>
            {/*<Route path={'/landing'} component={Index}/>*/}
            <CustomRouter path={'/'} component={HeaderLayout} check={'dsds'}  />
        </Switch>
);
};
export default RouteComponent;
