/**
 * Created by charnjeetelectrovese@gmail.com on 1/8/2020.
 */
import {postRequest} from "../libs/AxiosService.util";


export async function serviceGetTours(params) {
    return await postRequest('/tours', params);
}

export async function serviceGetTourDetail(params) {
    return await postRequest('/tours/detail', params);
}

export async function serviceGetTourAvailability(params) {
    return await postRequest('/tours/availability', params);
}

export async function serviceGetTourSlots(params) {
    return await postRequest('/tours/slots', params);
}

