import React, {Component} from 'react';
import styles from './AchivementCard.module.css';
import classNames from "classnames";
import Rating from '@material-ui/lab/Rating';

class AchivementCard extends Component{
    constructor(props) {
        super(props);
        // this._handleClick = this._handleClick.bind(this);
    }

    render(){
        return(
           <div className={styles.topContainer}>
               <div>
                   <div className={styles.blue}></div>
                   <img src={require('../../assets/img/factory/img.png')} height={180} className={styles.prof}/>
                   <div className={styles.blue}></div>
               </div>
               <div className={styles.desc}>
                   <div className={styles.main}>Pranav Bhasin</div>
                   <div className={styles.pos}>CEO.Electrovese Pvt Ltd</div>
                   <div>
                       <Rating value={5} readOnly className={styles.starRating}/>
                   </div>
                   <p className={styles.pharmaneeds}>
                       Pharmaneeds is committed to provide high quality<br/>
                       products to our customers.At our high tech,<br/>
                       independent,state-of-the-art manufacturing<br/>
                       facilities
                   </p>
               </div>
           </div>
        )
    }
}

export default AchivementCard