import React from 'react'
import Confetti from 'react-dom-confetti';
import styles from './PaymentSuccess.module.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import Close from '@material-ui/icons/Close';
import RouteNames from '../../routes/Route.name';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';

class PaymentSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_active: false
        }
    }

    componentDidMount() {
        const tempInterval = setInterval(() => {
            this.setState({
                is_active: !this.state.is_active,
            })
        }, 700);


        setTimeout(() => {
            clearInterval(tempInterval);
        }, 750)
    }

    _handleClick() {
        this.setState({
            is_active: !this.state.is_active,
        })
    }

    render() {
        const config = {
            angle: "90",
            spread: "66",
            startVelocity: 45,
            elementCount: "100",
            dragFriction: 0.1,
            duration: 3000,
            stagger: 0,
            width: "7px",
            height: "8px",
            colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
        };
        const {cart} = this.props;
        return (
            <div className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.navFlex}>
                        <div className={styles.back}>
                            <ButtonBase>
                                <Close fontSize={'medium'}/>
                            </ButtonBase>
                        </div>
                    </div>
                </div>

                <div className={styles.midContainer}>
                    <div style={{textAlign: 'center'}}>
                        <img src={require('../../assets/img/thank_you.png')}
                             style={{height: '100px', position: 'relative'}}/>
                    </div>
                    <div className={styles.heading}>
                        <div onClick={this._handleClick.bind(this)} className={styles.mainDesc}>Payment Confirmed</div>
                        <span className={styles.description}>Thanks for using Get A Tour . Your request <br/> has been placed successfully placed </span>
                        <div className={styles.ticketButton}>
                            <span style={{ color: 'white' }}>WAITING FOR THE CONFIRMATION</span>
                            {/*<Link to={RouteNames.ticket+'/'+cart.booking_code} style={{color:'white'}}>Show Ticket </Link>*/}
                        </div>
                    </div>
                    <div style={{marginLeft: '50%'}}>
                        <Confetti active={this.state.is_active} config={config}/>
                    </div>
                </div>

                {/*<div className={styles.header}>*/}
                {/*<div className={styles.headingCont}>*/}
                {/*<h4>Driver isn't available</h4>*/}
                {/*</div>*/}
                {/*</div>*/}

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess)


//style={{backgroundImage:'url(' + require('../../assets/img/driver_not_aval.png') + ')'}}
