import React, {Component} from 'react'
import styles from './Language.module.css'
import Dialog from '@material-ui/core/Dialog';
import staticData from '../../data'


// const style = {
//     paper : {
//         margin:'30px'
//     }
// }

class Language extends Component{
    constructor(props){
        super(props);

        this.state={
            checked: false,
        }
        this._handleClick = this._handleClick.bind(this)
    }
    _handleClick(){
        this.setState({
            checked: !this.state.checked,
        })
    }


    render(){
    // const {classes} = this.props
        return(
            <div>
                <Dialog
                    fullWidth
                    maxWidth={'lg'}
                onClose={this.props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                >
                    <div>
                        <div className={styles.heading}>
                            Select Country
                        </div>
                        <hr/>
                        <div>
                            {staticData.translate.map((val, index)=>{
                                return (
                                    <div key={'language_'+index} className={styles.container}>
                                        <div className={styles.icon}>
                                          <img src={val.img} className={styles.img}/>
                                        </div>
                                          <div className={styles.country}>
                                            {val.name}({val.short})
                                          </div>
                                        <div className={styles.code}>
                                            {val.c_code}
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default Language
