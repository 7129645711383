import React, {Component} from 'react';
import styles from './Cities.module.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import RouteNames from "../../../routes/Route.name";
import history from "../../../libs/history.util";

class Cities extends Component{

    _handleClick () {
        history.push(RouteNames.cities_list);
    }
    render(){
        return(
            <div className={styles.wrapper}>
            <div className={styles.cardContainer}>
                <div className={styles.backgroundImage} style={{
                    backgroundImage: 'url(' + require('../../../assets/img/tuxi/Berlin.png') + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center'
                }}>
                    <div className={styles.textContainer}>
                        <div className={styles.innerText}>Berlin</div>
                        <div className={styles.explore}>
                            <ButtonBase
                                className={styles.exploreButton} onClick={this._handleClick.bind(this)}>
                                Explore Now
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default Cities