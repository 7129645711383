import React, {Component} from 'react';
import styles from './Mini.module.css'

class Mini extends Component{
    render(){
        return(
            <div className={styles.mainContainer}>
            <div className={styles.cardContainer}>
                <div className={styles.backgroundImage} style={{
                    backgroundImage: 'url(' + require('../../../assets/img/tuxi/safari.png') + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center'
                }}>

                </div>
            </div>
                <div className={styles.bottomText}>
                    <div className={styles.name}>
                        Safari Ride
                    </div>
                    <div className={styles.totalCount}>
                        100 Tours and activities
                    </div>
                </div>
            </div>
        )
    }
}

export default Mini