import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './ChipsSlider.module.css'
import CitiesChips from "../Chips/CitiesChips.component";

class ChipsSlider extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
    }

    _renderCitiesChips(){
        return ['All','Paris','Berlin','Porto','Lisbon','Vienna','Vennice','Paris'].map(val => {
            return (
                <div>
                    <CitiesChips data={val}/>
                </div>
            )
        })
    }

    render(){
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold: 10
        }
        return(
            <div>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderCitiesChips()}
                </Slider>
            </div>
        )
    }
}

export default ChipsSlider

