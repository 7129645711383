import tinycolor from "tinycolor2";

const primary = "#2196F3";
const secondary = "#832739";
const warning = "#FFC260";
const success = "#4caf50";
const info = "#9013FE";
const green = '#4caf50';
const error = '#f44336';
const lightenRate = 7.5;
const darkenRate = 15;
const footer = "#11B1D2";

export default {
    direction: 'ltr',
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF"
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    error: {
      main: error,
      light: tinycolor(error)
          .lighten(lightenRate)
          .toHexString(),
      dark: tinycolor(error)
          .darken(darkenRate)
          .toHexString()
    },
    footerColor: {
      main: footer
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9"
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF"
    }
  },
  highlightColor: "#11B1D1",
  buttonGradient: "linear-gradient(to left, #04AFB7 , #1BB380)",
  linkColor: '#224CBA',
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A"
      }
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A"
      }
    },
    MuiSelect: {
      // select: {
      //   minWidth: '80px',
      // },
      icon: {
        color: "#B9B9B9",
      },
      label: {
        backgroundColor: 'white'
      }
    },
    MuiFormHelperText: {
      root: {
        margin: '0px',
        fontSize: '0.60rem',
        marginLeft: '5px',
        position: 'absolute',
        bottom: '-13px',
        right: '0px',
      },
      contained: {
        margin: '0px',
        marginLeft: '5px',
        position: 'absolute',
        bottom: '-13px',
        right: '0px',
      },
    },
      MuiFormControl:{
        root: {
            // background: '#f4f8f7',
        }
      },
    MuiFormLabel:{
      root: {
        fontSize: '0.9rem'
      },
      filled: {
        backgroundColor: 'white'
      }
    },
    MuiListItem: {
      button: {
        // '&:hover, &:focus': {
        //   backgroundColor: '#F3F5FF',
        // },
      },
      root: {
        "&$selected": {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF',
          },
        }
      }
    },
    MuiTouchRipple: {
      child: {
        // backgroundColor: "white"
      }
    },
    MuiTableRow: {
      root: {
        height: 40,
      }
    },
    MuiTableCell: {
      root: {
        padding: '10px',
        // borderBottom: '1px solid rgba(224, 224, 224, .5)',
      },
      head: {
        fontSize: '0.80rem',
      },
      body: {
        fontSize: '0.80rem',
      }
    }
  }
};
