import React, {Component} from 'react';
import styles from './Aside.module.css';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import {renderFileField} from "../../../libs/redux-material.utils";
import {ButtonBase} from '@material-ui/core';
import csx from 'classnames';

const validate = (values) => {
    const errors = {};
    const requiredFields = [];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

class Aside extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleBtnClick = this._handleBtnClick.bind(this);
    }

    _handleSubmit(data) {

    }

    _handleBtnClick(type) {
        this.props.handleClick(type)
    }

    render(){
        const {handleSubmit, user_details, isProfile} = this.props;
        return(
            <div>
                <form onSubmit={handleSubmit(this._handleSubmit)}>
                    <div className={styles.profileImage}>
                        <Field
                            max_size={1024*1024*5}
                            type={['jpg', 'png']}
                            fullWidth={true}
                            name="user_image"
                            component={renderFileField}
                            show_image
                            // default_image={user_details.user_image ? user_details.user_image : null}
                        />
                    </div>
                </form>
                    <div>
                        <div className={csx(styles.sideBox, (isProfile ? styles.shadowBox : '') )}>
                        <ButtonBase onClick={this._handleBtnClick.bind(this, 'PROFILE')} className={styles.buttonCard}>
                            <div className={styles.upperHeading}>
                            My Profile
                            </div>
                            <div className={styles.innerText}>
                                Manage your personal details
                            </div>
                        </ButtonBase>
                        </div>

                        <div className={csx(styles.sideBox, (!isProfile ? styles.shadowBox : '') )}>
                        <ButtonBase onClick={this._handleBtnClick.bind(this, 'PASSWORD')}  className={styles.buttonCard}>
                            <div  className={styles.upperHeading}>
                           Password
                            </div>
                            <div className={styles.innerText}>
                                Manage your Password
                            </div>
                        </ButtonBase>
                        </div>
                    </div>
            </div>
        )
    }

}

const aside = reduxForm({
    form:'aside',
    validate,
})(Aside);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(aside);
