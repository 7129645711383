import React, {Component} from 'react';
import styles from './Email.module.css';
import {Link} from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase'
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCartUpdate} from "../../actions/Cart.action";
import {withTheme} from "@material-ui/core/index";
import {renderOutlinedTextField,renderTextField} from "../../libs/redux-material.utils";
import MediaQuery from 'react-responsive';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['email'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};



class Subscribe extends Component{
    constructor(props){
        super(props);
        this.state= {
            is_submitted: false
        }
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(data) {
        this.setState({
            is_submitted: true
        })
    }

    _renderForm(){
        if (this.state.is_submitted){
            return (
                <div className={styles.innerFlex} style={{justifyContent:'center'}}>
                    <div className={styles.innerText}>
                    Thank you for subscribing<br/>Check your email for more
                    </div>
                </div>
            )
        }
        else {
            const {handleSubmit,city} = this.props;
            return (
                <div className={styles.innerFlex}>
                    <div className={styles.emailContainer}>
                        <div className={styles.latestText}>
                            {city ? 'Need Suggestions to Customize your tour?' :'Subscribe to Get Latest Updates'}
                        </div>
                        <p className={styles.signUpEmail}>
                            {city ? 'Enter your email address below to get details about how you can customize your tours.Details will be sent to your email address' :
                                'Sign up for our newsletter and discover travel experiences you will really want to try'}
                        </p>
                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            <div className={styles.buttonFlex}>
                                <div className={styles.emailField}>
                                    <Field className={styles.buttonBack}
                                        fullWidth
                                           name="email" component={renderOutlinedTextField} margin = {'dense'} label="E-Mail" />
                                </div>

                                <div className={styles.subscribeButton}>
                                    <ButtonBase
                                        type={'submit'}
                                        className={styles.signUp}>
                                        Subscribe
                                    </ButtonBase>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        }
    }

    render(){
        const {handleSubmit} = this.props;
    return(
        <div>
            <div className={styles.emailFlex}>
                <MediaQuery minDeviceWidth={768}>
                    <div className={styles.outerFlex}>
                        <img src={require('../../assets/img/tuxi/auto.png')} className={styles.autoImage}/>
                    </div>
                </MediaQuery>
                {this._renderForm()}

            </div>
            <div className={styles.infoText}>
                By signing up , you agree to receive promotional emails. You can unsubscribe at any time.
                For more information , read our<Link to='/privacy' style={{textDecoration:'none'}}><span
                className={styles.privacy}> privacy statement</span></Link>
            </div>
        </div>
    )
    }
}

const subscribe = reduxForm({
    form: 'subscribe',
    validate,
})(Subscribe);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionCartUpdate: actionCartUpdate
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        category: state.common.category,
        cart: state.cart,
        selected_tour: state.tours.selected_tour,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(subscribe);
