import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Card from '../Card/Card.component'
import styles from './Places.module.css';
import 'slick-carousel/slick/slick.css';

import staticData from '../../data'

class Places extends Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
        
            // centerPadding: '50px',
            // centerMode: true,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold:10
            // autoplay: true,
            
        }
        return(
            <div className={'cardContainer'}>
             <Slider {...settings}>
                {staticData.cardsData.map((val)=> {
                   return (
                    <Card val={val}/>
                )})}
                
            </Slider>
            </div>
        )
    }
}

export default Places