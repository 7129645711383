import React, {Component} from 'react'
import Paper from '@material-ui/core/Paper';
import styles from './Category.module.css'
import classnames from 'classnames'

class Category extends Component{
    render(){
        return(
            <div className={classnames(styles.card,this.props.className)}>
                <div>

                        <div className={styles.cardCont} style={{
                            backgroundImage: 'url(' + this.props.val.image + ')',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition:'center'
                        }}>
                        <div className={styles.posn}>
                            <div style={{margin:'0',marginBottom:'15px',textAlign:'center'}}>
                            <div className={styles.heading}>{this.props.val.title}</div>
                                <span style={{fontWeight:300}} className={styles.heading}>{this.props.val.tours + ' tours'} </span>
                            </div>
                        </div>
                    </div>
                </div>

                    </div>
        )
    }
}

export default Category