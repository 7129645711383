import React, {Component} from 'react';
import styles from './Delivery.module.css';


class Delivery extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <div className={styles.delContainer}>
                <div>
                    Standard
                </div>
            </div>
        )
    }
}

export default Delivery