/**
 * Created by charnjeetelectrovese@gmail.com on 3/11/2020.
 */
import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {TextField, ButtonBase,AppBar,Toolbar,IconButton,Typography} from '@material-ui/core';
import {KeyboardArrowLeft,LocalPhone} from '@material-ui/icons/';
import {Local} from '@material-ui/icons/LocalPhone';
import Constants from '../../config/constants';
import styles from './Chat.module.css';
import data from '../../data'
import {serviceGetMessages, serviceSendMessage} from "../../services/Common.service";
import {getSocketInstance} from "../../libs/socket.utils";

class Chats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            booking_id: null,
            is_calling: true,
            is_error: false,
            chats: [],
            message: '',
        };
        this._socket = getSocketInstance();
        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._messageProcessing = this._messageProcessing.bind(this);
    }

    componentDidMount() {
        // if ('location' in this.props && this.props.location.state && 'booking_id' in this.props.location.state) {

            // const bookingId = this.props.location.state.booking_id;
            const bookingId = this.props.booking_id;

            serviceGetMessages({booking_id: bookingId}).then((data) => {
                if (!data.error) {
                    this.setState({
                        is_calling: false,
                        chats: data.data,
                        booking_id: bookingId,
                    });
                    if (this._socket) {
                        this._socket.emit('subscribe', bookingId);
                    }
                    this.subscribeToTruckLocation();

                } else {
                    this.setState({
                        is_calling: false,
                        is_error: true,
                        booking_id: bookingId,
                    })
                }
            });

        // }
    }

    subscribeToTruckLocation() {
        if (this._socket) {
            this._socket.on(Constants.SOCKET_EVENTS.USER_MESSAGE_RECEIVED, this._messageProcessing);
        }
    }

    _messageProcessing(data) {
        const {chats} = this.state;
        const temps = chats;
        temps.push(data.message.data.chat);
        this.setState({
            chats: temps,
        });
    }


    componentWillUnmount() {
        const {is_error, booking_id} = this.state;
        if (!is_error) {
            if (this._socket) {
                // this._socket.emit('unsubscribe', booking_id);
                this._socket.removeListener(Constants.SOCKET_EVENTS.USER_MESSAGE_RECEIVED, this._messageProcessing);
            }
        }
    }

    _handleInputChange(e) {
        this.setState({
            message: e.target.value
        })
    }

    _handleSubmit() {
        serviceSendMessage({
            booking_id: this.state.booking_id,
            message: this.state.message,
        });
        this.setState({
            message: '',
        })
    }

    renderChat() {
        return this.state.chats.map((val,index)=> {
            const isUser = val.sender_id == this.props.user_id
            return (
                <li className={!isUser ? styles.left : styles.right}>
                    {val.message}
                </li>
            )
        })
    }

    render() {
        return (
            <div>
                <div style={{height: '65px'}}>
                </div>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                            <KeyboardArrowLeft fontSize={'medium'}/>
                        </IconButton>

                        <div className={styles.chatUpper}>
                            <div className={styles.driverImageCont}>
                                <div style={{
                                    backgroundImage: 'url(' + this.props.driver_image + ')',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition:'center'
                                }} className={styles.imageContainer}>
                                    {/*<img src={this.props.driver_image}*/}
                                 {/*className={styles.driverImage}/>*/}
                                </div>
                                <div style={{marginLeft:'10px'}}>
                                    {this.props.driver_name}
                                </div>
                            </div>

                            <div>
                                <ButtonBase>
                                    <a href={"tel:" + this.props.driver_contact}>
                                        <LocalPhone style={{color:'white'}}/>
                                        {/*<img src={require('../../assets/img/phone.png')} className={styles.chatIcon}/>*/}
                                    </a>
                                </ButtonBase>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <br/>

                <div className={styles.messageContainer}>
                    <div className={styles.textContainer}>
                        <ul className={styles.messageList}>
                                {this.renderChat()}
                        </ul>
                    </div>
                </div>
                {/*{this.state.chats.map((val) => {*/}
                    {/*return (*/}
                        {/*<div className={styles.chatContainer}>*/}
                            {/*<div className={styles.chatInner}>*/}
                                {/*<div className={styles.driverName}>*/}
                                    {/*<div className={styles.name}>{val.message}</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*)*/}
                {/*})}*/}
                <br/>
                <br/>
                <div className={styles.lowerFlexContainer}>
                    <input className={styles.lowerInput} value={this.state.message} onChange={this._handleInputChange}/>
                    <ButtonBase className={styles.btnBase} onClick={this._handleSubmit}>
                        <div className={styles.sendBtn}>
                            Send
                        </div>
                    </ButtonBase>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user_id:state.auth.user_details.id
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Chats);


