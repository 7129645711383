import React, {Component} from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './BoardLater.module.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionUpdateCategory} from "../../actions/Common.action";
import RouteNames from '../../routes/Route.name';

class BoardLater extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleClick = this._handleClick.bind(this);
        this._handleRide = this._handleRide.bind(this);
    }

    _handleClick() {
        this.props.actionUpdateCategory('tour');
        this.props.history.push(RouteNames.later_search);
    }

    _handleRide() {
        this.props.actionUpdateCategory('ride');
        this.props.history.push(RouteNames.ride);
    }

    render() {
        return (
            <div>
                <div className={styles.container}>
                    <div>
                        <br/>
                        <br/>
                        {/* <h3 className={styles.heading}>Select what you actually want</h3> */}
                        <br/>
                        <br/>
                        <div className={styles.display}>
                            <ButtonBase classes={{root: styles.btn}} onClick={this._handleClick}>
                                <div className={styles.bookbtn}>
                                    <span className={styles.button}>Book A Tour</span>
                                </div>
                            </ButtonBase>

                            <div className={styles.description}>
                                Travel around the city & visit some of the
                                {' '}
                                <br/>
                                {' '}
                                handpicked destinations listed by
                                {' '}
                                <br/>
                                {' '}
                                the tour providers.
                            </div>
                        </div>
                    </div>

                    <div>
                        <br/>
                        <br/>
                        <div className={styles.display}>
                            <ButtonBase classes={{root: styles.btn}} onClick={this._handleRide}>
                                <div className={styles.bookbtn}>
                                    <span className={styles.button}>Book A Ride</span>
                                </div>
                            </ButtonBase>

                            <div className={styles.description}>
                                Travel in style from one point to the
                                {' '}
                                <br/>
                                {' '}
                                other point within the city in the
                                {' '}
                                <br/>
                                {' '}
                                vehicle of your choice.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionUpdateCategory: actionUpdateCategory
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(BoardLater);
