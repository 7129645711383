import React, { Component } from 'react';
import styles from '../Drawer/Drawer.module.css'
import {Star} from '@material-ui/icons'

class Favourite extends Component {
    render(){
        return(
            <div>
                <div style={{height:'65px'}}>
                </div>

                <div className={styles.topContainer}>
                <div className={styles.favContainer}>
                    <div className={styles.ticketflex} style={{justifyContent:'space-between'}}>
                        <div style={{display:'flex'}}>
                        <div>
                            <img src={require('../../assets/img/TicketImg.jpg')} style={{height:'75px',width:'85px'}}/>
                        </div>
                        <div className={styles.ticketdescription}>
                            <div className={styles.scheduled}>6 August 2019, 9:30 AM - 11:30 AM</div>
                            <div className={styles.tourHeading}>
                                Lisbon Castle and medivial <br/> buildings
                            </div>
                            <div className={styles.tourDriver}>
                                Maddie Castro - Driver
                            </div>
                        </div>
                        </div>
                        <div>
                            <div className={styles.iconStar}>
                                <Star fontSize={'small'} className={styles.fontSizeSmall}/>
                                <span className={styles.rating}>4.6</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default Favourite
