import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './ReviewSlider.module.css'
import PortraitCard from "../../../../components/Paper/Portrait/PortraitCard.component";
import LandscapeCard from "../../../../components/Paper/LandScape/LandscapeCard.component";
import ReviewCard from "../ReviewCard/ReviewCard.view";

class VerticalSlider extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
        this.slide = this.slide.bind(this);
    }

    slide(e){
        const y = e.wheelDelta;
        if(y < 0) {
            this.slider.slickNext()
        }
      else {
            // this.slider.slickPrev()
        }
    }


    _renderInfo(){
        return (
                [1,2,3].map(val => <ReviewCard/>)
        )

    }

    _renderReview(){
        return (
            <ReviewCard/>
        )
    }


    render(){
        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     nav: true,
        //     accessibility: false,
        //     arrows: false,
        //     // verticalSwiping: true,
        //     // autoplay: true,
        //     speed: 500,
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     variableWidth: false,
        //     touchThreshold: 10,
        //     className:'center',
        //     centerMode: true,
        //     centerPadding: "60px"
        // }
        // const settings = {
        //     className: "center",
        //     centerMode: true,
        //     infinite: false ,
        //     draggable: true,
        //     centerPadding: "0px",
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     speed: 500
        // };

        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            // centerPadding: '50px',
            centerMode: true,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold:10,
            autoplay: true
        }
        return(
            <div className={'reviewSlider'}>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderInfo()}
                </Slider>
            </div>
        )
    }
}

export default VerticalSlider

