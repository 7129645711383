import React, { Component } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Keyboard from '@material-ui/icons/KeyboardArrowLeft';
import styles from './AllReviews.module.css';
import staticData from '../../data';
import ReviewsCard from './ReviewsCard.component';

class AllReviews extends Component {
    constructor (props) {
        super (props);
    }
  render() {
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.navFlex}>
            <div className={styles.back}>
              <ButtonBase>
                <Keyboard fontSize="large" />
              </ButtonBase>
            </div>
            <div>
              <span className={styles.headerTitle}>
                                   All Reviews (3)
              </span>
            </div>
          </div>

          <div>
            {staticData.driverReview.map(val => <ReviewsCard val={val} />)}
          </div>
        </div>
      </div>
    );
  }
}

export default AllReviews;
