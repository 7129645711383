import React,{Component} from 'react'
import styles from './Blog.module.css';
import {ButtonBase} from '@material-ui/core'
import BlogCard from "./card/BlogCard.view";
import DiscoverForm from "../Discover/DiscoverForm.view";
import Rfq from "../RFQ/Rfq.view";

class Blog extends Component{
    constructor(props){
        super(props);
        this.state={}
    }

    _renderCards(){
        return(
            [1,2,3,4,5,6].map(val => <BlogCard/>)
        )
    }

    render(){
        return(
            <div>
                <div style={{height:'40px'}}></div>
                <div className={'ncontainer'}>
                    <div className={styles.blogsFlex}>
                        <div>
                            <div style={{position:'relative'}}>
                                <img src={require('../../assets/img/factory/newimg.png')} className={styles.upperImage}/>
                                <div className={styles.tab}>Tablet</div>
                            </div>
                            <div className={styles.descr}>
                                It is a long established fact that a reader will be dist by the readable content of a page
                            </div>
                            <div>
                                <span className={styles.date}>06 May 2020 |</span> <span className={styles.name}><a href='/'>John Doe</a></span>
                            </div>
                        </div>
                        <div style={{marginLeft:'20px'}}>
                            <div style={{position:'relative'}}>
                                <img src={require('../../assets/img/factory/img1.png')} className={styles.upperImage}/>
                                <div className={styles.tab}>Tablet</div>
                            </div>
                            <div className={styles.descr}>
                                It is a long established fact that a reader will be dist by the readable content of a page
                            </div>
                            <div>
                                <span className={styles.date}>06 May 2020 |</span> <span className={styles.name}><a href='/'>John Doe</a></span>
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex',flexWrap:'wrap',justifyContent:'center'}}>
                    {this._renderCards()}
                    </div>
                    <br/>

                    <div className={styles.marketplaceFlex}>
                        <div className={styles.desc} style={{marginRight:'25px'}}>
                            <DiscoverForm/>
                        </div>
                        <div className={styles.desc}>
                            <Rfq/>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Blog