import React, {Component} from 'react'
import Mini from './Mini/Mini.component'
import data from '../../data'
import styles from './Filter.module.css'
import Slider, {Range} from 'rc-slider';
import Constants from '../../config/constants';
import 'rc-slider/assets/index.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Vehicle from "./Vehicle/Vehicle.component";
import PriceFilter from './PriceFilter/PriceFilter.component'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Filter extends Component {
    constructor(props) {
        super(props);
        this.filtersOrders = ['arrival', 'language', 'price','tour_type', 'category', 'duration', 'type_category', 'rating'];
        this.data = JSON.parse(JSON.stringify(Constants.filters));
        this.state = {
            checked: false,
            filter: JSON.parse(JSON.stringify(Constants.initialFilterState)),
        };
        this._handleApply = this._handleApply.bind(this);
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount(){
        this.setState({
            filter: this.props.filters
        });
        const { tour_types, type_categories, categories, languages } = this.props;
        this.data['language'].data = languages;
        this.data['tour_type'].data = tour_types;
        this.data['category'].data = categories;
        this.data['type_category'].data = type_categories;

    }

    componentWillReceiveProps(nextProps){
        if( (JSON.stringify(nextProps.filters) !== JSON.stringify(this.props.filters))){
            this.setState({
                filter: nextProps.filters
            })
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log('props', props.filters);
    //     console.log('state', state.filter);
    //     if (JSON.stringify(props.filters) !== JSON.stringify(state.filter)) {
    //         return {
    //             filter: props.filters,
    //         };
    //     }
    //     return null;
    // }



    _handleClick(type, data) {
        const temp = type in this.state.filter ? this.state.filter[type] : null;
        const filter = this.state.filter;
        if (temp != null) {
            if (temp instanceof Array) {
                var index = null;
                filter[type].some((val, i) => {
                    if (val == data) {
                        index = i;
                        return true;
                    }
                });
                if (index != null) {
                    filter[type].splice(index, 1);
                } else {
                    filter[type].push(data);
                }
            } else {
                filter[type] = data;
            }
            console.log(filter);
            this.setState({
                filter: filter,
            });
        }
    }

    _handleApply () {
        this.props.handleApply(this.state.filter)
    }

    _renderFilters() {
        return this.filtersOrders.map((val, index) => {
            if (val != 'price') {
                const tempData = this.data[val];
                return (
                    <div key={'filter_'+index}>
                        <label className={styles.filters}>
                            {tempData.title}
                        </label>
                        <ul className={val == 'vehicle' ? styles.vehicle : styles.main}>
                            {tempData.data.map((dataVal, index) => {
                                const categoryType = val;
                                const isThere = ((this.state.filter[categoryType]).indexOf(dataVal.value) >= 0) ? true : false;
                                return (val != 'vehicle' ?
                                    (<Mini
                                        key={Math.random()+index}
                                        val={dataVal}
                                        handleClick={this._handleClick}
                                        category={categoryType}
                                        checked={isThere}/>)
                                    :
                                    (<Vehicle
                                        key={Math.random()+index}
                                        val={dataVal}
                                        handleClick={this._handleClick}
                                        category={categoryType}
                                        checked={isThere}/>))
                            })}
                        </ul>
                        <hr/>
                    </div>
                )
            } else {
                return (
                    <div key={'filter_'+index}>
                        <label className={styles.filters}>
                            Price
                        </label>
                        <PriceFilter
                            data={this.state.filter['price']}
                            handleChange={this._handleClick}
                        />
                        <hr/>
                    </div>
                )
            }
        });
    }

    render() {
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                >
                    <div className={styles.outerFlex}>
                        <div className={styles.innFlex}>
                            <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <div style={{fontWeight: '700'}}>
                                Filters
                            </div>
                        </div>
                        <div style={{marginRight: '10px'}}>
                            <ButtonBase onClick={() => { this.props.handleClear('ALL') }}>
                            Clear
                            </ButtonBase>
                        </div>
                    </div>

                    <div style={{padding: '10px'}}>
                        {this._renderFilters()}
                        <div className={styles.bottomButton}>
                            <div style={{width:'100%'}}>

                                <ButtonBase classes={{root: styles.bookbtn}} onClick={this._handleApply}>
                                    <span style={{
                                        fontSize: '18px',
                                        color: 'white',
                                        fontWeight: '500',
                                        textAlign: 'center'
                                    }}>Apply</span>
                                </ButtonBase>

                            </div>
                        </div>

                    </div>
                </Dialog>
            </div>

        )
    }
}

export default Filter
