import React, { Component } from 'react';
import TicketView from '../../components/Ticket/Ticket.component';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {renderOutlinedTextField} from '../../libs/redux-material.utils';
import styles from './Report.module.css'
import ButtonBase from '@material-ui/core/ButtonBase';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['description'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    return errors;
};

class Report extends Component {
    constructor(props){
        super(props);
        this.state={}
        this._handleSubmit = this._handleSubmit.bind(this);
}
    _handleSubmit(data){
        console.log(data)
    }

    render(){
        const {handleSubmit, classes} = this.props;
        return(
            <div  style={{ backgroundColor: '#f9f9f9',height: '100vh'}}>

                <div style={{height:'65px'}}>
                </div>

            <div className={styles.ticketContainer}>
                <div style={{color:'grey',fontSize:'14px'}}>Sun,6 Aug 2019</div>
                <TicketView/>
            </div>

                <div className={styles.bottomContainer}>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                    <div className={styles.heading}>Tell us your reason with the ride</div>
                    <div>
                    <Field fullWidth={true} name="description" component={renderOutlinedTextField}
                           multiline
                           rows="7"
                           margin={'dense'}
                           label="Write something here..."/>
                    </div>

                    <div>
                        <ButtonBase className={styles.bottomButton} type={'submit'}>
                            Save
                        </ButtonBase>
                    </div>
                    </form>
                </div>
            </div>
        )
    }
}
const ReduxForm = reduxForm({
    form: 'report',  // a unique identifier for this form
    validate,
})(Report);

export default connect(null, null)(ReduxForm);
