import React, {Component} from 'react';
import styles from './Steps.module.css'
import {makeStyles,withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';


const steps = ['Personal Profile','Business Info','Kyc Details','Banking Details','Additional Info'];

function ColorlibStepIcon(props) {
        const icons = {
            1: <img src = {require('../../../assets/img/factory/uncheck.png')} className={styles.uncheck}/>,
            2: <img src = {require('../../../assets/img/factory/uncheck.png')} className={styles.uncheck}/>,
            3: <img src = {require('../../../assets/img/factory/uncheck.png')} className={styles.uncheck}/>,
            4: <img src = {require('../../../assets/img/factory/uncheck.png')} className={styles.uncheck}/>,
            5: <img src = {require('../../../assets/img/factory/uncheck.png')} className={styles.uncheck}/>,
        }
        return (
            <div>
                {icons[String(props.icon)]}
            </div>
        )
}

class Steps extends Component{
    constructor(props) {
        super(props)
        this.state = {
            activeStep:0
        }
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handleBack() {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    handleNext() {
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    };

    render(){
        return(
            <div className={'myprofile'}>
                <Stepper alternativeLabel activeStep={this.state.activeStep} connector>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                            >
                                {label}
                                </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {/*<div>*/}
                    {/*<Button disabled={this.state.activeStep === 0} onClick={this.handleBack}>*/}
                        {/*Back*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                        {/*variant="contained"*/}
                        {/*color="primary"*/}
                        {/*onClick={this.handleNext}*/}
                        {/*// className={classes.button}*/}
                    {/*>*/}
                        {/*{this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}*/}
                    {/*</Button>*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default Steps