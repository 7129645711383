import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import styles from './Wishlist.module.css';
import {CircularProgress} from "@material-ui/core";
import {TourListCard} from '../../components/index.component';


class Wishlist extends Component {
    constructor(props) {
        super(props);

    }

    _renderWishList() {
        const {tours_calling, tours,wishlist} = this.props;
       console.log(this.props.wishlist)
        if (tours_calling) {
            return (
                <div className={styles.waitingContainer}>
                    <CircularProgress/>
                </div>
            );
        } else {
            return wishlist.map((val, index) => {
                return (<TourListCard
                    key={val._id}
                    data={val}
                    handleClick={this._handleCardClick}
                />)
            })
        }
    }
    render(){
        return(
            <div className={'container'}>
                <div className={styles.heading}>Your Wishlist</div>
                {this._renderWishList()}
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps(state) {
    return {
        tours: state.tours.tours,
        tours_calling: state.tours.is_calling,
        wishlist: state.common.wishlist
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);