/**
 * Created by charnjeetelectrovese@gmail.com on 12/13/2018.
 */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styles from './Login.module.css';
import {renderTextField,renderOutlinedTextField,renderPasswordField} from '../../libs/redux-material.utils';
import {Button, withStyles, ButtonBase} from '@material-ui/core';
import {serviceLoginUser} from "../../services/Auth.service";
import {actionLoginUser} from '../../actions/Auth.action';
import DashboardSnackbar from "../../components/Snackbar.component";
import {Link} from 'react-router-dom';
import VerifyDialog from '../../views/Signup/VerifyDialog.view'
import EventEmitter from "../../libs/Events.utils";
import {updateTitle} from "../../libs/general.utils";
import RouteNames from "../../routes/Route.name";

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}


const useStyles = {
    btnColor: {
        backgroundColor: 'white',
        marginTop: '20px',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    btnBottom: {
        backgroundColor: 'white',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '10px',
        marginLeft: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    dialog: {
        padding: '10px 25px'
    },
    colorButton: {
        padding: '8px 35px',
        color: 'white',
        backgroundImage: 'linear-gradient(to right, #04AFB7 , #1BB380)',
        '&:hover': {
            color: 'white',
            backgroundColor:'linear-gradient(to right, #04AFB7 , #1BB380)',
        }
    },
    signUp: {
        color: '#2CB5A5',
        marginLeft: '10px',
        textDecoration:'none',
        fontWeight:'600'
    }

};

class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            dialog_open: false,
        };
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleForgotPassword = this._handleForgotPassword.bind(this);
        this._handleRoute = this._handleRoute.bind(this);
        // this._handleClose = this._handleClose.bind(this)
    }

    // async componentDidMount() {
    //     updateTitle('Login');
    // }

    _handleSubmit(data) {
        window.open(
            'http://91.205.173.97:2477',
            '_blank'
        );
        // this.props.history.push(RouteNames.otp)
        // serviceLoginUser(data).then((val) => {
        //     if (!val.error) {
        //         this.props.actionLoginUser(val.data);
        //     } else {
        //         EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Invalid Username/Password', type: 'error'});
        //     }
        // });
    }

    _handleForgotPassword() {
        // const {is_authenticated} = this.props;
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    // _handleClose() {
    //     this.setState({open: !this.state.open});
    // }

    _handleRoute(){
        this.props.history.push('/signup');
    }

    // _handleDialog() {
    //
    // }

    render() {
        const {handleSubmit, classes} = this.props;
        return (
            <div className={styles.mainLoginView}>
                <div className={styles.loginFlex1}>
                    {/*<div style={{color: 'white', fontSize: '30px', fontWeight: '500', textAlign: 'center'}}>*/}

                    {/*</div>*/}
                    <div style={{paddingLeft:'100px'}}>
                        <div className={styles.mainHeading}>
                            Welcome to India's first<br/>
                            e-manufacturing hub
                        </div>
                        {/*<br/>*/}

                        <div className={styles.list}>
                            <ul className={styles.circle}>
                                <li className={styles.val}>5000+ products portfolio</li>
                                <li className={styles.val}>Verified customers and manufactures</li>
                                <li className={styles.val}>Payment protection plan</li>
                                <li className={styles.val}>Quality assurance with proprietary checks</li>
                                <li className={styles.val}>Easy EMI based payment options</li>
                                <li className={styles.val}>Track your orders progress in realtime</li>
                            </ul>
                        </div>

                        <br/>
                        <br/>
                        <div>
                            <div className={styles.noacct}>If you didn't have account please</div>

                            <ButtonBase className={styles.looking} onClick={this._handleRoute}>
                                Signup
                            </ButtonBase>
                        </div>

                        <div className={styles.bottomContainer}>

                        </div>
                    </div>
                </div>
                <div className={styles.loginFlex2}>
                    {/*<div>*/}
                    {/*    <div style={{textAlign: 'center',marginTop:'40px'}}>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.marginCenter}>
                        <h1 className={styles.headingText}>Login to Factory Grid</h1>
                        <br/>
                        <div style={{marginTop: '10px'}}>
                            <div className="google-btn">
                                <div className="google-icon-wrapper">
                                    <img src={require('../../assets/img/tuxi/ic_google.png')} className='google-icon'/>
                                </div>
                                <p className="btn-text"><b>Sign in with Google</b></p>
                            </div>
                            {/*<ButtonBase className={styles.googleButton}>*/}
                            {/*    <div className={styles.iconImg}>*/}
                            {/*        <img src={require('../../assets/img/tuxi/ic_google.png')}/>*/}
                            {/*    </div>*/}

                            {/*    <div className={styles.textContainer}>*/}
                            {/*        Sign in with Google*/}
                            {/*    </div>*/}
                            {/*</ButtonBase>*/}
                        </div>
                        <div className={styles.loginText}>or login using your registered email:</div>
                        <br/>
                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            {/*<div className={styles.loginSignupText}>Login</div>*/}
                            <div>
                                <div>
                                    <Field fullWidth={true}
                                           name="email"
                                           component={renderTextField}
                                           // margin={'dense'}
                                           label="Enter your email id"/>
                                </div>

                                <div style={{marginTop:'30px'}}>
                                    <Field fullWidth={true} name="password"
                                           component={renderPasswordField}
                                           // margin={'dense'}
                                           label="Enter your password"/>
                                </div>
                                <div style={{ display: 'flex', justifyContent:'flex-end',marginTop:'15px' }}>
                                    <span className={styles.bottomSignup}><ButtonBase onClick={this._handleForgotPassword} className={styles.forgotBtn}>Forgot Password?</ButtonBase></span>
                                </div>
                                {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
                                    {/*<span className={styles.bottomSignup}>Don't have an account ? <Link to='/signup'>Sign Up here</Link></span>*/}
                                    {/*<span className={styles.bottomSignup}><ButtonBase*/}
                                        {/*onClick={this._handleForgotPassword} className={styles.forgotBtn}>Forgot Password?</ButtonBase></span>*/}
                                {/*</div>*/}
                                <br/>
                                <br/>
                                <div>
                                    <ButtonBase  type="submit" className={styles.login}>
                                        Login
                                    </ButtonBase>
                                </div>
                            </div>
                        </form>
                        <br/>
                        <div className={styles.privacy}>
                            *By signing up you agree to all <Link to="/">terms and conditions</Link> & <Link to={"/"}>privacy policy</Link>
                        </div>
                    </div>
                    <div></div>
                </div>
                <VerifyDialog
                open={this.state.dialog_open}
                handleClose={this._handleForgotPassword}/>
                <DashboardSnackbar/>
            </div>
        );
    }
}

LoginView = reduxForm({
    form: 'LoginPage',  // a unique identifier for this form
    validate,
    onSubmitFail: errors => {
        EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Credentials', type: 'error'});

    }
})(LoginView);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLoginUser: actionLoginUser
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(withStyles(useStyles)(LoginView));
