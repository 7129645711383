import React, {Component} from 'react'
import styles from './ReviewCard.module.css'
import Paper from '@material-ui/core/Paper';
import {Update as Duration} from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';

class ReviewCard extends Component {
    render() {
        return (
            <div className={styles.margin}>
                <Paper className={styles.newcontainer}>
                    <div className={styles.flex}>
                        <div>
                            <img src={require('../../../../assets/img/tuxi/profile_image.png')} className={styles.profileIcon}/>
                        </div>
                        <div className={styles.innerFlex}>
                            <div>
                                <div className={styles.title}>Jonathan Walker</div>
                                <a href="" className={styles.tourName}>Eiffel Tower Guided Summit Tour</a>

                            </div>
                            <div style={{marginTop:'15px'}}>
                                <div className={styles.duration}>
                                    <Duration fontSize={'0.4rem'} className={styles.ratingColor}/> <span className={styles.timePeriod}>2 April,2020</span>
                                </div>
                                <div className={styles.rating}>
                                    <Rating className={styles.ratingColor} value={4} readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={styles.dummy}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it
                        </p>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default ReviewCard