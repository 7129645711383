import React,{Component} from 'react'
import styles from './Vehicle.module.css'

class Vehicle extends Component{
    constructor(props) {
        super(props);

        this.state = {
        }

        this._handleClick = this._handleClick.bind(this)
    }

    _handleClick(){
        this.props.handleClick(this.props.category, this.props.val.title)
    }

    render(){
        return(
            <div className={styles.innerFlex}>
                <img src={ this.props.checked ? this.props.val.blueIcon : this.props.val.greyIcon} onClick={this._handleClick} style={{height:'20px'}} />
                <div style={this.state.checked ? {color:'#2196F3'}:{}} className={styles.categories}>{this.props.val.title}</div>
            </div>
        )
    }
}

export default Vehicle
