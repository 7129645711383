/**
 * Created by charnjeetelectrovese@gmail.com on 10/11/2019.
 */
import React, {Component} from 'react';
import {ButtonBase} from '@material-ui/core';
import styles from './style.module.css';
import Slider from "rc-slider";
import data from '../../../data';
import {getRandomSubarray} from "../../../libs/general.utils";

class Language extends Component{
    constructor(props){
        super(props);
        this.state={
        }
        this._handleClick = this._handleClick.bind(this)
    }
    _handleClick(){
        this.props.handleClick(this.props.data.value);
    }

    render(){

        return(
            <div className={styles.vehicles} style={this.props.checked ? {color: '#2196F3'}: {}} onClick={this._handleClick}>
                <input type="checkbox" name={"v1"} value={"v1"} checked={this.props.checked} />
                <span className={styles.cars} >
                                {this.props.data.title}
                        </span>
            </div>

        )
    }
}

class PriceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.data = data.filters.language.data;
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    _handleClick (value) {
        const index  = this.state.data.indexOf(value);
        const tempData = this.state.data;

        if (index >= 0) {
            tempData.splice(index, 1);
        } else {
            tempData.push(value);
        }

        this.setState({
            data: tempData,
        });

    }
    _renderList() {
        const { list_data } = this.props;
        return list_data.slice(0, 5).map((val) => {
            const isThere =  this.state.data.indexOf(val.value) >= 0;
            return <Language
                checked={isThere}
                data={val}
                handleClick={this._handleClick}
            />
        })
    }

    render() {
        return (
            <div>
                <div className={styles.outerFlex}>
                    <div className={styles.innFlex}>
                        <div style={{}}>
                            Select Language
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <ButtonBase onClick={() => {  this.props.handleClear('language'); }}>
                          <span className={styles.rightBtn}>
                          Clear
                              </span>
                        </ButtonBase>
                        <div className={styles.rightBtn} style={{ padding: '5px 0px' }}>
                            |
                        </div>
                        <ButtonBase  onClick={(e) => {
                            this.props.handleChange('language', this.state.data);
                        }}>
                          <span className={styles.rightBtn}>
                          Apply
                              </span>
                        </ButtonBase>
                    </div>
                </div>
                {/*Filter Content*/}
                <div className={styles.contentContainer}>
                    {this._renderList()}
                </div>
            </div>
        );
    }
}

export default PriceFilter;
