import React, {Component} from 'react';
import styles from './Quote.module.css';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {MenuItem,ButtonBase} from '@material-ui/core'
import {Link} from 'react-router-dom';
import {actionLoginUser} from "../../actions/Auth.action";
import {withStyles} from "@material-ui/core/index";
import {renderOutlinedSelectField, renderOutlinedTextField} from "../../libs/redux-material.utils";
import {ArrowRightAlt} from '@material-ui/icons'
import Delivery from "./delivery/Delivery.component";
import RouteNames from "../../routes/Route.name";
import history from "../../libs/history.util";

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name','category','quantity','location'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

class Quote extends Component {
        constructor(props){
            super(props);
            this.state={

            }
            this._handleSubmit = this._handleSubmit.bind(this);
        }

        _handleSubmit(){
            history.push(RouteNames.thank_you);
        }

        _renderquote(){
            const {handleSubmit} = this.props;
            return(
                <form onSubmit={handleSubmit(this._handleSubmit)}>

                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>I am looking for?</label>
                            <Field fullWidth={true} name="name" component={renderOutlinedTextField}
                                   margin={'dense'} type={'text'}
                                   label=" Name"
                            />
                        </div>
                    </div>
                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>Category</label>
                            <Field fullWidth={true} name="category" component={renderOutlinedSelectField}
                                   margin={'dense'}
                                   label="Category">
                                <MenuItem value={'A'}>A</MenuItem>
                                <MenuItem value={'B'}>B</MenuItem>
                                <MenuItem value={'C'}>C</MenuItem>
                            </Field>
                        </div>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>Quantity</label>
                            <Field fullWidth={true} name="quantity" component={renderOutlinedTextField}
                                   margin={'dense'} type={'number'}
                                   label=" Quantity"/>
                        </div>
                    </div>
                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>Location</label>
                            <Field fullWidth={true} name="location" component={renderOutlinedTextField}
                                   margin={'dense'} type={'text'}
                                   label="Location"/>
                        </div>
                    </div>

                    {/*{this._renderDelivery()}*/}

                    <ButtonBase className={styles.save} type={'submit'}>
                        Get Quotes<ArrowRightAlt/>
                    </ButtonBase>
                </form>
            )
        }

    _renderDelivery(){
            return(
                <div>
                    <Delivery/>
                </div>
            )
    }

        render(){
            const {handleSubmit, classes} = this.props;
            return(
                <div>
                    <div className={styles.topBackground}>
                        <div className={'ncontainer'}>
                            <div className={styles.fill}>Fill in more information so that we can get your best prices</div>
                            <div className={styles.contact}>Contact skilled eFactory within minutes.View profiles,ratings,portfolios and chat with them.<br/>
                            Pay the efactory only when you are 100% satisfied with their work.
                            </div>
                            <div className={styles.bgWhite}>
                                <div className={styles.quoteFlex}>
                                    <div style={{flex:2.5,marginRight:'15px'}}>
                                        {this._renderquote()}
                                        <div>

                                        </div>
                                    </div>
                                    <div style={{flex:1.5,marginLeft:'15px'}}>
                                        <img src={require('../../assets/img/factory/ad.png')} style={{width:'100%',marginTop:'30px'}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
}

Quote = reduxForm({
    form: 'Quote',  // a unique identifier for this form
    validate,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Login Credentials', type: 'none'});
    //
    // }
})(Quote);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}


export default connect(null, mapDispatchToProps)(Quote);