import React, {Component} from 'react';
import styles from './Chips.module.css'
import Close from '@material-ui/icons/Close'
import ButtonBase from "@material-ui/core/ButtonBase";


class Chips extends Component {
    constructor(props){
        super(props);

        this.state={}
        this._handleDelete = this._handleDelete.bind(this)
    }

    _handleDelete(){
        this.props.handleDelete(this.props.data)
    }

    render(){
        return(
            <div>
                <div className={styles.chipContainer}>
                    <div className={styles.chips}>
                        <div style={{fontSize:'14px',whiteSpace:'nowrap'}}>{this.props.data}</div>
                        <div className={styles.closeIcon}>
                            <ButtonBase>
                                <Close fontSize={'small'} onClick={this._handleDelete}/>
                            </ButtonBase></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Chips