import React, {Component} from 'react';
import styles from './HomeDesktop.module.css';
import staticData from '../../../data';
import {KeyboardArrowRight,KeyboardArrowLeft} from '@material-ui/icons'
import  { ButtonBase } from "@material-ui/core";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Coupons , Card} from '../../../components/index.component';
import TourSlider from '../Desktop/TourSlider/TourSlider.view'
import CategoryCard from './Category/CategoryCard.component'
import Provider from './Providers/Providers.view'
import imgdata from '../../../data'
import Footer from '../Footer/Footer.view.js'
import Language from '../Footer/Language/Language.view'
import TypeSlider from './TypeSlider/TypeSlider.view';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {actionFetchTours} from "../../../actions/Tours.action";

class HomeDesktop extends Component {
    constructor (props) {
        super (props);
        this.tourSlider = React.createRef();
        this.tourCoupon = React.createRef();
    }
    componentDidMount(){
        this.props.actionFetchTours();
    }

    render(){
        const {tours} = this.props;
        return (
            <div>
               <div style={{height:'65px'}}></div>
                <div>
                    <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true}>
                        <div>
                    <img src={require('../../../assets/img/desktop/image1@2x.png')} style={{width:'100%'}}/>
                    {/*<div style={{backgroundImage: 'url(' + require('../../../assets/img/desktop/image1@2x.png') + ')'}}/>*/}
                        </div>

                        <div>
                            <img src={require('../../../assets/img/desktop/image1@2x.png')} style={{width:'100%'}}/>
                        </div>
                    </Carousel>
                </div>

               <div className={styles.mainContainer}>
                   <div className={styles.upperFlex}>
                    <div>
                        <div className={styles.mainHeading}>TOP RATED TOURS</div>
                    </div>
                       <div className={styles.arrowsContainer}>
                           <ButtonBase onClick={() => {
                               this.tourSlider.current.handlePrev();
                           }}>
                           <KeyboardArrowLeft style={{color:'#2196F3'}}/>
                           </ButtonBase>

                           <ButtonBase onClick={() => {
                                   this.tourSlider.current.handleNext();
                           }}>
                           <KeyboardArrowRight style={{color:'#2196F3'}}/>
                           </ButtonBase>
                       </div>
                   </div>

                   <div className={styles.toursContainer}>
                       <TourSlider ref={this.tourSlider} tours={tours} className={styles.cardCon} />
                       {/*{staticData.cardsData.map(val => <Card className={styles.cardCon} val={val}/>)}*/}
                   </div>

                   <div className={styles.offersShadow}>
                       <div className={styles.upperFlex}>
                           <div>
                               <div className={styles.mainHeading}>PROMOTIONS AND OFFERS</div>
                           </div>
                           <div className={styles.arrowsContainer}>
                               <ButtonBase onClick={() => {
                                   this.tourCoupon.current.handlePrev();
                               }}>
                                   <KeyboardArrowLeft style={{color:'#2196F3'}} />
                               </ButtonBase>

                               <ButtonBase  onClick={() => {
                                   this.tourCoupon.current.handleNext();
                               }}>
                                   <KeyboardArrowRight style={{color:'#2196F3'}}/>
                               </ButtonBase>
                           </div>
                       </div>
                       <div className={styles.toursContainer}>
                       <Coupons data={staticData.coupons} ref={this.tourCoupon}/>
                       </div>
                   </div>
               </div>

                   <TypeSlider>

                   </TypeSlider>

                <div className={styles.mainContainer}>
                   <div>
                       <div className={styles.mainHeading} style={{display:'inline-block'}}>Tour Categories</div>
                       <div className={styles.innerContainer}>
                       <CategoryCard data={imgdata.categoriesData}/>
                       </div>
                   </div>

                   <div>
                       <div className={styles.mainHeading} style={{display:'inline-block'}}>EXPORE POPULAR CITIES</div>
                       <div className={styles.innerContainer}>
                           <CategoryCard data={imgdata.citiesData}/>
                       </div>
                   </div>

                   <div>
                       <div className={styles.mainHeading} style={{display: 'inline-block'}}>GO LITERALLY ANYWHERE FROM
                           ANYWHERE
                       </div>
                       <div className={styles.desciptionFlex}>
                           <div>
                               <div className={styles.heading}>Smart</div>
                               <div className={styles.innerText}>
                                   with access to millions of journeys , you <br/> can quickly find people nearby <br/>
                                   travelling your way
                               </div>
                           </div>
                           <div>
                               <div className={styles.heading}>Simple</div>
                               <div className={styles.innerText}>
                                   Enter your exact address to find the <br/> perfect ride. Choose who you'd like to
                                   <br/> travel with. And book!
                               </div>
                           </div>
                           <div>
                               <div className={styles.heading}>
                                   Seamless
                               </div>
                               <div className={styles.innerText}>
                                   Get to your exact destination , without <br/> the hassle. No queues. No waiting <br/>
                                   around
                               </div>
                           </div>
                       </div>
                   </div>
                </div>

                <div style={{backgroundColor:'#2196F3',marginBottom:'10px'}}>
                    <div style={{width: '90%', maxWidth: '1024px', margin: 'auto'}}>
                        <div style={{height:'15vh'}}>
                            <div className={styles.bottomContainer}>
                                <div className={styles.bookingText}>
                                    Looking For Taxi service ? You can book via Get a tour
                                </div>
                                <div>
                                    <ButtonBase className={styles.bottomButtom}>
                                        <div style={{color:'#2196F3',fontWeight:'500',fontSize:'20px'}}>Book Taxi</div>
                                    </ButtonBase>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                   <div className={styles.mainContainer}>
                       <div>
                           <div className={styles.mainHeading} style={{display: 'inline-block'}}>PROVIDERS</div>
                       </div>

                       <div className={styles.providerFlex}>
                       {imgdata.providers.map((val)=> {
                           return <Provider data = {val}/>
                       })}
                       </div>
                   </div>


                <div className={styles.footer}>
                <div className={styles.footerContainer} >
                    <div>
                        <Language/>
                    </div>

                    <Footer/>
                    <div className={styles.bottomFlex}>
                        <div> Copyright &copy; 2019 . Get A Tour. All Rights Reserved.</div>
                        <div className={styles.policy} >
                            <div className={styles.border}>
                                Privacy Policy
                                <div></div>
                            </div>
                            <div className={styles.border}>
                                Terms Of Use
                            </div>
                            <div style={{paddingLeft:'10px'}}>
                                Legal
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionFetchTours: actionFetchTours,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        tours: state.tours.tours,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeDesktop);