/**
 * Created by charnjeetelectrovese@gmail.com on 12/13/2018.
 */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import OtpInput from 'react-otp-input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from "classnames";
import styles from './NewOtp.module.css';
import {
    renderTextField,
    renderOutlinedTextField,
    renderOutlinedSelectField,
    renderCountryContact, renderCheckbox, renderCustomCheckbox, renderBlueCheckbox
} from '../../../libs/redux-material.utils';
import {Button, withStyles, ButtonBase,MenuItem} from '@material-ui/core';
// import {serviceLoginUser} from "../../services/Auth.service";
// import {actionLoginUser} from '../../actions/Auth.action';
// import DashboardSnackbar from "../../components/Snackbar.component";
import {Link} from 'react-router-dom';

import EventEmitter from "../../../libs/Events.utils";
// import {updateTitle} from "../../libs/general.utils";

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password','name','contact','industry','order','manufacturer'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}


const useStyles = {
    btnColor: {
        backgroundColor: 'white',
        marginTop: '20px',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    btnBottom: {
        backgroundColor: 'white',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '10px',
        marginLeft: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    dialog: {
        padding: '10px 25px'
    },
    colorButton: {
        padding: '8px 35px',
        color: 'white',
        backgroundImage: 'linear-gradient(to right, #04AFB7 , #1BB380)',
        '&:hover': {
            color: 'white',
            backgroundColor:'linear-gradient(to right, #04AFB7 , #1BB380)',
        }
    },
    signUp: {
        color: '#2CB5A5',
        marginLeft: '10px',
        textDecoration:'none',
        fontWeight:'600'
    }

};

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: false,
            otp: ''
        };
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleForgotPassword = this._handleForgotPassword.bind(this);
        this._handleRoute = this._handleRoute.bind(this);
        // this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // async componentDidMount() {
    //     updateTitle('Login');
    // }

    _handleSubmit(data) {

    }

    _handleForgotPassword() {
        this.props.history.push('/forgot/password');
    }

    _handleRoute(){
        this.props.history.push('/login');
    }

    handleChange(otp){
        this.setState({otp:otp})
    }

    render() {
        const {handleSubmit, classes} = this.props;
        return (
            <div className={styles.mainLoginView}>
                <div className={styles.loginFlex1}>
                    {/*<div style={{color: 'white', fontSize: '30px', fontWeight: '500', textAlign: 'center'}}>*/}

                    {/*</div>*/}


                    <div style={{paddingLeft:'100px'}}>
                        <div className={styles.mainHeading}>
                            Welcome to India's first<br/>
                            e-manufacturing hub
                        </div>
                        {/*<br/>*/}

                        <div className={styles.list}>
                            <ul className={styles.circle}>
                                <li className={styles.val}>5000+ products portfolio</li>
                                <li className={styles.val}>Verified customers and manufactures</li>
                                <li className={styles.val}>Payment protection plan</li>
                                <li className={styles.val}>Quality assurance with proprietary checks</li>
                                <li className={styles.val}>Easy EMI based payment options</li>
                                <li className={styles.val}>Track your orders progress in realtime</li>
                            </ul>
                        </div>

                        <br/>
                        <br/>
                        <div>
                            <div className={styles.noacct}>Already Registered?</div>

                            <ButtonBase className={styles.looking} onClick={this._handleRoute}>
                                Login
                            </ButtonBase>
                        </div>

                        <div className={styles.bottomContainer}>

                        </div>
                    </div>
                </div>


                <div className={styles.loginFlex2}>
                    {/*<div>*/}
                    {/*    <div style={{textAlign: 'center',marginTop:'40px'}}>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.marginCenter}>
                        <h1 className={styles.headingText}>Verification Code</h1>
                        <div className={styles.loginText}>We have send the OTP to your registered Mobile Number +99998-88888</div>
                        <br/>
                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            <div>
                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    hasErrored
                                    isInputNum={true}
                                    separator={<span></span>}
                                    inputStyle={styles.otpField}
                                    containerStyle={styles.contOtp}
                                />
                            </div>

                            <div className={styles.resend}>
                                <span>Didn't Receive OTP ? </span>
                                <ButtonBase type="submit" className={styles.resent}>
                                    Resend OTP
                                </ButtonBase>
                            </div>

                            {/*<div className={styles.verification}>*/}
                            {/*    We have also sent a verification to your email address<br/>*/}
                            {/*    which you can confirm later*/}
                            {/*</div>*/}

                            <div className={styles.sign}>
                                <ButtonBase type="submit" className={styles.login}>
                                    Confirm
                                </ButtonBase>
                            </div>
                        </form>

                        {/*<div className={styles.incorrect}>*/}
                        {/*    Incorrect details?<Link to='/'>Submit form again</Link>*/}
                        {/*</div>*/}

                        {/*<div className={styles.privacy}>*/}
                        {/*    *By signing up you agree to all <Link to="/">terms and conditions</Link> & <Link to={"/"}>privacy policy</Link>*/}
                        {/*</div>*/}
                    </div>

                    <div>
                    </div>
                </div>
                {/*<DashboardSnackbar/>*/}
            </div>
        );
    }
}

SignUp = reduxForm({
    form: 'LoginPage',  // a unique identifier for this form
    validate,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Login Credentials', type: 'none'});
    //
    // }
})(SignUp);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // actionLoginUser: actionLoginUser
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(withStyles(useStyles)(SignUp));
