/* eslint-disable indent,linebreak-style */
/**
 * Created by charnjeetelectrovese@gmail.com on 9/15/2017.
 */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AuthReducer from './Auth.reducer';
import CommonReducer from './Common.reducer';
import FilterReducer from './Filter.reducer';
import TourReducer from './Tours.reducer';
import CartReducer from './Cart.reducer';
import WishListReducer from './Wishlist.reducer';

const rootReducer = combineReducers({
    state: (state = {}) => state,
    form: formReducer,
    auth: AuthReducer,
    common: CommonReducer,
    filter: FilterReducer,
    tours: TourReducer,
    wishlist: WishListReducer,
    cart: CartReducer,
    // form: formReducer,

});

export default rootReducer;
