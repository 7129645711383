import React,{Component} from 'react'
import styles from './MyProfile.module.css';
import {Button, MenuItem, withStyles, FormControlLabel, Switch,IconButton,ButtonBase} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import {
    renderOutlinedTextField,
    renderCountryContact,
    renderOutlinedSelectField
} from "../../libs/redux-material.utils";
import Steps from "./Steps/Steps.view";
import Business from './components/Business/Business.view';
import KYC from './components/KYC/KYC.view';
import Banking from './components/Banking/Banking.view'
import Brands from "./components/Brands/Brands.view";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['password','confirm_password','new_password','first_name','last_name','email','contact','address','street','city','dob','country'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if(values.confirm_password && values.confirm_password != values.new_password) {
        errors.confirm_password = 'Password should match'
    }
    return errors;
};


class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_active: true,
        }
        this._handleActive = this._handleActive.bind(this);
    }

    _handleActive() {
        this.setState({
            is_active: !this.state.is_active,
        });
    }

    _renderMyProfile(){
        return(
            <div>
            <div className={styles.mainFlex}>
                <div className={styles.details}>
                    <img src={require('../../assets/img/factory/person_img.png')} height={100}/>
                    <div className={styles.personalInfo}>
                        <div className={styles.name}>Pranav Bhasin</div>
                        <div className={styles.common}>+98765-43210</div>
                        <div className={styles.common}>pranav@electrovese.com</div>
                    </div>
                </div>
                <div>
                    <div className={styles.common}>Regular Member <a href='/'>Upgrade</a></div>
                    <div className={styles.common}>Member Since: <span className={styles.name}>June 2020</span></div>
                </div>
            </div>
                <div style={{marginLeft:'13%'}} className={styles.common}>
                    Industries you are interested in
                </div>
            </div>
        )
    }

    render(){
        return(
            <div>
                {/*<div style={{height:'40px'}}></div>*/}
                <div className={'container'}>
                    <div className={styles.profileFlex}>
                        <div className={styles.heading}>
                            My Profile
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch color={'primary'} checked={this.state.is_active} onChange={this._handleActive.bind(this)}
                                            value="is_active"/>
                                }
                                label="Manufacturer Profile ?"
                            />
                        </div>
                    </div>

                    <div className={styles.completeContainer}>
                        <div className={styles.profile}>Complete your profile</div>
                        <Steps/>
                    </div>

                    <div className={styles.borderGrey}>
                        <div className={styles.appBarFlex}>
                            <div className={styles.title}>Personal Profile</div>
                            <div className={styles.editFlex}>
                                <img src={require('../../assets/img/factory/pp.png')} height={20}/>
                                <ButtonBase className={styles.edit}>
                                    Edit
                                </ButtonBase>
                            </div>
                        </div>
                        <div className={styles.individuals}>
                            {this._renderMyProfile()}
                        </div>
                    </div>

                    <div className={styles.borderGrey}>
                        <div className={styles.greyFlex}>
                            <div className={styles.title}>Business Information</div>
                            <div className={styles.editFlex}>
                                <img src={require('../../assets/img/factory/grey_icon.png')} height={20}/>
                                <ButtonBase className={styles.editing}>
                                    Edit
                                </ButtonBase>
                            </div>
                        </div>
                        <div className={styles.individuals}>
                            <Business/>
                        </div>
                    </div>

                    <div className={styles.borderGrey}>
                        <div className={styles.greyFlex}>
                            <div className={styles.title}>KYC Documents</div>
                            <div className={styles.editFlex}>
                                <img src={require('../../assets/img/factory/grey_icon.png')} height={20}/>
                                <ButtonBase className={styles.editing}>
                                    Edit
                                </ButtonBase>
                            </div>
                        </div>
                        <div className={styles.individuals}>
                            {<KYC/>}
                        </div>
                    </div>

                    <div className={styles.borderGrey}>
                        <div className={styles.greyFlex}>
                            <div className={styles.title}>Banking Details</div>
                            <div className={styles.editFlex}>
                                <img src={require('../../assets/img/factory/grey_icon.png')} height={20}/>
                                <ButtonBase className={styles.editing}>
                                    Edit
                                </ButtonBase>
                            </div>
                        </div>
                        <div className={styles.individuals}>
                            {<Banking/>}
                        </div>
                    </div>

                    <div className={styles.borderGrey}>
                        <div className={styles.greyFlex}>
                            <div className={styles.title}>Brand Details</div>
                            <div className={styles.editFlex}>
                                <img src={require('../../assets/img/factory/grey_icon.png')} height={20}/>
                                <ButtonBase className={styles.editing}>
                                    Edit
                                </ButtonBase>
                            </div>
                        </div>
                        <div className={styles.individuals}>
                            <Brands/>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

const myprofile = reduxForm({
    form:'profile',
    validate,
})(MyProfile);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(myprofile)