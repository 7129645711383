import React, {Component} from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import styles from './ImageGallery.module.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const images = {
    provider: [
        require('../../assets/img/attachments/1.png'),
        require('../../assets/img/attachments/2.png'),
        require('../../assets/img/attachments/3.png'),
        require('../../assets/img/attachments/4.png'),

    ],
    user: [
        require('../../assets/img/attachments/5.png'),
        require('../../assets/img/attachments/6.png'),
        require('../../assets/img/attachments/7.png'),
        require('../../assets/img/attachments/8.png'),
    ]
}

class ImageGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            provider_selected: true
        }
        this._handleUser = this._handleUser.bind(this)
    }

    _handleUser(type) {
        this.setState({
            provider_selected: type
        })
    }

    _renderPhotos() {
        const {images, user_images} = this.props;
        const tempImages = this.state.provider_selected ? images : user_images;
        if (tempImages.length > 0) {
            return tempImages.map((val) => {
                return (
                    <div className={styles.galleryContainer}>
                        <img src={val} className={styles.galleryImg}/>
                    </div>
                )
            })
        } return ( <div className={styles.noDataText}>No Images Found</div> )
    }

    render() {
        const {classes} = this.props
        return (
            <div>
                <div style={{height: '65px'}}></div>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            Gallery
                        </Typography>
                    </Toolbar>
                </AppBar>
                <br/>
                <div className={styles.galleryFlex}>
                    <div>
                        <ButtonBase className={this.state.provider_selected ? styles.providerBtn : styles.userBtn}
                                    onClick={this._handleUser.bind(this, true)}>
                            <div className={styles.text}>
                                By Provider
                            </div>
                        </ButtonBase>
                    </div>

                    <div>
                        <ButtonBase className={!this.state.provider_selected ? styles.providerBtn : styles.userBtn}
                                    onClick={this._handleUser.bind(this, false)}>
                            <div className={styles.text}>
                                By User
                            </div>
                        </ButtonBase>
                    </div>
                </div>

                <div>
                    {this._renderPhotos()}
                </div>
            </div>
        )
    }
}


export default ImageGallery
