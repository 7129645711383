import React, {Component} from 'react';
import ButtonBase from "@material-ui/core/ButtonBase";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Search,Cancel,ExpandMore} from '@material-ui/icons'
import styles from './Search.module.css'

class SearchCities extends Component{
    constructor(props) {
        super(props)
        this.state = {
            sorting: 'RECOMMENDED'
        }
        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(e) {
        this.setState({
            sorting: e.target.value
        })
    }

    render(){
        return(
          <div className={styles.mainContainer}>
            <div className={styles.outerContainer}>
                <div style={{display:'inline-block'}}>
                    <div className={styles.searchFlex}>
                        <input className={styles.inputBox} type="text" name="q"
                               autoComplete="off" placeholder="Where are you going?" value=""/>
                        <ButtonBase className={styles.cancelButton}>
                            <Cancel className={styles.cancelIcon}/>
                        </ButtonBase>
                        <ButtonBase className={styles.searchButton}>
                            <Search/>
                        </ButtonBase>
                    </div>
                </div>

                <div className={styles.sortFlex}>
                    <div className={styles.sortText}>
                    Sort by:
                    </div>
                    <div className={'sort'}>
                        <FormControl variant={'outlined'} className={styles.selectWidth}>
                            <Select
                                disableUnderline
                                value={this.state.sorting}
                                onChange={this._handleChange}
                                IconComponent={ExpandMore}
                            >
                                <MenuItem value={'RECOMMENDED'}>Recommended</MenuItem>
                                <MenuItem value={'PRICE'}>Price</MenuItem>
                                <MenuItem value={'RATING'}>Rating</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

            </div>
              <hr/>
          </div>

        )
    }
}

export default SearchCities