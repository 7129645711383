import React, {Component} from 'react';
import styles from './Profile.module.css';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import {
    renderOutlinedTextField,
    renderCountryContact,
    renderOutlinedSelectField
} from "../../libs/redux-material.utils";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {ButtonBase,MenuItem} from '@material-ui/core';
import OtpDialog from '../Otp/OtpDialog.view'
import Aside from './Aside/Aside.view'


const validate = (values) => {
    const errors = {};
    const requiredFields = ['password','confirm_password','new_password','first_name','last_name','email','contact','address','street','city','dob','country'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if(values.confirm_password && values.confirm_password != values.new_password) {
        errors.confirm_password = 'Password should match'
    }
    return errors;
};

class Profile extends Component{
    constructor(props){
        super(props);

        this.state = {
            profile:true,
            password:false,
            selectedDate: new Date(),
            is_edit:false,
            open: false,
            dialog_open: false,
        }
        this._handleSubmit = this._handleSubmit.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this);
        this._handleDialog = this._handleDialog.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleSideClick = this._handleSideClick.bind(this);
    }

    handleDateChange(data){
        this.setState({selectedDate: data})
    }

    _handleSubmit(data) {

    }

    _handleClose() {
        this.setState({open: !this.state.open});
    }

    _handleDialog() {
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    _handleSideClick(type) {
        this.setState({
            profile: type == 'PROFILE'
        })
    }


    _renderProfile(){
        const {handleSubmit} = this.props;
        const { profile } = this.state;
        if(profile){
            return(
                <div>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className={styles.headingFlex}>
                            <div>
                                <h2>Profile Details</h2>
                            </div>
                            <div>
                                <ButtonBase className={styles.editButton}>
                                    Edit Details
                                </ButtonBase>
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">First Name <span className={styles.important}>*</span></label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="first_name"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"First Name"}
                                />
                            </div>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">
                                    Last Name
                                    <span className={styles.important}>*</span>
                                </label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="last_name"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Last Name"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <div className={styles.verifyFlex}>
                                    <label className={styles.headerLabel} htmlFor="">
                                    Email Address <span className={styles.important}>*</span>
                                    </label>
                                    <div className={styles.verificationText}>
                                        Verification Pending
                                    </div>
                                </div>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="email"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Email"}
                                />
                            </div>
                            <div className={'formGroup'}>
                                <div className={styles.verifyFlex}>
                                    <label className={styles.headerLabel} htmlFor="">
                                        Mobile No. <span className={styles.important}>*</span>
                                    </label>
                                    <div className={styles.verifiedText}>
                                        Verified
                                    </div>
                                </div>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="contact"
                                       component={renderCountryContact}
                                       type={'text'}
                                       margin={'dense'}
                                       errorText="Required"
                                       label="Contact"/>
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">
                                    Address Lane
                                </label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="address"
                                    component={renderOutlinedTextField}
                                    label={"Address"}
                                    multiline
                                    margin={"dense"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Street</label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="street"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Street"}
                                />
                            </div>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">City</label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="city"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"City"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Country</label>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="country"
                                    component={renderOutlinedSelectField}
                                    margin={"dense"}
                                    label={"Country"}>
                                    <MenuItem value={'INDIA'}>India</MenuItem>
                                </Field>
                            </div>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Date Of Birth</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disabled={this.state.is_edit}
                                    disableFuture
                                    name='dob'
                                    openTo="year"
                                    format="dd/MM/yyyy"
                                    label="Date of birth"
                                    margin={"dense"}
                                    fullWidth
                                    inputVariant="outlined"
                                    views={["year", "month", "date"]}
                                    value={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <span className={styles.bottomText}> <span className={styles.important}>*</span> fields are required</span>
                            </div>
                        </div>

                        <div style={{float:'right',marginTop:'30px'}}>
                            <ButtonBase className={styles.saveButton} type={'submit'} onClick={this._handleDialog}>
                                Save Changes
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            )
        }
        else {
            return(
                <div>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>

                        <h1>Password</h1>
                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>Old Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="Password"/>
                            </div>
                        </div>

                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>New Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="new_password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="New Password"/>
                            </div>
                        </div>

                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>Retype New Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="confirm_password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="Retype New Password"/>
                            </div>
                        </div>

                        <div style={{float:'right'}}>
                            <ButtonBase className={styles.saveButton} type={'submit'}>
                               Save Changes
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            )
        }
    }

    render(){
        const { profile } = this.state;
        return(
            <div>
               <div className={styles.profileFlex}>
                   <div className={styles.leftSection}>
                        <Aside
                            isProfile={profile}
                            handleClick={this._handleSideClick}
                        />
                   </div>
                   <div className={styles.rightSection}>
                       {this._renderProfile()}
                   </div>
               </div>
                <OtpDialog open={this.state.dialog_open}  handleClose={this._handleDialog}/>
            </div>
        )
    }
}

const profile = reduxForm({
    form:'profile',
    validate,
})(Profile);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(profile)
