import React, {Component} from 'react';
import styles from './SearchCard.module.css';
import classNames from 'classnames';

class SearchCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            selected: false
        }
        this._handleSelection = this._handleSelection.bind(this);
    }
    _handleSelection(){
        this.setState({
            selected: !this.state.selected
        })
    }
    _renderSearch(){
            return(
                <div onClick={this._handleSelection} className={styles.container}>
                        <div className={!this.state.selected ? styles.grey : styles.green}>
                            {this.props.data}
                        </div>
                </div>
            )
    }

    render(){
        return(
            <div>
                {/*<div className={classNames(styles.bgSearch)}>*/}
                    {/*<div className={'ncontainer'}>*/}
                        {/*<h2 className={styles.top}>Top Searches</h2>*/}
                        {/*<div className={styles.searchFlex}>*/}
                            {/*{this._renderSearch()}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {this._renderSearch()}
            </div>
        )
    }
}

export default SearchCard