import React, {Component} from 'react';
import style from './TypeSlider.module.css';
import classNames from 'classnames';
import homeStyle from '../HomeDesktop.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class History extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let current = this.props.current;
        let items = this.props.items.map((el, index) => {
            let name = (index == current) ? 'active' : '';
            return (
                <li key={index}>
                    <button
                        className={name}
                        onClick={() => this.props.changeSilde(current, index)}
                    ></button>
                </li>
            )
        });

        return (
            <ul>{items}</ul>
        )
    }
}


class TypeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    image: require('../../../../assets/img/desktop/Land.png'),
                    title: 'Land Tour',
                    description: (
                        <p>Whether it is a tuk tuk or a convertible, a sports sedan or 7 seater, a 2 seater or an
                            electric rikshaw<br/> we cater everything that you will require</p>)
                },
                {
                    image: require('../../../../assets/img/desktop/Air.png'),
                    title: 'Air Tour',
                    description: (
                        <p>The best way to have the visit around the city and the coastal areas is from the bird's
                            eye.<br/>Witness the amazing city from the heights that you want from the variable options
                            available around you.</p>)
                }
                ,
                {
                    image: require('../../../../assets/img/desktop/Water.png'),
                    title: 'Water Tour',
                    description: (
                        <p>The city is surrounded by water and here is the best option to sail around the shore.<br/>
                            Travel like a king in your kind of vehicle.</p>)
                }
                ,
                {
                    image: require('../../../../assets/img/desktop/Walking.png'),
                    title: 'Walking Tour',
                    description: (
                        <p>There are a lot of things to do around the city and a lot of adventure while walking and
                            <br/> the amazing tour guides of Get A Tour will help you explore the city in the best
                            possible manner</p>)
                }
            ],
            current: 0,
            isNext: true
        };

        this.handlerPrev = this.handlerPrev.bind(this);
        this.handlerNext = this.handlerNext.bind(this);
        this.goToHistoryClick = this.goToHistoryClick.bind(this);
        this._handleTypeCard = this._handleTypeCard.bind(this);
    }

    handlerPrev() {
        let index = this.state.current,
            length = this.state.items.length;

        if (index < 1) {
            index = length;
        }

        index = index - 1;

        this.setState({
            current: index,
            isNext: false
        });
    }

    handlerNext() {
        let index = this.state.current,
            length = this.state.items.length - 1;

        if (index == length) {
            index = -1;
        }

        index = index + 1;

        this.setState({
            current: index,
            isNext: true
        });
    }

    _handleTypeCard(num) {
        console.log(num);
        let index = this.state.current,
            isNext = false;

        if (num > index) {
            isNext = true;
        }

        index = num;
        this.setState({
            current: index,
            isNext: isNext,
        });
    }

    goToHistoryClick(curIndex, index) {
        let next = (curIndex < index);
        this.setState({
            current: index,
            isNext: next
        });
    }

    render() {
        let index = this.state.current,
            isnext = this.state.isNext,
            data = this.state.items[index];

        return (
            <div className={style.app}>
                <div style={{width: '90%', maxWidth: '1024px', margin: 'auto'}}>
                    <div style={{textAlign: 'center'}} className={homeStyle.innerContainer}>
                        <div style={{fontSize: '20px', fontWeight: 600}}>EXPLORE THE TRAVELLER WITHIN</div>
                        <div style={{
                            borderBottom: '3px solid #1e90ff',
                            display: 'inline',
                            fontSize: '16px',
                            color: 'grey'
                        }}>The best way to travel the destination
                        </div>
                    </div>

                    <div className={homeStyle.categoryFlex}>
                        <div onClick={this._handleTypeCard.bind(this, 0)} className={homeStyle.toursFlex}>
                            <div
                                className={classNames(homeStyle.iconTour, this.state.current == 0 ? homeStyle.destinationTours : '')}>
                                <img src={require('../../../../assets/img/desktop/ic_land_unselected@2x.png')}
                                     className={homeStyle.tourIcon}/>
                            </div>
                            <div className={homeStyle.tourDescription}>Land Tour</div>
                        </div>

                        <div onClick={this._handleTypeCard.bind(this, 1)} className={homeStyle.toursFlex}>

                            <div className={classNames(
                                homeStyle.iconTour,
                                this.state.current == 1 ? homeStyle.destinationTours : '')
                            }>
                                <img src={require('../../../../assets/img/desktop/ic_air_unselected@2x.png')}
                                     className={homeStyle.tourIcon}/>
                            </div>
                            <div className={homeStyle.tourDescription}>Air Tour</div>
                        </div>
                        <div onClick={this._handleTypeCard.bind(this, 2)} className={homeStyle.toursFlex}>
                            <div className={classNames(
                                homeStyle.iconTour,
                                this.state.current == 2 ? homeStyle.destinationTours : '')
                            }>
                                <img src={require('../../../../assets/img/desktop/ic_ship_unselected@2x.png')}
                                     className={homeStyle.tourIcon}/>
                            </div>
                            <div className={homeStyle.tourDescription}>Water Tour</div>
                        </div>
                        <div onClick={this._handleTypeCard.bind(this, 3)} className={homeStyle.toursFlex}>
                            <div className={classNames(
                                homeStyle.iconTour,
                                this.state.current == 3 ? homeStyle.destinationTours : '')
                            }>
                                <img src={require('../../../../assets/img/desktop/ic_walking_unselected@2x.png')}
                                     className={homeStyle.tourIcon}/>
                            </div>
                            <div className={homeStyle.tourDescription}>Walking Tour</div>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor: '#F6F6F6'}}>
                    <div style={{width: '90%', margin: 'auto', maxWidth: '1024px'}}>
                        <div className={style.carousel}>
                            <ReactCSSTransitionGroup
                                transitionName={{
                                    enter: isnext ? style.enter_next : style.enter_prev,
                                    enterActive: style.enter_active,
                                    leave: style.leave,
                                    leaveActive: isnext ? style.leave_active_next : style.leave_active_prev
                                }}
                            >
                                <div className={style.carousel_slide} key={index}>
                                    {/*<img src={src}/>*/}
                                    <div className={style.tourFlex}>
                                        <div style={{flex: 1}}>
                                            <img src={data.image} style={{height: '200px'}}/>
                                        </div>
                                        <div style={{flex: '1', textAlign: 'left'}}>
                                            <div>
                                                <div className={style.headingTour}>{data.title}</div>
                                                <div>{data.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ReactCSSTransitionGroup>
                            {/*<button className={classNames(style.carousel_control, style.carousel_control__prev)} onClick={this.handlerPrev}><span></span></button>*/}
                            {/*<button className={classNames(style.carousel_control, style.carousel_control__next)} onClick={this.handlerNext}><span></span></button>*/}
                            {/*<div className={style.carousel_history}>*/}
                            {/*<History*/}
                            {/*current={this.state.current}*/}
                            {/*items={this.state.items}*/}
                            {/*changeSilde={this.goToHistoryClick}*/}
                            {/*/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default TypeSlider;