import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { ButtonBase, CircularProgress, } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import classnames from 'classnames';
import styles from './Coupons.module.css'
import {serviceGetPromotions} from "../../services/Common.service";
import history from '../../libs/history.util';

class Coupons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_calling: true,
            promotions: [],
        }
        this.slider = null;
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
    }

    componentDidMount() {
        const req = serviceGetPromotions();
        req.then((data) => {
            if (!data.error) {
                this.setState({
                    is_calling: false,
                    promotions: data.data,
                });
            }
        });
    }

    _renderCoupon() {

        if(this.props.is_calling) {
            return (<div>
                <ButtonBase  style={{ minHeight: '177px', width: '100%', textAlign: 'left', background: 'white' }} className={styles.offerSlideBase}>
                    <div style={{  }} className={styles.sliderCard}>
                        <div className={styles.flex1}>
                            <br/>

                            <p style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </p>
                            <br/>
                            <br/>
                        </div>
                        <div className={styles.flex2}>

                        </div>
                    </div>
                </ButtonBase>
            </div>);
        }
        return this.state.promotions.map((val, index) => {
            return (<div key={'promotionBtn_'+index}>
                <ButtonBase onClick={() => {
                    if (val.tour_id) {
                        history.push('/details/' + val.tour_id);
                    }
                    // if(this.props.is_authenticated) {
                    //     this.props.history.push('/insurances/' + val.insurance_id);
                    // } else {
                    //     this.props.history.push('/login');
                    // }
                }} style={{ }} className={styles.offerSlideBase}>
                    <div className={ classnames(styles.sliderCard, styles.boxShadow)} style={{  width: '100%', textAlign: 'left', background: 'url('+val.image+')', backgroundSize: 'cover'}}  >

                    </div>
                </ButtonBase>
            </div>);
        });


        return this.state.promotions.map((val, index) => {
            return (
                <ButtonBase key={'p_'+index} onClick={() => {
                    if (val.tour_id) {
                        history.push('/details/' + val.tour_id);
                    }}}
                    >
                    <div className={styles.card}>
                        <img src={val.image} style={{width: '100%'}}/>
                    </div>
                </ButtonBase>
            )
        })
    }

    handleNext() {
        if (this.slider) {
            this.slider.slickNext();
        }
    }

    handlePrev() {
        if (this.slider) {
            this.slider.slickPrev();
        }
    }

    render() {
        if (!this.state.is_calling) {
            const settings = false ? {
                dots: false,
                infinite: this.props.data.length > 1,
                nav: false,
                accessibility: true,
                arrows: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                centerPadding: 10
                // variableWidth: true
            } : {
                // dots: false,
                // infinite: true,
                // nav: false,
                // accessibility: false,
                // arrows: false,
                // speed: 500,
                // slidesToShow: 1,
                // slidesToScroll: 1,
                // variableWidth: true,
                // touchThreshold: 10
                // dots: false,
                // infinite: false,
                // nav: false,
                // accessibility: false,
                // arrows: false,
                // speed: 500,
                // slidesToShow: 1,
                // slidesToScroll: 1,
                // autoplay: true,
                variableWidth: true,
                dots: false,
                infinite: false,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                nav: false,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
                    },
                    {
                        breakpoint: 768,
                        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
                    },
                    {
                        breakpoint: 1024,
                        settings: { slidesToShow: 3, slidesToScroll: 2, infinite: false }
                    }
                ]
            };
            return (
                <div>
                    <Slider ref={c => {
                        this.slider = c;
                    }} {...settings}>
                        {this._renderCoupon()}
                    </Slider>
                </div>
            )
        } else {
            return (<div>Getting</div>)
        }
    }
}

export default Coupons
