import React, {Component} from 'react';
import styles from './ManufactureList.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import ManufactureCard from "../ManufactureCard.view";
import RouteNames from "../../../../routes/Route.name";
import history from "../../../../libs/history.util";

class ManufactureList extends Component{
    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(){
        history.push(RouteNames.manufacturer);
    }

    render(){
        return(
            <div>
                <div className={styles.mainContainer} onClick={this._handleClick}>
                    <div className={styles.logosFlex}>
                        <div style={{position:'relative'}}>
                            <img src={require('../../../../assets/img/factory/ManufacturersList/logo.png')} className={styles.logo}/>
                            <div className={styles.rating}>4.5</div>
                        </div>
                        <div className={styles.company}>
                            <div className={styles.title}>AHAR PHARMA</div>
                            <div className={styles.loc}>Baddi,India</div>
                            <div className={styles.spec}>Speciality: Syrups,Capsules,Injections</div>
                            <div className={styles.logosFlex}>
                                <img src={require('../../../../assets/img/factory/ManufacturersList/b1.png')} className={styles.badge}/>
                                <img src={require('../../../../assets/img/factory/ManufacturersList/b2.png')} className={styles.badge}/>
                                <img src={require('../../../../assets/img/factory/ManufacturersList/b3.png')} className={styles.badge}/>
                                <img src={require('../../../../assets/img/factory/ManufacturersList/b4.png')} className={styles.badge}/>
                                <img src={require('../../../../assets/img/factory/ManufacturersList/b5.png')} className={styles.badge}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonBase className={styles.inquire}>
                            inquire now
                        </ButtonBase>
                    </div>
                </div>
                <hr className={styles.bottomLine}/>
            </div>
        )
    }
}

export default ManufactureList
