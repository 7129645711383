import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import StarIcon from '@material-ui/icons/Star';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import styles from './styles.module.css'

class RatingFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 80
        };

    }

    componentDidMount() {

    }


    render() {
        const {progress} = this.state
        return (
            <div>
                <div className={styles.starFlex}>
                    <div className={styles.fivestar}>5<StarIcon style={{fontSize: '0.7rem'}}/></div>
                    <LinearProgress variant="determinate" value={progress} style={{width: '70%'}}/>
                    <div style={{marginLeft:'10px',fontSize:'10px'}}>30</div>
                </div>
                <div className={styles.avg}>Average Rating</div>
                <div className={styles.avgFlex}>
                    <div style={{marginLeft:'25px'}}>
                        {/*<Box component="fieldset" mb={3} borderColor="transparent">*/}
                            <Rating name="read-only" value={5} readOnly />
                        {/*</Box>*/}
                    </div>
                    <div style={{fontSize:'10px',marginRight:'20px'}}>4.6</div>
                </div>
            </div>
        );
    }

}

export default RatingFilter
