import React, {Component} from 'react'
import styles from './Providers.module.css'
import Buttonbase from '@material-ui/core/Buttonbase';

class Providers extends Component {
    render(){
        return(
            <div className={styles.card}>
                <div>
                    <div className={styles.cardCont} style={{
                        backgroundImage:'url(' + this.props.data.image + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition:'center'
                    }}>
                        <div className={styles.posn}>
                            <div style={{margin:'0',textAlign:'center'}}>
                                <div className={styles.heading}>{this.props.data.heading}</div>
                                <div className={styles.description}>{this.props.data.description}</div>
                            </div>

                            <div className={styles.button}>
                               <Buttonbase className={styles.bottomButtom}>
                                   <div style={{color:'white',fontSize:'14px'}}>Begin Here</div>
                               </Buttonbase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Providers