import React, {Component} from 'react';
import styles from './NegotiateDialog.module.css'
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {ButtonBase,MenuItem} from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import MediaQuery from 'react-responsive';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { Update as Duration, LocationOn} from '@material-ui/icons';
import {
    renderOutlinedSelectField,
    renderOutlinedTextField,
    renderTextField
} from "../../../../../libs/redux-material.utils";
// import {convertDurationToString} from "../../../../../libs/general.utils";
// import Constants from "../../../config/constants";
// import classNames from "classnames";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const validate = (values) => {
    const errors = {};
    const requiredFields = ['price','quantity','comments'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });

    return errors;
};

class NegotiateDialog extends Component{
    constructor(props){
        super(props);

        this.state={

        }
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(){

    }

    _renderNegotiateForm(){
        const {handleSubmit} = this.props;
        return(
            <div className={styles.formContainer}>
                <form onSubmit={handleSubmit(this._handleSubmit)}>
                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>Revised Price</label>
                            <Field fullWidth={true} name="price" component={renderOutlinedTextField}
                                   margin={'dense'} type={'number'}
                                   label=" Enter your price"/>
                        </div>

                        <div className={'formGroup'}>
                            <label className={styles.lab}>Quantity</label>
                            <Field fullWidth={true} name="quantity" component={renderOutlinedTextField}
                                   margin={'dense'} type={'number'}
                                   label=" Quantity"/>
                        </div>
                    </div>

                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <label className={styles.lab}>Comments</label>
                            <Field
                                fullWidth={true}
                                name="comments"
                                component={renderOutlinedTextField}
                                margin={"dense"}
                                label={"Type your Comments"}
                                multiline
                                rows={"3"}
                            />
                        </div>
                    </div>

                    <ButtonBase className={styles.looking} type={'submit'}>
                       Send to Manufacture
                    </ButtonBase>

                </form>
            </div>
        )
    }


    render(){
        return(
            <div>
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth={true}
                    maxWidth={'md'}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    {/*<div className={styles.closeIcon} onClick={this.props.handleClose}>*/}
                        {/*<Close className={styles.cancel}/>*/}
                    {/*</div>*/}
                    <div className={styles.negContainer}>
                        <div className={styles.description}>We understand that your business is your priority and we are there to look forward to your demands<br/></div>
                        <div className={styles.description}>Get the goods manufactured at a price that suits you and the manufacturer</div>

                        <div className={styles.innerCon}>
                            <div>Not Satisfied?</div>
                            <div>Negotiate on the quotation now</div>
                        </div>
                        <div>
                            Manufacture's quoted price /100kg: <span className={styles.price}>Rs 3,75,000.00</span>
                        </div>
                        <br/>
                        {this._renderNegotiateForm()}
                    </div>
                </Dialog>
            </div>

        )
    }
}

const negotiate = reduxForm({
    form:'negotiate',
    validate,
})(NegotiateDialog);

function mapStateToProps(state) {
    return {
        // user_details: state.auth.user_details,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(negotiate)