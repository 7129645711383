import React, {Component} from 'react'
import styles from './ReviewP.module.css'
import data from '../../../data'
import {Star,FavoriteBorder as Favorite } from '@material-ui/icons'

class ReviewsP extends Component {
    render(){
        return(
            <div style={{flex:'1'}}>
                <div style={{display:'flex',justifyContent:'space-evenly'}}>
                    {data.driver.map((val)=> {
                        return(
                            <div className={styles.container}>
                            <img src={val.d1} style={{height:'50px'}}/>
                            <div style={{textAlign:'center',marginTop:'-8px'}}>
                            <div className={styles.icons}>
                                <Star fontSize ={'small'} className={styles.fontSizeSmall}/>
                                <span className={styles.rating}>4.6</span>
                                </div>
                                <div className={styles.heading}>
                            <div>Harry</div>
                            </div>
                          </div>
                        </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default ReviewsP