import React, {Component} from 'react'
import styles from './About.module.css'
import ButtonBase from '@material-ui/core/ButtonBase'
import AboutEmail from "./components/Email/AboutEmail.component";
import Paper from '@material-ui/core/Paper';
import RatingSlider from "./components/RatingSlider/RatingSlider.view";

class About extends Component{
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <div>
                <div className={styles.backgroundImage}>
                </div>
                <div className={'container'}>
                <div className={styles.upperFlex}>
                    <div className={styles.description}>
                        <h1 className={styles.heading}>C'mmon Let's Tuxi</h1>
                        <p>
                            Let's Tuxi was founded to give travelers the freedom of choice. A sustainable and green choice.
                            Travelers around the world are becoming conscious of their carbon footprint while they travel around the world.
                            Let’s Tuxi provides you and your family a platform to choose sustainable, green and fun city tours and activities for your next travel destination.
                            Our aim is not to be the biggest but to be the most relevant and conscious destination experience providers in these critical times<br/>
                            Our inspiration:<br/>
                            <em style={{color:'black',fontWeight:'bold'}}>"Be the change you wish to see in the world"</em>
                            <p>
                                We believe change begins at a very personal level. If we become conscious of our own choices and actions, then each of us will become a catalyst to the change we wish to see in the world.<br/>
                                With so many eco-labels, calculations, and matrixes already being used, we decided to use something more fundamental, to begin with. Our own actions. All our touring partners self declare on the following points resulting in a green score for their respective tour. The rating is from 0 (not green) to 5 (greenest).<br/>
                                <b style={{color:'black'}}>
                                    1. The tours use zero-emission vehicles.<br/>

                                    2. No use of single-use plastics during the tour.<br/>

                                    3. No use of paper during or for booking the tour.<br/>

                                    4. Sustainable meal (Vegan/Vegetarian) options available during or post tour.<br/>

                                    5. Use of green energy for charging the tour vehicle
                                </b>
                            </p>
                        </p>
                    </div>
                    <div className={styles.video}>
                        <div className={styles.border}>
                            {/*<video className={styles.tuxivideo} controls="false" autoPlay loop muted>*/}
                                {/*<source src={require('../../assets/img/tuxi/tuxiwebsite_update_1_2.mp4')}*/}
                                        {/*type="video/mp4"/>*/}
                            {/*</video>*/}
                        </div>
                        <div className={styles.yoloFlex}>
                            <img src={require('../../assets/img/tuxi/yolo.png')} className={styles.yolo}/>
                            <img src={require('../../assets/img/banner_icons.png')} className={styles.icons}/>
                        </div>
                    </div>
                </div>
                </div>


                <div>
                    <AboutEmail/>
                </div>

                <div className={'container'}>
                <div className={styles.peopleFlex}>
                    <div>
                        <img src={require('../../assets/img/tuxi/images_grp.png')} height={350}/>
                    </div>
                    <div style={{flex:1,marginLeft:'50px'}}>
                        <div className={styles.people}><h2>What People Says About Us</h2></div>
                        <div>
                            <Paper className={styles.contains}>
                                <img src={require('../../assets/img/tuxi/profile_image.png')} style={{position:'absolute',top:'-40px'}}/>
                                <div className={styles.title}>Jonathan Walker</div>
                                <p className={styles.dummy}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                    galley of type and scrambled it
                                </p>
                            </Paper>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div style={{marginLeft:'35%'}}>
                            <Paper className={styles.contains}>
                                <img src={require('../../assets/img/tuxi/profile_image.png')} style={{position:'absolute',top:'-40px'}}/>
                                <div className={styles.title}>Jonathan Walker</div>
                                <p className={styles.dummy}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                                    galley of type and scrambled it
                                </p>
                            </Paper>
                        </div>
                    </div>
                </div>
                </div>

                <div style={{display:'flex',marginTop:'10%',marginBottom:'30px',overflow:'hidden'}}>
                    <div className={styles.trust}>
                        <b>Excellent</b>
                        <img src={require('../../assets/img/tuxi/stars-5.svg')} className={styles.fiveStar}/>
                        <div>4.9 out of 5 reviews</div>
                        <div>Trustpilot</div>
                    </div>
                    <div style={{display:'flex'}}>
                        <RatingSlider/>
                    </div>
                </div>

                <div className={styles.anotherbg}>

                    <div className={styles.partner}>Start as Partner with us & Start Earning Now</div><br/>
                    <div className={styles.register}>
                        Simply click the register now Button & Fill the Required Field to Start Earning with Lets Tuxi
                    </div>
                    <ButtonBase
                        onClick={this._handlePaymentClick}
                        className={styles.signUp}>
                        Register Now
                    </ButtonBase>
                </div>

                <div className={styles.companiesContainer}>
                        <div className={'container'}>
                        <div className={styles.companyFlex}>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo1.png')} className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo3.png')} className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo2.png')}className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo1.png')}className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo4.png')} className={styles.companyLogo}/>
                            </div>
                        </div>
                        </div>
                </div>
                <div className={styles.mainContainer}>
                    <div className={styles.love}>We all Love to curate experiences that you cherish all your life</div>
                    <div className={styles.buttonFlex}>
                        <div>
                            <ButtonBase
                                className={styles.viewA}>
                                Join Our Team
                            </ButtonBase>
                        </div>
                        <div>
                            <ButtonBase
                                className={styles.view}>
                               Explore Lets Tuxi
                            </ButtonBase>
                        </div>
                        <div>
                            <ButtonBase
                                className={styles.viewB}>
                                Contact Us
                            </ButtonBase>
                        </div>
                    </div>
                </div>

                <div className={styles.bgColor}>
                    <img src={require('../../assets/img/tuxi/ic_left.png')} className={styles.leftArrow}/>
                    <img src={require('../../assets/img/tuxi/ic_right.png')} className={styles.rightArrow}/>
                    <div className={styles.mainContainer}>
                        <div>
                            <div className={styles.mobileFlex}>
                                    <div>
                                        <img src={require('../../assets/img/tuxi/phone.png')} className={styles.mobile}/>
                                    </div>
                                    <div>
                                        <div className={styles.mainHead}>Be the Change!Download Now!</div>
                                        <div className={styles.iconsFlex}>
                                            <div>
                                                <div>
                                                    <img src={require('../../assets/img/tuxi/explore.png')} height={60}/>
                                                </div>
                                                <div style={{textAlign:'center'}} className={styles.exp}>
                                                        Explore
                                                </div>

                                            </div>

                                            <div>
                                                <img src={require('../../assets/img/tuxi/ic_right_arow.png')} />
                                            </div>

                                            <div>
                                                <div>
                                                    <img src={require('../../assets/img/tuxi/plan.png')} height={60}/>
                                                </div>
                                                <div style={{textAlign:'center'}} className={styles.exp}>
                                                    Plan
                                                </div>

                                            </div>

                                            <div>
                                                <img src={require('../../assets/img/tuxi/ic_right_arow.png')} />
                                            </div>

                                            <div>
                                                <div>
                                                    <img src={require('../../assets/img/tuxi/enjoy.png')} height={60}/>
                                                </div>
                                                <div style={{textAlign:'center'}} className={styles.exp}>
                                                    Enjoy
                                                </div>

                                            </div>
                                        </div>
                                        <div className={styles.play}>
                                            <img src={require('../../assets/img/tuxi/ic_appstore.png')} height={40}/>
                                            <img src={require('../../assets/img/tuxi/ic_googleplay.png')} height={40} style={{marginLeft:'20px'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About
