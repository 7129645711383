import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import styles from './PriceFilter.module.css'

const AirbnbSlider = withStyles({
    root: {
        color: '#2196F3',
        height: 7,
        padding: '13px 0px',
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0px 2px 2px',
        '&:focus,&:hover,&$active': {
            boxShadow: '#ccc 0px 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
})(Slider);

const useStyles = {
    root: {
        // width: 300,
        padding: 5
    },
    margin: {
        height: 10
    },
}

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
    );
}
 class CustomizedSlider extends React.Component{
        constructor(props){
            super(props);
            this._handleCommited = this._handleCommited.bind(this)
        }

    _handleCommited(e,object){
            this.props.handleChange('price',{
                min: object[0],
                max: object[1]
            });
    }
    render() {
            console.log(this.props.data);
        const classes = this.props.classes;
        return (
            <div className={classes.root}>
                <div className={styles.minMaxvalues}> €{this.props.data.min}-€{this.props.data.max} </div>
                <AirbnbSlider
                    min={0}
                    max={5000}
                    ThumbComponent={AirbnbThumbComponent}
                    getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                    defaultValue={[this.props.data.min, this.props.data.max]}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    onChangeCommitted={this._handleCommited}
                />

            </div>
        )
    }
}

export default withStyles(useStyles)(CustomizedSlider)
