import React,{Component} from 'react';
import styles from './PriceCard.module.css';
import Rating from '@material-ui/lab/Rating';
import {ButtonBase} from '@material-ui/core';
import classNames from 'classnames';
import Breakup from "./Breakup/Breakup.view";

class All extends Component{
    constructor(props){
        super(props);
        this.state={
            open: false,
        }
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick() {
        this.setState({
            open: !this.state.open
        });
    }

    _renderPrice(){
        return(
            <div className={styles.quoteBg}>
                <div className={styles.quoteFlex}>
                    <div className={styles.mediFlex}>
                        <div>
                            <img src={require('../../../../assets/img/factory/logo1.png')} height={50}/>
                        </div>
                        <div className={styles.mediContainer}>
                            <div className={styles.medicare}>Meridian
                                <div>Medicare</div>
                            </div>
                            <Rating value={5} readOnly className={styles.starRating}/>
                        </div>
                    </div>
                    <div className={styles.date}>
                        <div>Quotation Valid</div>
                        <div>7 Days Left</div>
                    </div>
                    <div className={styles.quotations}>
                        <div>Delivery Timeline</div>
                        <div>20 - 30 days Only</div>
                    </div>
                    <div className={styles.line}>
                        <span className={styles.status}>Ongoing<br/>Negotiation</span>
                    </div>
                    <div className={styles.line}>
                        <div className={styles.box}>Price</div>
                        <div className={styles.value}>Rs 4,48,000.00</div>
                        <ButtonBase onClick={this._handleClick}>
                            View Details
                        </ButtonBase>
                    </div>
                </div>
            </div>
        )
    }

    _renderTerms(){
        return(
            <div>
                <div className={styles.termFlex}>
                    <div style={{flex:'1.5',marginRight:'20px'}}>
                        <div>Terms & Condition</div>
                        <div className={styles.text}>
                            Guaranteed Quality and on-time delivery
                            MOQ 100 BOXES
                            Delivery Cost Extra (Mentioned in the Breakup)
                        </div>
                        <p className={styles.text}>
                            Known for Manufacturing and Trading a wide range of the finest quality of Printing
                            & Offset Machines, an ISO 9001-2008 certified Fair Deal
                            Engineers started its operation in the year 1994. The product range offered by us is
                            inclusive of Variable Data Printing Machine, Scratch Card Line and Mini Offset Printing
                            Machine. Their optimum performance, low maintenance, less power consumption,
                            longer serving life and user-friendly operation, make these printing and off machines highly demanded innvarious industrial printing and designing applications. To ensure high standards of quality. We are basically deal in our local manufacturing brand name "Fairprint". Backed with our large production capable infrastructural facility at Faridabad, we have been able to successful deal with bulk demands within the assured time frame. At regular intervals, we serviced and maintained different machinery installed at our infrastructure.
                        </p>
                    </div>
                    <div style={{flex:'1'}}>
                        <div>
                            <Breakup/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div>
                {this._renderPrice()}
                <div className={classNames(styles.panelCollapse, (this.state.open ? styles.panelOpen : styles.panelClose))}>
                    {this._renderTerms()}
                </div>
            </div>
        )
    }
}

export default All