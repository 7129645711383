/**
 * Created by charnjeetelectrovese@gmail.com on 5/27/2020.
 */
import React, { Component } from 'react';
import styles from './styles.module.css';
import CheckboxComponent from './CheckboxFilter.component';
import Constants from '../../../../config/constants';

class Filters extends Component{
    constructor(props) {
        super(props);
        this.filtersOrders = ['industry','category','certifications','commitments','rating','location'];
        this.data = JSON.parse(JSON.stringify(Constants.filters));
        this.state = {
            filter: JSON.parse(JSON.stringify(Constants.initialFilterState)),
        };
        this._handleChange = this._handleChange.bind(this);
        this._handleApply = this._handleApply.bind(this);
        this._handleClearValue = this._handleClearValue.bind(this)
    }

    componentDidMount() {
        console.log('filters', this.props);
        this.setState({
            filter: this.props.filters
        });
        // const { tour_types, type_categories, categories, languages } = this.props.filterList;
        // this.data['language'].data = languages;
        // this.data['tour_type'].data = tour_types;
        // this.data['category'].data = categories;
        // this.data['type_category'].data = type_categories;
    }

    componentWillReceiveProps(nextProps){
        if( (JSON.stringify(nextProps.filters) !== JSON.stringify(this.props.filters))){
            this.setState({
                filter: nextProps.filters
            })
        }
    }


    _handleApply(data) {
        this.props.handleChanges(data);
    }

    _handleChange(type, data) {
        this.props.handleChanges({[type]: data});
    }

    _handleClearValue(type){
        // this.props.handleClear(type);
    }

    _renderFilters() {
        return this.filtersOrders.map((val, index) => {
            if (val != 'price') {
                const tempData = this.data[val];
                const filter = (this.state.filter[val]);
                return (
                    <div key={'filter_'+index}>
                        <CheckboxComponent
                            type={val}
                            label={tempData.title}
                            filter={filter}
                            list_data={tempData.data}
                            handleChange={this._handleChange}
                        />
                    </div>
                )
            } else {
                return (
                    <div key={'filter_'+index}>
                        {/*<label className={styles.filters}>*/}
                            {/*Price*/}
                        {/*</label>*/}
                        {/*<PriceFilter*/}
                        {/*    data={this.state.filter['price']}*/}
                        {/*    handleChange={this._handleClick}*/}
                        {/*/>*/}
                        {/*<hr/>*/}
                    </div>
                )
            }
        });
    }

    render() {
        const {filters, filterList} = this.props;
        return (
            <div>
                {this._renderFilters()}
            </div>
        );
    }

}

export default Filters;
