import React, {Component} from 'react';
import styles from './style.module.css';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import {
    renderOutlinedTextField,
    renderCountryContact,
    renderOutlinedSelectField
} from "../../../libs/redux-material.utils";
import DateFnsUtils from "@date-io/date-fns/build/index";
import {ButtonBase,MenuItem} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['email','contact','name'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

class TourOperatorForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            value:'Email'
        }
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleChange = this._handleChange.bind(this);
    }

    _handleSubmit(data) {

    }

    _handleChange(e){
        this.setState({
            value: e.target.value
        })
    }

    render(){
        const {handleSubmit} = this.props;
        return(
            <div className={'operator'}>
                <div className={styles.profileFlex}>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Your Name <span className={styles.important}>*</span></label>
                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="name"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Your Name"}
                                />
                            </div>
                        </div>



                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Phone Number <span className={styles.important}>*</span></label>
                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="contact"
                                    component={renderCountryContact}
                                    type={'text'}
                                    margin={'dense'}
                                    errorText="Required"
                                    label="Contact"/>
                            </div>
                        </div>

                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <div className={styles.verifyFlex}>
                                    <label className={styles.headerLabel} htmlFor="">
                                        Email Address <span className={styles.important}>*</span>
                                    </label>
                                </div>

                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="email"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Email"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Choose Your Concern</label>
                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="concern"
                                    component={renderOutlinedSelectField}
                                    margin={"dense"}
                                    label={"Choose Your Concern"}>
                                    <MenuItem value={'BOOKING'}>Booking Error</MenuItem>
                                </Field>
                            </div>

                        </div>

                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">Vendor Id </label>
                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="id"
                                    type={"number"}
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Vendor Id"}
                                />
                            </div>
                        </div>


                        <div className={'formFlex'} style={{alignItems:'center'}}>
                            <div className={'formGroup'}>
                                <label className={styles.headerLabel} htmlFor="">
                                    Describe Your Issue
                                </label>
                            </div>
                            <div className={'formGroup'}>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="describe"
                                    component={renderOutlinedTextField}
                                    label={"Describe"}
                                    multiline
                                    rows={'3'}
                                    margin={"dense"}
                                />
                            </div>
                        </div>

                        {/*<div className={'formFlex'} style={{alignItems:'center'}}>*/}
                            {/*<div className={'formGroup'}>*/}
                                {/*<label className={styles.headerLabel} htmlFor="">*/}
                                    {/*Preferred mode Of Communication*/}
                                {/*</label>*/}
                            {/*</div>*/}
                            {/*<div className={'formGroup'}>*/}
                                {/*<RadioGroup aria-label="" name="communication" value={this.state.value} onChange={this._handleChange} className={styles.radioButtons}>*/}
                                    {/*<FormControlLabel value="Email" control={<Radio />} label="Email" />*/}
                                    {/*<FormControlLabel value="phone" control={<Radio />} label="Phone" />*/}
                                    {/*<FormControlLabel value="any" control={<Radio />} label="Any" />*/}
                                {/*</RadioGroup>*/}
                            {/*</div>*/}
                        {/*</div>*/}




                        <div style={{marginTop:'30px'}} className={styles.bottomButtom}>
                            <ButtonBase className={styles.saveButton} type={'submit'} onClick={this._handleDialog}>
                                Submit
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const customerForm = reduxForm({
    form:'tourOperator',
    validate,
})(TourOperatorForm);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(customerForm)
