/**
 * Created by charnjeetelectrovese@gmail.com on 1/8/2020.
 */
import {getRequest, postRequest} from "../libs/AxiosService.util";


export async function serviceGetCities(params) {
    return await getRequest('/cities', params);
}

export async function serviceGeocodeCity (params) {
    return await postRequest('geocode/city', params);
}


export async function serviceGetFilterList(params) {
    return await postRequest('/list/custom', params);
}

export async function serviceGetCountryCode() {
    return await getRequest('ip/country', {});
}


export async function serviceApplyCoupon(params) {
    return await postRequest('apply/coupon', params);
}

export async function serviceGetPromotions() {
    return await getRequest('promotions', {});
}

export async function serviceGetMessages(params) {
    return await postRequest('messages', params);
}

export async function serviceSendMessage(params) {
    return await postRequest('messages/send', params);
}


export async function serviceCaptureFcmInformation(params) {
    return await postRequest('capture/fcm', params);
}


