import React, {Component} from 'react';
import styles from './UserProfile.module.css'
import {Field, reduxForm} from 'redux-form';
import {renderOutlinedTextField,renderNewSelectField,renderOutlinedSelectField} from '../../libs/redux-material.utils'
import MenuItem from '@material-ui/core/MenuItem';
import {ButtonBase} from '@material-ui/core';

class UserProfile extends Component{
    render(){
        return(
            <div>
                <div style={{height:'65px'}}>
                </div>

                <div>
                <div className={styles.userImage}>
                    <img src={require('../../assets/img/ic_profile_picture.png')}/>
                    <img src={require('../../assets/img/ic_camera.png')} className={styles.cameraIcon}/>
                </div>
                </div>

                <div className={styles.container}>
                    <form>
                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                   name="name"
                                   component={renderOutlinedTextField}
                                   label={"Name"}
                                   />
                        </div>
                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                name="email"
                                component={renderOutlinedTextField}
                                label={"Email"}
                                 type={"email"}  />
                        </div>
                        <div className={styles.contact} style={{padding:'10px'}}>
                            <label>Phone Number</label>
                            <div>+351 8123456789</div>
                        </div>
                        <div className={styles.contact} style={{padding:'10px'}}>
                            <Field
                                fullWidth={true}
                                name="address"
                                component={renderOutlinedTextField}
                                label={"Address"}
                                multiline
                                rows={"3"}/>
                        </div>

                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                name="country"
                                component={renderOutlinedTextField}
                                label={"Country"}

                                 />
                        </div>
                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                name="country"
                                component={renderOutlinedSelectField}
                                label={"Gender"}>

                                <MenuItem value={'Male'}>Male</MenuItem>
                                <MenuItem value={'Female'}>Female</MenuItem>
                            </Field>
                        </div>

                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                name="age"
                                component={renderOutlinedTextField}
                                label={"Age"}
                                   />
                        </div>

                        <div>
                            <ButtonBase className={styles.bottomBtn}>

                                Save

                            </ButtonBase>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default reduxForm({
    form:'user'
})(UserProfile)