
import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import classnames from 'classnames';
import styles from './Reviews.module.css'
import Reviews from "./Reviews.component";


class ReviewCard extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this)
    }

    handleNext() {
        if (this.slider) {
            this.slider.slickNext();
        }
    }

    handlePrev() {
        if (this.slider) {
            this.slider.slickPrev();
        }
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            // autoplay: true
        }
        return (
            <div style={{paddingTop:'15px'}}>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    <Reviews/>
                    <Reviews/>
                    <Reviews/>
                </Slider>
            </div>
        )


    }
}

export default ReviewCard