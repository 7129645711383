import {postRequest} from "../libs/AxiosService.util";

export async function serviceToggleWishlist(params) {
    return await postRequest('/wishlists/toggle', params);
}

export async function serviceSyncWishlist(params) {
    return await postRequest('/wishlists/sync', params);
}
export async function serviceWishlistProducts(params) {
    return await postRequest('/wishlists/products', params);
}
