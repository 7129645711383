import React, {Component} from 'react';
import styles from './BlogCard.module.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import history from '../../../libs/history.util';
import RouteNames from "../../../routes/Route.name";

class BlogCard extends Component{
    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick () {
        history.push(RouteNames.blog_detail);
    }

    render(){
        return(
            <div className={styles.wrapper} onClick={this._handleClick}>
            <div className={styles.mainContainer}>
                <div>
                    <img src={require('../../../assets/img/tuxi/bb.png')} className={styles.upperImage}/>
                </div>
                <div className={styles.textContainer}>
                    <h3 className={styles.blogHeading}>Tips to have a fabulous holiday without breaking the bank</h3>
                    <p className={styles.innerText}>
                        Aliquam at pulvinar sem. Donec nec dolor elit.
                        Vivamus sollicitudin, nunc feugiat suscipit tincidunt, nulla lacus lacinia eros, non imperdiet dui ligula non arcu....
                    </p>
                    <div style={{textAlign:'center',marginBottom:'15px'}}>
                        <ButtonBase
                            className={styles.readMore}>
                            Read More
                        </ButtonBase>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default BlogCard
