import React, {Component} from 'react';
import styles from './Thankyou.module.css';
import {Link} from 'react-router-dom';
// import BookingCard from "../MyBooking/component/BookingCard.component";
import {connect} from "react-redux";
import {withTheme} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {ButtonBase, MenuItem} from '@material-ui/core';
import RouteNames from "../../routes/Route.name";
import history from "../../libs/history.util";
import {ArrowRightAlt} from '@material-ui/icons'
import ProdSlider from "../Products/components/ProdSlider/ProdSlider.component";
import ManufactureSlider from "../Manufacture/components/ManufactureSlider/ManufactureSlider.component";

class Thankyou extends Component {
    constructor(props){
        super(props);
        this.state={

        }
        this._handleQueries = this._handleQueries.bind(this);
    }
    // _renderBookingCard(){
    //     const { cart } = this.props;
    //     if (cart.checkout_data) {
    //         return (
    //             <BookingCard data={cart.checkout_data}/>
    //         )
    //     } else {
    //         return null;
    //     }
    // }

    _handleQueries(){
        history.push(RouteNames.queries);
    }

    _renderTopProducts() {
        return (
            <div>
                <ProdSlider/>
            </div>
        )
    }

    _renderManufactures() {
        return (
            <div>
                <ManufactureSlider/>
            </div>
        )
    }

    _renderWorks() {
        return (
            <div>
                <h2>How It Work?</h2>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>Define your requirement or search for a keyword</div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>Our team will contact and float your request to all the relevant service
                        providers
                    </div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>The service providers will send a quote and we get back to you with
                        their quotations
                    </div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>You may negotiate with them if you do not like the offer or accept the
                        best quotation and fill essentials in order to place an order
                    </div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>You may pay the advance and upload a proof of the same to process your
                        request
                    </div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>You get the status of your order on the portal</div>
                </div>
                <div className={styles.worksFlex}>
                    <img src={require('../../assets/img/factory/plus_icon.png')}/>
                    <div className={styles.def}>After the service request has been fulfilled,you are asked to review and
                        rate the service
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div style={{height:'40px'}}></div>
                <div className={styles.topBackground}>
                    <div className={'ncontainer'}>
                        <div className={styles.bgWhite}>
                            <img src={require('../../assets/img/factory/check_icon.png')} className={styles.check}/>
                            <div className={styles.thanks}>Thankyou</div>
                            <p className={styles.query}>
                                We have received your query.We will look for most suitable options for you in next 24
                                hours<br/>
                                Hold back and explore some of the best products and Manufactures on the portal
                            </p>
                            <ButtonBase onClick={this._handleQueries}>
                                go to my queries <ArrowRightAlt/>
                            </ButtonBase>
                            <br/>
                            <br/>
                            <div className={styles.greyBg}>
                                <div className={styles.mapFlex}>
                                    <img src={require('../../assets/img/factory/map.png')} height={320} style={{marginLeft:'40px'}}/>
                                    <div className={styles.premium}>
                                        Get premium membership and ease your business on<br/>
                                        <span className={styles.large}>INDIA'S LARGEST MANUFACTURING PORTAL</span> for
                                        Manufactures.
                                    </div>
                                    <div>
                                        <ButtonBase className={styles.works}>
                                            Become premium member
                                        </ButtonBase>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginTop: '18%'}}></div>
                <div className={'ncontainer'}>
                    <div>
                        <div className={'viewFlex'}>
                            <h2>Top Seller Products</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderTopProducts()}
                    </div>
                    <br/>
                    <div>
                        <div className={'viewFlex'}>
                            <h2>Top Seller Manufacturers</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderManufactures()}
                    </div>
                    <br/>

                    <div className={styles.howitwork}>
                        <div style={{flex: '1'}}>
                            <img src={require('../../assets/img/factory/vector.png')} height={450}/>
                        </div>
                        <div style={{flex: '1'}}>
                            {this._renderWorks()}
                            <ButtonBase className={styles.getStarted}>
                                get started <ArrowRightAlt/>
                            </ButtonBase>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomBack}>
                    <div className={styles.call}>Just Call at <span className={styles.number}>(088-9235-759)</span></div>
                    <div className={styles.pharma}>
                        Pharma needs engage in the manufacturing,sourcing,marketing and distribution,<br/>
                        of high quality pharmaceutical products and raw materials
                    </div>
                    <ButtonBase >
                        free consultation <ArrowRightAlt/>
                    </ButtonBase>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({});
}

function mapStateToProps(state) {
    return {
        // is_authenticated: state.auth.is_authenticated,
        // user_details: state.auth.user_details,
        // cart: state.cart,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Thankyou));
