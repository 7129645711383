import React,{Component} from 'react';
import styles from './All.module.css';
import AllMenu from './menu/AllMenu.component'

class All extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }

    _renderQuotations(){
        return(
            <div className={styles.quoteBg}>
                <div className={styles.quoteFlex}>
                    <div>
                        <span className={styles.heading}>Ambrodryl Cough Syrup</span>
                        <div className={styles.syrup}>Syrups- <span className={styles.box}>100 Box</span></div>
                    </div>
                    <div className={styles.date}>
                        28-01-2020
                        <div>Standard Delivery</div>
                        <div>Preference Time</div>
                    </div>
                    <div className={styles.quotations}>
                        02<br/>
                        Quotations
                    </div>
                    <div>
                        <span className={styles.status}>Awaited</span>
                        <div className={styles.time}>10:00am</div>
                    </div>
                    <div>
                        <AllMenu/>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div>
                {this._renderQuotations()}
            </div>
        )
    }
}

export default All