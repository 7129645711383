/**
 * Created by charnjeetelectrovese@gmail.com on 1/29/2020.
 */
import {
    APPLY_COUPON, APPLYING_COUPON,
    CART_RESET,
    CART_UPDATE, CHECKOUT_DONE, CHECKOUT_ERROR, CHECKOUT_INIT, REMOVE_COUPON
} from '../actions/Cart.action';

const initialState = {
    seats: 1,
    price: 0,
    tax: 0,
    tour_id: null,
    tour_name: '',
    selected_point: null,
    coupon_applied: false,
    is_checkout: false,
    checked_out: false,
    checkout_error: null,
    checkout_data: null,
    coupon: {
        name: '',
        discount: 0,
        discount_type: '',
        total_discount: 0,
    },
    booking_code: null,
    is_set: false
};

export default function (state = JSON.parse(JSON.stringify(initialState)), action) {
    switch (action.type) {
        case CART_RESET: {
            return {...state, ...(JSON.parse(JSON.stringify(initialState)))};
        }
        case CART_UPDATE: {
            return {...state, ...(action.payload), is_set: true};
        }
        case APPLYING_COUPON: {
            return {
                ...state,
                coupon_applying: true,
            }
        }
        case APPLY_COUPON: {
            return {
                ...state,
                coupon_applied: true,
                coupon_applying: false,
                coupon: action.payload,
            }
        }
        case REMOVE_COUPON: {
            return {
                ...state,
                coupon_applied: false,
                coupon_applying: false,
                coupon: JSON.parse(JSON.stringify(initialState.coupon))
            }
        }
        case CHECKOUT_INIT: {
            return {
                ...state,
                is_checkout: true,
                checkout_error: null,
                checked_out: false,
                checkout_data: null,
            }
        }
        case CHECKOUT_ERROR: {
            return {
                ...state,
                is_checkout: false,
                checkout_error: action.payload,
                checked_out: false,
                checkout_data: null,
            }
        }
        case CHECKOUT_DONE: {
            return {
                ...state,
                is_checkout: false,
                checkout_error: null,
                checkout_data: {...action.payload}
            }
        }
        default: {
            return state;
        }
    }
}
