/**
 * Created by charnjeetelectrovese@gmail.com on 10/10/2019.
 */
import React, {Component} from 'react';
import classNames from 'classnames';
import styles from './Popover.module.css';

class ToolTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            listener: this.handleClickOutSide.bind(this),
        };
    }

    hasSomeParentTheClass(element, classname) {
        if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
    }

    handleClickOutSide(e) {
        // console.log(e.target === document.querySelector(".divPrice"))
        // e.stopPropagation();
        if (this.node) {
            if (!this.node.contains(e.target) && !(this.hasSomeParentTheClass(e.target, 'divPrice'))) {
                this.props.handleClick();
                // const dom = ReactDOM.findDOMNode(this);
                // console.log(dom.getBoundingClientRect());
            }
        }
    }

    componentDidMount() {
        console.log('TOOLTIP MOUNTED');
        // this.node && this.node.focus();
        if (!this.state.isMounted) {
            window.document.addEventListener('click', this.state.listener, false);
            this.setState({
                isMounted: true,
            });
        }
    }

    componentWillUnmount() {
        console.log('TOOLTIP UNMOUNTED');
        // this.node && this.node.blur();
        window.document.removeEventListener('click', this.state.listener, false);
    }


    _handleChange(active) {
        this.setState({
            tooltipActive: active
        });
    }

    render() {
        // if(this.isMounted()) {

        // }
        // console.log(this.props.children);
        // var self = this;
        // var nodes = this.props.children.map( function (child) {
        //     child.props.tooltipActive = self.state.tooltipActive;
        //     child.props.handleChange = self._handleChange;
        //     if(self.isMounted()) {
        //         child.props.position = self.getDOMNode().getBoundingClientRect();
        //     }
        //     return child;
        // });
        return (
            <div ref={node => this.node = node} className={classNames(styles.TooltipContent, styles.bottom)} style={{}}>
                <div style={{position: 'relative'}}>
                    <div className={styles.arrow} style={{left: this.props.x_axis}}></div>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

class Content extends Component {
    // render() {
    //     var active = this.props.tooltipActive;
    //     var position = this.props.position || null;
    //     if (active && position) {
    //         var style = {
    //             left: position.left - 10,
    //             top: position.bottom + 18
    //         };
    //         return (
    //             <div style={style} className="Tooltip-content bottom">
    //                 {this.props.children}
    //             </div>
    //         );
    //     } else {
    //         return null;
    //     }
    // }

    render() {
        if (this.props.open) {
            return (
                <ToolTip x_axis={this.props.x_axis} handleClick={this.props.handleClick} {...this.props}/>
            )
        }
        return null;
    }
}


export default Content;
