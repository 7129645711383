import React, {Component} from 'react';
import styles from './style.module.css'
import {Link} from "react-router-dom";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.component";
import {ButtonBase} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionLogoutUser} from "../../actions/Auth.action";
import {connect} from "react-redux";
import history from '../../libs/history.util';

class NavScroll extends Component{
    constructor(props) {
        super(props);
    }

    _handleClick(val) {
        if (val == 'logout') {
            // this.props.actionLogoutUser();
        } else {
            // this.props.history.push(val)
            history.push(val)
        }
    }
    render(){
        const { injectCss } = this.props;
        return(
            <div className={styles.navScroll} style={injectCss}>
                <div className={'ncontainer'} >
                    <div className={styles.navFlex}>
                        <div>
                            <Link to="/">
                                <img className={styles.logo} src={require('../../assets/img/factory/logo_new.png')} alt="logo"/>
                            </Link>
                        </div>

                        <div className={styles.searchContainer}>
                            <input type='text' className={styles.inputSearch} placeholder={'Search Products'}/>
                            <ButtonBase className={styles.searchButton} onClick={this._handleSearchButton}>
                                Search
                            </ButtonBase>
                        </div>

                        <div>
                            {/*{this.props.is_authenticated ? (<CustomDropdown handleClick={this._handleProfileClick} />) : */}
                            <ButtonBase className={styles.loginButton}
                                        onClick={this._handleClick.bind(this, '/signup')}>
                                Signup
                            </ButtonBase>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLogoutUser: actionLogoutUser
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NavScroll)