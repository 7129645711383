import React from 'react';
import styles from './Ticket.module.css';
import moment from 'moment';
import QRCode from 'qrcode.react';
import {ButtonBase, CircularProgress,Dialog,Slide} from '@material-ui/core';
import Conversation from '../../views/Chats/Conversation.view'
import {serviceGetTicketDetail} from "../../services/Booking.service";
import RouteNames from "../../routes/Route.name";
import { getSocketInstance } from '../../libs/socket.utils';
import Constants from "../../config/constants";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const status = {
    pending: { title: 'Ride Pending',  color: '#75b729' },
    accepted: { title: 'Ride Accepted',  color: '#75b729' },
    on_ride: { title: 'On Ride',  color: '#2894e2' },
    rejected: { title: 'Cancelled',  color: '#b39406' },
    completed: { title: 'Ride Completed',  color: '#b39406' },
}
class Tickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            is_fetching: true,
            is_error: false,
            error: null,
            ticket_detail: null,
            ride_status: 'pending',
            open:false
        };
        this._socket = getSocketInstance();
        this._handleClick = this._handleClick.bind(this);
        this._handleReport = this._handleReport.bind(this);
        this._handleChats = this._handleChats.bind(this);
        this._handleClose = this._handleClose.bind(this)
        this._tourCompleted = this._tourCompleted.bind(this);
        this._onBoarded = this._onBoarded.bind(this);
    }

    componentDidMount() {
        const {id} = this.props.match.params;
        serviceGetTicketDetail({unique_code: id}).then((res) => {
            if (!res.error) {
                const data = res.data;
                this.setState({
                    ticket_detail: data,
                    is_fetching: false,
                    ride_status: data.ride_status,
                });
                this.subscribeToSockets();
                this._socket.emit('subscribe', data.user_booking_id);
            } else {
                this.setState({
                    is_fetching: false,
                    is_error: true,
                    error: 'Ticket Not Found',//res.message,
                });
                setTimeout(()=> {
                    this.props.history.replace(RouteNames.home);
                }, 800);

            }
        })
    }

    subscribeToSockets() {
        if (this._socket) {
            this._socket.on(Constants.SOCKET_EVENTS.TOUR_COMPLETED, this._tourCompleted);
            this._socket.on(Constants.SOCKET_EVENTS.ON_BOARDED, this._onBoarded);
        }
    }

    componentWillUnmount() {
        console.log('dss');
        if (!this.state.is_error && this.state.ticket_detail) {
            this._socket.emit('unsubscribe', this.state.ticket_detail.user_booking_id);
            this._socket.on(Constants.SOCKET_EVENTS.TOUR_COMPLETED, this._tourCompleted);
            this._socket.on(Constants.SOCKET_EVENTS.ON_BOARDED, this._onBoarded);
        }
    }

    _tourCompleted() {
        this.setState({
            ride_status: 'completed',
        });
    }
    _onBoarded() {
        this.setState({
            ride_status: 'on_ride',
        });
    }
    _handleChats() {
        // this.props.history.push(RouteNames.conversations, {
        //     booking_id: this.state.ticket_detail.tour_booking_id
        // });
        this.setState({
            open: true
        })
    }

    _handleClick() {
        this.setState({
            selected: !this.state.selected
        });
    }

    _handleReport() {
        this.props.history.push(RouteNames.report);
    }

    _handleClose(){
        this.setState({
            open: !this.state.open
        })
    }

    renderDialog(){
        return (
            <Dialog
                fullScreen
                open={this.state.open}
                onClose={this._handleClose}
                TransitionComponent={Transition}
            >
            <Conversation booking_id = {this.state.ticket_detail.user_booking_id}
                          driver_name = {this.state.ticket_detail.driver.name}
                          driver_image = {this.state.ticket_detail.driver.image}
                          driver_contact = {this.state.ticket_detail.driver.contact}
                          handleClose={this._handleClose}/>
            </Dialog>
        )

    }

    render() {
        const {is_fetching, is_error, error, ticket_detail, ride_status} = this.state;
        if (is_fetching) {
            return (<div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress/>
            </div>)
        } else if (!is_error) {
            return (
                <div style={{backgroundColor: '#f6f6f6', height: '100vh'}}>
                    <div style={{height: '70px'}}></div>

                    <div className={styles.mainContainer}>
                        <div className={styles.upperFlex}>
                            <div style={{color: 'grey'}}>{moment(new Date(ticket_detail.booking_date)).format('ddd, DD MMM YYYY')}</div>
                            <div style={this.state.selected ? {color: '#2196F3', fontWeight: '500'} : {
                                color: (status[ride_status]).color,
                                fontWeight: '500'
                            }}>
                                {(status[ride_status]).title}
                            </div>
                        </div>
                        <div className={styles.stamp}>
                            <div style={{
                                background: 'white',
                                height: '100%',
                                position: 'relative',
                                padding: '3px',
                                marginTop: '10px'
                            }}>
                                <div className={styles.ticketflex}>
                                    <div style={{ width: '75px', height: '75px', overflow: 'hidden' }}>
                                        <img src={ticket_detail.tour.images.length > 0 ? ticket_detail.tour.images[0] : require('../../assets/img/TicketImg.jpg')} style={{height: '75px'}}/>
                                    </div>
                                    <div className={styles.ticketdescription}>
                                        <div className={styles.scheduled}>{moment(new Date(ticket_detail.booking_date)).format('DD, MMM YYYY HH:mm')}</div>
                                        {/*, 9:30 AM - 11:30 AM*/}
                                        <div className={styles.tourHeading}>
                                            {ticket_detail.tour.name}
                                        </div>
                                        <div className={styles.tourDriver}>
                                            {ticket_detail.driver.name} - Driver
                                        </div>
                                    </div>
                                </div>

                                <img src={require('../../assets/img/booked.png')} className={styles.bookedTicket}/>

                                {/*<hr style={{opacity:'0.2'}}/>*/}

                                <div className={styles.ticketLower}>
                                    <div className={styles.bottomDescription}>
                                        <div>
                                            <div className={styles.price}>Total Paid</div>
                                            <div className={styles.priceFlex}>
                                                <img src={require('../../assets/img/money.png')}
                                                     style={{height: '25px', color: '#2196F3'}}/>
                                                <span className={styles.amount}>{ticket_detail.total_amount}</span>
                                            </div>
                                        </div>
                                        <div style={{marginLeft: '50px'}}>
                                            <div className={styles.price}>Total Seats</div>
                                            <div className={styles.priceFlex}>
                                                <img src={require('../../assets/img/seats.png')}
                                                     style={{height: '25px', color: '#2196F3'}}/>
                                                <span className={styles.amount}>{ticket_detail.seats}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    {/*<br/>*/}

                    <div className={styles.container}>
                        <div className={styles.qrCode}>
                           <div className={styles.qrCodeContainer} style={{ borderColor: (status[ride_status]).color }}>
                               <QRCode
                                   value={ticket_detail.unique_code}
                                   size={128}
                                   bgColor={"#ffffff"}
                                   // fgColor={"#000000"}
                                   level={"L"}
                                   includeMargin={false}
                                   renderAs={"svg"}
                                   // imageSettings={{
                                   //     src: require('../../assets/logos/logo.png'),
                                   //     x: null,
                                   //     y: null,
                                   //     height: 18,
                                   //     width: 22,
                                   //     excavate: true,
                                   // }}
                               />
                           </div>
                            <div className={styles.imageText}>
                                {ticket_detail.unique_code}
                            </div>
                            {ride_status == 'accepted' && (<>
                            <div className={styles.qrDescription}>
                                Show this QR code to the driver
                            </div>
                            <div className={styles.description}>
                                You can access your ticket from your profile.We will send you an <br/> email/SMS your
                                details with this ticket.
                            </div>
                                </>)}
                        </div>
                    </div>

                    <div className={styles.innerContainer} style={{marginTop: '40px'}}>
                        <div className={styles.qrflex}>
                            <div style={{flex: 1}}>
                                <div className={styles.pickup}>Your Pickup Location</div>
                                <div style={{fontWeight: 500}}>{ticket_detail.pickup_loc.name} </div>
                            {/*    (11:00 PM)*/}
                            </div>
                            <div>
                                <ButtonBase  className={styles.mapView}>
                                    <a target={'_blank'} href={'https://www.google.com/maps/?q='+`${ticket_detail.pickup_loc.coordinates[1]},${ticket_detail.pickup_loc.coordinates[0]}`}>View On Map</a>
                                </ButtonBase>
                            </div>
                        </div>
                        <hr style={{marginTop: '10px', opacity: '0.2'}}/>
                    </div>

                    <div className={styles.innerContainer}>
                        <div className={styles.reportflex}>
                            <div className={styles.supportFlex}>
                                <div>
                                    <img src={require('../../assets/img/support.png')} className={styles.supporticon}/>
                                </div>
                                <div className={styles.issueRide}>
                                    Have an issue with this ride ?
                                </div>
                            </div>
                            <div>
                                <ButtonBase className={styles.reportButton} onClick={this._handleReport}>
                                    <div style={{fontWeight: 600}}>REPORT HERE</div>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>

                    <div className={styles.bottomDriver}>
                        <div className={styles.driverFlex}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className={styles.driverImageCont}>
                                    <img src={ticket_detail.driver.image}
                                         className={styles.driverImage}/>
                                </div>
                                <div className={styles.driverDetails}>
                                    <div className={styles.driverName}>{ticket_detail.driver.name}</div>
                                    <div className={styles.carDetails}>{ticket_detail.vehicle.vehicle_number}</div>
                                </div>
                            </div>
                            <div>
                                <ButtonBase onClick={this._handleChats}>
                                    <img src={require('../../assets/img/chat.png')} className={styles.chatIcon}/>
                                </ButtonBase>

                                <ButtonBase style={{marginLeft: '8px'}}>
                                    <a href={"tel:" + ticket_detail.driver.contact}><img src={require('../../assets/img/phone.png')} className={styles.chatIcon}/></a>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                    {this.renderDialog()}
                </div>
            )
        } else {
            return (
                <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                    <p>{error}</p>
                    <p >Redirecting to home</p>
                    </div>
                </div>
            )
        }
    }
}

export default Tickets
