import React, { Component } from 'react';
import styles from './DetailDesk.module.css'
// import AboutDriver from '../AboutDriver/AboutDriver.view'
import About from '../AboutDriver/Desktop/About.view'
import TabsDetails from '../Tabs/TabsDetails/TabsDetails.view'
import Tabs from '../Tabs/Tabs.component';
import GallerySlider from '../../ImageGallery/GallerySlider/GallerySlider.view'
import ReviewsP from '../ReviewsP/ReviewsP.component'
import RouteComponent from '../Route/Route.component'
import {ReviewsCard , Places} from '../../../components/index.component'
import ButtonBase from '@material-ui/core/ButtonBase';
import {KeyboardArrowRight,KeyboardArrowLeft} from '@material-ui/icons'
import RouteName from '../../../routes/Route.name';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import RouteList from '../../../components/RouteComponent/Routes.component';
import AboutDriver from "../AboutDriver/AboutDriver.view";

class DetailsDesk extends Component{
    constructor(props) {
        super(props);
        this.state = {
            route: 'LIST',
        }
        this.tourReview = React.createRef();
        this._handleClick = this._handleClick.bind(this);
        this._handlePaymentClick = this._handlePaymentClick.bind(this);


    }
    _handleClick() {
        this.props.history.push(RouteName.payment);
    }

    _renderRouteComponent() {
        const {tour} = this.props;
            return (
                <RouteComponent routes={tour.route_list}/>
            )
        // return (<RouteList data={{routes: tour.routes}}></RouteList>);
    }

    _handlePaymentClick() {
        this.props.history.push(RouteName.payment);
    }

    render(){
        const {tour} = this.props;
        return(
            <div>
                <div style={{height: '65px'}}/>
                <div className={styles.galleryFlex}>
                    <div className={styles.imageContainer}>
                        {/*<img src={require('../../../assets/img/desktop/ImageBanner.jpg')} className={styles.gallery}/>*/}
                        <GallerySlider images={tour.images} history={this.props.history}/>
                    </div>

                    <div className={styles.aboutSection}>
                            {/*<h2 className={styles.aboutHeader}> About</h2>*/}
                        <div className={styles.aboutContainer}>
                            <h5 style={{marginTop: '5px', marginBottom: '5px',fontSize:'22px'}}>Details</h5>
                            <Tabs info={tour}/>
                            {/*<TabsDetails/>*/}
                        </div>

                        {/*<div style={{display:'flex',alignItems:'center'}}>*/}
                            {/*<div>*/}
                           {/*<AboutDriver history={this.props.history}/>*/}
                            {/*</div>*/}
                        {/*</div>*/}


                            <div>
                                <ButtonBase className={styles.bookButton} onClick={this._handlePaymentClick}>
                                    <span style={{ fontSize: '18px', color: 'white', fontWeight: '500' }}>{this.props.board_type == 'board_now' ? 'Board Now' : 'Board For Later'}</span>
                                </ButtonBase>
                            </div>
                        </div>


                    {/*<div>*/}
                        {/*<AboutDriver/>*/}
                        {/*<div style={{marginTop: '40px'}}>*/}
                            {/*<ReviewsP/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>

                <div>
                    <div className={styles.mainContainer} style={{marginTop:'20px',marginBottom:'20px'}}>
                        <div className={styles.category}>{tour.category}</div>
                        <h3 className={styles.title}>{tour.name}</h3>
                        <small style={{color:'grey'}}>
                            {tour.overview}
                        </small>
                        {/*<div className={styles.category}>Libson Castle is a historic castle in the portugese capital of Libson</div>*/}
                    </div>
                </div>

                <div className={styles.mainContainer}>
                    <div>
                    <div className={styles.mainHeading}>Route</div>
                    </div>

                    <div style={{display:'flex'}}>
                        <div style={{flex:'1.25'}}>
                        {this._renderRouteComponent()}
                        </div>
                        <div style={{flex:'1'}}>
                            <RouteList data={{routes: tour.routes}}></RouteList>
                        </div>
                    </div>

                    <div>
                        <h3 className={styles.mainHeading} style={{ paddingTop: '15px' }}>Tour Description</h3>
                        <p className={styles.description}>
                            {tour.description.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </p>
                        <p className={styles.description}>
                            {tour.instructions.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </p>
                        <hr />
                    </div>

                    <div>
                        <h3 className={styles.mainHeading} style={{ paddingTop: '15px' }}>Important Instructions</h3>
                        <p className={styles.description}>
                            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                            Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are reproduced in their exact original form.
                        </p>
                        <p className={styles.description}>
                            Lorem Ipsum is simply dummy text of the printing and typesett industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gall type and scrambled it to make a type specimen book.
                            It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <hr />
                    </div>

                    <div>
                        <div className={styles.reviewsFlex}>
                            <div>
                                <h3 className={styles.mainHeading}>Reviews</h3>
                            </div>
                            <div className={styles.arrowsContainer}>
                                <ButtonBase onClick={() => {
                                    this.tourReview.current.handlePrev();
                                }}>
                                    <KeyboardArrowLeft style={{color:'#2196F3'}}/>
                                </ButtonBase>

                                <ButtonBase onClick={() => {
                                    this.tourReview.current.handleNext();
                                }}>
                                    <KeyboardArrowRight style={{color:'#2196F3'}}/>
                                </ButtonBase>
                            </div>
                        </div>
                        <ReviewsCard ref = {this.tourReview}/>
                    </div>

                    <div>
                    <div>
                        <h3 className={styles.mainHeading}>Driver Information</h3>
                    </div>
                    <About data={tour.driver} history={this.props.history}/>
                    </div>

                    <div>
                        <h3 className={styles.mainHeading}>Similar Tours</h3>
                        <Places />
                    </div>
                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps(state) {
    return {
        tour: state.tours.selected_tour,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)((DetailsDesk));

