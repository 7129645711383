/**
 * Created by charnjeetelectrovese@gmail.com on 5/27/2020.
 */

import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from "react-redux";
import moment from 'moment';
import {bindActionCreators} from 'redux';
import MediaQuery from 'react-responsive';
import styles from './style.module.css';
import SideDate from './components/datesearch/DateHeader.component';
import Filters from './components/filters/filters.component';
import {actionUpdateFilter, actionCleanFilter, actionHydrateFilters} from '../../actions/Filter.action';
import {actionFetchTours} from "../../actions/Tours.action";
import {TourListCard} from '../../components/index.component';
import {CircularProgress,Button} from "@material-ui/core";
import {ExpandMore} from '@material-ui/icons'
import RouteNames from "../../routes/Route.name";
import SearchCities from "../Search/Search.view";
import FiltersDialog from "./components/FiltersDialog/FiltersDialog.view";

class TourList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog_open: false,
            open: false,
        };
        this._handleFilterChange = this._handleFilterChange.bind(this);
        this._handleFilterClear = this._handleFilterClear.bind(this);
        this._onBackButtonEvent = this._onBackButtonEvent.bind(this);
        this._handleCardClick = this._handleCardClick.bind(this);
        this._handleClose = this._handleClose.bind(this);
    }

    componentWillMount() {
        this.props.actionHydrateFilters(this.props.location.search);
    }

    componentDidMount() {
        window.onpopstate = this._onBackButtonEvent;
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    _onBackButtonEvent(e) {
        this.props.actionHydrateFilters(this.props.location.search);
    }


    _handleFilterChange(data) {
        console.log(data);
        this.props.actionUpdateFilter(data);
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    _handleFilterClear(type) {
        this.props.actionClearFilter(type);
    }

    _handleCardClick(tourId) {
        this.props.history.push(RouteNames.details+'/'+tourId);
    }

    _handleClose() {
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }


    _renderTourList() {
        const {tours_calling, tours} = this.props;
        if (tours_calling) {
            return (
                <div className={styles.waitingContainer}>
                    <CircularProgress/>
                </div>
            );
        }
        else {
            if(tours.length > 0) {
                return tours.map((val, index) => {
                    return (<TourListCard
                        key={val._id}
                        data={val}
                        handleClick={this._handleCardClick}
                    />)
                })
            }
            else {
                return (<div style={{textAlign:'center',marginTop:'30px',marginBottom:'30px'}}>
                    <img className={styles.noTour} src={require('../../assets/img/tuxi/no.png')}/>
                    <h2>No Results Found</h2>
                </div>)
            }
        }
    }

    _renderFiltersDialog(){
        return(
            <FiltersDialog
                open={this.state.dialog_open}
                handleClose={this._handleClose}
                filters={this.props.filter}
                filterList={this.props.filterList.list_data}
                handleChange={this._handleFilterChange}
                handleClear={this._handleFilterClear}
            />
        )
    }
    render() {
        return (
            <div>
            <MediaQuery minDeviceWidth={768}>
                <div className={classNames('container', styles.desktopSearch)}>
                    <MediaQuery minDeviceWidth={768}>
                        <div style={{marginTop:'70px'}}></div>
                        <SearchCities/>
                    </MediaQuery>
                    <section className={styles.filterSidebar}>
                        <SideDate></SideDate>
                        <MediaQuery minDeviceWidth={768}>
                            <Filters
                                handleChanges={this._handleFilterChange}
                                filters={this.props.filter}
                                filterList={this.props.filterList.list_data}
                                handleClear={this._handleFilterClear}
                            ></Filters>
                        </MediaQuery>
                    </section>

                    <section className={styles.resultActivities}>
                        {this._renderTourList()}
                    </section>
                </div>
            </MediaQuery>


                <MediaQuery maxDeviceWidth={768}>
                    <div style={{marginTop:'70px'}}></div>
                    <section className={styles.filterSidebar}>
                        <SideDate></SideDate>
                    </section>

                    <section className={styles.resultActivities}>

                        <div className={styles.filterContainer}>
                            <div style={{marginLeft:'12px',fontWeight:'bold'}}>
                                Paris
                            </div>
                            <Button className={styles.filter} onClick={this._handleClose}>
                                <div className={styles.filterButton}>
                                    <img src={require('../../assets/img/tuxi/ic_filter@2x.png')} className={styles.filterIcon}/>
                                    <span className={styles.headingFilter}>Filter</span>
                                    <ExpandMore/>
                                </div>
                            </Button>
                        </div>

                        <div className={styles.filterContainer}>
                            <div style={{marginLeft:'12px'}}>
                                13 tours Found
                            </div>
                            <Button className={styles.sorting}>
                                <div className={styles.filterButton}>
                                    <span>Sort By</span>
                                    <ExpandMore/>
                                </div>
                            </Button>
                        </div>

                        <div className={styles.tourSpacing}>
                         {this._renderTourList()}
                        </div>
                        {this._renderFiltersDialog()}
                    </section>
                </MediaQuery>
            </div>
        )
    }

}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionUpdateFilter: actionUpdateFilter,
        actionClearFilter: actionCleanFilter,
        actionFetchTours: actionFetchTours,
        actionHydrateFilters: actionHydrateFilters,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        date: state.common.date,
        time: state.common.time,
        filter: state.filter,
        filterList: state.common.filter_list,
        tours: state.tours.tours,
        tours_calling: state.tours.is_calling,
        location_data: state.common.location,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TourList);
