/**
 * Created by charnjeetelectrovese@gmail.com on 10/18/2019.
 */
import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {ButtonBase, TextField} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Slide from '@material-ui/core/Slide';
import styles from './CountryDialog.module.css';
import {Room} from '@material-ui/icons'
import data from '../../data';
import EventEmitter from '../../libs/Events.utils';
import {serviceGeocodeCity, serviceGetCities} from "../../services/Common.service";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CountryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
        };
        this._handleClose = this._handleClose.bind(this);
        this._handleSelection = this._handleSelection.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._handleLocation = this._handleLocation.bind(this);
        this._handleAutoComplete = this._handleAutoComplete.bind(this);
        this._handleAutoClose = this._handleAutoClose.bind(this);
        this._handleInputChange = this._handleInputChange.bind(this);
    }
    async componentDidMount() {
        this.setState({
             city: this.props.selected_city,
        });
        const req = await serviceGetCities();
        if (!req.error) {
            this.setState({
                cities: req.data.cities,
                city: null,
                search: '',
            })
        }
    }

    _handleAutoComplete(e, val) {
        console.log('last state', this.state.city);
        if (val) {
            this.setState({
                city: val,
                search: val.name
            });
        }
    }
    _handleAutoClose() {
        console.log('close call');
        // this.setState({
        //     city: null,
        // })
    }

    _handleClick(city) {
        this.props.handleClick({
            name: city,
            lat: 14.44,
            lng: 79.9899
        });
    }

    _handleClose = (event) => {
        this.props.handleClick(null);
    };

    _handleSelection(e) {
        if (this.state.city) {
            this.props.handleClick(this.state.city);
        } else {
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Select City', type: 'error'});
        }
        e.stopPropagation();
    }

    _handleInputChange(e,value) {
        this.setState({
            search: value,
        });
    }

    _handleLocation() {
        const location = window.navigator && window.navigator.geolocation;

        if (location) {
            location.getCurrentPosition((position) => {
                const tempLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
                console.log(tempLocation.latitude, tempLocation.longitude);
                serviceGeocodeCity({
                    lat: tempLocation.latitude,
                    lng: tempLocation.longitude,
                }).then((data) => {
                    if (!data.error) {
                        this.setState({
                            city: data.data,
                        });
                    }
                })

            }, (error) => {

            })
        } else {
            alert('location service is not enabled');
        }
    }


    render() {
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this._handleClose}
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
                classes={{paper: styles.paperTransparent}}
            >
                <div className={styles.backgroundColor} onClick={this._handleClose}>
                    <div className={styles.dialogContent} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.dialogContainer}>
                            <h4>Search Location</h4>
                            <div>
                                <Autocomplete
                                    disableClearable
                                    onChange={this._handleAutoComplete}
                                    onClose={this._handleAutoClose}
                                    onInputChange={this._handleInputChange}
                                    options={this.state.cities}
                                    getOptionLabel={option => option.name}
                                    id="disable-open-on-focus"
                                    disableOpenOnFocus
                                    inputValue={this.state.search}
                                    renderInput={params => { return (
                                        <TextField  {...params} label="Search For Your location.." variant="outlined" margin={'dense'} fullWidth />
                                        // <input {...params} className={styles.location} type="text" name="location"
                                        //        placeholder="Search For Your location.."/>
                                    )}}
                                />

                            </div>

                            <div className={styles.currentLoc}>
                                <ButtonBase onClick={this._handleLocation}>
                                    <Room size={'small'}/> <span
                                    style={{marginLeft: '5px'}}> Use Current location </span>
                                </ButtonBase>
                            </div>


                            <br/>
                            <br/>

                            <div>
                                <div>Popular Cities</div>

                                <div>
                                    <div className={styles.outerFlex}>
                                        {data.cities.map((val) => {
                                            return (
                                                <ButtonBase className={styles.buttonCity}
                                                            onClick={this._handleClick.bind(this, val.city)}>
                                                    <div className={styles.innerFlex}>
                                                        <img
                                                            src={this.props.selected_city == val.city ? val.blueCity : val.greyCity}
                                                            className={styles.imgGrey}/>
                                                        <div
                                                            style={this.props.selected_city == val.city ? {color: '#2196F3'} : {}}>{val.city}</div>
                                                    </div>
                                                </ButtonBase>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>


                            <div className={styles.bottomButton}>
                                <ButtonBase onClick={this._handleSelection} className={styles.buttonContinue}>
                                    <div className={styles.continue}>
                                        Continue
                                    </div>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}


export default CountryDialog;
