import React, { Component } from 'react';
import { ButtonBase } from '@material-ui/core';
import { ArrowDownward as DownIcon } from '@material-ui/icons';
import styles from './Rider.module.css';
import SwipeableBottomSheet from '../../../components/SwipeViews/lib/SwipeableBottomSheet';
import { FilterView } from '../../../components/index.component';
import Card from './Card/Card.component';
import RouteNames from '../../../routes/Route.name';

class Rider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleBottomSheet = this.toggleBottomSheet.bind(this);
    this.openBottomSheet = this.openBottomSheet.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick() {
    // console.log('hello')
    this.props.history.push(RouteNames.ride_detail);
  }

  openBottomSheet(open) {
    this.setState({ open });
  }

  toggleBottomSheet() {
    this.openBottomSheet(!this.state.open);
  }

  _renderCards() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9].map(val => (<Card board_type={this.props.board_type} onClick={this._handleClick} />));
  }

  _renderHeader() {
    if (this.state.open) {
      return (
        <div className={styles.topHeader}>
          <div className={styles.topHeaderIcon}>
            <ButtonBase onClick={this.toggleBottomSheet}>
              <DownIcon />
            </ButtonBase>
          </div>
          <div className={styles.topHeadingText}>
                        Choose a Ride
          </div>
        </div>
      );
    }
    return (
      <div className={styles.dragController}>
        <div className={styles.dragBar} />
        <div className={styles.dragText}>Choose a ride. Slide for more</div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div>
          {/* <MapCompoenent> */}
          <img src={require('../../../assets/img/map.png')} style={{ width: '100%', marginTop: '54px' }} />
        </div>
        <SwipeableBottomSheet
          overflowHeight={300}
          open={this.state.open}
          onChange={this.openBottomSheet.bind(this)}
          style={{ zIndex: 2 }}
        >
          <div style={{ height: '100vh' }}>
            {this._renderHeader()}
            <div style={this.state.open ? { marginTop: '70px' } : {}}>
              {this._renderCards()}
            </div>
          </div>
        </SwipeableBottomSheet>
        {/*<FilterView />*/}
      </div>

    );
  }
}

export default Rider;
