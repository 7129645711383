import React, {Component} from 'react'
import styles from './Galery.module.css'
import Slide from '@material-ui/core/Slide';



function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Gallery extends Component{
    constructor(props){
        super(props);
        this.detailDialog = null;
        this.imageGallery = null;
        this.state={

        }
        this._handlePhotos = this._handlePhotos.bind(this)
        this._handleClose = this._handleClose.bind(this)
    }
    _handlePhotos() {
        // this.props.history.push(RouteNames.gallery);
        if (this.detailDialog == null) {
            this.detailDialog = require('@material-ui/core/Dialog').default;
            this.imageGallery = require('../../views/ImageGallery/ImageGallery.component').default;
        }
        this.setState({
            open:true
        })
    }

    _handleClose(){
        this.setState({
            open: !this.state.open
        })
    }


    _renderDialog(){
        const { images, user_images } = this.props;
        if(this.detailDialog && this.state.open && this.imageGallery){
            const DetailDialog = this.detailDialog;
            const ImageGallery = this.imageGallery;
            return( <DetailDialog
                fullScreen
                open={this.state.open}
                onClose={this._handleClose}
                TransitionComponent={Transition}
            >
                <ImageGallery images={images} user_images={user_images}  handleClose={this._handleClose}/>
            </DetailDialog>)
        }
    }

    render(){
        const { images } = this.props;
        let tempImage = [];
        if (images && images.length > 0) {
            tempImage[0] = images[0];
            tempImage[1] = images.length > 1 ? images[1] : images[0];
            tempImage[2] = images[images.length - 1];
            return (
                <div>
                    <div className={styles.row}>
                        <div className={styles.gallery}>
                            <img style={{
                                // width: '100%',
                                height: '300px',
                                transform: 'scale(1.1)'
                            }}
                                 src={tempImage[0]}/>
                        </div>
                        <div className={styles.column}>
                            <div className={styles.sideUp}>
                                <img style={{
                                    height: '150px'
                                }}
                                     src={tempImage[1]}/>
                            </div>

                            <div className={styles.sideDown}>
                                <img style={{
                                    height: '150px'
                                }} src={tempImage[2]}/>

                                <h4 className={styles.text}>
                                    <span className={styles.viewMore} onClick={this._handlePhotos}>View More</span>
                                </h4>
                            </div>
                        </div>
                    </div>

                    {this._renderDialog()}
                </div>
            )
        } else {
            return null;
        }
    }
}

export default Gallery;
