import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from 'redux-form';
import Compliments from '../../components/Compliments/Compliments.component'
import Rating from '@material-ui/lab/Rating';
import {ButtonBase} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {AppBar,Toolbar,Typography} from '@material-ui/core/';
import {renderOutlinedTextField,renderNewSelectField,renderOutlinedSelectField,renderCheckbox,renderFileField} from '../../libs/redux-material.utils'
import styles from './Rating.module.css';
import data from '../../data'

const label = {
    // 0:'',
    // 1: 'Useless',
    // 2: 'Poor',
    // 3: 'Ok',
    // 4: 'Good',
    5: 'Excellent',
}

class Ratings extends Component {
    constructor(props){
        super(props);
        this.state = {
            value:0,
            hover:0,
            selected:false,
        }
        this._handleChange = this._handleChange.bind(this)
        this._handleChangeHover = this._handleChangeHover.bind(this)
        this._handleClose = this._handleClose.bind(this);
        this._handleDelete = this._handleDelete.bind(this)
    }

    _handleChange(e,value) {
        // console.log('handleChamnge',e,value)
        // this.setState({
        //     value:value
        // })
    }

    _handleChangeHover(e,hover) {
        console.log('hover', hover)
        if (hover > -1) {
            this.setState({
                value: hover
            })
        }
    }


    _handleSubmit(data){
      console.log(data)
    }

    _handleClose() {
        this.props.history.goBack();
    }

    _handleDelete(index) {
        const { form_values } = this.props;
        if (form_values) {
            const oldState = form_values.tour_image;
            oldState.splice(index, 1);
            this.props.change('tour_image', oldState);
            console.log('tour_image', oldState);
            this.setState({
                a: Math.random(),
            })
        }
    }

    _renderImages(temp){
        if (temp) {
            return temp.map((val, index) => {
                return (<div>
                    <img src={URL.createObjectURL(val)} key={'images'+index} alt="" height={60} width={60} style={{margin:'5px',position:'relative'}}/>
                    <ButtonBase onClick={this._handleDelete.bind(this, index)}>
                        <CloseIcon/>
                    </ButtonBase>
                </div>)
            })
        } return null;
    }

    _renderImageComponent(prop) {
        return(
            <>
            <div className={styles.wrapper} style={{display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}>
                <div className={styles.uploadIcon}>
                    <img src={require('../../assets/img/camera.png')} style={{width:'80px',height:'80px'}}/>
                </div>
                <div>
                    {prop.placeholder_title}
                </div>
                <input multiple={prop.multiple} id="upload" data-value={'JPG'} accept={prop.accept ? prop.accept : 'image/*'}
                       onChange={prop.handleFileChange}
                       className={styles.ratingInput}
                       type="file"/>
            </div>
            <div style={{display:'flex',marginTop:'15px',overflow:'scroll'}}>
                {this._renderImages(prop.value)}
            </div>
            </>
        )
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div>
                <div style={{height: '65px'}}>
                </div>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this._handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>

                        <div>
                        <Typography variant="h6">
                           Rating
                        </Typography>
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={styles.driverProfile}>
                    <div>
                        <img src={require('../../assets/img/Driver.png')} className={styles.profileImage}/>
                    </div>
                    <div>
                        Maddie Castro
                    </div>
                </div>

                <div className={styles.ratingFlex}>
                    <div>
                        <Rating
                    name="hover-feedback"
                    size={'large'}
                    value={this.state.value}
                    onChange={this._handleChange}
                    onChangeActive={this._handleChangeHover}
                         />
                    </div>
                    <div className={styles.textColor}>
                        { label[this.state.value] }
                    </div>
                </div>


                <div className={styles.bottomContainer}>
                <div>
                    <span className={styles.complimentText}> Give Compliments ? </span>
                    <div className={styles.icons}>
                        {data.compliments.map((val)=> {
                            return (<Compliments data={val} />)
                        })}

                    </div>
                </div>

                <div className={styles.container}>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className={styles.formContainer}>
                            <Field
                                fullWidth={true}
                                name="comment"
                                component={renderOutlinedTextField}
                                margin={"dense"}
                                label={"Write down your Comment"}
                                multiline
                                rows={"3"}
                            />
                        </div>
                    </form>
                </div>

                <div className={styles.uploadContainer}>
                    <div style={{fontWeight:'500',marginBottom:'10px'}}>
                        Upload Photo
                    </div>
                    <div style={{width:'100%'}}>
                        <Field
                            max_size={1024*1024*5}
                            type={['jpg', 'png', 'pdf']}
                            fullWidth={true}
                            name="tour_image"
                            component={renderFileField}
                            placeholder={'Select a photo'}
                            label={'Select a photo'}
                            renderComponent={(prop) => { return this._renderImageComponent(prop) }}
                            renderCustom
                            multiple
                            // accept={'image/*, application/pdf'}
                            // default_image={user_details.user_image ? user_details.user_image : null}
                        />
                    </div>
                </div>



                <div style={{marginTop:'20px'}}>
                    <ButtonBase className={styles.bottomBtn} type={'submit'}>
                       Submit
                    </ButtonBase>
                </div>

                </div>
            </div>
        )
    }
}

const rating = reduxForm({
    form:'rating_form',

})(Ratings);

function mapStateToProps(state) {
    return {
        form_values: state.form.rating_form ? state.form.rating_form.values : null
    }
}

export default connect(mapStateToProps)(rating)
