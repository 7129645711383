import React, {Component} from 'react';
import styles from './Style.module.css';
import Rating from '@material-ui/lab/Rating';
import RouteNames from '../../../routes/Route.name';
import {FavoriteBorder as Favorite, Favorite as FavoriteFilled, Update as Duration} from '@material-ui/icons';
import {convertDurationToString} from "../../../libs/general.utils";
import {addToWishlist,removeFromWishlist} from '../../../actions/Common.action'
import {actionFetchTours} from "../../../actions/Tours.action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MediaQuery from 'react-responsive';


class   FullWidthCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false
        };
        this._handleClick = this._handleClick.bind(this);
        this._handleCardClick = this._handleCardClick.bind(this);
    }

    _handleClick(e) {
        e.stopPropagation();
        this.setState({
            is_filled: !this.state.is_filled
        })
    }

    _handleCardClick(e) {
        e.preventDefault();
        const { data } = this.props;
        this.props.handleClick(data._id);
    }

    addToWishlist(){
        console.log(this.props.data)
        this.props.addToWishlist(this.props.data)
    }

    removeFromWishlist(){

    }

    _renderFavourite() {
        if (this.state.is_filled)
            return (
                <FavoriteFilled fontSize={'2rem'} className={styles.icons} onClick={this.addToWishlist()}/>
            );
        else
            return <Favorite fontSize={'2rem'} className={styles.icon} onClick={this.removeFromWishlist()}/>

    }

    _renderEcoImage() {
        const { data } = this.props;
        const  { eco_friendly } = data;
        let totalPoints = 0;
        Object.keys(eco_friendly).forEach((val) => {
            if (eco_friendly[val]) {
                totalPoints++;
            }
        });
        return (<img src={require('../../../assets/img/eco/ic_eco'+totalPoints+'.png')} className={styles.ecoIcon}/>);
    }

    render() {
        const { data } = this.props;
        if (data) {
            const image = data.images.length > 0 ? (data.thumbnail_index <= data.images.length ? data.images[data.thumbnail_index] : data.images[0]) : '';

            return (
                <div onClick={this._handleCardClick} className={styles.cardContainer}>
                    <div>
                        <div className={styles.tourImage} style={{
                            backgroundImage: 'url(' + image + ')',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}>
                        </div>
                    </div>
                    <div className={styles.tourDetailContainer}>
                        <div>
                            <Rating value={data.rating} readOnly className={styles.ratings}/>
                        </div>
                        <div>
                            <div className={styles.tourHeading}>
                                {data.name}
                            </div>
                            <MediaQuery minDeviceWidth={768}>
                                <p className={styles.tourDescription}>
                                    {data.overview.length > 100 ? data.overview.substring(0, 100)+'...' : data.overview}
                                </p>
                            </MediaQuery>
                            <div className={styles.duration}>
                                <Duration className={styles.durationIcon}/>
                                <span className={styles.timePeriod}>Duration: {convertDurationToString(data.duration)}</span>
                            </div>
                            <div className={styles.bottomFlex}>
                                <div className={styles.categoryFlex}>
                                    <img src={require('../../../assets/img/tuxi/tuktuk.svg')}
                                         className={styles.svgIcons}/>
                                    <div className={styles.heading}>
                                        {data.category}
                                    </div>
                                </div>

                                <MediaQuery minDeviceWidth={768}>
                                    <div className={styles.categoriesFlex}>
                                        <img src={require('../../../assets/img/tuxi/travel.svg')}
                                             className={styles.svgIcons}/>
                                        <div className={styles.heading}>
                                            {data.type_category}
                                        </div>
                                    </div>
                                </MediaQuery>
                                <MediaQuery maxDeviceWidth={768}>
                                    <div className={styles.categoriesFlex}>
                                        <img src={require('../../../assets/img/tuxi/travel.svg')}
                                             className={styles.svgIcons}/>
                                        <div className={styles.heading}>
                                            {data.languages}
                                        </div>
                                    </div>
                                </MediaQuery>
                                <MediaQuery minDeviceWidth={768}>
                                    <div className={styles.categoriesFlex}>
                                        <img src={require('../../../assets/img/tuxi/language.svg')}
                                             className={styles.svgIcons}/>
                                        <div className={styles.heading}>
                                            {data.languages}
                                        </div>
                                    </div>
                                </MediaQuery>
                            </div>
                            <MediaQuery maxDeviceWidth={768}>
                                <div className={styles.categoriesFlexN}>
                                    <img src={require('../../../assets/img/tuxi/language.svg')}
                                         className={styles.svgIcons}/>
                                    <div className={styles.heading}>
                                        {data.type_category}
                                    </div>
                                </div>
                            </MediaQuery>
                        </div>
                    </div>
                    <div className={styles.filledFlex}>
                        <div onClick={this._handleClick} style={{display:'flex',alignItems:'center'}}>
                            {this._renderFavourite()}
                            {this._renderEcoImage()}
                        </div>
                        <div className={styles.priceFlex}>

                            <div style={{marginLeft: '10px', textAlign: 'left'}}>
                                <div className={styles.fromText}>
                                    From
                                </div>
                                <div className={styles.price}>
                                    <span>{data.currency_shortcode} {data.starting_price}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else {
            return null;
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addToWishlist: addToWishlist,
        removeFromWishlist: removeFromWishlist,

    }, dispatch);
}

function mapStateToProps(state) {
    return {

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(FullWidthCard);
