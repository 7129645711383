import React, {Component} from 'react';
import styles from './Activity.module.css';
import {convertDurationToString} from "../../../../libs/general.utils";

class Activity extends Component{

    _renderPersonalized(){
        const { data } = this.props;
        return(
            <div className={styles.activityFlex}>
                <div >
                    <img src={require('../../../../assets/img/tuxi/activity/flyer.svg')} className={styles.activitiesIcons}/>
                </div>
                <div className={styles.textContainer}>
                    Customization { data.is_personalized ?  'Available' : 'Not Available' }
                </div>
            </div>
        )
    }

    _renderChildren() {
        const { data } = this.props;
        return (
            <div className={styles.activityFlex}>
                <div>
                    <img src={require('../../../../assets/img/tuxi/activity/ic_minimum_age.png')} className={styles.activitiesIcons}/>
                </div>
                <div className={styles.textContainer}>
                    Minimum age for members {data.minimum_age} Years
                </div>
            </div>
        )
    }

    _renderKids(){
        const { data } = this.props;
        return(
            <div className={styles.activityFlex}>
                <div>
                    <img src={require('../../../../assets/img/tuxi/activity/child.svg')} className={styles.activitiesIcons}/>
                </div>
                <div className={styles.textContainer}>
                    {data.is_kids ? '' : 'Not'} Kids friendly
                </div>
            </div>
        )
    }
    render(){
        const { data } = this.props;
        return(
            <div>
                <div className={styles.activityFlex}>
                    <div>
                        <img src={require('../../../../assets/img/tuxi/activity/ic_duration.png')} className={styles.activitiesIcons}/>
                    </div>
                    <div className={styles.textContainer}>
                        Duration: {convertDurationToString(data.duration)}
                    </div>
                </div>

                    {data.is_personalized ? this._renderPersonalized(): ''}

                <div className={styles.activityFlex}>
                    <div>
                        <img src={require('../../../../assets/img/tuxi/activity/ic_languges.png')} className={styles.activitiesIcons}/>
                    </div>
                    <div className={styles.textContainer}>
                       Languages: {data.languages}
                    </div>
                </div>

                    {data.minimum_age > 0 ? this._renderChildren(): ''}

                    {data.is_kids == true ? this._renderKids() : ''}

                <div className={styles.activityFlex}>
                    <div>
                        <img src={require('../../../../assets/img/tuxi/activity/ic_24hours.png')} className={styles.activitiesIcons}/>
                    </div>
                    <div className={styles.textContainer}>
                        24 Hours Cancellation Available
                    </div>
                </div>
                <div className={styles.activityFlex}>
                    <div>
                        <img src={require('../../../../assets/img/tuxi/activity/interface.svg')} className={styles.activitiesIcons}/>
                    </div>
                    <div className={styles.textContainer}>
                        Pickup from guest location
                    </div>
                </div>
            </div>
        )
    }
}

export default Activity
