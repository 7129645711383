import React, {Component} from 'react';
import styles from './ProfileMobile.module.css';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import {
    renderOutlinedTextField,
    renderCountryContact,
    renderOutlinedSelectField, renderFileField
} from "../../../libs/redux-material.utils";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {ButtonBase,MenuItem} from '@material-ui/core';
import OtpDialog from '../../Otp/OtpDialog.view'


const validate = (values) => {
    const errors = {};
    const requiredFields = ['password','confirm_password','new_password','name','email','contact','address','street','city','dob','country'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if(values.confirm_password && values.confirm_password != values.new_password) {
        errors.confirm_password = 'Password should match'
    }
    return errors;
};

class ProfileMobile extends Component{
    constructor(props){
        super(props);

        this.state = {
            profile:true,
            password:false,
            selectedDate: new Date(),
            is_edit:false,
            open: false,
            dialog_open: false,
        }
        this._handleSubmit = this._handleSubmit.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this);
        this._handleDialog = this._handleDialog.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleSideClick = this._handleSideClick.bind(this);
    }

    handleDateChange(data){
        this.setState({selectedDate: data})
    }

    _handleSubmit(data) {

    }

    _handleClose() {
        this.setState({open: !this.state.open});
    }

    _handleDialog() {
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    _handleSideClick(type) {
        this.setState({
            profile: type == 'PROFILE'
        })
    }


    _renderProfile(){
        const {handleSubmit} = this.props;
        const { profile } = this.state;
        if(profile){
            return(
                <div>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className={styles.profileImage}>
                            <Field
                                max_size={1024*1024*5}
                                type={['jpg', 'png']}
                                fullWidth={true}
                                name="user_image"
                                component={renderFileField}
                                show_image
                                // default_image={user_details.user_image ? user_details.user_image : null}
                            />
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                {/*<label className={styles.headerLabel} htmlFor="">First Name <span className={styles.important}>*</span></label>*/}
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="name"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Full Name"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <div className={styles.verifyFlex}>
                                    {/*<label className={styles.headerLabel} htmlFor="">*/}
                                    {/*Email Address <span className={styles.important}>*</span>*/}
                                    {/*</label>*/}
                                    {/*<div className={styles.verificationText}>*/}
                                        {/*Verification Pending*/}
                                    {/*</div>*/}
                                </div>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="email"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label={"Email"}
                                />
                            </div>

                        </div>


                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <div className={styles.verifyFlex}>
                                    {/*<label className={styles.headerLabel} htmlFor="">*/}
                                        {/*Mobile No. <span className={styles.important}>*</span>*/}
                                    {/*</label>*/}
                                    {/*<div className={styles.verifiedText}>*/}
                                        {/*Verified*/}
                                    {/*</div>*/}
                                </div>
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="contact"
                                    component={renderCountryContact}
                                    type={'text'}
                                    margin={'dense'}
                                    errorText="Required"
                                    label="Contact"/>
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="Password"/>
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                {/*<label className={styles.headerLabel} htmlFor="">*/}
                                    {/*Address Lane*/}
                                {/*</label>*/}
                                <Field
                                    disabled={this.state.is_edit}
                                    fullWidth={true}
                                    name="address"
                                    component={renderOutlinedTextField}
                                    label={"Address"}
                                    multiline
                                    margin={"dense"}
                                />
                            </div>
                        </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                {/*<label className={styles.headerLabel} htmlFor="">Date Of Birth</label>*/}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disabled={this.state.is_edit}
                                    disableFuture
                                    name='dob'
                                    openTo="year"
                                    format="dd/MM/yyyy"
                                    label="Date of birth"
                                    margin={"dense"}
                                    fullWidth
                                    inputVariant="outlined"
                                    views={["year", "month", "date"]}
                                    value={this.state.selectedDate}
                                    onChange={this.handleDateChange}
                                />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>


                        <div style={{textAlign:'center',marginTop:'30px'}}>
                            <ButtonBase className={styles.saveButton} type={'submit'} onClick={this._handleDialog}>
                                Save Changes
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            )
        }
        else {
            return(
                <div>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>

                        <h4>Enter your password details to change password</h4>
                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>Old Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="Password"/>
                            </div>
                        </div>

                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>New Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="new_password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="New Password"/>
                            </div>
                        </div>

                        <div className={styles.formSpacing}>
                            <label className={styles.labelText}>Retype New Password</label>
                            <div className={styles.formContainer}>
                                <Field
                                    type={'password'}
                                    fullWidth
                                    name="confirm_password"
                                    component={renderOutlinedTextField}
                                    margin={"dense"}
                                    label="Retype New Password"/>
                            </div>
                        </div>

                        <div style={{float:'right'}}>
                            <ButtonBase className={styles.saveButton} type={'submit'}>
                               Save Changes
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            )
        }
    }

    render(){
        const { profile } = this.state;
        return(
            <div>
               <div className={styles.profileFlex}>
                   <div style={{width:'100%'}}>
                       {this._renderProfile()}
                   </div>
               </div>
                <OtpDialog open={this.state.dialog_open}  handleClose={this._handleDialog}/>
            </div>
        )
    }
}

const profile = reduxForm({
    form:'profile',
    validate,
})(ProfileMobile);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(profile)
