import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ButtonBase from "@material-ui/core/ButtonBase";
import {withStyles} from "@material-ui/core/styles/index";
import styles from './TopBar.module.css'

const useStyles = {
    btnColor: {
        padding: '10px 30px',
        borderRadius: '5px' ,
        backgroundImage: 'linear-gradient(to left, #04AFB7 , #1BB380)',
        color: 'white'
    }
}

class TopBar extends React.Component{
    constructor(props){
        super(props);
        this.state={
            logout:false
        }
        this._handleClick = this._handleClick.bind(this)
        this._handleButtonSignIn = this._handleButtonSignIn.bind(this);
        this.activeRoute = this.activeRoute.bind(this)
    }

    componentDidMount() {
        console.log(this.props.location.pathname);
    }

    _handleClick(route){
        this.props.history.push(route);
    }

    _handleButtonSignIn() {
        // console.log('dialog',logout_dialog);
        this.setState({
            logout: true
        })
    }

    activeRoute(routeName) {
        return false;
        if(routeName == '/') {
            return this.props.location.pathname == '/';
        } else {
            return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
        }
    }

    render(){
        const classes = this.props;
        return(
            <div className={classes.root}>
                <AppBar position="static" color="default" className={styles.background}>
                    <Toolbar >
                        <div className={styles.imageFlex}>
                        <img className={styles.logo} src={require('../../assets/img/ic_logo.png')} alt="logo"/>
                        </div>
                        <div className={styles.innerFlex}>
                            <ul className={styles.navList}>
                                <li style={{overflow:'hidden'}}>
                                    <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/')}>
                                        <div className={styles.iconsFlex}>
                                            <img src={ this.activeRoute('/') ? require('../../assets/img/navbar/ic_home_selected.png') : require('../../assets/img/navbar/ic_home_unselected.png')} className={styles.navIcon}/>
                                            <span className={this.activeRoute('/cards') ? styles.navText : 'inherit' }>Home</span>
                                        </div>
                                    </ButtonBase>
                                </li>
                            </ul>
                            <ul className={styles.navList}>
                                <li style={{overflow:'hidden'}}>
                                    <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/')}>
                                        <div className={styles.iconsFlex}>
                                            <img src={ this.activeRoute('/') ? require('../../assets/img/navbar/ic_cities_selected.png') :require('../../assets/img/navbar/ic_cities_unselected.png')} className={styles.navIcon}/>
                                            <span className={this.activeRoute('/') ? styles.navText : 'inherit' }>Cities</span>
                                        </div>
                                    </ButtonBase>
                                </li>
                            </ul>
                            <ul className={styles.navList}>
                                <li style={{overflow:'hidden'}}>
                                    <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/')}>
                                        <div className={styles.iconsFlex}>
                                            <img src={ this.activeRoute('/') ? require('../../assets/img/navbar/ic_blogs_selected.png') :require('../../assets/img/navbar/ic_blogs_unselected.png')} className={styles.navIcon}/>
                                            <span className={this.activeRoute('/') ? styles.navText : 'inherit' }>Blogs</span>
                                        </div>
                                    </ButtonBase>
                                </li>
                            </ul>
                            <ul className={styles.navList}>
                                <li style={{overflow:'hidden'}}>
                                    <ButtonBase className={styles.buttonBase} onClick={this._handleClick.bind(this, '/')}>
                                        <div className={styles.iconsFlex}>
                                            <img src={ this.activeRoute('/') ? require('../../assets/img/navbar/ic_wishlist_selected.png') :require('../../assets/img/navbar/ic_wishlist_unselected.png')} className={styles.navIcon}/>
                                            <span className={this.activeRoute('/') ? styles.navText : 'inherit' }>Wishlist</span>
                                        </div>
                                    </ButtonBase>
                                </li>
                            </ul>
                        </div>
                        <ButtonBase className={styles.loginButton} onClick={this._handleButtonSignIn}>
                            {this.props.is_authenticated ? 'Logout' : 'Login/Signup'}
                        </ButtonBase>
                    </Toolbar>

                </AppBar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(TopBar))
