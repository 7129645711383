/**
 * Created by charnjeetelectrovese@gmail.com on 1/8/2020.
 */
import {
    SELECT_TOUR,
    TOURS_DONE,
    TOURS_INIT
} from '../actions/Tours.action';

const initialState = {
    is_calling: true,
    tours: [],
    selected_tour: null,
    page: 1,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOURS_INIT: {
            return {...state, is_calling: true};
        }
        case TOURS_DONE: {
            return {...state, is_calling: false, tours: action.payload};
        }
        case SELECT_TOUR: {
            return { ...state, selected_tour: action.payload };
        }
        default: {
            return state;
        }
    }
}
