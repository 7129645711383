import React, {Component} from 'react';
import styles from './Portfolio.module.css';
import classNames from "classnames";
import {ButtonBase,MenuItem} from '@material-ui/core';
import {ArrowRightAlt,ArrowForward} from '@material-ui/icons';
import RouteNames from "../../routes/Route.name";
import history from "../../libs/history.util";


class Portfolio extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
        this._handleInquire= this._handleInquire.bind(this);
    }

    _handleInquire(){
        history.push(RouteNames.quote)
    }


    render() {
        const {products} = this.props
        return (
            <div>
                <div className={products ? styles.pharmaContainer :styles.bgContainer}>
                    <div className={'ncontainer'}>
                        <div className={styles.portfolioFlex}>
                            <img src={products ? require('../../assets/img/factory/products.png'):require('../../assets/img/factory/Increase_products.png')} className={styles.products}/>
                            <div className={products ? styles.pharmadesc : styles.portfoliodesc}>
                                <h2>Increase Your Portfolio with Ease</h2>
                                <div className={styles.bringText}>FactoryGrid brings to you a secured platform with verified<br/>
                                    manufacturers who bring to you the right products in the <br/>
                                    right price range so that you can easily market and grow.
                                </div>
                                <div className={styles.prodFlex}>
                                    <div className={styles.num}>5000+<br/>PRODUCTS</div>
                                    <hr className={styles.spacing}/>
                                    <div className={styles.num}>100+<br/>MANUFACTURERS</div>
                                </div>
                                <div style={{marginBottom:'15px'}}/>
                                <ButtonBase onClick={this._handleInquire}>
                                    Enquire now <ArrowForward className={styles.forward}/>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Portfolio