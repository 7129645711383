import React, { Component } from 'react'
import {Star as StarFilled, StarBorder as Star} from "@material-ui/icons";
import cx from 'classnames';
import styles from './Style.module.css';
import {bindActionCreators} from "redux";
import {actionToggleWishlist} from "../../actions/Wishlist.action";
import {connect} from "react-redux";

class WishlistComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            touchClass: '',
            temp:[]
        }
        this._handleToggle = this._handleToggle.bind(this);
        this.timeout = null;
    }

    _handleToggle(e) {
        e.stopPropagation();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const { productId } = this.props;
        this.props.actionToggleWishlist(productId);
        this.setState({
            touchClass: styles.touchClass,
        });
        this.timeout = setTimeout(() => {
            this.setState({
                touchClass: ''
            });
        }, 500)
       this.props.handleClick(productId);
    }

    render() {
        const { wishlist, productId } = this.props;
        const isThere = (wishlist && Array.isArray(wishlist) ?  (wishlist.indexOf(productId) >= 0 ? true : false) : false);
        if (isThere)
            return (
                <StarFilled fontSize={'2rem'} onClick={this._handleToggle} className={cx(styles.icons, this.state.touchClass)}/>
            );
        else
            return <StarFilled fontSize={'2rem'} onClick={this._handleToggle} className={styles.icon}/>
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionToggleWishlist: actionToggleWishlist
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.list,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(WishlistComponent);
