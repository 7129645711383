import React ,{Component} from 'react'

import styles from './TourLater.module.css'
import ButtonBase from '@material-ui/core/ButtonBase';
import {Navbar} from '../../../components/index.component'
import DateSelector from '../DateSelector/DateSelector.component';
import RouteNames from '../../../routes/Route.name';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import {connect} from "react-redux";
import { bindActionCreators } from 'redux';
import {actionTourLaterUpdateTime, actionUpdateLocation} from '../../../actions/Common.action';
import {actionUpdateFilter} from '../../../actions/Filter.action';
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {serviceGetCities} from "../../../services/Common.service";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class FindTour extends Component {
    constructor(props){
        super(props);
        const {location} = props;
        this.change = 0;
        this.state={
            open: false,
            date: moment(new Date()).format('MM-DD-YYYY'),
            time: '09:00',
            city: location ? location.name : '',
            cities: [],
            search: location ? location.name : ''
        };
        this._handleClick = this._handleClick.bind(this);
        this._handleDialog = this._handleDialog.bind(this);
        this._handleSelectNext = this._handleSelectNext.bind(this);
        this._handleAutoComplete = this._handleAutoComplete.bind(this);
        this._handleAutoClose = this._handleAutoClose.bind(this);
        this._handleInputChange = this._handleInputChange.bind(this);
    }

    async componentDidMount() {
        const { date, time } = this.state;
        const { location } = this.props;
        this.props.actionTourLaterUpdateTime(date, time);


        const req = await serviceGetCities();
        if (!req.error) {
            this.setState({
                cities: req.data.cities,
                // city: null,
                // search: '',
            })
        }

    }

    _handleAutoComplete(e, val) {
        console.log('last state', this.state.city);
        if (val) {
            this.setState({
                city: val,
                search: val.name
            });
            this.props.actionUpdateLocation(val);
        }
    }

    _handleAutoClose() {
        console.log('close call');
        // this.setState({
        //     city: null,
        // })
    }

    _handleInputChange(e,value) {
        if (value || this.change > 0) {
            this.change++;
            this.setState({
                search: value,
            });
        }
    }

    _handleDialog() {
        this.setState({
            open: !this.state.open,
        });
    }

    _handleClick(){
        const {location} = this.props;
        const { date, time } = this.state;
        // this.props.history.push(RouteNames.home);
        this.props.actionUpdateFilter({city: location.name, board_type: 'board_now', date, time }, false, false);
        this.props.history.push(RouteNames.home+'?city='+location.name+'&board_type=board_later&date='+date+'&time='+time);
    }

    _handleSelectNext(date, time) {
        this.props.actionTourLaterUpdateTime(date, time);
        this.setState({
            open: false,
            date: date,
            time: time,
        })
    }

    render(){
        console.log('SEARCH', this.state.search);
        const { location } = this.props;
        const { date, time } = this.state;

        const t = moment(new Date(date));
        const dateSting = t.format('MMM DD, YYYY');
        return(
            <div>
                <Navbar/>
                <div style={{height:'60px'}}>
                </div>

                <div className={styles.rideContainer}>
                <div className={styles.container}>
                    <div>
                        <img src={require('../../../assets/img/ic_car@3x.png')} className={styles.carIcon}/>
                    </div>

                    <div className={styles.inner}>
                        <div className={styles.heading}>
                            Find A Tour
                        </div>

                        <div>
                            <Autocomplete
                                disableClearable
                                onChange={this._handleAutoComplete}
                                onClose={this._handleAutoClose}
                                onInputChange={this._handleInputChange}
                                options={this.state.cities}
                                getOptionLabel={option => option.name}
                                id="disable-open-on-focus"
                                disableOpenOnFocus
                                inputValue={this.state.search}
                                renderInput={params => { return (
                                    <TextField  {...params} label="Search For Your location.." variant="outlined" margin={'dense'} fullWidth />
                                    // <input {...params} className={styles.location} type="text" name="location"
                                    //        placeholder="Search For Your location.."/>
                                )}}
                            />
                            {/*<input value={location ? location.name : ''} className={styles.ride} type="text" name="From" placeholder="Leaving From ?"/>*/}
                        </div>
                    </div>
                </div>
                <hr style={{opacity:'0.2'}}/>
                </div>

                <div className={styles.rideContainer}>
                    <ButtonBase className={styles.dialogBtn} onClick={this._handleDialog}>
                    <div className={styles.dBtnInner}>
                        <div>
                            <img src={require('../../../assets/img/ic_time.png')} className={styles.carIcon}/>
                        </div>

                        <div className={styles.inner}>
                            <div className={styles.heading}>
                               Date and Time
                            </div>

                            <div>
                               <div style={{color:'#2196F3',marginTop:'5px',fontWeight:'600'}}>{dateSting} {time}</div>
                            </div>
                        </div>
                    </div>
                    </ButtonBase>
                    <hr style={{opacity:'0.2'}}/>
                </div>

                <div className={styles.rideContainer} style={{textAlign:'center'}}>
                    <ButtonBase className={styles.search} onClick={this._handleClick}>
                        <div >
                        Search
                        </div>
                    </ButtonBase>
                </div>

                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this._handleDialog}
                    TransitionComponent={Transition}
                >
                    <DateSelector
                        handleClose={this._handleDialog}
                        handleNext={this._handleSelectNext}
                    />
                </Dialog>
            </div>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionTourLaterUpdateTime: actionTourLaterUpdateTime,
        actionUpdateFilter: actionUpdateFilter,
        actionUpdateLocation: actionUpdateLocation
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        location: 'location' in state.common ? state.common.location : null,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(FindTour);
