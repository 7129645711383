import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import styles from './QuoterForm.module.css';
import {renderOutlinedSelectField, renderOutlinedTextField, renderTextField} from '../../../libs/redux-material.utils';
import {ButtonBase} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'email','contact','message'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

class QuoteForm extends Component {
    constructor(props) {
        super(props);
        this.state={}
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(data) {
        console.log(data);
        // const fd = new FormData();
        // Object.keys(data).forEach((key)=> {
        //     fd.append(key,data[key])
        // })
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className={styles.mainLoginView}>
                <div className={styles.container}>
                    <div className={styles.imageContainer}>
                       <h2>Request for Quote</h2>
                        <div className={styles.fill}>Fill in this quick form and our team shall help you get <br/> the best quote for your requirement</div>
                    </div>

                    <div >
                        <form className={'discover'} onSubmit={handleSubmit(this._handleSubmit)}>
                            <div>
                                <div className={styles.formContainer}>
                                    <Field
                                        fullWidth={true}
                                        name="name"
                                        component={renderOutlinedTextField}
                                        margin={"dense"}
                                        label={"Full Name"}
                                    />
                                </div>

                                <div className={styles.formFlex}>
                                    <div className={styles.formContainer}>
                                        <Field
                                            fullWidth={true}
                                            name="email"
                                            component={renderOutlinedTextField}
                                            margin={"dense"}
                                            label={"Email"}
                                        />
                                    </div>
                                    <div className={styles.formContainer} style={{marginLeft:'15px'}}>
                                        <Field
                                            fullWidth={true}
                                            name="contact"
                                            type={'number'}
                                            component={renderOutlinedTextField}
                                            margin={"dense"}
                                            label={"Phone Number"}>
                                        </Field>
                                    </div>
                                </div>

                                <div className={styles.formContainer}>
                                    <Field
                                        fullWidth={true}
                                        name="message"
                                        component={renderOutlinedTextField}
                                        label={"Message"}
                                        multiline
                                        margin={"dense"}
                                        rows={"2"}/>
                                </div>
                                <br/>
                                <ButtonBase className={styles.looking} type={'submit'}>
                                    Request Quote
                                </ButtonBase>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

QuoteForm = reduxForm({
    form: 'Discover', // a unique identifier for this form
    validate,
})(QuoteForm);

export default QuoteForm;