import React, {Component} from 'react';
import styles from './ProductPort.module.css';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import ButtonBase from "@material-ui/core/ButtonBase";
// import history from '../../../libs/history.util';
// import RouteNames from "../../../routes/Route.name";

class ProductPort extends Component{
    constructor(props) {
        super(props);
        // this._handleClick = this._handleClick.bind(this);
    }

    // _handleClick () {
    //     history.push(RouteNames.blog_detail);
    // }

    render(){
        return(
            <div className={styles.productContainer}>
                <div className={styles.wrapper}>
                    <div className={styles.medicine}>
                        <img src={require('../../../assets/img/factory/medicine.png')} height={50}/>
                    </div>
                    <div className={styles.med}>PARACETAMOL</div>
                    <div className={styles.strip}><span className={styles.med}>3.00</span><span>/Strip</span></div>
                    <div className={styles.min}>Minimum Order quantity</div>
                    <div className={styles.box}>120 Box</div>
                    <ButtonBase className={styles.works}>
                        get a quote
                    </ButtonBase>
                </div>
            </div>
        )
    }
}

export default ProductPort
