import React, {Component} from 'react';
import styles from './MyReviews.module.css'
import ReviewsCard from "./component/ReviewsCard.component";
import {serviceGetMyBookings} from "../../services/Booking.service";
import {WaitingComponent} from "../../components/index.component";

class MyReviews extends Component{
    constructor(props){
        super(props);

        this.state = {
            reviews: [1,2,3],
            isGetting: true
        }
    }
    // async componentDidMount() {
    //     const req = await serviceGetMyBookings();
    //     if (!req.error) {
    //         this.setState({
    //             bookings: req.data,
    //             isGetting: false,
    //         })
    //     }
    //     this.setState({
    //         isGetting: false,
    //     })
    // }

    _renderBookings(){
        const { reviews } = this.state;
        if(reviews.length > 0){
            return(
                <div>
                    <h2>My Reviews</h2>
                    {reviews.map((val)=> {
                        return (<ReviewsCard data={val}/>)
                    })}
                </div>
            )
        }
        else {
            return(
                <div className={styles.imageSmile}>
                    <img src={require('../../assets/img/tuxi/smile.png')} style={{height:'150px'}}/>
                    <div className={styles.heading}>No Reviews yet</div>
                    <div>Review us by visiting our Homepage or cities page to book your trips <br/>
                        with full adventurous & memorable with us
                    </div>
                </div>
            )
        }
    }

    render(){
        const { isGetting } = this.state;
        if (!isGetting) {
            return (<WaitingComponent />)
        }
        return(
            <div>
                {this._renderBookings()}
            </div>
        )
    }
}

export default MyReviews
