/**
 * Created by charnjeetelectrovese@gmail.com on 10/30/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import classNames from 'classnames';
// creates a beautiful scrollbar

import {Navbar} from '../../components/index.component';
import headerRoutes from '../../routes/Header.route';
import IndexView from "../../views/index/Index.component";
import CustomRouter from "../../libs/CustomRouter.utils";
import CustomSnackbar from '../../components/Snackbar.component';
import FooterView from "../../views/Footer/Footer.view";
import NavScroll from "../../views/NavScroll/NavScroll.view";
import debounce from 'lodash.debounce';
const switchRoutes = (
    <Switch>
        {headerRoutes.map((prop, key) => {
            return <CustomRouter exact path={prop.path} component={prop.component}
                                 desktopComponent={prop.desktopComponent} key={key} {...prop} />;
        })}
    </Switch>
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPos: 0
        };
        this.mainNav = null;

        this._handleScroll = this._handleScroll.bind(this);
        this._debouncedScroll = debounce(this._handleScroll, 100);
    }


    getRoute() {
        return this.props.location.pathname !== '/maps';
    }

    componentDidMount() {
        window.addEventListener('scroll',this._debouncedScroll);
        setTimeout(() => {
            this._handleScroll(null);
        }, 100);
    }

    componentDidUpdate() {
        // this.refs.mainPanel.scrollTop = 0;

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this._debouncedScroll)
    }

    _handleScroll(e) {
      const windowScrollTop =  document.querySelector("html").scrollTop;
      console.log('windowScrollTop', windowScrollTop);
      this.setState({
          scrollPos: windowScrollTop,
      });
    }

    render() {
        const { scrollPos } = this.state;
        return (
            <div>
                {/*<Navbar*/}
                {/*    injectCss={ ((scrollPos > 100) ? { marginTop: '-100px' } : {} ) }*/}
                {/*    innerRef={(ref) => { this.mainNav = ref }}*/}
                {/*    history={this.props.history}*/}
                {/*    {...this.props}/>*/}
                {/*<NavScroll*/}
                {/*    injectCss={ (scrollPos < 120 ? { } : { top: '0px' } ) }*/}
                {/*/>*/}
                <div>
                    <div>{switchRoutes}</div>
                </div>
                <CustomSnackbar/>
                {/*<FooterView {...this.props}/>*/}
            </div>
        );
    }
}

App.propTypes = {};

export default (App);
