/* eslint-disable indent,linebreak-style */

const isProduction = !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
export default {
    app_name: 'Letsconnect Lens',
    DEFAULT_APP_URL: isProduction ? 'http://91.205.173.97:4545/api/' :'http://192.168.1.21:4545/api/',
    SOCKET_URL: isProduction ? 'http://91.205.173.97:4545' : 'http://192.168.1.21:4545',
    DEFAULT_TIME_FORMAT: 'DD-MM-YYYY, HH:mm',
    TIME_ZONE: 5.5,
    // DEFAULT_APP_URL: 'http://35.154.147.169:5055/api/',
    DEFAULT_PAGE_VALUE: 100,
    GOOGLE_LOGIN_KEY: '1027293586426-qg6lv2vsp57m05tn32m9stku2ljsd1uh.apps.googleusercontent.com',
    FACEBOOK_LOGIN_KEY: '213504989180156',
    GOOGLE_MAP_KEY: 'AIzaSyAlZAxH7-jcdMDs39coWM8wuRBRr7l-qiM',
    SOCKET_EVENTS: {
        TOUR_ACCEPTED: 'TOUR_ACCEPTED',
        TOUR_REJECTED: 'TOUR_REJECTED',
        TOUR_STARTED: 'TOUR_STARTED',
        DRIVER_ARRIVED: 'DRIVER_ARRIVED',
        TOUR_COMPLETED: 'TOUR_COMPLETED',
        ON_BOARDED: 'ON_BOARDED',
        USER_MESSAGE_RECEIVED: 'MESSAGE_RECEIVED',
    },
    initialFilterState: {
        arrival: [],
        commitments: [],
        price: {
            min: 0,
            max: 5000
        },
        category: [],
        tour_type: '',
        duration: [],
        industry: [],
        rating: [],
        green_score: [],
        certifications: [],
        location:[],
        city: 'Chandigarh',
        board_type: 'board_now',
        date: '',
        time: '',
    },
    BOOKING_STATUS: {
        PENDING: 'PENDING',
        ASSIGNED: 'ASSIGNED',
        PAYMENT: 'PAYMENT',
        ON_PICKUP_LOCATION: 'ON_PICKUP_LOCATION',
        OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
        ON_DROP_LOCATION: 'ON_DROP_LOCATION',
        DELIVERED: 'DELIVERED',
        REJECTED: 'REJECTED',
        ACCEPTED: 'ACCEPTED'
    },
    BOOKING_STATUS_TEXT: {
        ORDER: 'Ordered',
        PENDING: 'Pending',
        ASSIGNED: 'Driver Assigned',
        PAYMENT: 'Payment Pending',
        ON_PICKUP_LOCATION: 'Dispatching',
        OUT_FOR_DELIVERY: 'Out For Delivery',
        ON_DROP_LOCATION: 'Arrived On Location',
        DELIVERED: 'Order Delivered',
        REJECTED: 'Rejected',
        ACCEPTED: 'Confirmed'
    },
    filters: {
        arrival: {
            title: 'Estimated Time of Arrival',
            data: [
                {
                    title: '0-5',
                    value: '0-5'
                },
                {
                    title: '5-10',
                    value: '5-10'
                },
                {
                    title: '10-15',
                    value: '10-15'
                },

            ],
        },
        commitments: {
            title: 'COMMITMENTS',
            data: [],
        },
        category: {
            title: 'CATEGORY',
            data: []
        },
        tour_type: {
            title: 'Tour Types',
            data: []
        },
        duration: {
            title: 'DURATION',
            data: [
                {
                    title: '2 Hours',
                    value: '2',
                },
                {
                    title: '3 Hours',
                    value: '3',
                },
                {
                    title: '4 Hours',
                    value: '4',
                },
                {
                    title: '5 Hours',
                    value: '5',
                },
            ]
        },
        rating: {
            title: 'RATINGS',
            data:[
                {
                    type:'Rating',
                    total: '37'
                }
            ]
        },
        green_score: {
            title: 'Eco Rating',
            data:[
                {
                    title: '4 and above',
                    value: '4',
                },
                {
                    title: '3 and above',
                    value: '3',
                },
                {
                    title: '2 and above',
                    value: '2',
                },
                {
                    title: '1 and above',
                    value: '1',
                },
                // {
                //   title: '4.5',
                // },
            ]
        },
        certifications: {
            title: 'CERTIFICATIONS',
            data: [
                {
                    title:'ISO90001',
                    image:require('../assets/img/factory/iso.png')
                },
                {
                    title:'GMP',
                    image:require('../assets/img/factory/gmcom.png')
                }
            ]
        },
        industry: {
            title: 'INDUSTRIES',
            data: [
                {
                    id:'1',
                    title:'Pharmaceuticals',
                    child: ['Injections','Syrups','Tablets','Dry Syrups']
                },
                {
                    id:'2',
                    title: 'FMCG'
                },
                {
                    id:'3',
                    title: 'Printing & Packaging'
                },
                {
                    id:'4',
                    title: 'Apparels'
                },
                {
                    id: '5',
                    title: 'Consumer Durables'
                },
                {
                    id: '6',
                    title: 'Furniture',
                    child: ['Sofa sets','Beds','Almirahs','Chairs','Tables']
                },
                {
                    id: '7',
                    title: 'Electronics'
                },
                {
                    id:'8',
                    title: 'Vehicles & Accessories'
                },
                {
                    id: '9',
                    title: 'Footware',
                    child: ['Kids','Men','Women']
                },
                {
                    id:'10',
                    title: 'Sports and Toys'
                }
            ]
        },
        location: {
            title:'LOCATION HUB',
            data: []
        }
    },
};
