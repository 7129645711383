/**
 * Created by charnjeetelectrovese@gmail.com on 5/30/2020.
 */
import React, { Component } from 'react';
import styles from "./Style.module.css";
import {ButtonBase} from "@material-ui/core";

class TotalSidebar extends Component {
    constructor(props) {
        super(props);

    }

    _renderPrices() {
        const { passengers,currency } = this.props;
        if (passengers.length > 0) {
            return passengers.map((val) => {
                return (
                    <div className={styles.cartFlex}>
                        <div className={styles.text}>
                            {val.title}
                        </div>
                        <div>
                            {currency} {val.price} X {val.number}
                        </div>
                    </div>
                );
            });
        } return null;
    }
    _renderTotal() {
        let total = 0;
        const { passengers } = this.props;
        passengers.forEach((val) => {
           total += val.total;
        });
        return total + 60;
    }
    render() {
        const { handlePaymentClick, currency } = this.props;
        return (
            <div className={styles.boxContainer}>
                <h3>Subtotal</h3>
                {this._renderPrices()}
                <div className={styles.cartFlex}>
                    <div className={styles.text}>
                        Tax
                    </div>
                    <div>
                        {currency} 60
                    </div>
                </div>
                <hr/>
                <div className={styles.cartFlex}>
                    <div className={styles.text}>
                        Total
                    </div>
                    <div>
                        {currency} {this._renderTotal()}
                    </div>
                </div>

                <div>
                    <ButtonBase
                        onClick={() => { handlePaymentClick && handlePaymentClick() }}
                        type={'submit'}
                        className={styles.proceed}>
                        Proceed To Pay
                    </ButtonBase>
                </div>

                <div className={styles.taxText}>
                    *Taxes Extra
                </div>
            </div>
        );
    }

}

export default TotalSidebar;
