import React from 'react';
import styles from './Queries.module.css'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme,withStyles, withTheme } from '@material-ui/core/styles';
import {ButtonBase} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import MyReviews from '../../views/MyReviews/MyReviews.view';
import MediaQuery from 'react-responsive';
import All from "./components/All/All.view";
import PriceCard from './components/PriceCard/PriceCard.view'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            // aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

class Bookings extends React.Component{
    constructor(props){
        super(props);
        this.state={
            value: 0
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    _upperHeading(){
        return(
            <div>
                <div className={styles.headingFlex}>
                    <h2 className={styles.quotations}>My Queries</h2>
                    <div>
                        <input type="text" className={styles.inputSearch} placeholder={"Search"} />
                    </div>
                </div>
            </div>
        )
    }

        _renderQueries(){
        return (
            [1,2,3].map(val => <All/>)
        )
    }

    render(){
        const {classes,theme} = this.props
        return(
            <div className={'Queries'}>
                <div className={styles.upperHeading}>
                    <div className={'container'} style={{display:'flex',justifyContent:'space-around'}}>
                        <span className={styles.quotations}>2 out of 5 Quotations Received</span>
                        <span className={styles.stop}>Do not stop!Be a premium member of Efactory India and get more and more quotations</span>
                        <ButtonBase className={styles.looking}>
                            Go Premium
                        </ButtonBase>
                    </div>
                </div>
                <div className={'container'}>
                    {this._upperHeading()}
                <AppBar position="static" className={styles.backgroundAppBar}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                            // centered
                        // aria-label="full width tabs example"
                    >
                        <Tab label="ALL" {...a11yProps(0)} />
                        <Tab label="PENDING QUERIES" {...a11yProps(1)} />
                        <Tab label="APPROVAL AWAITED" {...a11yProps(2)} />
                        <Tab label="AWARDED" {...a11yProps(2)} />
                        <Tab label="CLOSED" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <TabPanel value={this.state.value} index={0} dir={theme.direction}>
                        {this._renderQueries()}
                        <PriceCard/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1} dir={theme.direction}>

                    </TabPanel>
                    <TabPanel value={this.state.value} index={2} dir={theme.direction}>

                    </TabPanel>
                </SwipeableViews>
            </div>
            </div>
        )
    }
}

export default withTheme(Bookings);

