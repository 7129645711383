import React, {Component} from 'react'
import styles from './Addition.module.css';
import {actionCartUpdate} from "../../actions/Cart.action";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ButtonBase from '@material-ui/core/ButtonBase';
import {Add, Remove} from '@material-ui/icons'

class Addition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seats: 0,
            additon: false,
            subtraction: false
        }
        this._handleAddition = this._handleAddition.bind(this);
        this._handleSubtraction = this._handleSubtraction.bind(this);
    }

    _handleAddition() {
        const {data, max, totalPassengers} = this.props;
        console.log('_handleAddtion', max, totalPassengers);
        if (totalPassengers + 1 <= this.props.max) {
            this.setState({
                additon: true,
                subtraction: false
            })
            this.props.handleChange(data.type, data.number + 1);
        }
    }


    _handleSubtraction() {
        const {data} = this.props;
        if (data.number - 1 >= this.props.min) {
            this.setState({
                subtraction: true,
                additon: false
            });
            this.props.handleChange(data.type, data.number - 1);
        }
    }

    render() {
        const {data} = this.props;
        return (
            <div>
                <div className={styles.outerFlex}>
                    <ButtonBase onClick={this._handleSubtraction}
                                style={this.state.subtraction ? {color: '#55B953'} : null}>
                        <div className={styles.buttons}>
                            <Remove className={styles.counter}/>
                        </div>
                    </ButtonBase>

                    <div className={styles.members}>
                        {data.number}
                    </div>

                    <ButtonBase onClick={this._handleAddition} style={this.state.additon ? {color: '#55B953'} : null}>
                        <div className={styles.buttons}>
                            <Add className={styles.counter}/>
                        </div>
                    </ButtonBase>
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
    return {};
}


export default connect(mapStateToProps, mapDispatchToProps)(Addition);
