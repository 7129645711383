/**
 * Created by charnjeetelectrovese@gmail.com on 7/9/2019.
 */
import React, {Component} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


const CustomRouter = (props) => {
    const isDesktop = useMediaQuery({minDeviceWidth: 1024});
    const isMobile = useMediaQuery({maxDeviceWidth: 768});

    const {component: Component, ...rest} = props;
    console.log(props)
    return (<Route
            {...rest}
            render={(childProps) => {
                if (props.is_authenticated || !props.is_protected) {
                    if (props.desktopComponent) {
                        if (isDesktop) {
                            const {desktopComponent: DesktopComponent} = props;
                            return (
                                <DesktopComponent {...rest} {...childProps} board_type={props.board_type}
                                                  category={props.category}
                                                  isDesktop={isDesktop} isMobile={isMobile}/>
                            );
                        }
                        return (
                            <Component {...rest} {...childProps} board_type={props.board_type} category={props.category}
                                       isDesktop={isDesktop} isMobile={isMobile}/>
                        );
                    }
                    return (
                        <Component
                            {...rest}
                            {...childProps}
                            board_type={props.board_type}
                            category={props.category}
                            isDesktop={isDesktop}
                            isMobile={isMobile}/>
                    );
                } else {
                    return (<Redirect to={{
                        pathname : '/login',
                        state : { from: childProps.location}
                    }}/>);
                }
            }
            }
        />
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        category: state.common.category,
        board_type: state.common.board_type,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRouter);
