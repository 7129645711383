import React, {Component} from 'react';
import styles from '../Home.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';

class ManufacturersO extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icon: false
        };

    }

    render() {
        return (
            <div>
                {/*<div className={styles.outsource}>Outsource manufacturing with ease and trust</div>*/}
                <div className={styles.buildFlex} style={{marginTop: '10px'}}>
                    <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                    <div className={styles.nego}>Cater to serious customers with complete integrated order management</div>
                </div>
                <div className={styles.buildFlex}>
                    <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                    <div className={styles.nego}>Earn better profits with no middle men. </div>
                </div>
                <div className={styles.buildFlex}>
                    <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                    <div className={styles.nego}>Get access to complete network of other manufacturers, service providers & industry experts</div>
                </div>
            </div>

        )
    }
}

export default ManufacturersO