/**
 * Created by charnjeetelectrovese@gmail.com on 4/27/2020.
 */
import moment from 'moment';
import Constants from '../config/constants';
import axios from "axios";

class DateUtils {
    changeTimezoneFromUtc = function(date, timeZone =  Constants.TIME_ZONE, format = Constants.DEFAULT_TIME_FORMAT) {
        const temp = new Date(date);
        const newDate = new Date(temp.getTime() + (3600000 * timeZone));
        if (format) {
            let tempMoment = moment(newDate);
            tempMoment.utcOffset(0);
            return tempMoment.format(format)
        } return newDate;
    };
    changeTimeStamp = function(date, format = Constants.DEFAULT_TIME_FORMAT) {
        return moment(new Date(date)).format(format);
    }

    formattedTimeZone = function(date, time, tz) {
        let timeZone = tz;
        let sign = '+';
        if (timeZone < 0) {
            sign = '-';
            timeZone = timeZone * -1;
        }
        const totalMinutes = timeZone * 60;
        let hours = Math.floor(totalMinutes/60) + '';
        let minutes = totalMinutes%60 + '';

        if (hours.length < 2) {
            hours = '0'+hours;
        }

        if (minutes.length < 2) {
            minutes = '0'+minutes;
        }

        return `${date}T${time}:00${sign}${hours}${minutes}`;
    }

    getLocalTimeZone() {
        const tempDate = new Date();
        return -(tempDate.getTimezoneOffset()/60);
    }
}

export default new DateUtils();
