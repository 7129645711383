import React from 'react';
import styles from './Booking.module.css'
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme,withStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MyBooking from "../../views/MyBooking/MyBooking.view";
import Profile from '../../views/Profile/Profile.view';
import ProfileMobile from '../../views/Profile/mobile/ProfileMobile.view'
import MyReviews from '../../views/MyReviews/MyReviews.view';
import MediaQuery from 'react-responsive';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            // aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className={'container'}>
                    <div className={styles.innerContainer}>
                    {children}
                    </div>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
}));

class Bookings extends React.Component{
    constructor(props){
        super(props);
        this.state={
        value: 1
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render(){
        const {classes,theme} = this.props
        return(
            <div>
                    <div style={{marginTop:'70px'}}></div>
                <hr/>
                <AppBar position="static" className={styles.backgroundColor}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        // aria-label="full width tabs example"
                    >
                        <Tab label="My Reviews" {...a11yProps(0)} />
                        <Tab label="My Bookings" {...a11yProps(1)} />
                        <Tab label="My Profile" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <TabPanel value={this.state.value} index={0} dir={theme.direction}>
                        <MyReviews/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1} dir={theme.direction}>
                       <MyBooking/>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2} dir={theme.direction}>
                        <MediaQuery minDeviceWidth={768}>
                               <Profile/>
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={768}>
                            <ProfileMobile/>
                        </MediaQuery>
                    </TabPanel>
                </SwipeableViews>
            </div>
        )
    }
}

export default withTheme(Bookings);

