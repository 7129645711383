import React, {Component} from 'react'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {Star} from '@material-ui/icons'
import styles from '../Rider.module.css'

class Card extends Component {
    constructor(props){
        super(props);

        this.state={

        }

    }

    _renderETA () {
        if (this.props.board_type == 'board_now') {
            return (
                <div>
                    <span className={styles.description}> ETA </span>
                    <div>
                        10 min
                    </div>
                </div>
            )
        } return null;
    }
    render(){
        return(
            <div>
                <div style={{margin:'10px'}} >
                    <Paper elevation={2} onClick={this.props.onClick}>
                        <div className={styles.container}>
                            <div className={styles.carCard}>
                                <div>
                                    <img src={require('../../../../assets/img/Oval@3x.png')} className={styles.carIcon}/>
                                </div>
                                <div className={styles.carName}>
                                    Polo
                                    <div className={styles.icons}>
                                        <Star fontSize ={'small'} className={styles.fontSizeSmall}/>
                                        <span className={styles.rating}>4.6</span>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.languageContainer}>
                                <div>
                                    <div>
                                        Steve Rogers
                                    </div>
                                    <div className={styles.languageContainer}>
                                        <img src={require('../../../../assets/img/speak.png')} className={styles.languageIcon}/>
                                        <div className={styles.language}>Eng/Spa</div>
                                    </div>
                                </div>
                                <div>
                                    <img src={require('../../../../assets/img/ic_driver_main@3x.png')} className={styles.carIcon}/>
                                </div>
                            </div>
                        </div>
                        <hr style={{opacity:'0.2'}}/>
                        <div className={styles.cost}>
                            <div>
                                <span className={styles.description}> Per km </span>
                                <div>
                                    6/km
                                </div>
                            </div>
                            <div>
                                <span className={styles.description}>Cost</span>
                                <div>
                                    55
                                </div>
                            </div>
                            {this._renderETA()}
                        </div>
                    </Paper>
                </div>
            </div>
        )
    }
}

Card.defaultProps = {
    board_type: 'board_now',
}

export default Card
