import React, {Component} from 'react';
import styles from './RecentReview.module.css';
import Rating from '@material-ui/lab/Rating';

class RecentReview extends Component{
    render(){
        return(
            <div className={styles.cardContainer}>
                <div className={styles.reviewsFlex}>
                    <div className={styles.recentHeading}>
                        Recent Reviews
                    </div>
                    <div className={styles.viewLink}>
                        View all
                    </div>
                </div>
                <div className={styles.innerFlex}>
                    <div>
                        <div className={styles.name}>
                            Jonathan Walker
                        </div>
                            <div className={styles.daysAgo}>
                                2 days ago
                            </div>
                        </div>
                    <div className={styles.rating}>
                        <Rating value={5} readOnly className={styles.starRating}/>
                    </div>
                </div>

                <div className={styles.textContainer}>
                    At vero eos et accusamus et iusto odio dignissimos ducimus qui
                    blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                </div>
            </div>
        )
    }
}

export default RecentReview