import React, {Component} from 'react';
import styles from './Home.module.css';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {Animated} from "react-animated-css";
import CountUp from 'react-countup';
import {ButtonBase,MenuItem} from '@material-ui/core';
import {ArrowRightAlt} from '@material-ui/icons'
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {FilterView, Card, Coupons, TourMap} from '../../components/index.component';
import staticData from '../../data';
import {actionUpdateFilter, actionCleanFilter} from '../../actions/Filter.action';
import Chips from './Chips/Chips.component'
import {actionFetchTours} from "../../actions/Tours.action";
import { actionHydrateFilters } from "../../actions/Filter.action";
import classNames from 'classnames';
import data from '../../data'
import ProductCard from "../Products/components/ProductCard.view";
import ManufactureCard from "../Manufacture/components/ManufactureCard.view";
import DiscoverForm from "../Discover/DiscoverForm.view";
import ReadsCard from "../Reads/components/ReadsCard.view";
import SearchCard from "../Searches/component/SearchCard.component";
import AchivementCard from "../Achivements/AchivementCard.view";
import Banner from "../Banner/Banner.view";
import Portfolio from "../Portfolio/Portfolio.view";
import ProdSlider from "../Products/components/ProdSlider/ProdSlider.component";
import ManufactureSlider from "../Manufacture/components/ManufactureSlider/ManufactureSlider.component";
import AchivementSlider from "../Achivements/AchivementSlider/AchivementSlider.component";
import OutSource from "./OutSource/OutSource.view";
import history from "../../libs/history.util";
import RouteNames from "../../routes/Route.name";
import Rfq from "../RFQ/Rfq.view";
import NavScroll from "../NavScroll/NavScroll.view";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total: [],
            open: false
        };
        this._handleIndustries = this._handleIndustries.bind(this);
        this._handleSearchClick = this._handleSearchClick.bind(this);
    }

    _handleIndustries(data){
        const route = data;
        history.push('/'+route);
    }

    _handleSearchClick() {
        this.props.history.push(RouteNames.product);
    }

    _renderIndustries(){
        return(
            data.industries.map((val)=>{
                return (
                    <div className={styles.industryContainer}  onClick={this._handleIndustries.bind(this,val.text)}>
                        <div className={styles.indusCont}><img src={val.image} className={styles.industryIcon}/></div>
                        <div className={styles.title}>{val.text}</div>
                        <div className={styles.vendor}>{val.vendors}</div>
                    </div>
                )
            })
        )
    }

    _renderProducts(){
        return(
            <div>
                <Animated animationIn="slideInLeft" animationOut="slideOutRight" animationInDuration={3000} animationOutDuration={3000} isVisible={true}>
                <div className={styles.iconFlex}>
                    <img src={require('../../assets/img/factory/products/svg/touch_icon.svg')} height={35}/>
                    <div className={styles.easy}>
                        Easy Click & Order hassle free
                    </div>
                </div>
                <div className={styles.iconFlex}>
                    <img src={require('../../assets/img/factory/products/svg/money_icon.svg')} height={35}/>
                    <div className={styles.easy}>
                        Easy Finance/EMI option for making payments
                    </div>
                </div>
                <div className={styles.iconFlex}>
                    <img src={require('../../assets/img/factory/products/svg/discount_icon.svg')} height={35}/>
                    <div className={styles.easy}>
                        Assured competitive and transparent prices
                    </div>
                </div>
                <div className={styles.iconFlex}>
                    <img src={require('../../assets/img/factory/products/svg/pay_icon.svg')} height={35}/>
                    <div className={styles.easy}>
                        Order Protection and Payment Security
                    </div>
                </div>
                <div className={styles.iconFlex}>
                    <img src={require('../../assets/img/factory/products/svg/home-delivery_icon.svg')} height={35}/>
                    <div className={styles.easy}>
                        Quality delivery with real time monitoring
                    </div>
                </div>
                </Animated>
            </div>
        )
    }

    _renderTopProducts(){
        return(
            <div>
                <ProdSlider/>
            </div>
        )
    }
    _renderManufactures(){
        return(
            <div>
                <ManufactureSlider/>
            </div>
        )
    }

    _renderReads(){
        return(
            [1,2,3,4,5].map(val => <ReadsCard/>)
        )
    }

    _renderTopSearches(){
        return ['Paracetamol','Face Mask'].map((val)=> {
            return <SearchCard data={val}/>
        })
    }

    _renderSearch(){
        return ['Paracetamol Liquid','Paracetamol','Face Mask'].map((val)=> {
            return <SearchCard data={val}/>
        })
    }

    render() {
        return (
            <div>

                <Banner
                    handleClick={this._handleSearchClick}/>
                <div className={styles.margin}></div>
                <div className={'ncontainer'}>
                    <div className={'viewFlex'}>
                    <h2>Top Industries</h2>
                        <Link className={'offerViewBtn'} to="/industries">view all</Link>
                    </div>
                    <div className={styles.industyFlex}>
                    {this._renderIndustries()}
                    </div>
                </div>

                <Portfolio/>

                <div className={'ncontainer'}>
                    <div className={styles.manufacturedFlex}>
                        <div className={styles.desc}>
                            <div className={styles.neverText}>IT WAS NEVER EASY TO GET YOUR <br/> PRODUCTS MANUFACTURED UNTIL NOW!</div>
                            <div className={styles.breakthroughText}>
                                FactoryGrid is a breakthrough in connecting you with verified and trusted manufacturers
                                and making your manufacturing orders hassle free
                            </div>
                            <div >
                                {this._renderProducts()}

                            </div>
                        </div>
                        <div className={styles.desc} style={{textAlign:'center'}}>
                            <img src={require('../../assets/img/factory/products/video_part.png')} className={styles.vid}/>
                            <div>
                                <ButtonBase className={styles.looking}>
                                    How It Works
                                </ButtonBase>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div>
                        <div className={'viewFlex'}>
                        <h2>Top Seller Products</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderTopProducts()}
                    </div>
                    <br/>
                    <div>
                        <div className={'viewFlex'}>
                        <h2>Top Manufacturers</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderManufactures()}
                    </div>
                    <br/>

                    <div className={styles.marketplaceFlex}>
                        <div className={styles.desc} style={{marginRight:'25px'}}>
                            <DiscoverForm/>
                        </div>
                        <div className={styles.desc}>
                            <Rfq/>
                        </div>
                    </div>

                    {/*<div className={styles.premiumFlex}>*/}
                    {/*    <div className={styles.desc}>*/}
                    {/*        <div className={styles.negotiate}>*/}
                    {/*            <h2>We Negotiate for you</h2>*/}
                    {/*            <div className={styles.negoText}>*/}
                    {/*                Moppers offering cleaning services in 1950 as a small window*/}
                    {/*                cleaner, located near Le Pigalle in Paris, France.*/}
                    {/*            </div>*/}
                    {/*            <div className={styles.negoText}>*/}
                    {/*                Shortly after opening, founder Mitchel Sweedon offered something*/}
                    {/*                special cleaning backed by a 100% best carpet company.*/}
                    {/*            </div>*/}

                    {/*            <div className={styles.buildFlex}>*/}
                    {/*                <img src={require('../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
                    {/*                <div className={styles.nego}>Best deal Assurance with best price, quality and time</div>*/}
                    {/*            </div>*/}
                    {/*            <div className={styles.buildFlex}>*/}
                    {/*                <img src={require('../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
                    {/*                <div className={styles.nego}>Coordination for complete project until delivery.</div>*/}
                    {/*            </div>*/}
                    {/*            <div className={styles.buildFlex}>*/}
                    {/*                <img src={require('../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
                    {/*                <div className={styles.nego}>Recurring requests management</div>*/}
                    {/*            </div>*/}

                    {/*            <div>Manage production management outsource for</div>*/}
                    {/*            <div><b>Just Rs 5999/-</b></div>*/}

                    {/*            <ButtonBase className={styles.looking}>*/}
                    {/*                what are you looking for?*/}
                    {/*            </ButtonBase>*/}

                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={styles.desc} style={{marginLeft:'20px'}}>*/}
                    {/*        <div className={styles.premium}>*/}
                    {/*            <div className={styles.plans}>*/}
                    {/*                <h1 className={styles.textpremium}>Premium Plans</h1>*/}
                    {/*                <div style={{fontSize:'1.4rem'}}>*/}
                    {/*                    Unlock premium services and<br/>*/}
                    {/*                    explore higher opportunities for*/}
                    {/*                </div>*/}
                    {/*                <p>*/}
                    {/*                    <b> Just Rs 999/- per month</b>*/}
                    {/*                </p>*/}

                    {/*                <ButtonBase className={styles.looking}>*/}
                    {/*                    Go Premium*/}
                    {/*                </ButtonBase>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div>
                        <h2>Our Achievements</h2>
                        <div className={styles.madeFlex}>
                            <div className={styles.desc} style={{marginRight:'20px',overflow:'hidden',paddingBottom:'20px'}}>
                                <AchivementSlider/>
                            </div>
                            <div className={styles.desc} style={{marginLeft:'15px'}}>
                                <img src={require('../../assets/img/factory/madeinindia.png')} className={styles.madeIn} height={280}/>
                                <br/>
                                <br/>
                                <div className={styles.greyBack}>
                                    <div className={styles.numberFlex}>
                                        <div className={styles.ind}>
                                            <div className={styles.total}>
                                                {/*1154+*/}
                                                <CountUp delay={3} end={1154} suffix={'+'}/>
                                            </div>
                                            <div className={styles.happy}>Happy Clients</div>
                                        </div>
                                        <div className={styles.ind}>
                                            <div className={styles.total}>
                                                {/*800+*/}
                                                <CountUp delay={3} end={800} suffix={'+'}/>
                                            </div>
                                            <div className={styles.happy}>Deliveries</div>
                                        </div>
                                        <div className={styles.ind}>
                                            <div className={styles.total}>
                                                {/*500+*/}
                                                <CountUp delay={3} end={500} suffix={'+'}/>
                                            </div>
                                            <div className={styles.happy}>Manufacturer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={'viewFlex'}>
                            <h2>Interesting Reads</h2>
                            <Link className={'offerViewBtn'} to="/blogs">view all</Link>
                        </div>
                        <div style={{display:'flex'}}>
                            {this._renderReads()}
                        </div>
                        <br/>
                        <br/>
                    </div>
                    <OutSource/>
                </div>

                <div>
                    <div className={classNames(styles.bgSearch)}>
                        <div className={'ncontainer'}>
                            <div className={styles.topSearchFlex}>
                            <div className={styles.top}>Top Searches</div>
                            <div className={styles.searchFlex}>
                                {this._renderTopSearches()}
                            </div>
                            <div className={styles.searchFlex}>
                                {this._renderSearch()}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionUpdateFilter: actionUpdateFilter,
        actionClearFilter: actionCleanFilter,
        actionFetchTours: actionFetchTours,
        actionHydrateFilters: actionHydrateFilters,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        board_type: state.common.board_type,
        date: state.common.date,
        time: state.common.time,
        filter: state.filter,
        filterList: state.common.filter_list,
        tours: state.tours.tours,
        location_data: state.common.location,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);
