import React,{Component} from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Language.module.css'

class Language extends Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'English',
            currency:'US$'
        }
        this._handleChange = this._handleChange.bind(this);
        this._handleCurrency = this._handleCurrency.bind(this)
    }

    _handleChange(e) {
        this.setState({
            language: e.target.value
        })
    }

    _handleCurrency(e) {
        this.setState({
            currency: e.target.value
        })
    }

    render() {
        return (
            <div>
                <div className={styles.formContainer}>
                    <div>
                        <div className={styles.label}>Default Language</div>
                        <FormControl className={styles.formWidth}>
                            {/*<InputLabel id={"label"}>US</InputLabel>*/}
                            <Select
                                disableUnderline
                                id={"label"}
                                value={this.state.language}
                                onChange={this._handleChange}
                            >
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Spanish"}>Spanish</MenuItem>
                                <MenuItem value={"German"}>German</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <div className={styles.label}>Default Currency</div>
                        <FormControl className={styles.formWidth}>
                            <Select
                                disableUnderline
                                value={this.state.currency}
                                onChange={this._handleCurrency}
                            >
                                <MenuItem value={"US$"}>US$</MenuItem>
                                <MenuItem value={"UK$"}>UK$</MenuItem>
                                <MenuItem value={"INR"}>INR</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                </div>
            </div>
        )
    }
}

export default Language