import React, {Component} from 'react';
import styles from './Blogs.module.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import BlogCard from '../../components/Paper/BlogCard/BlogCard.component'
import {serviceGetTourDetail} from "../../services/Tours.service";
import MediaQuery from 'react-responsive';
import MobBlogCard from '../../components/Paper/BlogCard/Mobile/MobBlogCard.component'

class Blogs extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _renderBlogs(){
        return(
            [1,2,3,4,5,6,7,8,9,10,11,12].map(val => <BlogCard/>)
        )
    }

    _renderMobileBlogs() {
        return(
            [1,2,3,4,5,6].map(val => <MobBlogCard/>)
        )
    }

    render() {
        return (
            <div>
                <div className={styles.upperHeading}>
                    <div style={{height:'70px'}}></div>
                    <h2 className={styles.mainHeading}>Travel Diaries </h2>
                    <div className={styles.subHeading}> Discover all blogs around you & get knowledge of experiences</div>
                </div>

                <MediaQuery minDeviceWidth={768}>
                <div className={'container'}>
                    <div className={styles.blogFlex}>
                        {this._renderBlogs()}
                    </div>
                </div>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={768}>
                        {this._renderMobileBlogs()}
                </MediaQuery>
            </div>
        )
    }
}

export default Blogs