/**
 * Created by charnjeetelectrovese@gmail.com on 3/4/2020.
 */
import socketIOClient from "socket.io-client";
import Constants from "../config/constants";
import EventEmitter from "./Events.utils";
import history from './history.util';
import RouteNames from "../routes/Route.name";


var socket = null;

export const connectToSocket = (token = null) => {
    disconnectSocket();
    console.log('connecting to socket @connectToSocket');
    socket = socketIOClient(Constants.SOCKET_URL, { query: `token=${token ? token : ''}` });
    if (socket) {
        socket.on('connect_timeout', (data) => {
            console.log('connection timeout');
        })
        socket.on('reconnect_attempt', (data) => {
            console.log('reconnect_attempt');
        })
        socket.on('connect_error', (data) => {
            console.log('connect_error');
        });

        socket.on('reconnect_error', (data) => {
            console.log('reconnect_error');
        })
        socket.on('reconnect_failed', (data) => {
            console.log('reconnect_failed');
        });


        socket.on(Constants.SOCKET_EVENTS.TOUR_ACCEPTED, (data) =>  {
            // console.log(Constants.SOCKET_EVENTS.TOUR_ACCEPTED, data);
            history.push(RouteNames.ticket+'/'+data.message.unique_code);
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'info'
            });
        });
        socket.on(Constants.SOCKET_EVENTS.TOUR_REJECTED, (data) =>  {
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'error'
            });
        });
        socket.on(Constants.SOCKET_EVENTS.TOUR_STARTED, (data) =>  {
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'info'
            });
        });
        socket.on(Constants.SOCKET_EVENTS.DRIVER_ARRIVED, (data) =>  {
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'info'
            });
        });
        socket.on(Constants.SOCKET_EVENTS.ON_BOARDED, (data) =>  {
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'info'
            });
        });

        socket.on(Constants.SOCKET_EVENTS.TOUR_COMPLETED, (data) =>  {
            // cb(null, JSON.parse(data))
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: data.message.title,
                type: 'info'
            });
        });

        socket.on(Constants.SOCKET_EVENTS.USER_MESSAGE_RECEIVED, (data) =>  {
            // cb(null, JSON.parse(data))
            if (window.location.href.indexOf('conversations') < 0 ) {
                EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                    error: data.message.body,
                    type: 'info'
                });
            }
        });

    }
    return socket;
}

export const getSocketInstance = () => {
    if (socket) {
        return socket;
    };
    return connectToSocket();
};


export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
    }
}
