import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {MenuItem,ButtonBase} from '@material-ui/core'
// import PageBox from '../../../../components/PageBox/PageBox.component';
import styles from './Business.module.css';
import {
    renderTextField,
    renderSelectField,
    renderOutlinedTextField,
    renderOutlinedSelectField,
    renderOutlinedMultipleSelectField, renderFileField, renderCountryContact, renderCheckbox
} from '../../../../libs/redux-material.utils';

let requiredFields = ['name','company_logo','business_type','industry','web_link','gst_number','cnn_number','pan_number'];

const validate = (values) => {
    const errors = {};
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
        if (values.company_contact && values.company_contact.length < 10) {
            errors.company_contact = 'Minimum 10 digits'
        }

        if (values.personal_contact && values.personal_contact.length < 10) {
            errors.personal_contact = 'Minimum 10 digits'
        }
    });
    return errors
};


class ContactView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._submitBtn = React.createRef();
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleFileChange = this._handleFileChange.bind(this);
        this.childSubmit = this.childSubmit.bind(this);
        this._handleChange = this._handleChange.bind(this)
    }

    componentDidMount() {
        // const {data, is_edit} = this.props;
        // if (is_edit) {
        //     requiredFields = ['company_contact', 'registration_no', 'company_address', 'personal_contact'];
        //     if (!data.company_logo) {
        //         // requiredFields.push('company_logo');
        //     }
        //     if (!data.company_proof) {
        //         requiredFields.push('company_proof');
        //     }
        //     Object.keys(data).forEach((key) => {
        //         if (['company_logo', 'company_proof'].indexOf(key) == -1) {
        //             const val = data[key];
        //             this.props.change(key, val);
        //         }
        //     });
        // } else {
        //     requiredFields = ['company_contact', 'registration_no', 'company_address', 'personal_contact', 'company_proof']; //'company_logo'
        //     Object.keys(data).forEach((key) => {
        //         const val = data[key];
        //         this.props.change(key, val);
        //     });
        // }

    }


    _handleSubmit(data) {
        this.props.submitCallback({
            ...data
        });
    }

    _handleFileChange(file, name) {
        const tempFiles = this.state.files;
        tempFiles[name] = file;
        this.setState({
            files: tempFiles
        });
    }

    handleFileChange(e) {
        e.preventDefault();
        if (e.target.files[0]) {
            this.setState({
                file: e.target.files[0],
                file_title: 'File Selected',
                file_url: URL.createObjectURL(e.target.files[0])
            });
            e.target.value = null;
        }
    }

    childSubmit() {
        if (this._submitBtn) {
            // this.props.handleSubmit(this._handleSubmit);
            this._submitBtn.click();
            // this._submitBtn.submit();
            // this._submitBtn.dispatch(new Event('submit'));
        }
    }

    _handleChange(){
        this.setState({
            is_checked: !this.state.is_checked
        })
    }

    render() {
        const {handleSubmit, common, data} = this.props;
        return (
            <div>

                <form onSubmit={handleSubmit(this._handleSubmit)} className={'business'}>
                    {/*onSubmitFail={() => {*/}
                    {/*// alert('dasd')}*/}

                    <div className={styles.businessFlex}>
                    <div className={'formFlex'} style={{flex:'0.5',marginTop:'20px'}}>
                        <div>
                            <div className={styles.profileInfo}>
                                <Field
                                    max_size={1024 * 1024 * 5}
                                    type={['jpg', 'png', 'pdf']}
                                    fullWidth={true}
                                    name="company_logo"
                                    component={renderFileField}
                                    label="Company Logo"
                                    show_image
                                    // default_image={data.company_logo}
                                />
                            </div>
                        </div>
                    </div>


                     <div style={{flex:'2.5'}}>
                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <Field fullWidth={true} name="name" component={renderOutlinedTextField}
                                   margin={'dense'} type={'text'}
                                   label="Company Name"/>
                        </div>

                        <div className={'formGroup'}>
                            <Field fullWidth={true} name="business_type" component={renderOutlinedSelectField}
                                   margin={'dense'}
                                   label="Business Type">
                                <MenuItem value={'Driving Licence'}>Driving Licence</MenuItem>
                                <MenuItem value={'Passport'}>Passport</MenuItem>
                                <MenuItem value={'Govt ID'}>Govt. ID</MenuItem>
                            </Field>
                        </div>
                    </div>

                        <div className={'formFlex'}>
                            <div className={'formGroup'}>
                                <Field fullWidth={true} name="industry" component={renderOutlinedSelectField}
                                       margin={'dense'}
                                       label="Industry Segment">
                                    <MenuItem value={'Driving Licence'}>Driving Licence</MenuItem>
                                    <MenuItem value={'Passport'}>Passport</MenuItem>
                                    <MenuItem value={'Govt ID'}>Govt. ID</MenuItem>
                                </Field>
                            </div>

                            <div className={'formGroup'}>
                                <Field fullWidth={true} name="web_link" component={renderOutlinedTextField}
                                       margin={'dense'} type={'text'}
                                       label="Website Link"/>
                            </div>
                        </div>

                    <div className={'formFlex'} style={{alignItems: 'center'}}>
                        <div className={'formGroup'}>
                            <Field
                                max_size={1024 * 1024 * 5}
                                type={['jpg', 'png', 'pdf', 'jpeg']}
                                fullWidth={true}
                                name="gst_number"
                                component={renderFileField}
                                accept={'application/pdf, image/*'}
                                label="Gst No."
                                // link={data ? data.company_proof : null}
                            />
                        </div>

                        <div className={'formGroup'}>
                            <Field
                                max_size={1024 * 1024 * 5}
                                type={['jpg', 'png', 'pdf', 'jpeg']}
                                fullWidth={true}
                                name="cnn_number"
                                component={renderFileField}
                                accept={'application/pdf, image/*'}
                                label="CNN No/Business Aadhaar No"
                                // link={data ? data.company_proof : null}
                            />
                        </div>
                    </div>

                    <div className={'formFlex'}>
                        <div className={'formGroup'}>
                            <Field
                                max_size={1024 * 1024 * 5}
                                type={['jpg', 'png', 'pdf', 'jpeg']}
                                fullWidth={true}
                                name="pan_number"
                                component={renderFileField}
                                accept={'application/pdf, image/*'}
                                label="PAN No."
                                // link={data ? data.company_proof : null}
                            />
                        </div>
                        <div className={'formGroup'}>
                        </div>
                    </div>
                    </div>
                    </div>

                    <br/>

                    <div className={'formFlex'} style={{alignItems: 'center'}}>
                        <div className={'formGroup'}>
                            <Field
                                name="order"
                                component={renderCheckbox}
                                label={"I am authorized representative member of my organization.All the information submitted is to the best of my knowledge"}
                                onChange={this._handleChange}
                            />
                        </div>
                        <div>
                            {/*<button ref={ref => {*/}
                            {/*this._submitBtn = ref;*/}
                            {/*}} type={'submit'}>Save</button>*/}
                            <ButtonBase className={styles.save} type={'submit'}>
                               Save
                            </ButtonBase>
                        </div>
                    </div>

                </form>

            </div>
        )
    }
}

const ReduxForm = reduxForm({
    form: 'contactinfo',  // a unique identifier for this form
    validate,
    enableReinitialize: true,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Required Parameters'});
    // }

})(ContactView);

export default connect(null, null, null, {forwardRef: true})(ReduxForm);
