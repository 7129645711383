import React, {Component} from 'react';
import {Star, FavoriteBorder as Favorite} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import TOC from '@material-ui/icons/Toc';
import Map from '@material-ui/icons/Map';
import ButtonBase from '@material-ui/core/ButtonBase';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Tabs from './Tabs/Tabs.component';
import {ReviewsCard, Places, Gallery} from '../../components/index.component';
import styles from './Description.module.css';
import RouteComponent from './Route/Route.component';
import RouteNames from '../../routes/Route.name';
import AboutDriver from './AboutDriver/AboutDriver.view';
import {serviceGetTourDetail} from "../../services/Tours.service";
import {actionSelectTour} from '../../actions/Tours.action';
import Slide from "@material-ui/core/Slide";
import RouteList from '../../components/RouteComponent/Routes.component';
// import MyReviews from './ReviewsP/ReviewsP.component'


const themestyles = theme => ({
    fontSizeSmall: {
        fontSize: '15px !important',
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Description extends Component {
    constructor(props) {
        super(props);
        // this.profileDialog = null;
        // this.materialDialog = null;
        this.state = {
            route: 'LIST',
            // show_profile_dialog: false,
        };
        this._handlePaymentClick = this._handlePaymentClick.bind(this);
        // this._handleProfile = this._handleProfile.bind(this);
        this._handleReviews = this._handleReviews.bind(this);
        // this._handleProfileClose = this._handleProfileClose.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {id} = this.props.match.params;
        const request = serviceGetTourDetail({id: id});
        request.then((data) => {
            if (!data.error) {
                this.props.actionSelectTour(data.data);
            }
        });
    }

    _handlePaymentClick() {
        this.props.history.push(RouteNames.payment);
    }

    // _handleProfile() {
    //
    //     // this.props.history.push(RouteNames.driver_profile);
    //     if (this.profileDialog == null) {
    //         this.materialDialog = require('@material-ui/core/Dialog').default;
    //         this.profileDialog = require('../../views/DriverProfile/Driver.component').default;
    //     }
    //     this.setState({
    //         show_profile_dialog: true
    //     })
    // }
    // _handleProfileClose() {
    //     this.setState({
    //         show_profile_dialog: false,
    //     });
    // }

    _handleReviews() {
        this.props.history.push(RouteNames.all_reviews);
    }

    // _renderDriverProfileDialog(){
    //     const { tour } = this.props;
    //     if(this.profileDialog && this.materialDialog){
    //         const DetailDialog = this.profileDialog;
    //         const MaterialDialog = this.materialDialog;
    //         return( <MaterialDialog
    //             fullScreen
    //             open={this.state.show_profile_dialog}
    //             onClose={this._handleProfileClose}
    //             TransitionComponent={Transition}
    //         >
    //             <DetailDialog data={tour.driver} handleClose={this._handleProfileClose}/>
    //         </MaterialDialog>)
    //     }
    // }

    _renderRouteComponent() {
        const {tour} = this.props;
        if (this.state.route == 'LIST') {
            return (
                <RouteComponent routes={tour.route_list}/>
            )
        } return (<RouteList data={{routes: tour.routes}}></RouteList>);
    }

    _handleMapSelection(type) {
        this.setState({
            route: type,
        });
    }

    render() {
        const {tour} = this.props;
        if (tour) {
            return (
                <div>
                    <div style={{height: '65px'}}/>
                    <div style={{marginLeft: '12px', marginRight: '12px'}}>
                        <div className={styles.innerFlex}>
                            <div className={styles.category}>{tour.category}</div>
                            <div className={styles.icon}>
                                <Star fontSize="small" className={styles.fontSizeSmall}/>
                                <span>4.6</span>
                            </div>
                        </div>
                        <div className={styles.display}>
                            <h3 className={styles.title}>
                                {tour.name}
                            </h3>
                        </div>
                        <small className={styles.desc}>
                            {tour.overview}
                            {/*{tour.overview.split('\n').map(i => {*/}
                            {/*    return <div>{i}</div>*/}
                            {/*})}*/}
                        </small>
                    </div>

                    <Gallery images={tour.images} user_images={[]} history={this.props.history}/>

                    {/* <Grid/> */}
                    <Tabs info={tour}/>


                    <div style={{display: 'flex', paddingLeft: '15px', alignItems: 'center'}}>
                        <div style={{flex: 1}}>
                            <h3 className={styles.heading}>Route</h3>
                        </div>

                        <div style={{paddingRight: '10px', display: 'flex', alignItems: 'center'}}>
                            <div onClick={this._handleMapSelection.bind(this, 'LIST')} ><TOC style={{color: (this.state.route == 'LIST' ? '#2196F3' : 'black')}}/></div>
                            <div  onClick={this._handleMapSelection.bind(this, 'MAP')} style={{marginLeft: '10px', color: (this.state.route == 'MAP' ? '#2196F3' : 'black')}}><Map/></div>
                        </div>
                    </div>
                    {this._renderRouteComponent()}


                    {/* <img src={require('../../assets/img/route.jpg')} style={{width:'100%'}}/> */}

                    <div className={styles.back}>
                        <h3 className={styles.heading} style={{paddingTop: '15px'}}>Tour Description</h3>
                        <p className={styles.description}>
                            {tour.description.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </p>
                        <hr/>

                        <h3 className={styles.heading} style={{paddingTop: '15px'}}>Important Instructions</h3>
                        <p className={styles.description}>
                            {tour.instructions.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </p>
                        <hr/>

                         <h3 className={styles.heading}>About the Driver</h3>
                        <AboutDriver
                            data={tour.driver}
                            vehicle={tour.vehicle}
                            tour={tour}
                            history={this.props.history}
                            // handleProfile={this._handleProfile}
                        />
                        <hr/>

                        <div className={styles.reviewsFlex}>
                            <h3 className={styles.heading}>Reviews</h3>
                            <div style={{fontSize: '14px', color: '#2196F3'}} onClick={this._handleReviews}>
                                See all
                            </div>
                        </div>
                        <ReviewsCard/>

                        <h3 className={styles.heading}>Similar Tours</h3>
                        <Places/>
                        <div style={{height: '65px'}}/>
                    </div>


                    <div className={styles.bottomButton}>
                        <div>
                            <ButtonBase classes={{root: styles.bookbtn}} onClick={this._handlePaymentClick}>
                            <span style={{
                                fontSize: '18px',
                                color: 'white',
                                fontWeight: '500'
                            }}>{this.props.board_type == 'board_now' ? 'Board Now' : 'Board For Later'}</span>
                            </ButtonBase>
                        </div>
                    </div>
                    {/*{this._renderDriverProfileDialog()}*/}
                </div>
            );
        }
        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionSelectTour: actionSelectTour
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        board_type: state.common.board_type,
        tour: state.tours.selected_tour,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(themestyles)(Description));
