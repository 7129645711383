import React,{Component} from 'react';
import styles from './Breakup.module.css';
import classNames from 'classnames';
import {ButtonBase,MenuItem} from '@material-ui/core';
import NegotiateDialog from '../NegotitateDialog/NegotiateDialog.view'

class Breakup extends Component{
    constructor(props){
        super(props);
        this.state={
            dialog_open: false,
        }
        this._handleClose = this._handleClose.bind(this);
    }

    _handleClose() {
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    render(){
        return(
            <div>
                <div className={styles.bgGrey}>
                        <div className={styles.breakContainer}>
                            <div className={styles.price}>Price Breakup</div>
                            <div className={styles.dis}>Price:100kg@ Rs 375.00/kg</div>
                        </div>

                    <div className={styles.cartFlex}>
                        <div>
                            Salt
                        </div>
                        <div className={styles.value}>
                            375000.00
                        </div>
                    </div>
                    <div className={styles.cartFlex}>
                        <div>
                            Cylinder Cost
                        </div>
                        <div className={styles.value}>
                            1500.00
                        </div>
                    </div>
                    <div className={styles.cartFlex}>
                        <div>
                            COA
                        </div>
                        <div className={styles.value}>
                            1500.00
                        </div>
                    </div>
                    <div className={styles.cartFlex}>
                        <div>
                            Delivery Charges
                        </div>
                        <div className={styles.value}>
                            2000.00
                        </div>
                    </div>
                    <hr className={styles.priceLine}/>
                    <div className={classNames(styles.cartFlex,styles.total)}>
                        <div>
                            Total
                        </div>
                        <div>
                            Rs 3,80,000.00
                        </div>
                    </div>
                    <hr className={styles.priceLine}/>
                    <div className={classNames(styles.cartFlex,styles.total)}>
                        <div>
                            Tax
                        </div>
                        <div>
                            Rs 68000
                        </div>
                    </div>
                    <hr className={styles.priceLine}/>
                    <div className={classNames(styles.cartFlex,styles.total)}>
                        <div>
                            Grand Total
                        </div>
                        <div>
                            Rs 4,48,000.00
                        </div>
                    </div>
                </div>

                <div className={styles.buttonFlex}>
                    <ButtonBase className={styles.looking} onClick={this._handleClose}>
                        Negotiate
                    </ButtonBase>

                    <ButtonBase className={styles.looking} style={{marginLeft:'20px'}}>
                        Accept
                    </ButtonBase>
                </div>
                <div className={styles.breakContainer}>
                    <b>We Negotiate</b>
                </div>
                <NegotiateDialog
                    open={this.state.dialog_open}
                    handleClose={this._handleClose}/>
            </div>
        )
    }
}

export default Breakup