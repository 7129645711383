/* eslint-disable react/jsx-filename-extension */
import React, {Component} from 'react';
import history from './libs/history.util';
import Constants from './config/constants';
import {Router} from 'react-router-dom';
// import {ThemeProvider, createMuiTheme} from '@material-ui/styles';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import RouterComponent from './routes/index.route';
import themes, {overrides} from './themes';
import './App.css';

const theme = createMuiTheme({...themes.default, ...overrides});

class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <RouterComponent/>
                </Router>
            </ThemeProvider>
        );
    }
}

export default App;
