import React, {Component} from 'react';
import styles from './PickupCard.module.css';

class PickupCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            selected: false
        }
        this._handleSelection = this._handleSelection.bind(this);
    }
    _handleSelection(){
        this.setState({
            selected: !this.state.selected
        })
    }
    render(){
        return(
            <div onClick={this._handleSelection} className={styles.container}>
                <div className={!this.state.selected ? styles.grey : styles.green}>
                    Lorem Ipsum
                </div>
            </div>
        )
    }
}

export default PickupCard