import React, {Component} from 'react'
import styles from './Cities.module.css'
import ButtonBase from '@material-ui/core/ButtonBase'
import Banner from "../Banner/Banner.view";
import MediaQuery from 'react-responsive';
import PortraitCard from "../../components/Paper/Portrait/PortraitCard.component";
import LandscapeCard from "../../components/Paper/LandScape/LandscapeCard.component";
import CitiesCard from "../../components/Paper/Cities/Cities.component";
import VerticalSlider from "./components/ImageSlider/VerticalSlider.view";
import CitiesChips from "./components/Chips/CitiesChips.component";
import PortraitSlider from "../../components/Paper/Portrait/PortraitSlider/PortraitSlider.component";
import ChipsSlider from "./components/ChipsSlider/ChipsSlider.view";

class Cities extends Component{
    constructor(props) {
        super(props);
        this.state = {

        }
        this._handleSearchClick = this._handleSearchClick.bind(this)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _renderCities(){
        return(
            [1,2,3,4,5,6,7,8,9,10,11,12].map(val => <CitiesCard/>)
        )
    }

    _renderChips(){
        return ['All','Paris','Berlin','Porto','Lisbon','Vienna','Vennice','Paris'].map(val => <CitiesChips data={val}/>)
    }

    _renderChipsAgain(){
        return ['Paris','Berlin','Porto','Lisbon','Vienna','Vennice','Paris','Berlin'].map(val => <CitiesChips data={val}/>)
    }

    _renderTopCities() {
        return(
            [1,2,3,4].map(val => <CitiesCard/>)
        )
    }

    _renderChipsSlider(){
        return(
            <ChipsSlider/>
        )
    }

    _handleSearchClick(){

    }
    render() {
        return (
            <div>
              <Banner
                  cities
                  handleClick={this._handleSearchClick}
              />

                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        <MediaQuery minDeviceWidth={768}>
                            <div className={styles.pickupFlex}>
                                {this._renderChips()}
                            </div>
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={768}>
                            {this._renderChipsSlider()}
                        </MediaQuery>


                        <div className={styles.upperHeading}>
                            <h2 className={styles.mainHeading}>Popular Cities </h2>
                            <div className={styles.subHeading}> Discover top cities around you & roam with fun</div>
                        </div>
                        <MediaQuery minDeviceWidth={768}>
                            <VerticalSlider/>
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={768}>
                            <LandscapeCard/>
                            <PortraitSlider/>
                            <div className={styles.listButton}>
                                <ButtonBase
                                    className={styles.explore}>
                                    Explore More
                                </ButtonBase>
                            </div>
                        </MediaQuery>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.upperHeading}>
                            <h2 className={styles.mainHeading}>All Cities </h2>
                            <div className={styles.subHeading}> Discover all cities around you & experience with fun</div>
                        </div>
                        <div className={styles.citiesFlex}>
                            <MediaQuery minDeviceWidth={768}>
                                {this._renderCities()}
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={768}>
                                {this._renderTopCities()}
                            </MediaQuery>
                        </div>
                        <div className={styles.listButton}>
                            <ButtonBase
                                className={styles.view}>
                                View More
                            </ButtonBase>
                        </div>
                    </div>
                </div>

                <MediaQuery minDeviceWidth={768}>
                <div>
                    <img src={require('../../assets/img/tuxi/image@2x.jpg')} style={{width:'100%'}}/>
                </div>
                </MediaQuery>

                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        <h2 class={styles.search}>Top Searches</h2>
                        <MediaQuery minDeviceWidth={768}>
                            <div className={styles.pickupFlex}>
                                {this._renderChipsAgain()}
                            </div>
                        </MediaQuery>
                    </div>
                </div>

                {/*<div className={styles.emailFlex}>*/}
                {/*<div className={styles.outerFlex}>*/}
                {/*<img src={require('../../assets/img/tuxi/auto.png')} className={styles.autoImage}/>*/}
                {/*</div>*/}
                {/*<div className={styles.innerFlex}>*/}
                {/*<div className={styles.emailContainer}>*/}
                {/*<div className={styles.latestText}>*/}
                {/*Subscribe to Get Latest Updates*/}
                {/*</div>*/}
                {/*<p className={styles.signUpEmail}>*/}
                {/*Sign up for our newsletter and discover travel experiences you'll really*/}
                {/*want to try*/}
                {/*</p>*/}
                {/*<div className={styles.buttonFlex}>*/}
                {/*<div className={styles.emailBox}>*/}
                {/*Email*/}
                {/*</div>*/}
                {/*<div className={styles.subscribeButton}>*/}
                {/*<ButtonBase*/}
                {/*className={styles.signUp}>*/}
                {/*Subscribe*/}
                {/*</ButtonBase>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*<div className={styles.infoText}>*/}
                {/*By signing up , you agree to receive promotional emails. You can unsubscribe at any time.*/}
                {/*For more information , read our <span className={styles.privacy}>privacy statement</span>*/}
                {/*</div>*/}

            </div>

        )
    }
}

export default Cities