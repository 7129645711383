import React,{Component} from 'react'
import styles from './Manufacturer.module.css';
import {Field, reduxForm} from 'redux-form';
import {ArrowRightAlt,TurnedIn} from '@material-ui/icons'
import {ButtonBase,MenuItem} from '@material-ui/core';
import ProductPort from "./productport/ProductPort.view";
import {renderOutlinedSelectField, renderOutlinedTextField} from "../../libs/redux-material.utils";
import Portfolio from "../Portfolio/Portfolio.view";
import DiscoverForm from "../Discover/DiscoverForm.view";
import Rfq from "../RFQ/Rfq.view";
import RouteNames from "../../routes/Route.name";
import history from "../../libs/history.util";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['name', 'price','minimum','quantity'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

class Manufacturer extends Component {

    constructor(props) {
        super(props);
        this.state={}
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(data) {
        console.log(data);
        history.push(RouteNames.thank_you);
    }

    _renderCompany(){
        return(
            <div className={styles.pharmaFlex}>
                <div>
                    <div style={{position:'relative'}}>
                        <img src={require('../../assets/img/factory/amar.png')} className={styles.logo}/>
                        <div className={styles.rating}>4.5</div>
                    </div>

                    <div className={styles.preFlex}>
                        <img src={require('../../assets/img/factory/a1.png')} height={25}/>
                        <div className={styles.seller}>Premium Seller</div>
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.manuf}>
                        <div className={styles.amar}>AHAR PHARMA</div>
                        <div className={styles.locations}>
                            <div className={styles.place}><b>Baddi,India</b></div>
                            <div>1998</div>
                            <div className={styles.place}>200+ employees</div>
                            <div>Manufacturing Capacity</div>
                        </div>
                    </div>
                    <div className={styles.orders}>
                        200 Orders Processed
                    </div>
                </div>
            </div>
        )
    }

    _renderTab(){
        return(
            <div>
                <div className={styles.amar}>PARACETAMOL(500MG)</div>
                <div className={styles.lorem}>Lorem Ipsum is simply dummy text of the priniting and<br/>typesetting industry</div>
                <div className={styles.locations}>
                    <div>Minimum Order Quantity:</div>
                    <div className={styles.place}>Price:</div>
                    <div>Delivery Period:</div>
                </div>
                <div className={styles.viewFlex}>
                    <ButtonBase className={styles.works}>
                        view variants
                    </ButtonBase>

                    <ButtonBase className={styles.works} style={{marginLeft:'15px'}}>
                        place order
                    </ButtonBase>
                </div>
            </div>
        )
    }

    _renderAchieve(){
        return(<>
            {[1,2,3].map(val => (
            <div className={styles.achivementContainer}>
                <div className={styles.achFlex}>
                    <img src={require('../../assets/img/factory/a1.png')} height={30} />
                    <div className={styles.acheiveText}>
                        Lorem Ipsum is dummy text
                    </div>
                </div>
            </div>
                ))}
            </>
        )
    }

    _renderProducts(){
        return(
        [1,2,3,4,5,6,7,8].map(val => <ProductPort/>)
        )
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div>
                <div style={{height:'40px'}}></div>
                <div className={'ncontainer'}>
                    <div className={styles.mainFlex}>
                        <div className={styles.company}>
                            <div className={styles.turned}><TurnedIn className={styles.bookmark}/></div>
                            {this._renderCompany()}
                        </div>
                        <div className={styles.tablet}>
                            {this._renderTab()}
                        </div>
                    </div>

                    <div className={styles.blogFlex}>
                        <div className={styles.detailFlex}>
                            <div className={styles.bg}>
                                <div className={styles.profFlex}>
                                    <div className={styles.heading}>About Company</div>
                                    <div>
                                        <ButtonBase className={styles.works} style={{marginLeft: '15px'}}>
                                            download profile
                                        </ButtonBase>
                                    </div>
                                </div>
                                <div className={styles.about}>
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters<br/>
                                    as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)
                                </div>

                                <div className={styles.heading}>Specialities</div>
                                <div className={styles.about}>
                                    It is a long established fact that a reader will be distracted by the readable content
                                </div>

                                <div className={styles.heading}>Awards & Cerifications</div>
                                <div className={styles.blogFlex}>
                                    <img src={require('../../assets/img/factory/c_img.jpg')} height={'150'} style={{marginRight:'10px'}}/>
                                    <img src={require('../../assets/img/factory/c_img.jpg')}  height={'150'}/>
                                </div>
                                </div>

                            <div className={styles.bg}>
                                <div className={styles.heading}>Achievement</div>
                                {this._renderAchieve()}
                            </div>

                            <div className={styles.bg}>
                                <div className={styles.profFlex}>
                                    <div className={styles.heading}>Product Portfolio</div>
                                    <div>
                                        <ButtonBase className={styles.works}>
                                            view all
                                        </ButtonBase>

                                        <ButtonBase className={styles.works}  style={{marginLeft: '15px'}}>
                                            product catalogue
                                        </ButtonBase>
                                    </div>
                                </div>
                                <div className={styles.products}>
                                    {this._renderProducts()}
                                </div>
                            </div>

                        </div>

                        <div className={styles.serachFlex}>
                            <img src={require('../../assets/img/factory/with-text.png')} width={'100%'}/>
                            <div className={styles.quick}>
                                <div className={styles.quote}>Get Quick Quote</div>

                                <form className={'discover'} onSubmit={handleSubmit(this._handleSubmit)}>
                                    <div>
                                        <div className={styles.formContainer}>
                                            <Field
                                                fullWidth={true}
                                                name="name"
                                                component={renderOutlinedSelectField}
                                                margin={"dense"}
                                                label={"Product Name"}>

                                                <MenuItem value={'A'}>A</MenuItem>
                                            </Field>
                                        </div>

                                        <div className={styles.formFlex}>
                                            <div className={styles.formContainer}>
                                                <Field
                                                    fullWidth={true}
                                                    name="price"
                                                    // type={'number'}
                                                    component={renderOutlinedTextField}
                                                    margin={"dense"}
                                                    label={"Product Price"}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.formFlex}>
                                            <div className={styles.formContainer}>
                                                <Field
                                                    fullWidth={true}
                                                    name="minimum"
                                                    type={'number'}
                                                    component={renderOutlinedTextField}
                                                    margin={"dense"}
                                                    label={"Minimum Order Quantity"}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.formFlex}>
                                            <div className={styles.formContainer}>
                                                <Field
                                                    fullWidth={true}
                                                    name="quantity"
                                                    component={renderOutlinedSelectField}
                                                    margin={"dense"}
                                                    label={"Quantity"}>

                                                    <MenuItem value={'400'}>400</MenuItem>
                                                    {/*<MenuItem value={'B'}>B</MenuItem>*/}
                                                </Field>
                                            </div>
                                        </div>
                                        <ButtonBase className={styles.looking} type={'submit'}>
                                            Request Quote
                                        </ButtonBase>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Portfolio/>
                <div className={'ncontainer'}>
                    <div className={styles.marketplaceFlex}>
                        <div className={styles.desc} style={{marginRight:'25px'}}>
                            <DiscoverForm/>
                        </div>
                        <div className={styles.desc}>
                            <Rfq/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Manufacturer = reduxForm({
    form: 'quote', // a unique identifier for this form
    validate,
})(Manufacturer);

export default Manufacturer;