/**
 * Created by charnjeetelectrovese@gmail.com on 5/26/2020.
 */
import React, {Component} from 'react';
import {CalendarToday as CalendarIcon} from '@material-ui/icons';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import styles from './Style.module.css';
import PropTypes from 'prop-types';

const CustomInput = ({value, defaultValue, inputRef, ...props}) => {
    return (
        <div className={styles.inputWithIcon}>
        <span>
        <CalendarIcon style={{ width: '18px', color: props.color }} />
            </span>
            <input {...props} placeholder={props.label} defaultValue={defaultValue} ref={inputRef} className={styles.dateInput} style={{borderColor: props.color}} />
        </div>
    );
};

class DateComponent extends Component {
    constructor() {
        super();

        this.state = {
            date: ''
        };
    }
    componentDidMount() {
        const { initialValue } = this.props;
        if (initialValue) {
            this.setState({
                date: initialValue,
            });
        }
    }

    render() {
        const {date} = this.state;
        const { color, minDate, options } = this.props;
        const tempOptions = options ? options : {};
        return (
            <Flatpickr
                value={date}
                options={{
                    minDate: minDate ? minDate : "today",
                    enableTime: false,
                    altInput: true,
                    altFormat: "F j, Y",
                    dateFormat: "Y-m-d",
                    ...tempOptions

                }}
                onChange={date => {
                    console.log('selectedDate', date);
                    this.setState({date});
                    this.props.handleChange && this.props.handleChange(date[0]);
                }}
                render={
                    ({defaultValue, value, ...props}, ref) => {
                        return <CustomInput defaultValue={defaultValue} label={this.props.label} color={color} inputRef={ref}/>
                    }
                }
            />
        );
    }
}
DateComponent.defaultProps = {
    color: 'black',
}

export default DateComponent;
