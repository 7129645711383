/**
 * Created by charnjeetelectrovese@gmail.com on 10/18/2019.
 */
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import  { ButtonBase } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import styles from './PickupDialog.module.css';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class PickupDialog extends Component {
    constructor (props) {
        super (props);
        this.state = {
            selected_city: null,
        };
        this._handleClose = this._handleClose.bind(this);
        this._handleSelection = this._handleSelection.bind(this);
        this._handleList = this._handleList.bind(this);
    }
    _handleClose = (event) => {
        this.props.handleClick(null);
    };

    _handleSelection (e) {
        this.props.handleClick(this.state.selected_city);
        e.stopPropagation();
    }

    _handleList(point, e) {
        this.props.handleClick(point);
        e.stopPropagation();
    }

    _renderList () {
        const {routes} = this.props;
        return routes.map((val) => {
           return (
               <div className={styles.listItem}>
               <ButtonBase onClick={this._handleList.bind(this, val)} className={styles.listName} >
                   {val.name}
               </ButtonBase>
               </div>
           )
        });
    }
    render () {
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this._handleClose}
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}
                classes={{ root: styles.paperTransparent, paper: styles.paperTransparent, paperFullScreen: styles.paperFullScreen }}
            >
                <div className={styles.backgroundColor} onClick={this._handleClose}>
                <div className={styles.dialogContent}>
                    <div>
                        <h3 style={{margin:'0'}}>Select Pickup Point</h3>
                        {this._renderList()}
                    </div>
                </div>
                </div>
            </Dialog>
        );
    }
}


export default PickupDialog;
