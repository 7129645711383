import React, {Component} from 'react';
import styles from './SignUp.module.css'
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ButtonBase} from '@material-ui/core';
import Close from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import {renderCheckbox, renderOutlinedTextField} from "../../libs/redux-material.utils";
import {actionSetActive, actionStoreUserData, actionLoginUser} from "../../actions/Auth.action";
import {actionClearFromLocation} from "../../actions/Common.action";
import {serviceLoginUser, serviceSaveUser, serviceSignupUser} from "../../services/Auth.service";
import EventEmitter from "../../libs/Events.utils";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const validate = (values) => {
    const errors = {};
    const requiredFields = ['password','confirm_password','new_password','email'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (values.confirm_password && values.confirm_password != values.new_password) {
        errors.confirm_password = 'Password should match'
    }
    return errors;
};


class VerifyDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logged: false,
        }
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleLogin = this._handleLogin.bind(this);
        this._handleSignUp = this._handleSignUp.bind(this);
        this._handleLoginSubmit = this._handleLoginSubmit.bind(this);
    }

    componentDidMount() {

    }

    _handleSubmit(data) {
        this.setState({
            logged: true
        })
    }

    _handleLogin() {
        this.setState({
            logged: false
        })
    }

    _renderLogin() {
        return (
            <ButtonBase onClick={this._handleSubmit} className={styles.loginButton}>
                Login
            </ButtonBase>
        )
    }

    _renderSignUp() {
        return (
            <ButtonBase onClick={this._handleLogin} className={styles.loginButton}>
                Sign Up
            </ButtonBase>
        )
    }

    async _handleLoginSubmit(data) {
        // const tempRequest = await serviceLoginUser({email: data.lemail, password: data.lpassword});
        // if (!tempRequest.error) {
        //     this.props.actionLoginUser({
        //         ...tempRequest.data,
        //         is_active: true,
        //         token: tempRequest.data.token
        //     });
        //     this.props.actionSetActive(true);
        //     this.props.handleClose();
        // } else {
        //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Invalid Email/Password', type: 'error'});
        // }
    }

    async _handleSignUp(data) {
        console.log(data);
        // const fd = new FormData();
        // Object.keys(data).forEach((key)=> {
        //     fd.append(key,data[key])
        // });

        const tempRequest = await serviceSignupUser(data);
        if (!tempRequest.error) {
            this.props.actionLoginUser({
                ...tempRequest.data,
                is_active: true,
                token: tempRequest.data.token
            });
            // this.props.actionStoreUserData({...tempRequest.data, is_active: true});
            this.props.actionSetActive(true);
            this.props.handleClose();
        } else {
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Email Aready Exists', type: 'error'});
        }
        // if (from_location) {
        //     this.props.actionClearFromLocation();
        //     this.props.history.replace(from_location.pathname, {
        //         ...(from_location.state ? from_location.state : {})
        //     })
        // } else {
        //     // this.props.history.replace(RouteNam.home);
        // }
    }

    _renderForms() {
        const {handleSubmit} = this.props;
            return (
                <div>
                    <form onSubmit={handleSubmit(this._handleLoginSubmit)}>
                        {/*<div className={'newForm'}>*/}
                            {/*<Field*/}
                                {/*type={'password'}*/}
                                {/*fullWidth*/}
                                {/*name="password"*/}
                                {/*component={renderOutlinedTextField}*/}
                                {/*margin={"dense"}*/}
                                {/*icon={require('../../assets/img/factory/field/pass.png')}*/}
                                {/*label="Old Password"/>*/}
                        {/*</div>*/}
                        {/*<div className={'newForm'}>*/}
                            {/*<Field*/}
                                {/*type={'password'}*/}
                                {/*fullWidth*/}
                                {/*name="new_password"*/}
                                {/*component={renderOutlinedTextField}*/}
                                {/*margin={"dense"}*/}
                                {/*icon={require('../../assets/img/factory/field/pass.png')}*/}
                                {/*label="New Password"/>*/}
                        {/*</div>*/}
                        {/*<div className={'newForm'}>*/}
                            {/*<Field*/}
                                {/*type={'password'}*/}
                                {/*fullWidth*/}
                                {/*name="confirm_password"*/}
                                {/*component={renderOutlinedTextField}*/}
                                {/*margin={"dense"}*/}
                                {/*icon={require('../../assets/img/factory/field/pass.png')}*/}
                                {/*label="Retype New Password"/>*/}
                        {/*</div>*/}
                        <div>
                            <Field fullWidth={true}
                                   name="email"
                                   component={renderOutlinedTextField}
                                   icon={require('../../assets/img/factory/field/email.png')}
                                    margin={'dense'}
                                   label="E-Mail"/>
                        </div>

                        <div style={{textAlign: 'center', marginTop: '15px'}}>
                            <ButtonBase  type="submit" className={styles.login}>
                                submit
                            </ButtonBase>
                        </div>
                    </form>
                </div>
            )
    }

    render() {
        const {handleSubmit} = this.props;
        // const theme = useTheme();
        // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <div className={'signup'}>
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className={styles.closeIcon} onClick={this.props.handleClose}>
                        <Close className={styles.cancel}/>
                    </div>
                    <div className={styles.pwdContainer}>
                        <h1 className={styles.headingText}>Forgot your Password?</h1>
                        <br/>
                        {this._renderForms()}

                        <div className={styles.agree}>
                            *By signing up you agree to all <Link to="/">terms and conditions</Link> & <Link to={"/"}>privacy policy</Link>
                        </div>
                    </div>


                </Dialog>
            </div>
        )
    }
}

const signUp = reduxForm({
    form: 'user',
    validate,
})(VerifyDialog);

function mapStateToProps(state) {
    return {
        // user_details: state.auth.user_details,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // actionLoginUser: actionLoginUser,
        // actionStoreUserData: actionStoreUserData,
        // actionSetActive: actionSetActive,
        // actionClearFromLocation: actionClearFromLocation,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(signUp)
