import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import PortraitCard from "../PortraitCard.component";


class PortraitSlider extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
        this.handleNext = this.handleNext.bind(this);
    }

    _renderPortraitCards(){
        return [1,2,3].map((val)=> {
            return (
                <div>
                    <PortraitCard/>
                </div>
            )
        })
    }

    handleNext() {
        if (this.slider) {
            this.slider.slickNext();
        }
    }

    handlePrev() {
        if (this.slider) {
            this.slider.slickPrev();
        }
    }

    render(){
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold: 10
        }
        return(
            <div>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderPortraitCards()}
                </Slider>
            </div>
        )
    }
}

export default PortraitSlider