import React, {Component} from 'react';
import {ArrowForward} from '@material-ui/icons'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionUpdateBoardType, actionUpdateLocation} from '../../actions/Common.action';
import classNames from 'classnames';
import EventEmitter from "../../libs/Events.utils";
import styles from "./Index.module.css";
import {ButtonBase,MenuItem} from '@material-ui/core';
import qs from 'qs';
import {
    renderCountryContact,
    renderOutlinedSelectField,
    renderOutlinedTextField
} from "../../libs/redux-material.utils";
import {reduxForm,Field} from "redux-form";
import MediaQuery from 'react-responsive';
import axios from "axios";


const validate = (values) => {
    const errors = {};
    const requiredFields = ['name','contact','email','interested']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};

const contactNormalize = (value, prevValue) => {
    if (value.length > 10) {
        return prevValue
    }
    return value;
}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            show:false,
        };
        this._handleBtnClick = this._handleBtnClick.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this)
    }

    componentDidMount() {
    }

    _handleBtnClick(type) {
        this.setState({
            selected: type,
            show:true
        })
    }


    async _handleSubmit(data){
        // e.preventDefault();
        const temp = await axios.post('https://factorygrid.com/factorygridmailcode/mailer.php',qs.stringify({...data}), { headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }});
        this.props.reset();
    }

    _renderForm(){
        const {handleSubmit} = this.props;
        return (
            <div>
                <form className={'contactForm'} onSubmit={handleSubmit(this._handleSubmit)}>
                    <div className={styles.upperFlex}>
                    <div className={styles.formContainers}>
                        <Field
                            fullWidth={true}
                            name="name"
                            component={renderOutlinedTextField}
                            // margin={"dense"}
                            label={"Name"}
                        />
                    </div>

                    <div className={styles.formContainers}>
                        <Field
                            fullWidth={true}
                            name="email"
                            component={renderOutlinedTextField}
                            // margin={"dense"}
                            label={"Email"}
                        />
                    </div>

                    <div className={styles.formContainers}>
                        <Field
                            fullWidth={true}
                            name="contact"
                            component={renderOutlinedTextField}
                            type={'number'}
                            normalize={contactNormalize}
                            // margin={'dense'}
                            errorText="Required"
                            label="Phone"/>
                    </div>

                    <div className={classNames(styles.formContainers,styles.multiple)}>
                        <Field
                            fullWidth={true}
                            name="interest"
                            component={renderOutlinedSelectField}
                            // margin={"dense"}
                            label={"Interested in"}>
                            <MenuItem value={'ORDERING'}>Ordering</MenuItem>
                            <MenuItem value={'MANUFACTURING'}>Manufacturing</MenuItem>
                        </Field>
                    </div>

                        <div>
                            <ButtonBase className={styles.contact} type={'submit'}>
                                Contact Me
                            </ButtonBase>
                        </div>

                    </div>
                </form>
            </div>
        )
    }

    render() {
        const {selected} = this.state
        return (
            <div >
                <div className={classNames(styles.indexContainer)}>
                <div className={styles.mainView}>
                    <div className={classNames(styles.innerView,styles.newContainer)}>
                    <div className={styles.videoFlex}>
                            <div className={styles.videoTuxi}>
                                <div className={styles.logoFactoryNew}>
                                    <img src={require('../../assets/img/factory/fg_logo.png')} className={styles.logoFactory} alt="factory grid logo"/>
                                    <div className={styles.title}>FACTORY GRID</div>
                                    <div className={styles.empower}>Proudly Empowering Made In India</div>
                                </div>
                                <div className={styles.heading}>
                                    GET READY FOR A REVOLUTION<span>&nbsp;</span></div>
                                <div className={styles.getText}>IN HOW YOU GET YOUR PRODUCTS MANUFACTURED<span>&nbsp;</span></div>
                                {/*<img src={require('../../assets/img/factory/landing.png')} height={350}/>*/}
                                <video className={styles.tuxivideo} controls="false" playsinline autoPlay loop muted>
                                    <source src={require('../../assets/img/factory/factorygrid.mp4')}
                                            type="video/mp4"/>
                                </video>
                                <div className={styles.qualityText}>Now just don't discover but manage your orders with</div>
                                <div className={styles.trust}>Trust,Security & Reliability</div>
                            </div>

                        <div className={styles.description}>
                            <div className={styles.logoContainer}>
                                <img src={require('../../assets/img/factory/fg_logo.png')} className={styles.logo} alt="factory grid logo"/>
                                <div className={styles.deskTitle}>FACTORY GRID</div>
                                <div className={styles.deskempower}>Proudly Empowering Made In India</div>
                            </div>
                            <div className={styles.imageFlex}>
                                <div>
                                    <img src={require('../../assets/img/factory/risk-free.png')} alt="factorygrid protect" className={styles.logos}/>
                                </div>
                                <div>
                                    <img src={require('../../assets/img/factory/easy.png')} alt="factorygrid payments" className={styles.logos}/>
                                </div>
                                <div>
                                    <img src={require('../../assets/img/factory/trusted.png')} alt="factorygrid seal" className={styles.logos}/>
                                </div>
                                <div>
                                    <img src={require('../../assets/img/factory/quality.png')} alt="factorygrid product predict" className={styles.logos}/>
                                </div>
                            </div>

                            <div className={styles.innerText}>
                                At Factory Grid we bring you India's first ecosystem to enable businesses to order your products from verified manufacturers & vendors.
                            </div>
                            <div className={styles.innerText}>
                                Our <b><i>proprietary technology</i></b> enables you to not only connect with verified vendors but also manage your complete order seamlessly with full security.
                            </div>

                            <div className={styles.launchText}>While we get ready to launch</div>
                            <div className={styles.tellUs}>Tell us who you are!</div>
                            <div className={styles.bottomButtons}>
                                <div>
                                    <ButtonBase
                                        onClick={this._handleBtnClick.bind(this, 'MARKETER')}
                                        className={selected == 'MARKETER' ? styles.blank : styles.filled}>
                                        I'm a Marketer
                                    </ButtonBase>
                                </div>

                                <div style={{marginLeft:'10px'}}>
                                    <ButtonBase
                                        onClick={this._handleBtnClick.bind(this, 'MANUFACTURER')}
                                        className={selected == 'MANUFACTURER' ? styles.blank : styles.filled}>
                                        I'm a Manufacturer
                                    </ButtonBase>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <MediaQuery minDeviceWidth={768}>
                <div className={this.state.show ? styles.bottomsUp : styles.bottomFlex}>
                    <div className={styles.newContainer}>
                        <div className={styles.help}>Need Help? Let us get back to you</div>
                        {this._renderForm()}
                    </div>
                </div>
                </MediaQuery>
                </div>
                <MediaQuery maxDeviceWidth={768}>
                    <div className={this.state.show ? styles.bottomMobile : styles.bottomDown}>
                        <div className={styles.newContainer}>
                            <div className={styles.help}>Need Help? Let us get back to you</div>
                            {this._renderForm()}
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

const customerForm = reduxForm({
    form:'customer',
    validate,
})(Main);


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionUpdateBoardType: actionUpdateBoardType,
        actionUpdateLocation: actionUpdateLocation
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        location: state.common.location
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(customerForm);


