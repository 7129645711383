/**
 * Created by charnjeetelectrovese@gmail.com on 11/12/2019.
 */
import {
    FILTER_UPDATE,
    CLEAR_FILTER,
} from '../actions/Filter.action';
import Constants from '../config/constants';
import { setParams } from '../libs/URL.utils';
import history from '../libs/history.util';

const initialState = {
    ...(JSON.parse(JSON.stringify(Constants.initialFilterState))),
};

export default function (state = (initialState), action) {
    switch (action.type) {
        case FILTER_UPDATE: {
            const data = action.payload;
            const tempReduceData = {};
            Object.keys(data).forEach((val) => {
               tempReduceData[val] = data[val];
            });

            const newState = {
                ...state,
                ...tempReduceData,
            };

            return newState;
        }
        case CLEAR_FILTER: {
            if (action.payload == 'ALL') {
                return {
                    ...state,
                    ...JSON.parse(JSON.stringify(initialState))
                };
            } else {
                const p = action.payload;
                return {
                    ...state,
                    [p]: JSON.parse(JSON.stringify(initialState))[p]
                }
            }
        }
        default: {
            return state;
        }
    }
}
