import React,{Component} from 'react'
import styles from './BlogCard.module.css';
import {ButtonBase} from '@material-ui/core'

class BlogCard extends Component{
    constructor(props){
        super(props);
        this.state={}
    }
    render(){
        return(
            <div>
                <div className={styles.wrapper}>
                        <div style={{position:'relative'}}>
                            <img src={require('../../../assets/img/factory/img2.png')} className={styles.upperImage}/>
                            <div className={styles.tab}>Tablet</div>
                        </div>
                        <div className={styles.desc}>
                            It is a long established fact that will be dist by the readable
                        </div>
                        <div>
                            <span className={styles.date}>06 May 2020 |</span> <span className={styles.name}><a
                            href='/'>John Doe</a></span>
                        </div>
                </div>
            </div>
        )
    }
}

export default BlogCard