import React, {Component} from 'react';
import styles from './Product.module.css';
import {connect} from "react-redux";
import moment from 'moment';
import {bindActionCreators} from 'redux';
import classNames from "classnames";
import {ButtonBase,MenuItem} from '@material-ui/core';
import ProductCard from "./components/ProductCard.view";
import Filters from "../Listing/components/filters/filters.component";
import ProdCard from "./ProdCard/ProdCard.view";
import ShortList from "../ShortList/ShortList.view";
import Portfolio from "../Portfolio/Portfolio.view";
import DiscoverForm from "../Discover/DiscoverForm.view";
import Rfq from "../RFQ/Rfq.view";
import Pagination from '@material-ui/lab/Pagination';
import BottomBar from './components/BottomBar/BottomBar.component'

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: []
        }
        this._handleFilterChange = this._handleFilterChange.bind(this);
        this._handleFilterClear = this._handleFilterClear.bind(this);
        this.addTempValues = this.addTempValues.bind(this);
        // this._handleClick = this._handleClick.bind(this);
    }

// _handleClick () {
//     history.push(RouteNames.blog_detail);
// }
    addTempValues(val){
        console.log('val', val);
        const temp = this.state.selected;
        const tempIndex =temp.indexOf(val);
        if(tempIndex > 0){

        } else{
            temp.push(val)
        }
        this.setState({
            selected: temp
        })
    }
    _renderProductCard(){
        return(
            [1,2,3,4,5,6,7,8,9].map((index,value) => <ProdCard data={index} addToTemp={this.addTempValues}/>)
        )
    }

    _handleFilterChange(data) {
        console.log(data);
        this.props.actionUpdateFilter(data);
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    _handleFilterClear(type) {
        this.props.actionClearFilter(type);
    }


    render() {
        const { wishlist } = this.props;
        return (
            <div>
                <div style={{height:'40px'}}></div>
                <div className={'ncontainer'}>
                    <div className={styles.look}>What are you looking for?</div>
                    <div style={{display:'flex'}}>
                        <input type="text" className={styles.inputCity} placeholder={"Paracetamol"} />
                        <ButtonBase className={styles.quote} type={'submit'}>
                            <span>Search</span>
                        </ButtonBase>
                    </div>
                </div>
            <div className={classNames('ncontainer', styles.desktopSearch)}>
                <section className={styles.filterSidebar}>
                    <div className={styles.filterText}>
                        <img src={require('../../assets/img/factory/filter_icon.png')} className={styles.logo}/>
                        Filters
                    </div>
                        <Filters
                            handleChanges={this._handleFilterChange}
                            filters={this.props.filter}
                            filterList={this.props.filterList.list_data}
                            handleClear={this._handleFilterClear}
                        ></Filters>
                </section>

                <section className={styles.resultActivities}>
                    <div className={styles.num}>Showing 9 of 20</div>
                    <div className={styles.prodFlex}>
                        {this._renderProductCard()}
                    </div>
                    <div className={styles.short}>
                        <Pagination count={3} variant="outlined" shape="rounded"/>
                    </div>
                </section>
            </div>


                <div className={'ncontainer'}>
                    <div className={styles.benefit}>
                        <ButtonBase className={styles.looking}>
                            Go Premium
                        </ButtonBase>
                    </div>

                    <div>
                        <div className={styles.marketplaceFlex}>
                            <div className={styles.desc} style={{marginRight:'25px'}}>
                                <DiscoverForm/>
                            </div>
                            <div className={styles.desc}>
                                <Rfq/>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomBar open={wishlist && Array.isArray(wishlist) && wishlist.length > 0}>
                    <ShortList/>
                </BottomBar>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // actionUpdateFilter: actionUpdateFilter,
        // actionClearFilter: actionCleanFilter,
        // actionFetchTours: actionFetchTours,
        // actionHydrateFilters: actionHydrateFilters,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        // date: state.common.date,
        // time: state.common.time,
        filter: state.filter,
        filterList: state.common.filter_list,
        wishlist: state.wishlist.list,
        // tours: state.tours.tours,
        // tours_calling: state.tours.is_calling,
        // location_data: state.common.location,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Product);

