import React, {Component} from 'react';
import styles from './ProductCard.module.css';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import ButtonBase from "@material-ui/core/ButtonBase";
import history from '../../../libs/history.util';
import RouteNames from "../../../routes/Route.name";
import data from '../../../data'

class ProductCard extends Component{
    constructor(props) {
        super(props);
        // this._handleClick = this._handleClick.bind(this);
    }

    // _handleClick () {
    //     history.push(RouteNames.blog_detail);
    // }

    _renderTopProducts(){
        return (
            data.products.map((val)=> {
                return (
                    <div className={styles.mainContainer}>
                        <div className={styles.indusCont}><img src={val.image} className={styles.industryIcons}/></div>
                        <div className={styles.outline}>
                        <div className={styles.innerCard}>
                            <div className={styles.title}>{val.title}</div>
                            <div className={styles.description}>{val.desc}</div>
                            <div className={styles.priceFlex}>
                                <div>
                                    <div> ₹{val.price}
                                        <div className={styles.perPrice}>per {val.per}</div>
                                    </div>
                                </div>
                                <div className={styles.minContainer}>
                                    <div className={styles.min}>(Min order {val.min}{val.per})</div>
                                    <div className={styles.supp}>{val.supplier} suppliers</div>
                                </div>
                            </div>

                            <ButtonBase className={styles.inquire}>
                                inquire now <ArrowRightAlt/>
                            </ButtonBase>
                        </div>
                        </div>
                    </div>
                )
            })
        )
    }

    render(){
        return(
            <div className={classNames(styles.wrapper,styles.prodFlex)} onClick={this._handleClick}>
                {this._renderTopProducts()}
            </div>
        )
    }
}

export default ProductCard
