/**
 * Created by charnjeetelectrovese@gmail.com on 10/10/2019.
 */
/**
 * Created by charnjeetelectrovese@gmail.com on 8/27/2019.
 */
import React, { Component } from 'react'
import Constants from '../../config/constants';
const tempGoogleMapStyles = [[
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]];

class GoogleMapHtml extends Component {
    constructor (props) {
        super(props);
        this.state = {
            venues: []
        };
        this.map = null;
        this.caseMarkers = {};
        this.truckMarkers = {};
        this.dataMarkers = [];
        this.z = null;

        this._smoothZoom = this._smoothZoom.bind(this);
    }

    componentDidMount() {
        // this.getVenues()
        this.renderMap();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props) != JSON.stringify(nextProps)) {
            return true;
        } return false;
    }


    _smoothZoom (map, max, cnt) {
        const prop = this;
        if (cnt >= max) {
            return;
        }
        else {
            this.z = window.google.maps.event.addListener(map, 'zoom_changed', function(event){
                window.google.maps.event.removeListener(prop.z);
                prop._smoothZoom(map, max, cnt + 1);
            });
            setTimeout(function(){map.setZoom(cnt)}, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
        }
    }

    callCenter(data) {
        console.log('Google map', data);
        if(window.google && this.map) {
            var location = new window.google.maps.LatLng(data[0], data[1]);
            // this.map.setZoom(14);
            this.map.panTo(location);
            this._smoothZoom(this.map, 14, this.map.getZoom());
        }
    }
    bearing( from, to ) {
        console.log(from, to)
        // Convert to radians.
        var lat1 = (Math.PI * from[0]) / 180;
        var lon1 = (Math.PI * from[1]) / 180;
        var lat2 = (Math.PI * to[0]) / 180;
        var lon2 = (Math.PI * to[1]) / 180;
        // Compute the angle.
        var angle = - Math.atan2( Math.sin( lon1 - lon2 ) * Math.cos( lat2 ), Math.cos( lat1 ) * Math.sin( lat2 ) - Math.sin( lat1 ) * Math.cos( lat2 ) * Math.cos( lon1 - lon2 ) );
        if ( angle < 0.0 )
            angle  += Math.PI * 2.0;
        if (angle == 0) {angle=1.5;}
        return angle;
    }

    move(marker, latlngs, index, wait, newDestination) {
        marker.setPosition(latlngs[index]);
        if(index != latlngs.length-1) {
            // call the next "frame" of the animation
            const prop = this;
            setTimeout(function() {
                prop.move(marker, latlngs, index+1, wait, newDestination);
            }, wait);
        }
        else {
            // assign new route
            // marker.position = marker.destination;
            // marker.destination = newDestination;
        }
    }

    _renderTruckMarkers(trucks) {
        const google = window.google;
        Object.keys(trucks).map((key, index) => {
            const tempTruck = trucks[key];
            if (!this.caseMarkers[tempTruck.truck_id]) {
                const icon = {
                    // url: require('../../assets/fire_brigade.svg'),
                    // path: 'M22.1,15.1c0,0-1.4-1.3-3-3l0-1.9c0-0.2-0.2-0.4-0.4-0.4l-0.5,0c-0.2,0-0.4,0.2-0.4,0.4l0,0.7c-0.5-0.5-1.1-1.1-1.6-1.6l0-1.5c0-0.2-0.2-0.4-0.4-0.4l-0.4,0c-0.2,0-0.4,0.2-0.4,0.4l0,0.3c-1-0.9-1.8-1.7-2-1.9c-0.3-0.2-0.5-0.3-0.6-0.4l-0.3-3.8c0-0.2-0.3-0.9-1.1-0.9c-0.8,0-1.1,0.8-1.1,0.9L9.7,6.1C9.5,6.2,9.4,6.3,9.2,6.4c-0.3,0.2-1,0.9-2,1.9l0-0.3c0-0.2-0.2-0.4-0.4-0.4l-0.4,0C6.2,7.5,6,7.7,6,7.9l0,1.5c-0.5,0.5-1.1,1-1.6,1.6l0-0.7c0-0.2-0.2-0.4-0.4-0.4l-0.5,0c-0.2,0-0.4,0.2-0.4,0.4l0,1.9c-1.7,1.6-3,3-3,3c0,0.1,0,1.2,0,1.2s0.2,0.4,0.5,0.4s4.6-4.4,7.8-4.7c0.7,0,1.1-0.1,1.4,0l0.3,5.8l-2.5,2.2c0,0-0.2,1.1,0,1.1c0.2,0.1,0.6,0,0.7-0.2c0.1-0.2,0.6-0.2,1.4-0.4c0.2,0,0.4-0.1,0.5-0.2c0.1,0.2,0.2,0.4,0.7,0.4c0.5,0,0.6-0.2,0.7-0.4c0.1,0.1,0.3,0.1,0.5,0.2c0.8,0.2,1.3,0.2,1.4,0.4c0.1,0.2,0.6,0.3,0.7,0.2c0.2-0.1,0-1.1,0-1.1l-2.5-2.2l0.3-5.7c0.3-0.3,0.7-0.1,1.6-0.1c3.3,0.3,7.6,4.7,7.8,4.7c0.3,0,0.5-0.4,0.5-0.4S22,15.3,22.1,15.1z',
                    path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
                    // scaledSize: new google.maps.Size(25, 50), // scaled size
                    // origin: new google.maps.Point(0, 0), // origin
                    // anchor: new google.maps.Point(0, 0), // anchor
                    // rotation: 150
                    fillColor: '#F00',
                    fillOpacity: 2,
                    scale: 0.85,
                    anchor: new google.maps.Point(11, 11),
                    strokeWeight: 0
                };
                // Create A Marker
                const marker = new window.google.maps.Marker({
                    position: {lat: parseFloat(tempTruck.lat), lng: parseFloat(tempTruck.lng)},
                    map: this.map,
                    title: 'truck',
                    icon: icon,
                    // rotation: google.maps.geometry.spherical.computeHeading(prevPosn, marker.getPosition())
                })
                this.caseMarkers[tempTruck.truck_id] = marker;
            } else {
                const tempMarker = this.caseMarkers[tempTruck.truck_id];
                if(tempMarker) {
                    var newLatLng = new window.google.maps.LatLng(parseFloat(tempTruck.lat), parseFloat(tempTruck.lng));
                    const oldPosition = tempMarker.getPosition();
                    const frames = [];
                    const fromLat = oldPosition.lat();
                    const fromLng = oldPosition.lng();

                    for (var percent = 0; percent < 1; percent += 0.01) {
                        const curLat = fromLat + percent * (parseFloat(tempTruck.lat) - fromLat);
                        const curLng = fromLng + percent * (parseFloat(tempTruck.lng) - fromLng);
                        frames.push(new google.maps.LatLng(curLat, curLng));
                    }

                    this.move(tempMarker, frames, 0, 20, tempMarker.getPosition());
                    // tempMarker.setPosition(newLatLng);
                    var icon = tempMarker.getIcon();
                    console.log(icon);
                    icon.rotation = (google.maps.geometry.spherical.computeHeading(

                        new window.google.maps.LatLng(parseFloat(tempTruck.lat), parseFloat(tempTruck.lng) ),
                        new window.google.maps.LatLng(parseFloat(oldPosition.lat()), parseFloat(oldPosition.lng()))
                    ) + 180);
                    console.log(icon.rotation);
                    tempMarker.setIcon(icon);
                }
            }

            // Click on A Marker!

        })
    }

    _renderDataMarkers(data, data_type) {
        this.dataMarkers.map((val) => {
            val.setMap(null);
        });
        this.dataMarkers = [];
        const google = window.google;
        console.log('UDPATEADSADSA', data, data_type);
        if(google) {
            data.map((val, index) => {
                var contentString = `${val.address}`;
                const infowindow = new window.google.maps.InfoWindow();
                const icon = {
                    url: require('../../assets/'+data_type+'.png'),
                    scaledSize: data_type == 'station' ? new google.maps.Size(44, 40) : new google.maps.Size(20, 43), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };
                // Create A Marker
                const marker = new window.google.maps.Marker({
                    position: {lat: parseFloat(val.loc[0]), lng: parseFloat(val.loc[1])},
                    map: this.map,
                    title: data_type,
                    icon: icon
                });
                marker.addListener('click', function () {

                    // Change the content
                    infowindow.setContent(contentString)

                    // Open An InfoWindow
                    infowindow.open(this.map, marker)
                });
                this.dataMarkers.push(marker);
            });
        }
    }


    renderMap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?libraries=geometry&key="+Constants.GOOGLE_MAP_KEY+"&callback=initMap")
        window.initMap = this.initMap
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    initMap = () => {

        const google = window.google;
        // Create A Map
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 29.0588, lng: 76.0856 },
            zoom: 6,
            styles: tempGoogleMapStyles,
            mapTypeControl: false,
            scaleControl: false,
            zoomControl:false,
            streetViewControl:false,
        });

    }

    render() {
        return (
            <main style={{ height: '100%', flex: 1}}>
                <div id="map" style={{ height: '100%', width: '100%' }}></div>
            </main>
        )
    }
}

function loadScript(url) {
    var index  = window.document.getElementsByTagName("script")[0];
    console.log(index);
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

export default GoogleMapHtml;
