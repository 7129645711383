import React, {Component} from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import styles from './Board.module.css';
import RouteNames from '../../routes/Route.name';
import {actionUpdateFilter} from '../../actions/Filter.action';
import {actionUpdateCategory} from "../../actions/Common.action";


class Board extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleClick = this._handleClick.bind(this);
        this._handleRide = this._handleRide.bind(this);
    }

    componentDidMount() {
        const { location } = this.props;
        if (location == null) {
            this.props.history.push(RouteNames.index);
        }
    }

    _handleClick() {
        const { location } = this.props;
        this.props.actionUpdateCategory('tour');
        this.props.actionUpdateFilter({city: location.name, board_type: 'board_now'}, false, false);
        this.props.history.push(RouteNames.home+'?city='+location.name+'&board_type=board_now');
    }

    _handleRide() {
        this.props.actionUpdateCategory('ride');
        this.props.history.push(RouteNames.ride);
    }

    render() {
        return (
            <div>
                <div className={styles.container}>
                    <div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className={styles.display}>
                            <ButtonBase classes={{root: styles.btn}} onClick={this._handleClick}>
                                <div className={styles.bookbtn}>
                                    <span className={styles.button}>Tour & Experiences</span>
                                </div>
                            </ButtonBase>

                            <div className={styles.description}>
                                Destinations and mesmerizing activities
                                {' '}
                                <br/>
                                {' '}
                                from our official
                                {' '}
                                <br/>
                                {' '}
                                providers and partners in just one click on the platform.
                            </div>
                        </div>
                    </div>

                    <div>
                        <br/>
                        <br/>
                        <div className={styles.display}>
                            <ButtonBase classes={{root: styles.btn}} onClick={this._handleRide}>
                                <div className={styles.bookbtn}>
                                    <span className={styles.button}>Book A Ride</span>
                                </div>
                            </ButtonBase>

                            <div className={styles.description}>
                                Travel in style from point A to B
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionUpdateCategory: actionUpdateCategory,
        actionUpdateFilter: actionUpdateFilter,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        location: state.common.location,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Board);
