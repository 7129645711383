/**
 * Created by charnjeetelectrovese@gmail.com on 12/13/2018.
 */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from "classnames";
import styles from './SignUp.module.css';
import {
    renderTextField,
    renderOutlinedTextField,
    renderOutlinedSelectField,
    renderCountryContact, renderCheckbox, renderCustomCheckbox, renderBlueCheckbox, renderPasswordField
} from '../../libs/redux-material.utils';
import {Button, withStyles, ButtonBase,MenuItem} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {serviceLoginUser} from "../../services/Auth.service";
import {actionLoginUser} from '../../actions/Auth.action';
import DashboardSnackbar from "../../components/Snackbar.component";
import {Link} from 'react-router-dom';
import RouteNames from '../../routes/Route.name';

import EventEmitter from "../../libs/Events.utils";
import {updateTitle} from "../../libs/general.utils";

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password','first_name','last_name'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}


const useStyles = {
    btnColor: {
        backgroundColor: 'white',
        marginTop: '20px',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    btnBottom: {
        backgroundColor: 'white',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '10px',
        marginLeft: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    dialog: {
        padding: '10px 25px'
    },
    colorButton: {
        padding: '8px 35px',
        color: 'white',
        backgroundImage: 'linear-gradient(to right, #04AFB7 , #1BB380)',
        '&:hover': {
            color: 'white',
            backgroundColor:'linear-gradient(to right, #04AFB7 , #1BB380)',
        }
    },
    signUp: {
        color: '#2CB5A5',
        marginLeft: '10px',
        textDecoration:'none',
        fontWeight:'600'
    }

};

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: 'order'
        };
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleForgotPassword = this._handleForgotPassword.bind(this);
        this._handleRoute = this._handleRoute.bind(this);
        this._handleChange = this._handleChange.bind(this)
    }

    // async componentDidMount() {
    //     updateTitle('Login');
    // }

    _handleSubmit(data) {
        this.props.history.push(RouteNames.profile)
        // serviceLoginUser(data).then((val) => {
        //     if (!val.error) {
        //         this.props.actionLoginUser(val.data);
        //     } else {
        //         EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Invalid Username/Password', type: 'error'});
        //     }
        // });
    }

    _handleForgotPassword() {
        this.props.history.push('/forgot/password');
    }

    _handleRoute(){
        this.props.history.push('/login');
    }

    _handleChange(e){
        this.setState({
            is_checked: e.target.value
        })
    }

    render() {
        const {handleSubmit, classes} = this.props;
        return (
            <div className={styles.mainLoginView}>
                <div className={styles.loginFlex1}>
                    {/*<div style={{color: 'white', fontSize: '30px', fontWeight: '500', textAlign: 'center'}}>*/}

                    {/*</div>*/}


                    <div style={{paddingLeft:'100px'}}>
                        <div className={styles.mainHeading}>
                            Welcome to India's first<br/>
                            e-manufacturing hub
                        </div>
                        {/*<br/>*/}

                        <div className={styles.list}>
                            <ul className={styles.circle}>
                                <li className={styles.val}>5000+ products portfolio</li>
                                <li className={styles.val}>Verified customers and manufactures</li>
                                <li className={styles.val}>Payment protection plan</li>
                                <li className={styles.val}>Quality assurance with proprietary checks</li>
                                <li className={styles.val}>Easy EMI based payment options</li>
                                <li className={styles.val}>Track your orders progress in realtime</li>
                            </ul>
                        </div>

                        <br/>
                        <br/>
                        <div>
                            <div className={styles.noacct}>Already Registered?</div>

                            <ButtonBase className={styles.looking} onClick={this._handleRoute}>
                                Login
                            </ButtonBase>
                        </div>

                        <div className={styles.bottomContainer}>

                        </div>
                    </div>
                </div>
                <div className={styles.loginFlex2}>
                    {/*<div>*/}
                    {/*    <div style={{textAlign: 'center',marginTop:'40px'}}>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.marginCenter}>
                        <h1 className={styles.headingText}>Create your Account for free</h1>
                        <div style={{marginTop: '30px'}}>
                            <div className="google-btn">
                                <div className="google-icon-wrapper">
                                    <img src={require('../../assets/img/tuxi/ic_google.png')} className='google-icon'/>
                                </div>
                                <p className="btn-text"><b>Sign in with Google</b></p>
                            </div>
                            {/*<ButtonBase className={styles.googleButton}>*/}
                            {/*    <div className={styles.iconImg}>*/}
                            {/*        <img src={require('../../assets/img/tuxi/ic_google.png')}/>*/}
                            {/*    </div>*/}

                            {/*    <div className={styles.textContainer}>*/}
                            {/*        Sign in with Google*/}
                            {/*    </div>*/}
                            {/*</ButtonBase>*/}
                        </div>
                        <div className={styles.loginText}>or use your email for registration</div>

                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            {/*<div className={styles.loginSignupText}>Login</div>*/}
                            <div>
                                <div className={'newForm'}>
                                    <Field
                                        fullWidth={true}
                                        name="first_name"
                                        component={renderTextField}
                                        // margin={"dense"}
                                        label={"Enter your first name"}
                                    />
                                </div>

                                <div className={'newForm'}>
                                    <Field fullWidth={true}
                                           name="last_name"
                                           component={renderTextField}
                                           label="Enter your last name"/>
                                </div>

                                <div className={'newForm'}>
                                    <Field
                                        fullWidth={true}
                                        name="email"
                                        component={renderTextField}
                                        // margin={"dense"}
                                        label={"Enter your email address"}
                                    />
                                </div>

                                <div className={'newForm'}>
                                    <Field fullWidth={true}
                                           name="password"
                                           component={renderPasswordField}
                                            // margin={'dense'}
                                           label="Enter your password"/>
                                </div>


                                {/*<div className={'newForm'} style={{display:'flex',alignItems:'center'}}>*/}
                                {/*    <div className={styles.checkboxes}>*/}
                                {/*        <RadioGroup aria-label="order" name="order" value={this.state.is_checked} onChange={this._handleChange}>*/}
                                {/*            <FormControlLabel value="order" control={<Radio />} label="I'm here to Order" />*/}
                                {/*            /!*<FormControlLabel value="manufacturer" control={<Radio />} label="I'm a Manufacturer" />*!/*/}
                                {/*        </RadioGroup>*/}
                                {/*    </div>*/}

                                {/*    <div className={styles.checkboxes}>*/}
                                {/*        <RadioGroup aria-label="manufacturer" name="manufacturer" value={this.state.is_checked} onChange={this._handleChange}>*/}
                                {/*            <FormControlLabel value="manufacturer" control={<Radio />} label="I'm a Manufacturer" />*/}
                                {/*        </RadioGroup>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <br/>
                                <div className={styles.sign}>
                                    <ButtonBase  type="submit" className={styles.login}>
                                        Proceed
                                    </ButtonBase>
                                </div>
                            </div>
                        </form>

                        <div className={styles.privacy}>
                            *By signing up you agree to all <Link to="/">terms and conditions</Link> & <Link to={"/"}>privacy policy</Link>
                        </div>
                    </div>

                    <div>

                    </div>

                </div>
                <DashboardSnackbar/>
            </div>
        );
    }
}

SignUp = reduxForm({
    form: 'Signup',  // a unique identifier for this form
    validate,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Login Credentials', type: 'none'});
    //
    // }
})(SignUp);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLoginUser: actionLoginUser
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(withStyles(useStyles)(SignUp));
