import React, { Component } from 'react';
import styles from './Ticket.module.css';
import ButtonBase from '@material-ui/core/ButtonBase';

class Ticket extends Component {
    render(){
        return(
                <div className={styles.stamp}>
                        <div style={{background:'white', height: '100%'}}>
                            <div className={styles.ticketflex}>
                                <div>
                                    <img src={require('../../assets/img/TicketImg.jpg')} style={{height:'75px'}}/>
                                </div>
                                <div className={styles.ticketdescription}>
                                    <div className={styles.scheduled}>6 August 2019, 9:30 AM - 11:30 AM</div>
                                    <div className={styles.tourHeading}>
                                        Lisbon Castle and medivial <br/> buildings
                                    </div>
                                    <div className={styles.tourDriver}>
                                        Maddie Castro - Driver
                                        <div>
                                            <ButtonBase className={styles.mapView}>
                                                <div>View On Map</div>
                                            </ButtonBase>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.ticketLower} >
                                <div className={styles.bottomDescription}>
                                    <div>
                                        <div className={styles.price}>Total Paid</div>
                                        <div className={styles.priceFlex}>
                                            <img src={require('../../assets/img/money.png')} style={{height:'25px',color:'#2196F3'}} />
                                            <span className={styles.amount}>30</span>
                                        </div>
                                    </div>
                                    <div style={{marginLeft:'50px'}}>
                                        <div className={styles.price}>Total Seats</div>
                                        <div className={styles.priceFlex}>
                                            <img src={require('../../assets/img/seats.png')} style={{height:'25px',color:'#2196F3'}} />
                                            <span className={styles.amount}>2</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginRight:'30px'}}>
                                    <img src={require('../../assets/img/qrcode.png')} style={{width:'35px',border: '4px solid rgba(92,193,24,.83)'}}/>
                                </div>

                            </div>
                        </div>
                    </div>
        )
    }
}

export default Ticket
