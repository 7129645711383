import React, {Component} from 'react'
import styles from './Otp.module.css';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {
    renderOutlinedTextField,
    renderTextField
} from '../../libs/redux-material.utils'
import MenuItem from '@material-ui/core/MenuItem';
import RouteNames from '../../routes/Route.name'
import {ButtonBase} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons'
import {actionLoginUser, actionSetActive} from '../../actions/Auth.action';
import {serviceVerifyOTP} from "../../services/Auth.service";
import {actionClearFromLocation} from "../../actions/Common.action";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['otp'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

class Otp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleBack = this._handleBack.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleResendOtp = this._handleResendOtp.bind(this);
    }

    _handleBack() {
        this.props.history.goBack();
    }

    async _handleSubmit(data) {
        const {contact, country_code, from_location} = this.props;
        const tempContact = country_code + ' ' + contact;
        const tempRequest = await serviceVerifyOTP({contact: tempContact, otp: data.otp});
        console.log(tempRequest);
        if (tempRequest.response_code == 0) {
            this.setState({
                snackbar: true,
                message: 'Wrong Otp'
            })
        } else if (tempRequest.response_code == 2) {

            this.props.actionSetActive(false);
            this.props.actionLoginUser({
                token: tempRequest.data.token,
                is_active: false,
                contact: contact,
                country_code: country_code
            });
            this.props.history.replace(RouteNames.signup);
        } else {

            this.props.actionLoginUser({
                ...tempRequest.data,
                is_active: true,
                token: tempRequest.data.token
            });
            this.props.actionSetActive(true);
            if (from_location) {
                this.props.actionClearFromLocation();
                this.props.history.replace(from_location.pathname, {
                    ...(from_location.state ? from_location.state : {})
                })
            } else {
                this.props.history.replace(RouteNames.home);
            }
            // if(relationship == 'MARRIED' && this.props.selected_insurance == 'Health') {
            //     this.props.history.replace('/familydetails');
            // } else {
            //     this.props.history.replace('/insurances');
            // }
        }
    }

    _handleResendOtp() {

    }

    render() {
        const {contact, country_code, handleSubmit} = this.props;
        return (
            <div>
                <div style={{height: '65px'}}>
                </div>

                <div className={styles.container}>
                    <div className={styles.back}>
                        <ArrowBack onClick={this._handleBack}>
                        </ArrowBack>
                    </div>
                    <form onSubmit={handleSubmit(this._handleSubmit)}>

                        <br/>
                        <div className={styles.innerText}>
                            Enter the 6 digit code send to you at <br/>
                            <span style={{fontSize: '18px', marginTop: '3px'}}>{country_code} - {contact}</span>
                        </div>

                        <br/>
                        <br/>
                        <div className={styles.numberField}>
                            <Field
                                fullWidth={true}
                                type="number"
                                name="otp"
                                component={renderTextField}/>
                        </div>

                        <br/>
                        <br/>
                        <br/>
                        <div className={styles.bottomText}>
                            <div style={{fontWeight: '500'}}>Didn't Receive OTP ?</div>
                            <ButtonBase onClick={this._handleResendOtp} type={'button'} className={styles.resendText}>
                                Resend OTP
                            </ButtonBase>
                        </div>


                        <ButtonBase type={'submit'} className={styles.bottomButton}>
                            <div className={styles.verifyText}>
                                Verify Now
                            </div>
                        </ButtonBase>
                    </form>
                </div>

            </div>
        )
    }
}

const otp = reduxForm({
    form: 'otp',
    validate
})(Otp);

function mapStateToProps(state) {
    return {
        contact: state.auth.user_details.contact,
        country_code: state.auth.user_details.country_code,
        from_location: state.common.from_location
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLoginUser: actionLoginUser,
        actionSetActive: actionSetActive,
        actionClearFromLocation: actionClearFromLocation,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(otp)
