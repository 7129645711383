import React, {Component} from 'react'
import styles from './Footer.module.css'

class Footer extends Component {
    render(){
        return(
            <div>
                <div className={styles.footerFlex}>

                    <div className={styles.innerFlex}>
                        <h4>Contact</h4>
                        <ul className={styles.footerList}>
                            <li>
                                <a href="">Payment & Refund </a>
                            </li>
                            <li>
                                <a href="">Cancellation</a>
                            </li>
                            <li>
                                <a href="">Booking Modification</a>
                            </li>
                            <li>
                                <a href="">Booking Information</a>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.innerFlex}>
                        <h4>Support</h4>
                        <ul className={styles.footerList}>
                            <li>
                                <a href="">Legal</a>
                            </li>
                            <li>
                                <a href="">Management</a>
                            </li>
                            <li>
                                <a href="">Company Registration</a>
                            </li>
                            <li>
                                <a href="">VAT Registration</a>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.innerFlex}>
                        <h4>Policies</h4>
                        <ul className={styles.footerList}>
                            <li>
                                <a href="" >Privacy Statement </a>
                            </li>
                            <li>
                                <a href="">Collection Processing</a>
                            </li>
                            <li>
                                <a href="">Data Processing</a>
                            </li>
                            <li>
                                <a href="">Third Party Cookies</a>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.innerFlex}>
                        <h4>Address</h4>
                        <ul className={styles.footerList}>
                            <li>
                               Lisbon , Portugal
                            </li>
                            <li>
                                Call +351-1234567890
                            </li>
                            <li>
                                E-mail: admin@getatour.com
                            </li>
                        </ul>
                    </div>
                </div>
                <hr/>
            </div>
        )
    }
}

export default Footer