import React from 'react'
import styles from './ReviewsCard.module.css'
import {Star} from '@material-ui/icons'


class ReviewsCard extends React.Component{
    render(){
        return(
            <div style={{marginTop:'10px'}}>
                <div className={styles.card}>
                    <div className={styles.cardContainer}>
                        <div className={styles.outerFlex}>
                            <div>
                                <div>
                                <img src={this.props.val.image} className={styles.imgDriver}/>
                                </div>
                                <div className={styles.iconStar}>
                                    <Star fontSize ={'small'} className={styles.fontSizeSmall}/>
                                    <span>{this.props.val.rating}</span>
                                </div>
                                <div className={styles.driverDescription}>
                                    {this.props.val.description}
                                </div>
                            </div>

                            <div>
                                <div className={styles.galleryFlex}>
                                <div className={styles.driverSection}>
                                    {this.props.val.name}
                                </div>
                                    <div className={styles.date}>
                                        {this.props.val.date}
                                    </div>
                                </div>

                                <div className={styles.driverReviews}>
                                    {this.props.val.reviews}
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ReviewsCard