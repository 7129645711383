import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {ButtonBase} from '@material-ui/core';
import classNames from 'classnames';
import {connect} from "react-redux";
import moment from 'moment';
import {bindActionCreators} from 'redux';
import styles from './Contact.module.css';
import {renderCountryContact, renderOutlinedTextField} from "../../libs/redux-material.utils";
import {withStyles} from "@material-ui/core/index";
import {actionLoginUser} from "../../actions/Auth.action";
import RouteNames from "../../routes/Route.name";
import Portfolio from "../Portfolio/Portfolio.view";
import DiscoverForm from "../Discover/DiscoverForm.view";
import Rfq from "../RFQ/Rfq.view";

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email','name','contact','subject','message'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(data) {
    }


    _renderBottomForm(){
        const {handleSubmit, classes} = this.props;
        return(
            <div>
                <form onSubmit={handleSubmit(this._handleSubmit)}>
                    <div className={styles.cForm}>
                        <Field
                            fullWidth={true}
                            name="name"
                            component={renderOutlinedTextField}
                            margin={"dense"}
                            label={"Full Name"}
                        />
                    </div>

                    <div className={styles.cForm}>
                        <Field
                            fullWidth={true}
                            name="contact"
                            component={renderOutlinedTextField}
                            type={'number'}
                            margin={'dense'}
                            errorText="Required"
                            label="Phone No."/>
                    </div>

                    <div className={styles.cForm}>
                        <Field
                            fullWidth={true}
                            name="email"
                            component={renderOutlinedTextField}
                            margin={"dense"}
                            label={"Email Id"}
                        />
                    </div>

                    <div className={styles.cForm}>
                        <Field
                            fullWidth={true}
                            name="subject"
                            component={renderOutlinedTextField}
                            margin={"dense"}
                            label={"Subject"}
                        />
                    </div>

                </form>
            </div>
        )
    }

    _renderContact(){
        return(
            <div className={styles.conFlex}>
                <div className={styles.innerFlex}>
                    <img src={require('../../assets/img/factory/l;ocation.png')} className={styles.contactIcons}/>
                    <div className={styles.title}>Address</div>
                    <div className={styles.address}>First Floor,Plot No.45,<br/>
                        Ind. Area Phase 1,Chandigarh
                    </div>
                </div>

                <div className={styles.innerFlex}>
                    <img src={require('../../assets/img/factory/phone.png')} className={styles.contactIcons}/>
                    <div className={styles.title}>Phone Number</div>
                    <div className={styles.address}>978877878<br/>
                        +91 9869489845,+91 4857487564
                    </div>
                </div>

                <div className={styles.innerFlex}>
                    <img src={require('../../assets/img/factory/mail.png')} className={styles.contactIcons}/>
                    <div className={styles.title}>Email address</div>
                    <div className={styles.address}>admin@gmail.com<br/>

                    </div>
                </div>
            </div>
        )
    }
    render() {
        const {handleSubmit, classes} = this.props;
        return (
            <div>
                <div className={'ncontainer'}>
                    <h2 className={styles.mainHeading}>Get In Touch with us</h2>
                    {this._renderContact()}

                    <div>
                    <h2>Drop A Message</h2>
                        <div className={styles.lorem}>Lorem Ipsum is a dummy tect of printing industry</div>
                        <div className={styles.formFlex}>
                            <div style={{flex:'1'}}>{this._renderBottomForm()}</div>
                            <div style={{flex:'1'}} className={styles.mess}>
                                <form onSubmit={handleSubmit(this._handleSubmit)}>
                                <div className={styles.cForm}>
                                        <Field
                                            fullWidth={true}
                                            name="message"
                                            component={renderOutlinedTextField}
                                            label={"Message"}
                                            multiline
                                            rows={"8"}/>
                                </div>

                                    <div className={styles.sign}>
                                        <ButtonBase  type="submit" className={styles.login}>
                                            Submit
                                        </ButtonBase>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Portfolio/>
                <div className={'ncontainer'}>
                    <div className={styles.marketplaceFlex}>
                        <div className={styles.desc} style={{marginRight:'25px'}}>
                            <DiscoverForm/>
                        </div>
                        <div className={styles.desc}>
                            <Rfq/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Contact = reduxForm({
    form: 'Contact',  // a unique identifier for this form
    validate,
})(Contact);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // actionLoginUser: actionLoginUser
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(Contact);