/**
 * Created by charnjeetelectrovese@gmail.com on 10/31/2019.
 */
import React, { Component } from 'react';
import styles from "./style.module.css";
import PropsTypes from 'prop-types';

class CartRide extends Component {
    constructor(props) {
        super(props);

    }
    _renderDate() {
        if (this.props.board_type == 'board_later') {
            return (
                <div className={styles.timeContainer}>
                    <div>
                        <img src={require('../../assets/img/ic_time.png')} className={styles.icon}/>
                    </div>
                    <div className={styles.timeDescription}>
                        Thu,3 Oct 08:00
                    </div>
                </div>
            )
        } return null;
    }
    render() {
        const { data } = this.props;
        return (
            <div className={styles.background}>
                <div>
                    <h3 className={styles.title}>{data.tour_name}</h3>
                </div>
                <div className={styles.cont}>
                    <div>
                        <img src={require('../../assets/img/ic_loc@3x.png')} className={styles.image}/>
                    </div>
                    <div>
                        <div className={styles.location}>Your Pickup is from {data.selected_point ? data.selected_point.name : ''}</div>
                    </div>
                </div>

                <div className={styles.innerCon}>
                    <div className={styles.flex}>
                        <div>
                            <img src={require('../../assets/img/ic_car@3x.png')} className={styles.icon}/>
                        </div>

                        <div className={styles.loc}>
                            Polo GT
                        </div>
                    </div>
                    <div className={styles.flex} style={{marginLeft:'30px'}}>
                        <div>
                            <img src={require('../../assets/img/ic_driver@3x.png')} className={styles.icon}/>
                        </div>

                        <div className={styles.loc}>
                            Steve Rogers
                        </div>
                    </div>
                </div>
                <div>
                    {this._renderDate()}
                </div>
            </div>
        );
    }

}

CartRide.defaultProps = {
    board_type: 'board_now',
}

export default CartRide;
