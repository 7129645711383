/* eslint-disable indent,linebreak-style,max-len */
/**
 * Created by charnjeetelectrovese@gmail.com on 9/20/2017.
 */
// import { browserHistory } from 'react-router';
import { serviceCallReferer } from '../services/Auth.service';
import { setAuthorizationToken } from '../libs/set_auth_token.utils';
import history from '../libs/history.util';
import {connectToSocket} from "../libs/socket.utils";
export const AUTH_USER = 'AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_ACTIVE = 'SET_ACTIVE';
export const UPDATE_FAMILY = 'UPDATE_FAMILY';
export const SET_REFERER = 'SET_REFERER';
export const CALL_REFERER = 'CALL_REFERER';
export const STORE_CONTACT = 'STORE_CONTACT';

export function actionLoginUser(data) {
    return (dispatch) => {
        if (data) {
            localStorage.setItem('jwt_token', data.token);
            localStorage.setItem('user', JSON.stringify({ is_active: false, ...data }));
            setAuthorizationToken(data.token);
            dispatch({ type: AUTH_USER, payload: { is_active: false, ...data } });
            connectToSocket(data.token);
            // browserHistory.push(`${process.env.PUBLIC_URL}/photographer/`+data.user_id);
        }
    };
    // return ({type: AUTH_USER, payload: data});
}

export function actionLogoutUser() {
    return (dispatch) => {
        connectToSocket(null);
        localStorage.removeItem('jwt_token');
        localStorage.removeItem('user');
        setAuthorizationToken(false);
        dispatch({ type: LOGOUT_USER });
        // history.push(`/login`);
    };
}

export function actionStoreUserData(data) {
    return (dispatch) => {
        dispatch({ type: UPDATE_USER, payload: data });
    };
    // return ({type: AUTH_USER, payload: data});
}

export function actionStoreFamilyDetails(data) {
    return (dispatch) => {
        dispatch({ type: UPDATE_FAMILY, payload: data });
    }
}

export function actionSetActive(data) {
    return (dispatch) => {
        dispatch({ type: SET_ACTIVE, payload: data });
    };
}

export function actionSetReferer (refer){
    return (dispatch) => {
        dispatch({ type: SET_REFERER, payload: refer });
    };
}
export function actionCallReferer (referer) {
    const request = serviceCallReferer({referer: referer});
    return (dispatch) => {
        dispatch({ type: CALL_REFERER });
    };
}
