import React, {Component} from 'react';
import styles from './LandscapeCard.module.css';
import ButtonBase from '@material-ui/core/ButtonBase';
import history from "../../../libs/history.util";
import RouteNames from "../../../routes/Route.name";
import MediaQuery from 'react-responsive';

class LandscapeCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false
        };
        this._handleClick = this._handleClick.bind(this);
    }


    _handleClick () {
        history.push(RouteNames.cities);
    }

    render(){
        return(
            <div style={{flex:1}} className={styles.mainContainer} onClick={this._handleClick}>
                <div className={styles.cardContainer}>
                    <div className={styles.backgroundImage} style={{
                        backgroundImage: 'url(' + (this.props.add ? this.props.add : require('../../../assets/img/desktop/provider1.png') ) + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition:'center'
                    }}>
                    </div>
                </div>

                <div className={this.props.image ? styles.newColor : styles.bottomText}>
                    <div className={styles.heading}>
                        {this.props.title ? this.props.title : 'Porto'}
                    </div>
                    <div className={styles.description}>
                        Porto is a coastal city in northwest Portugal known for its strategy bridges and port wine production,
                        In the medivial Ribeira(riverside) district,narrow cobbled streets.
                    </div>

                    <MediaQuery minDeviceWidth={768}>
                    <div className={styles.iconsFlex}>
                    <div className={styles.button}>
                        <ButtonBase
                            className={styles.exploreButton}>
                            Explore More
                        </ButtonBase>
                    </div>
                        <div className={styles.marginAbove}>
                            <img src={ this.props.image ? this.props.image :require('../../../assets/img/tuxi/porto_icons.png')} style={{height:'45px'}}/>
                        </div>
                    </div>
                    </MediaQuery>
                </div>
            </div>
        )
    }
}

export default LandscapeCard