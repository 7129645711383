import React, {Component} from 'react'
import styles from './FiltersDialog.module.css'
import 'rc-slider/assets/index.css';
import ButtonBase from "@material-ui/core/ButtonBase";
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Constants from "../../../../config/constants";
import Mini from '../Mini/Mini.component'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class FiltersDialog extends Component {
    constructor(props) {
        super(props);
        this.filtersOrders = ['category', 'type_category', 'price', 'language', 'rating', 'green_score'];
        this.data = JSON.parse(JSON.stringify(Constants.filters));
        this.state = {
            filter: JSON.parse(JSON.stringify(Constants.initialFilterState)),
        }
        this._handleClick = this._handleClick.bind(this);
        this._handleApply = this._handleApply.bind(this);
    }

    componentDidMount() {
        console.log('filters', this.props);
        this.setState({
            filter: this.props.filters
        });
        const {tour_types, type_categories, categories, languages} = this.props.filterList;
        this.data['language'].data = languages;
        this.data['tour_type'].data = tour_types;
        this.data['category'].data = categories;
        this.data['type_category'].data = type_categories;
    }

    _handleClick(type, value) {
        const temp = type in this.state.filter ? this.state.filter[type] : null;
        const filter = this.state.filter;
        if (temp != null) {
            if(Array.isArray(temp)){
                var index = null;
                filter[type].some((val,i)=> {
                    if(val == value) {
                        index = i;
                        return true
                    }
                });
                if(index != null){
                    filter[type].splice(index,1)
                }
                else {
                    filter[type].push(value);
                }
            }
            else {
                filter[type] = value;
            }
            console.log(filter);
            this.setState({
                filter: filter,
            });
        }
    }

    _handleApply () {
        this.props.handleChange(this.state.filter)
    }

    _renderFilters(){
        return this.filtersOrders.map((val,index)=> {
            if(val !='price'){
                const tempData = this.data[val];
                return(
                    <div>
                        <label className={styles.labelHeading}>
                            {tempData.title}
                        </label>
                        <ul className={styles.main}>
                            {tempData.data.map((dataVal,index)=> {
                                const categoryType = val;
                                const checked = ((this.state.filter[categoryType]).indexOf(dataVal.value) >= 0 ? true : false)
                                return (
                                    <Mini
                                     key={Math.random()+index}
                                     val={dataVal}
                                     handleClick={this._handleClick}
                                     category={categoryType}
                                     checked ={checked}
                                    />
                                )
                            })}
                        </ul>
                    </div>
                )
            }
        })
    }
    render(){
        return(
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    TransitionComponent={Transition}
                >
                    <div className={styles.outerFlex}>
                        <div className={styles.innFlex}>
                            <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <div style={{fontWeight: '700'}}>
                                Filters
                            </div>
                        </div>
                        <div style={{marginRight: '10px'}}>
                            <ButtonBase onClick={() => {this.props.handleClear('ALL') }}>
                                Clear
                            </ButtonBase>
                        </div>
                    </div>

                    <div style={{padding: '10px'}}>
                        {this._renderFilters()}
                        <div className={styles.listButton}>
                            <ButtonBase
                                className={styles.view} onClick={this._handleApply}>
                                Apply
                            </ButtonBase>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default FiltersDialog