import React, {Component} from 'react';
import Constants from '../../../config/constants';
import styles from './ReviewsCard.module.css'
import {convertDurationToString} from "../../../libs/general.utils";
import { Update as Duration, LocationOn} from '@material-ui/icons';
import ButtonBase from "@material-ui/core/ButtonBase";
import Rating from '@material-ui/lab/Rating';

class ReviewsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    render(){
        // const { data } = this.props;
        return(
            <div className={styles.cardContainer} >
            <div  className={styles.outerFlex}>
                <div className={styles.innerFlex}>
                <div>
                    <div className={styles.tourImage} style={{
                        backgroundImage: 'url(' + require('../../../assets/img/tuxi/tourA.png') + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                    </div>
                </div>



                <div className={styles.tourDetailContainer}>
                    <div>
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <div className={styles.tourHeading}>
                            {/*{data.tour_name}*/}
                            Eiffel Tower Guided Summit
                        </div>
                            <div className={styles.fromText}>
                                <Rating value={'5'} readOnly className={styles.reviewRating}/>
                            </div>
                        </div>
                        <div className={styles.scheduleFlex}>
                            <div className={styles.duration}>
                                <img src={require('../../../assets/img/tuxi/ic_calender.png')} style={{height:'15px'}}/>
                                <span className={styles.timePeriod}>
                                    {/*{data.booking_date}*/}
                                    22 June 2020 - 12:00 PM
                                    </span>
                            </div>
                        </div>
                        <div className={styles.locationFlex}>
                            <div className={styles.duration}>
                               <p style={{textAlign:'justify'}}>
                                   It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                   The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                   Many desktop publishing packages and web page editors now use Lorem Ipsum.
                               </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div>


                </div>
            </div>

            </div>
        )
    }
}

export default ReviewsCard
