/**
 * Created by charnjeetelectrovese@gmail.com on 10/30/2019.
 */
export default {
    board_now: '/board/now',
    board_later: '/board/later',
    home: '/home',
    pharmaceuticals: '/pharmaceuticals',
    details: '/details',
    gallery: '/gallery',
    driver_profile: '/driver/profile',
    all_reviews: '/reviews',
    payment: '/payment',
    cart: '/cart',
    blogs: '/blogs',
    quote: '/quote',
    queries: '/queries',
    industry: '/industries',
    manufacturer: '/manufacturer',
    blog_detail: '/blogs/detail',
    payment_success: '/payment/confirm',
    cities: '/cities',
    cities_list: '/city/list',
    wishlist: '/wishlist',
    support: '/support',
    ticket: '/ticket',
    ride: '/ride',
    about: '/about',
    bookings: '/bookings',
    thank_you:'/thankyou',
    ride_list: '/rides',
    ride_detail: '/rides/detail',
    later_search: '/later/search',
    contact: '/contact',
    favourite: '/favourite',
    chats: '/chats',
    conversations: '/conversations',
    ticketView: '/ticketView',
    user_profile: '/user/profile',
    login: '/login',
    signup:'/signup',
    profile: '/profile',
    product: '/search',
    privacy:'/privacy',
    terms:'/terms',
    social_login:'/social/login',
    email_login: '/email/login',
    otp:'/otp',
    card: '/cards',
    list: '/list',
    description: '/description',
    report:'/report',
    rating: '/rating',
    tours: '/tours/',
    booking_success: '/booking/success/',
    index: '/',

};
