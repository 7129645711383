import React, {Component} from 'react';
import {Room, } from '@material-ui/icons';
import classnames from 'classnames';
import styles from './Ride.module.css';
import RouteNames from '../../../routes/Route.name';
import {TourMap } from '../../../components/index.component';

class Ride extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          is_full: false,
        };
        this._handleTextClick = this._handleTextClick.bind(this);
        this._handleEndLocation = this._handleEndLocation.bind(this);
    }

    _handleTextClick() {
        this.setState({
            is_full: true,
        });
        // this.props.history.push(RouteNames.ride_list);
    //    comment
    }

    _handleEndLocation() {
        this.props.history.push(RouteNames.ride_list);
    }
    // _renderUpperHome () {
    //         return (
    //             <div style={{height:'calc(50vh)'}}>
    //                 <TourMap/>
    //             </div>
    //         )
    // }


    render() {
        return (
            <div>
                <div style={{ background: "url("+require('../../../assets/img/map.png')+")", height: '100vh', width: '100%'}}>
                </div>
                {/*{this._renderUpperHome()}*/}
                <div className={classnames(styles.lowerPanel, (this.state.is_full ? styles.lowerFull : ''))}>
                    <h4 className={styles.heading}>Hello,Welcome</h4>
                    <hr/>
                    <div>
                        <div className={classnames(styles.container, (this.state.is_full ? styles.singleInputHide : ''))}>
                            <img className={styles.image} src={require('../../../assets/img/ic_car@3x.png')}/>
                            <input onClick={this._handleTextClick} className={styles.place} type="text" name="To"
                                   placeholder="Where to ?"/>
                        </div>
                        <div className={classnames(styles.findContainer, (this.state.is_full ? styles.findShow : ''))}>
                            <div style={{display:'flex',alignItems:'center'}}>
                            <div>
                                <img src={require('../../../assets/img/ic_car@3x.png')} className={styles.img}/>
                            </div>
                            <div style={{marginLeft:'8px'}}>Find a Ride</div>
                            </div>
                            <div>
                                <input className={styles.destination} type="text" name="To"
                                       placeholder="Leaving From"/>
                                <input onClick={this._handleEndLocation} className={styles.destination} type="text" name="To"
                                       placeholder="Leaving To"/>
                            </div>
                        </div>
                        <div className={classnames(styles.location, styles.container)}>
                            <div className={styles.icons}>
                                <Room size="small" className={styles.icon}/>
                            </div>
                            <div className={classnames(styles.country)}>
                                Lisbon
                                <div className={styles.city}>Portugal</div>
                                <div style={{marginTop: '14px'}}>
                                    <hr/>
                                </div>
                            </div>
                        </div>

                        <div className={classnames(styles.location, styles.container)}>
                            <div className={styles.icons}>
                                <Room size="small" className={styles.icon}/>
                            </div>
                            <div className={classnames(styles.country)}>
                                Braga
                                <div className={styles.city}>Portugal</div>
                                <div style={{marginTop: '14px'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Ride;
