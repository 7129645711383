import React, {Component} from 'react';
import {ButtonBase, withTheme } from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons'
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import styles from './Checkout.module.css';
import Rating from '@material-ui/lab/Rating';
import DateUtils from '../../libs/DateUtils.lib';
import {TotalSidebar, WaitingComponent} from '../../components/index.component';
import EventEmitter from "../../libs/Events.utils";
import RouteNames from "../../routes/Route.name";
import { actionCheckout } from '../../actions/Cart.action';
import MediaQuery from 'react-responsive';
import classNames from "classnames";

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleProceed = this._handleProceed.bind(this);
        this._handleBack = this._handleBack.bind(this);
        this._handlePaymentClick = this._handlePaymentClick.bind(this);
    }

    _handleBack() {
        this.props.history.goBack();
    }

    _handleProceed() {

    }

    _renderEcoImage() {
        // const { data } = this.props;
        const eco_friendly = this.props.eco_friendly;
        let totalPoints = 0;
        Object.keys(eco_friendly).forEach((val) => {
            if (eco_friendly[val]) {
                totalPoints++;
            }
        });
        return (<img src={require('../../assets/img/eco/ic_eco'+totalPoints+'.png')} className={styles.ecoIcon}/>);
    }

    _renderBack() {
        const { theme } = this.props;
        return (
            <div className={styles.arrowFlex}>
                <ButtonBase onClick={this._handleBack}>
                    <div className={styles.backText} >
                        <ArrowBack/>
                        <span style={{ color: theme.linkColor }}>Go back to Details</span>
                    </div>
                </ButtonBase>
            </div>
        )
    }

    _renderPax() {
        const { cart } = this.props;
        let text = '';
        cart.passengerPrices.forEach((val, index) => {
            text += `${val.number} ${val.title}`;
            if (index+1 != cart.passengerPrices.length) {
                text += ', '
            }
        });
        return text;
    }

    _handlePaymentClick() {
        const { is_authenticated } = this.props;
        if (is_authenticated) {
            this.props.actionCheckout();
        } else {
            EventEmitter.dispatch(EventEmitter.OPEN_LOGIN_DIALOG, {});
        }
    }

    render() {
        const { cart } = this.props;
        if (cart) {
            return (
                <div className={'container'}>
                    <MediaQuery minDeviceWidth={768}>
                        <div style={{marginTop:'80px'}}></div>
                        {this._renderBack()}
                    </MediaQuery>

                    <MediaQuery maxDeviceWidth={768}>
                        <div style={{marginTop:'70px'}}></div>
                        <div className={styles.centerHeading}>
                            Your Cart
                        </div>
                    </MediaQuery>

                    <MediaQuery minDeviceWidth={768}>
                    <div className={styles.outerFlex}>
                        <div>
                            {this._renderEcoImage()}
                            {/*<img src={require('../../assets/img/tuxi/ic_eco5.png')} className={styles.ecoIcon}/>*/}
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.tourHeading}>
                                {cart.tour_name}
                            </div>
                            <div>
                                <Rating value={4} readOnly className={styles.starRating}/>
                            </div>
                        </div>
                    </div>
                    </MediaQuery>

                    <div className={styles.flexContainer}>
                        <div className={styles.tourFlex}>
                            <div className={styles.cardContainer}>
                                We are listed as best seller of this tour.Enjoy your trips with lots of fun & activities
                            </div>

                            <MediaQuery maxDeviceWidth={768}>
                                <div className={styles.outerFlex}>
                                    <div>
                                        {/*<img src={require('../../assets/img/tuxi/ic_eco5.png')} className={styles.ecoIcon}/>*/}
                                        {this._renderEcoImage()}
                                    </div>
                                    <div className={styles.textContainer}>
                                        <div>
                                            <Rating value={4} readOnly className={styles.starRating}/>
                                        </div>
                                        <div className={styles.tourHeading}>
                                            {cart.tour_name}
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>

                            <div>
                                <h3>Customer Details</h3>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>Date & Time</div>
                                    <div
                                        className={styles.rightText}> {DateUtils.changeTimeStamp(cart.selectedDate, 'DD-MM-YYYY')}
                                        <span
                                            style={{marginLeft: '10px'}}>{cart.time_slot} </span></div>
                                </div>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>Language</div>
                                    <div className={styles.rightText}> {cart.language}</div>
                                </div>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>No. Of Pax</div>
                                    <div className={styles.rightText}> {this._renderPax()}</div>
                                </div>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>Pickup Location</div>
                                    <div className={styles.rightText}>{cart.pickup}</div>
                                </div>
                            </div>

                            <div>
                                <h3>Tour Details</h3>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>Date & Time</div>
                                    <div
                                        className={styles.rightText}> {DateUtils.changeTimeStamp(cart.selectedDate, 'DD-MM-YYYY')}<span
                                        style={{marginLeft: '10px'}}>{cart.time_slot}  </span></div>
                                </div>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>Language</div>
                                    <div className={styles.rightText}>  {cart.language}</div>
                                </div>
                                <div className={styles.bottomFlex}>
                                    <div className={styles.leftText}>No. Of Pax</div>
                                    <div className={styles.rightText}> {this._renderPax()}</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.totalFlex}>
                            <TotalSidebar currency={cart.currency_shortcode} handlePaymentClick={this._handlePaymentClick} passengers={cart.passengerPrices}/>
                            <div className={styles.bottomText}>
                                Zero Cancellation fee up to 24 hours
                                subject to acceptance by provider
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<WaitingComponent/>)
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionCheckout: actionCheckout
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        is_authenticated: state.auth.is_authenticated,
        eco_friendly: state.tours.selected_tour.eco_friendly,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Checkout));
