import React, {Component} from 'react';
import styles from './CustomDropdown.module.css';
import {ButtonBase, withStyles} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons'
import RouteNames from '../../routes/Route.name';
import csx from 'classnames';

class CustomDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isMounted: false,
        }
        this._handleButtonClick = this._handleButtonClick.bind(this);
        this._handleClickOutSide = this._handleClickOutSide.bind(this);
        this._handleLinkClick = this._handleLinkClick.bind(this);
    }

    componentDidMount() {
        if (!this.state.isMounted) {
            window.document.addEventListener('click', this._handleClickOutSide, false);
            this.setState({
                isMounted: true,
            });
        }
    }

    componentWillUnmount() {
        // this.node && this.node.blur();
        window.document.removeEventListener('click', this._handleClickOutSide, false);
    }


    _handleClickOutSide(e) {
        if (this.node) {
            if (!this.node.contains(e.target) && !(this.hasSomeParentTheClass(e.target, 'parentDrop'))) {
                this.setState({
                    open: false,
                })
                // const dom = ReactDOM.findDOMNode(this);
                // console.log(dom.getBoundingClientRect());
            }
        }
    }

    hasSomeParentTheClass(element, classname) {
        if (element.className && typeof element.className == 'string' && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
    }


    _handleButtonClick() {
        this.setState({
            open: !this.state.open
        })
    }

    _handleLinkClick(link) {
        this.setState({
            open: !this.state.open
        });
        this.props.handleClick(link);
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div ref={node => this.node = node} className={csx(styles.container, 'parentDrop')}>
                    <ButtonBase className={styles.myAccount} onClick={this._handleButtonClick}>
                        <div className={styles.accountButton}>
                            <img className={styles.logo} src={require('../../assets/img/tuxi/man-users.svg')}/>
                            <span className={styles.textAccount}>My Account</span>
                            <ExpandMore/>
                        </div>
                    </ButtonBase>
                    {this.state.open && (
                        <div className={csx(styles.list,)}>
                            <ul className={styles.menu}>
                                <li className={styles.text}>
                                    <ButtonBase onClick={this._handleLinkClick.bind(this, (RouteNames.bookings))}>
                                        <div className={styles.accountFlex}>
                                            <div>
                                                <img className={styles.accountIcon}
                                                     src={require('../../assets/img/tuxi/blogs.png')}/>
                                            </div>
                                            <div>
                                                <div className={styles.heading}>
                                                    My Reviews
                                                </div>
                                                <div className={styles.innerText}>See your reviews given to trips or
                                                    yours
                                                </div>
                                            </div>
                                        </div>
                                    </ButtonBase>
                                </li>

                                <li className={styles.text}>
                                    <ButtonBase onClick={this._handleLinkClick.bind(this, (RouteNames.bookings))}>
                                        <div className={styles.accountFlex}>
                                            <div>
                                                <img className={styles.accountIcon}
                                                     src={require('../../assets/img/tuxi/bill.png')}/>
                                            </div>
                                            <div>
                                                <div className={styles.heading}>
                                                    My Bookings
                                                </div>
                                                <div className={styles.innerText}>See your reviews given to trips or
                                                    yours
                                                </div>
                                            </div>
                                        </div>
                                    </ButtonBase>
                                </li>

                                <li className={styles.text}>
                                    <ButtonBase onClick={this._handleLinkClick.bind(this, (RouteNames.bookings))}>
                                        <div className={styles.accountFlex}>
                                            <div>
                                                <img className={styles.accountIcon}
                                                     src={require('../../assets/img/tuxi/man-user.png')}/>
                                            </div>
                                            <div>
                                                <div className={styles.heading}>
                                                    My Profile
                                                </div>
                                                <div className={styles.innerText}>See your reviews given to trips or
                                                    yours
                                                </div>
                                            </div>
                                        </div>
                                    </ButtonBase>
                                </li>

                                <li className={styles.text}>
                                    <ButtonBase className={classes.buttonBase} onClick={this._handleLinkClick.bind(this, 'logout')}>
                                        <div className={styles.accountFlex}>
                                            <div>
                                                <img className={styles.accountIcon}
                                                     src={require('../../assets/img/tuxi/logout.png')}/>
                                            </div>
                                            <div>
                                                <div className={styles.heading}>
                                                    Logout
                                                </div>
                                            </div>
                                        </div>
                                    </ButtonBase>
                                </li>

                            </ul>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const useStyles = (theme) => ({
    buttonBase: {
        width: '100%',
        justifyContent: 'flex-start'
    }
});
export default withStyles(useStyles)(CustomDropdown);
