import React, { Component } from 'react';
import styles from '../Footer.module.css'
import {connect} from "react-redux";
import {withTheme} from "@material-ui/core";
import {bindActionCreators} from "redux";
import CollapseMobile from '../component/CollapseMobile.component';
import {Link} from 'react-router-dom';


class FooterCollapse extends Component {
    renderLinksLeft(){
        return(
            <div>
                <CollapseMobile title={'Support'}>
                    <ul className={styles.footerList}>
                        <li>
                            <a href="">Contact</a>
                        </li>
                        <li>
                            {/*<a href="">Report</a>*/}
                            <Link to ='/about'>About</Link>
                        </li>
                        <li>
                            <Link to ='/support'>Company Support </Link>
                        </li>
                        <li>
                            <a href="">Legal</a>
                        </li>
                    </ul>
                </CollapseMobile>
            </div>
        )
    }

    _renderLinksCenter(){
        return(
            <div>
                <CollapseMobile title={'Privacy Policy'}>
                    <ul className={styles.footerList}>
                        <li>
                            <Link to ='/privacy'>Privacy Statement </Link>
                        </li>
                        <li>
                            <a href="">Cancellation Policy</a>
                        </li>
                        <li>
                            <a href="">Third Party Cookies</a>
                        </li>
                    </ul>
                </CollapseMobile>
            </div>
        )
    }

    _renderLinksRight(){
        return(
            <div>
                <CollapseMobile title={'Contact'}>
                    <ul className={styles.footerList}>
                        <li>
                            <a href="">Payment & Refund</a>
                        </li>
                        <li>
                            <a href="">Cancellation</a>
                        </li>
                        <li>
                            <a href="">Booking Modification</a>
                        </li>
                    </ul>
                </CollapseMobile>
            </div>
        )
    }
    render(){
        return(
            <div>
                {this.renderLinksLeft()}
                {this._renderLinksCenter()}
                {this._renderLinksRight()}
            </div>
        )
    }
}

export default FooterCollapse;