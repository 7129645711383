/**
 * Created by charnjeetelectrovese@gmail.com on 12/13/2018.
 */
import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classNames from "classnames";
import styles from './Login.module.css';
import {
    renderTextField,
    renderSelectField,
    renderNewSelectField
} from '../../libs/redux-material.utils';
import {Button, withStyles, ButtonBase,MenuItem} from '@material-ui/core';
import {actionLoginUser} from '../../actions/Auth.action';
import DashboardSnackbar from "../../components/Snackbar.component";
import {Link} from 'react-router-dom';
import RouteNames from '../../routes/Route.name';

import EventEmitter from "../../libs/Events.utils";
import {updateTitle} from "../../libs/general.utils";

const contactNormalize = (value, prevValue) => {
    if (value.length > 10) {
        return prevValue
    }
    return value;
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password','first_name','last_name'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}


const useStyles = {
    btnColor: {
        backgroundColor: 'white',
        marginTop: '20px',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    btnBottom: {
        backgroundColor: 'white',
        paddingLeft: '20px',
        color: '#2196F3',
        marginRight: '10px',
        marginLeft: '15px',
        paddingRight: '20px',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    dialog: {
        padding: '10px 25px'
    },
    colorButton: {
        padding: '8px 35px',
        color: 'white',
        backgroundImage: 'linear-gradient(to right, #04AFB7 , #1BB380)',
        '&:hover': {
            color: 'white',
            backgroundColor:'linear-gradient(to right, #04AFB7 , #1BB380)',
        }
    },
    signUp: {
        color: '#2CB5A5',
        marginLeft: '10px',
        textDecoration:'none',
        fontWeight:'600'
    }

};

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: 'order',
            selected: 'ORDER'
        };
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleForgotPassword = this._handleForgotPassword.bind(this);
        this._handleRoute = this._handleRoute.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleDescribe = this._handleDescribe.bind(this)
    }

    // async componentDidMount() {
    //     updateTitle('Login');
    // }

    _handleSubmit(data) {
        this.props.history.push(RouteNames.otp)
        // serviceLoginUser(data).then((val) => {
        //     if (!val.error) {
        //         this.props.actionLoginUser(val.data);
        //     } else {
        //         EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Invalid Username/Password', type: 'error'});
        //     }
        // });
    }

    _handleForgotPassword() {
        this.props.history.push('/forgot/password');
    }

    _handleRoute(){
        this.props.history.push('/login');
    }

    _handleChange(e){
        this.setState({
            is_checked: e.target.value
        })
    }

    _handleDescribe(type){
        this.setState({
            selected: type
        })
    }

    render() {
        const {handleSubmit, classes} = this.props;
        const {selected} = this.state
        return (
            <div className={styles.mainLoginView}>
                <div className={styles.loginFlex1}>
                    {/*<div style={{color: 'white', fontSize: '30px', fontWeight: '500', textAlign: 'center'}}>*/}

                    {/*</div>*/}


                    <div style={{paddingLeft:'100px'}}>
                        <div className={styles.mainHeading}>
                            Welcome to India's first<br/>
                            e-manufacturing hub
                        </div>
                        {/*<br/>*/}

                        <div className={styles.list}>
                            <ul className={styles.circle}>
                                <li className={styles.val}>5000+ products portfolio</li>
                                <li className={styles.val}>Verified customers and manufactures</li>
                                <li className={styles.val}>Payment protection plan</li>
                                <li className={styles.val}>Quality assurance with proprietary checks</li>
                                <li className={styles.val}>Easy EMI based payment options</li>
                                <li className={styles.val}>Track your orders progress in realtime</li>
                            </ul>
                        </div>

                        <br/>
                        <br/>
                        <div>
                            <div className={styles.noacct}>Already Registered?</div>

                            <ButtonBase className={styles.looking} onClick={this._handleRoute}>
                                Login
                            </ButtonBase>
                        </div>

                        <div className={styles.bottomContainer}>

                        </div>
                    </div>
                </div>
                <div className={styles.loginFlex2}>
                    {/*<div>*/}
                    {/*    <div style={{textAlign: 'center',marginTop:'40px'}}>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={styles.marginCenter}>
                        <h1 className={styles.headingText}>We're almost set Pranav</h1>
                        <div className={styles.little}>Tell a little more about yourself so that we can refine your experience</div>

                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            {/*<div className={styles.loginSignupText}>Login</div>*/}
                            <div>
                                <div className={'formFlex'} style={{alignItems:'center'}}>
                                    <div className={styles.code}>
                                        +91
                                    </div>
                                    <div className={'formGroup'}>
                                        <Field fullWidth={true} name="contact"
                                               component={renderTextField}
                                               normalize={contactNormalize}
                                               // margin={'dense'}
                                               type={'number'}
                                               label="Enter your phone number"/>
                                    </div>
                                </div>
                                <br/>

                                <div className={'formFlex'}>
                                    <div style={{flex:1}}>
                                        <Field fullWidth={true}
                                               name="contact"
                                               component={renderNewSelectField}
                                            // margin={'dense'}
                                               label="Which Industry are you from ?">
                                            <MenuItem value={'Pharmaceuticals'}>Pharmaceuticals</MenuItem>
                                        </Field>
                                    </div>
                                </div>
                                <br/>

                                <p className={styles.best}><b>What best describes you ?</b></p>

                                <div className={styles.radioBtn} onClick={this._handleDescribe.bind(this,'ORDER')}>
                                    <input type="radio" id="order" name="order" checked={selected == 'ORDER'} className={styles.radio}/>
                                    <label for="order">
                                        <img src={selected == 'ORDER' ? require('../../assets/img/factory/radio/manager_w.png') :require('../../assets/img/factory/radio/manager.png')} height={50}/>
                                        <div className={styles.newText}>I'm here to Order</div>
                                    </label>
                                </div>

                                <div className={styles.radioBtn} style={{marginLeft:'15px'}} onClick={this._handleDescribe.bind(this,'MANUFACTURER')}>
                                    <input type="radio" id="manufacturer" name="order" checked={selected == 'MANUFACTURER'} value="" className={styles.radio}/>
                                    <label htmlFor="manufacturer">
                                        <img src={selected == 'MANUFACTURER' ? require('../../assets/img/factory/radio/chimney_w.png')  :require('../../assets/img/factory/radio/chimney.png')} height={50}/>
                                        <div className={styles.newText}>I'm a Manufacturer</div>
                                    </label>
                                </div>

                                <br/>
                                <br/>
                                <div className={styles.sign}>
                                    <ButtonBase  type="submit" className={styles.login}>
                                        Proceed
                                    </ButtonBase>
                                </div>
                            </div>
                        </form>
                        <br/>
                        <div className={styles.privacy}>
                            We will be sending OTP in order to verify your phone no.
                        </div>
                    </div>

                    <div>

                    </div>

                </div>
                <DashboardSnackbar/>
            </div>
        );
    }
}

SignUp = reduxForm({
    form: 'LoginPage',  // a unique identifier for this form
    validate,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Login Credentials', type: 'none'});
    //
    // }
})(SignUp);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionLoginUser: actionLoginUser
    }, dispatch);
}


export default connect(null, mapDispatchToProps)(withStyles(useStyles)(SignUp));
