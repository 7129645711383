import React, {Component} from 'react';
import Constants from '../../../config/constants';
import styles from './BookingCard.module.css'
import {convertDurationToString} from "../../../libs/general.utils";
import { Update as Duration, LocationOn} from '@material-ui/icons';
import ButtonBase from "@material-ui/core/ButtonBase";
import MediaQuery from 'react-responsive';
import classNames from "classnames";

class BookingCard extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    render(){
        const { data } = this.props;
        return(
            <div className={styles.cardContainer} >
            <div  className={styles.outerFlex}>
                <div className={styles.innerFlex}>
                <div>
                    <div className={styles.tourImage} style={{
                        backgroundImage: 'url(' + data.tour_image + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}>
                    </div>
                </div>



                <div className={styles.tourDetailContainer}>
                    <div>
                        <div className={styles.tourHeading}>
                            {data.tour_name}
                        </div>
                        <div className={styles.booking}>
                            Booking Id: {data.booking_code}
                        </div>
                        <div className={styles.scheduleFlex}>
                            <div className={styles.duration}>
                                <img src={require('../../../assets/img/tuxi/ic_calender.png')} style={{height:'15px'}}/>
                                <span className={styles.timePeriod}>{data.booking_date}</span>
                            </div>
                            <div className={classNames(styles.duration,styles.extraSpace)}>
                                <Duration className={styles.durationIcon}/>
                                <span className={styles.timePeriod}>{convertDurationToString(data.duration)}</span>
                            </div>
                        </div>
                        <hr/>
                        <div className={styles.locationFlex}>
                            <div className={styles.duration}>
                                <img src={require('../../../assets/img/tuxi/ic_location.png')} style={{height:'15px'}}/>
                                <span className={styles.locationText}>{data.pickup_loc.name}</span>
                            </div>
                            <div>
                                {/*<ButtonBase className={styles.mapButton}>*/}
                                {/*    View on Map*/}
                                {/*</ButtonBase>*/}
                            </div>
                        </div>
                        <div className={styles.confirmedIcon}>
                            <img src={require('../../../assets/img/tuxi/bill.png')} style={{height:'15px'}}/>
                            <span className={styles.confirmed}>{Constants.BOOKING_STATUS_TEXT[data.status]}</span>
                        </div>
                    </div>
                </div>
                </div>


                <div>
                    <MediaQuery minDeviceWidth={768}>
                        <div className={styles.fromText}>
                            Total Price
                        </div>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={768}>
                        <div className={styles.fromText}>
                            From
                        </div>
                    </MediaQuery>
                    <div className={styles.price}>
                        <span>{data.price_data.formatted_price}</span>
                    </div>
                </div>

            </div>

                <div className={styles.buttonsFlex}>
                    <MediaQuery minDeviceWidth={768}>
                    <div>
                        <ButtonBase className={styles.bookingButton} style={{borderRight: '2px solid #1BB380'}}>
                            View Booking Voucher
                        </ButtonBase>
                    </div>
                    </MediaQuery>

                    <MediaQuery maxDeviceWidth={768}>
                        <div>
                            <ButtonBase className={styles.bookingButton} style={{borderRight: '2px solid #1BB380'}}>
                                View  Voucher
                            </ButtonBase>
                        </div>
                    </MediaQuery>
                    <div >
                        <ButtonBase className={styles.bookingButton}  style={{borderRight: '2px solid #1BB380'}}>
                            Cancel Booking
                        </ButtonBase>
                    </div>
                    <div>
                        <ButtonBase className={styles.bookingButton}>
                            Raise a Request
                        </ButtonBase>
                    </div>
                </div>
            </div>
        )
    }
}

export default BookingCard
