import React,{Component} from 'react'
import styles from './BlogDetail.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';
import Portfolio from "../../Portfolio/Portfolio.view";
import DiscoverForm from "../../Discover/DiscoverForm.view";
import Rfq from "../../RFQ/Rfq.view";

class BlogDetail extends Component{

    render(){
        return(
            <div>
                <div style={{height:'40px'}}></div>
            <div className={'ncontainer'}>
                <div className={styles.blogFlex}>
                    <div className={styles.detailFlex}>
                        <img src={require('../../../assets/img/factory/blodet.png')} className={styles.blogImage}/>
                        <div className={styles.innerFlex}>
                            <div className={styles.innerFlex}>
                                <img src={require('../../../assets/img/factory/user_icon.png')}/>
                                <span className={styles.name}>Pranav Bhasin</span>
                            </div>
                            <div className={styles.innerFlex} style={{marginLeft:'40px'}}>
                                <img src={require('../../../assets/img/factory/clander.png')}/>
                                <span className={styles.name}>May 06,2020</span>
                            </div>
                        </div>

                        <div className={styles.title}>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos
                            dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
                        </div>
                        <hr className={styles.dot}/>
                            <div className={styles.socialFlex}>
                                <span>Shares</span>
                                <img src={require('../../../assets/img/factory/facebook.png')} className={styles.ico}/>
                                <img src={require('../../../assets/img/factory/instagram.png')} className={styles.ico}/>
                                <img src={require('../../../assets/img/factory/twitter.png')} className={styles.ico}/>
                            </div>
                        <hr className={styles.dot}/>
                        <p style={{fontSize:'0.8rem'}}>
                            On the other hand, we denounce with righteous indignation and dislike men
                            who are so beguiled and demoralized by the charms of pleasure of the moment,
                            <br/>
                            so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue;
                            and equal blame belongs to those who fail in their duty through weakness of will, which is the same as
                            saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
                            <br/>
                            In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do
                            what we like best, every pleasure is to be welcomed and every pain avoided.
                            But in certain circumstances and owing to the claims of duty or
                            <br/>
                            <br/>
                            On the other hand, we denounce with righteous indignation and dislike men
                            who are so beguiled and demoralized by the charms of pleasure of the moment,
                            <br/>
                            so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue;
                            and equal blame belongs to those who fail in their duty through weakness of will, which is the same as
                            saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish.
                            <br/>
                            In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do
                            what we like best, every pleasure is to be welcomed and every pain avoided.
                            But in certain circumstances and owing to the claims of duty or
                        </p>
                    </div>
                    <div className={styles.serachFlex}>
                        <div>
                            <input type="text" className={styles.inputSearch} placeholder={"Search"} />
                        </div>
                        <div className={styles.catContainer}>
                            <div className={styles.category}>Categories</div>
                            <div className={styles.type}>Categorie Type 1</div>
                            <div className={styles.type}>Categorie Type 2</div>
                            <div className={styles.type}>Categorie Type 3</div>
                            <div className={styles.type}>Categorie Type 4</div>
                            <div className={styles.type}>Categorie Type 5</div>
                        </div>

                        <div className={styles.prem}>
                            <img src={require('../../../assets/img/factory/tab.png')} className={styles.tab}/>
                            <div className={styles.bottomCont}>
                                <span className={styles.ben}>BENEFITS</span><br/>
                                of Premium services

                                <div className={styles.bottomFlex}>
                                    <div className={styles.innerFlex} style={{marginBottom:'10px'}}>
                                        <img src={require('../../../assets/img/factory/plus.png')}/>
                                        <span className={styles.more}>More than 25+ quotations at a time</span>
                                    </div>
                                    <div className={styles.innerFlex} style={{marginBottom:'10px'}}>
                                        <img src={require('../../../assets/img/factory/plus.png')}/>
                                        <span className={styles.more}>Express Delivery at simply no cost</span>
                                    </div>
                                    <div className={styles.innerFlex} style={{marginBottom:'10px'}}>
                                        <img src={require('../../../assets/img/factory/plus.png')}/>
                                        <span className={styles.more}>Faster response for your request</span>
                                    </div>
                                    <div className={styles.innerFlex} style={{marginBottom:'10px'}}>
                                        <img src={require('../../../assets/img/factory/plus.png')}/>
                                        <span className={styles.more}>EMI for all orders above 50k</span>
                                    </div>
                                    <div className={styles.innerFlex} style={{marginBottom:'10px'}}>
                                        <img src={require('../../../assets/img/factory/plus.png')}/>
                                        <span className={styles.more}>Free 3rd party COA tests</span>
                                    </div>
                                </div>

                                <div style={{textAlign:'center'}}>
                                    <ButtonBase className={styles.looking}>
                                        Go Premium
                                    </ButtonBase>
                                </div>
                            </div>
                        </div>

                        <div className={styles.hire}>
                            <div>Hire Us for Negotiation</div>
                            <div className={styles.text}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry
                            </div>
                            <div>
                                <ButtonBase className={styles.buy}>
                                    Buy Now
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <Portfolio/>
                <div className={'ncontainer'}>
                    <div className={styles.marketplaceFlex}>
                        <div className={styles.desc} style={{marginRight:'25px'}}>
                            <DiscoverForm/>
                        </div>
                        <div className={styles.desc}>
                            <Rfq/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default BlogDetail