
import {
    SYNC_WISHLIST,
    TOGGLE_WISHLIST,
    WISHLIST_RESET,
    WISHLIST_SET
} from '../actions/Wishlist.action';

const initialState = {
    list:[],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_WISHLIST: {
            const temp = state.list ? JSON.parse(JSON.stringify(state.list)) : [];
            const productId = action.payload;
            const index = temp.indexOf(productId);
            if (index >= 0) {
                temp.splice(index, 1);
            } else {
                temp.push(productId);
            }
            return {
                ...state,
                list: temp,
            }
        }
        case WISHLIST_RESET: {
            return {
                ...state,
                list:[]
            }
        }
        case WISHLIST_SET: {
            return {
                ...state,
                list: action.payload,
            }
        }
        case SYNC_WISHLIST: {
            return  {
                ...state,
                list: action.payload,
            };
        }
        default: {
            return state;
        }
    }
}
