/**
 * Created by charnjeetelectrovese@gmail.com on 10/30/2019.
 */
import IndexView from '../views/index/Index.component';
import BoardNow from '../views/BoardNow/Board.component';
import BoardLater from '../views/BoardLater/BoardLater.component';
import RouteNames from '../routes/Route.name';
import Index from '../views/index/Index.component'

import HomeView from '../views/Home/Home.component';
import Pharma from '../views/Pharmaceuticals/Pharma.view';
import Login from '../views/Login/Login.view';
import Signup from '../views/Signup/SignUp.view';
import NewOtp from '../views/Otp/components/NewOtp.view';
import Industry from '../views/Industry/Industry.view';
import Blog from '../views/Blog/Blog.view';
import Contact from '../views/Contact/Contact.view';
import BlogDetail from '../views/Blog/BlogDetail/BlogDetail.view';
import Manufacturer from "../views/Manufacturer/Manufacturer.view";
import MyProfile from '../views/MyProfile/MyProfile.view';
import Product from '../views/Products/Product.view';
import Thankyou from '../views/Thankyou/Thankyou.view';
import Quote from '../views/Quotation/Quote.view';
import Queries from '../views/Queries/Queries.view';
import Profile from '../views/Login/NewLogin.view'
import DetailView from '../views/Detail/Description.component';
import DriverProfile from '../views/DriverProfile/Driver.component';
import AllReviews from '../views/ReviewPage/AllReviews.component';
import Payment from '../views/Payment/Payment.component';
import PaymentSuccess from '../views/PaymentSuccess/PaymentSucces.component';
import Cart from '../views/Cart/Cart.view';
import TicketView from '../views/Tickets/Tickets.component';
import RideView from '../views/Ride/Index/Ride.component';
import RideList from '../views/Ride/RideDetail/Rider.component';
import RideDetail from '../views/Ride/Detail/Confirm.component';
import LaterSearch from '../views/BoardLater/Tour/TourLater.component';
import ImageGallery from '../views/ImageGallery/ImageGallery.component'

import Favourite from '../views/UserProfile/Favourite.view'
import Ticket from '../views/Tickets/TicketList.view'
import Chats from '../views/Chats/Chat.view'
import Conversations from '../views/Chats/Conversation.view'
import UserProfile from "../views/UserProfile/UserProfile.view";


import Otp from '../views/Otp/Otp.view';
import Report from '../views/Report/Report.view'
import Ratings from '../views/Rating/Rating.view';
/* Tuxi */
import FullWidthCard from '../components/Paper/TourListCard/TourListCard.component';
import Bookings from '../components/Bookings/Booking.component';
import Cards from '../views/Cards/Cards.view';
import TourDetail from '../views/TourDetail/TourDetails.view';
import Checkout from '../views/Checkout/Checkout.view'
/* Desktop Views */
import HomeDesktop from '../views/Home/Desktop/HomeDesktop.view'
import DetailsDesk from '../views/Detail/Desktop/DetailsDesk.view'
import TourList from '../views/Listing/TourList.view';
import Privacy from '../views/Privacy/Privacy.component';
import Terms from '../views/Privacy/Terms.component';
import Blogs from '../views/Blogs/Blogs.view';

import Cities from "../views/Cities/Cities.view";
import Wishlist from "../views/Wishlist/Wishlist.view";
import Support from "../views/Support/Support.view";
import About from "../views/About/About.view";
import CityList from '../views/Cities/components/CityList/CityList.view'


const headerRoutes = [
    {
        path: '/',
        navbarName: 'Material Dashboard',
        component: IndexView,
    },
    // {
    //     path: RouteNames.signup,
    //     navbarName: 'Material Dashboard',
    //     component: Signup,
    // },
    // {
    //     path: RouteNames.login,
    //     navbarName: 'Material Dashboard',
    //     component: Login,
    // },
    // {
    //     path: RouteNames.profile,
    //     navbarName: 'Material Dashboard',
    //     component: Profile,
    // },
    // {
    //     path: RouteNames.otp,
    //     navbarName: 'Material Dashboard',
    //     component: NewOtp,
    // },
    // {
    //     path: RouteNames.industry,
    //     navbarName: 'Material Dashboard',
    //     component: Industry,
    // },
    // {
    //     path: RouteNames.contact,
    //     navbarName: 'Material Dashboard',
    //     component: Contact,
    // },
    // {
    //     path: RouteNames.manufacturer,
    //     navbarName: 'Material Dashboard',
    //     component: Manufacturer,
    // },
    // {
    //     path: RouteNames.blogs,
    //     navbarName: 'Material Dashboard',
    //     component: Blog,
    // },
    // {
    //     path: RouteNames.blog_detail,
    //     navbarName: 'Material Dashboard',
    //     component: BlogDetail,
    // },
    // {
    //     path: RouteNames.product,
    //     navbarName: 'Material Dashboard',
    //     component: Product,
    // },
    // {
    //     path: RouteNames.user_profile,
    //     navbarName: 'Material Dashboard',
    //     component: MyProfile,
    // },
    // {
    //     path: RouteNames.thank_you,
    //     navbarName: 'Material Dashboard',
    //     component: Thankyou,
    // },
    // {
    //     path: RouteNames.quote,
    //     navbarName: 'Material Dashboard',
    //     component: Quote,
    // },
    // {
    //     path: RouteNames.queries,
    //     navbarName: 'Material Dashboard',
    //     component: Queries,
    // },
    // {
    //     path: RouteNames.pharmaceuticals,
    //     navbarName: 'Material Dashboard',
    //     component: Pharma,
    // },



    //
    //
    //
    //
    // {
    //     path: RouteNames.wishlist,
    //     navbarName: 'Material Dashboard',
    //     component: Wishlist,
    // },
    // {
    //     path: RouteNames.blog_detail,
    //     navbarName: 'Material Dashboard',
    //     component: BlogDetail,
    // },
    // {
    //     path: RouteNames.support,
    //     navbarName: 'Material Dashboard',
    //     component: Support,
    // },
    // {
    //     path: RouteNames.about,
    //     navbarName: 'Material Dashboard',
    //     component: About,
    // },
    // {
    //     path: RouteNames.bookings,
    //     navbarName: 'Material Dashboard',
    //     component: Bookings,
    // },
    //
    // {
    //     path: RouteNames.otp,
    //     navbarName: 'Material Dashboard',
    //     component: Otp,
    // },
    // {
    //     path: RouteNames.signup,
    //     navbarName: 'Material Dashboard',
    //     component: SignUp,
    // },
    // {
    //     path: RouteNames.login,
    //     navbarName: 'Material Dashboard',
    //     component: SocialLogin,
    // },
    //
    // {
    //     path: RouteNames.board_now,
    //     navbarName: 'Material Dashboard',
    //     component: BoardNow,
    // },
    // {
    //     path: RouteNames.board_later,
    //     navbarName: 'Material Dashboard',
    //     component: BoardLater,
    // },
    // {
    //     path: RouteNames.home,
    //     navbarName: 'Material Dashboard',
    //     component: HomeView,
    //     desktopComponent: HomeDesktop
    // },
    // {
    //     path: RouteNames.details+'/:id',
    //     navbarName: 'Material Dashboard',
    //     component: TourDetail,
    //     // desktopComponent: DetailsDesk
    // },
    // {
    //     path: RouteNames.gallery,
    //     navbarName: 'Material Dashboard',
    //     component: ImageGallery,
    // },
    // {
    //     path: RouteNames.driver_profile+'/:id',
    //     navbarName: 'Material Dashboard',
    //     component: DriverProfile,
    // },
    // {
    //     path: RouteNames.all_reviews,
    //     navbarName: 'Material Dashboard',
    //     component: AllReviews,
    // },
    // {
    //     path: RouteNames.payment,
    //     navbarName: 'Material Dashboard',
    //     component: Checkout,
    // },
    // {
    //     path: RouteNames.cart,
    //     navbarName: 'Material Dashboard',
    //     component: Cart,
    //     is_protected: false
    // },
    // {
    //     path: RouteNames.payment_success,
    //     navbarName: 'Material Dashboard',
    //     component: PaymentSuccess,
    // },
    // {
    //     path: RouteNames.ticket+'/:id',
    //     navbarName: 'Material Dashboard',
    //     component: TicketView,
    // },
    // {
    //     path: RouteNames.ride,
    //     navbarName: 'Material Dashboard',
    //     component: RideView,
    // },
    // {
    //     path: RouteNames.ride_list,
    //     navbarName: 'Material Dashboard',
    //     component: RideList,
    // },
    // {
    //     path: RouteNames.ride_detail,
    //     navbarName: 'Material Dashboard',
    //     component: RideDetail,
    // },
    // {
    //     path: RouteNames.later_search,
    //     navbarName: 'Material Dashboard',
    //     component: LaterSearch,
    // },

    // {
    //     path: RouteNames.favourite,
    //     navbarName: 'Material Dashboard',
    //     component: Favourite,
    // },
    //
    // {
    //     path: RouteNames.list,
    //     navbarName: 'Material Dashboard',
    //     component: FullWidthCard,
    // },
    // {
    //     path: RouteNames.description,
    //     navbarName: 'Material Dashboard',
    //     component: TourDetail,
    // },
    // {
    //     path: RouteNames.ticketView,
    //     navbarName: 'Material Dashboard',
    //     component: Ticket,
    // },
    //
    // {
    //     path: RouteNames.chats,
    //     navbarName: 'Material Dashboard',
    //     component: Chats,
    // },
    // {
    //     path: RouteNames.conversations,
    //     navbarName: 'Material Dashboard',
    //     component: Conversations,
    // },
    // {
    //     path: RouteNames.rating,
    //     navbarName: 'Material Dashboard',
    //     component: Ratings,
    // },
    //
    // {
    //     path: RouteNames.report,
    //     navbarName: 'Material Dashboard',
    //     component: Report,
    // },
    // {
    //     path: RouteNames.user_Profile,
    //     navbarName: 'Material Dashboard',
    //     component: UserProfile,
    // },




    // {
    //   path: '/dashboard',
    //   sidebarName: 'User Profile',
    //   navbarName: 'Profile',
    //   icon: Person,
    //   component: DashboardPage2,
    // },
    // { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default headerRoutes;
