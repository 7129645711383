import React, {Component} from 'react';
import styles from './MyBooking.module.css'
import BookingCard from "./component/BookingCard.component";
import {serviceGetMyBookings} from "../../services/Booking.service";
import {WaitingComponent} from "../../components/index.component";

class MyBooking extends Component{
    constructor(props){
        super(props);

        this.state = {
            bookings: [],
            isGetting: true
        }
    }
    async componentDidMount() {
        const req = await serviceGetMyBookings();
        if (!req.error) {
            this.setState({
                bookings: req.data,
                isGetting: false,
            })
        }
        this.setState({
            isGetting: false,
        })
    }

    _renderBookings(){
        const { bookings } = this.state;
        if(bookings.length > 0){
            return(
                <div>
                    <h2>Upcoming Bookings</h2>
                    {bookings.map((val)=> {
                        return (<BookingCard data={val}/>)
                    })}
                </div>
            )
        }
        else {
            return(
                <div className={styles.imageSmile}>
                    <img src={require('../../assets/img/tuxi/smile.png')} className={styles.noBooking}/>
                    <div className={styles.heading}>No Upcoming Bookings yet</div>
                    <div>Visit our Homepage or cities page to book your trips with full <br/>
                    adventurous & memorable with us
                    </div>
                </div>
            )
        }
    }

    render(){
        const { isGetting } = this.state;
        if (isGetting) {
            return (<WaitingComponent />)
        }
        return(
            <div>
                {this._renderBookings()}
            </div>
        )
    }
}

export default MyBooking
