/**
 * Created by charnjeetelectrovese@gmail.com on 10/31/2019.
 */
import React, { Component } from 'react';
import styles from "./style.module.css";


class CartTour extends Component {
    constructor (props) {
        super (props);
    }

    render () {
        const { data } = this.props;
        return (
            <div className={styles.background}>
                <div>
                    <h3 className={styles.title}>{data.tour_name}</h3>
                </div>
                <div className={styles.cont}>
                    <div>
                        <img src={require('../../assets/img/ic_loc@3x.png')} className={styles.image}/>
                    </div>
                    <div>
                        <div className={styles.location}>Your Pickup is from {data.selected_point ? data.selected_point.name : ''}</div>
                    </div>
                </div>

                <div className={styles.innerCon}>
                    <div className={styles.flex}>
                        <div>
                            <img src={require('../../assets/img/ic_seat@3x.png')} className={styles.icon}/>
                        </div>

                        <div className={styles.loc}>
                            {data.seats} {data.seats == 1 ? 'Seat' : ' Seats'}
                        </div>
                    </div>
                    <div className={styles.flex} style={{marginLeft: '30px'}}>
                        <div>
                            <img src={require('../../assets/img/ic_price@3x.png')} className={styles.icon}/>
                        </div>

                        <div className={styles.loc}>
                            {data.price}
                            <span className={styles.each}></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default CartTour;
