import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './VerticalSlider.module.css'
import PortraitCard from "../../../../components/Paper/Portrait/PortraitCard.component";
import LandscapeCard from "../../../../components/Paper/LandScape/LandscapeCard.component";

class VerticalSlider extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
        this.slide = this.slide.bind(this);
    }

    slide(e){
        const y = e.wheelDelta;
        if(y < 0) {
            this.slider.slickNext()
        }
      else {
            // this.slider.slickPrev()
        }
    }

    componentDidMount(){
        // window.addEventListener('wheel', (e) => {
        //     console.log(e)
        //     this.slide(e.wheelDelta);
        // })
        // window.addEventListener('wheel', this.slide)
    }

    componentWillUnmount() {
        // window.removeEventListener('wheel', this.slide);
    }


    _renderPortraitCards(){
        return (
            [1,2,3].map(val => <PortraitCard/>)
        )
    }

    _renderInfo(){
        return (
            <div>
                <div style={{display: 'flex'}}>
                <div>
                <PortraitCard/>
                </div>
                <div>
                <LandscapeCard/>
                </div>
                </div>
                <div style={{display: 'flex'}}>
                {this._renderPortraitCards()}
                </div>
            </div>
        )

    }

    _renderLandscape(){
        const landscapeImage = require('../../../../assets/img/tuxi/lisbonicons.png');
        const addImage = require('../../../../assets/img/tuxi/add.png')
        return(
            <div>
                <div style={{display: 'flex'}}>
                    <LandscapeCard title='Lisbon' image={landscapeImage} add={addImage}/>
                    <PortraitCard/>
                </div>
                <div style={{display: 'flex'}}>
                    {this._renderPortraitCards()}
                </div>
            </div>
        )
    }
    render(){
        const settings = {
            dots: true,
            infinite: true,
            nav: true,
            accessibility: false,
            arrows: false,
            vertical: true,
            verticalSwiping: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            touchThreshold: 10,
            dotsClass:"vertical-dots"
        }
        return(
            <div className={'tourDetails'}>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderInfo()}
                    {this._renderLandscape()}
                    {this._renderInfo()}
                </Slider>
            </div>
        )
    }
}

export default VerticalSlider

