import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import styles from './CityList.module.css'
import ButtonBase from '@material-ui/core/ButtonBase'
import Banner from "../../../Banner/Banner.view";
import PortraitCard from "../../../../components/Paper/Portrait/PortraitCard.component";
import LandscapeCard from "../../../../components/Paper/LandScape/LandscapeCard.component";
import CitiesCard from "../../../../components/Paper/Cities/Cities.component";
import VerticalSlider from "../../components/ImageSlider/VerticalSlider.view";
import CitiesChips from "../../components/Chips/CitiesChips.component";
import TourCard from "../../../../components/Paper/TourCard/TourCard.component";
import Email from "../../../../components/Email/Email.component";
import BlogCard from "../../../../components/Paper/BlogCard/BlogCard.component";
import ReviewSlider from '../ReviewSlider/ReviewSlider.view';
import MediaQuery from 'react-responsive';
import TourSlider from "../../../../components/Paper/TourCard/TourSlider/TourSlider.component";
import MobileReviewSlider from "../ReviewSlider/mobile/MobileReviewSlider.component";
import MobBlogCard from '../../../../components/Paper/BlogCard/Mobile/MobBlogCard.component'

class Cities extends Component{
    constructor(props) {
        super(props);
        this.state = {

        }
        this._handleSearchClick = this._handleSearchClick.bind(this)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _renderCities(){
        return(
            [1,2,3,4,5,6,7,8,9,10,11,12].map(val => <CitiesCard/>)
        )
    }

    _renderTours(){
        return(
            <>
            <MediaQuery minDeviceWidth={768}>
                {[1,2,3,4,5,6,7,8,9].map(val => <TourCard/>)}
            </MediaQuery>
                <MediaQuery maxDeviceWidth={768}>
                    <TourSlider/>
                </MediaQuery>
            </>
        )
    }

    _renderChips(){
        return ['All','Paris','Berlin','Porto','Lisbon','Vienna','Vennice','Paris'].map(val => <CitiesChips data={val}/>)
    }

    _renderBlogs(){
        return(
            [1,2,3].map(val => <BlogCard/>)
        )
    }

    _renderMobileBlogs() {
        return(
            [1,2,3].map(val => <MobBlogCard/>)
        )
    }



    _handleSearchClick(){

    }
    render() {
        return (
            <div>
              <Banner
                  newList
                  handleClick={this._handleSearchClick}
              />

                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        <MediaQuery minDeviceWidth={768}>
                            <div className={styles.pickupFlex}>
                                {this._renderChips()}
                            </div>
                        </MediaQuery>
                        <div className={styles.upperHeading}>
                            <h2 className={styles.mainHeading}>Available Tours </h2>
                            <div className={styles.subHeading}> Discover top tours around you & roam with fun</div>
                        </div>
                        {this._renderTours()}
                        <div className={styles.listButton}>
                            <ButtonBase
                                className={styles.signUp}>
                                See all tours
                            </ButtonBase>
                        </div>
                    </div>

                </div>

                    <div className={styles.container}>
                        <div className={styles.upperHeading}>
                            <h2 className={styles.mainHeading}>Traveler Reviews </h2>
                            <div className={styles.subHeading}> Here are some reviews for Paris tour</div>
                        </div>
                        <MediaQuery minDeviceWidth={768}>
                            <ReviewSlider/>
                        </MediaQuery>
                        <MediaQuery maxDeviceWidth={768}>
                            <MobileReviewSlider/>
                        </MediaQuery>

                        <div className={styles.mainContainer}>
                            <Email city/>
                        </div>
                    </div>

                <MediaQuery minDeviceWidth={768}>
                    <div>
                        <img src={require('../../../../assets/img/tuxi/image@2x.jpg')} style={{width:'100%'}}/>
                    </div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={768}>
                    <img src={require('../../../../assets/img/tuxi/Image_greentours@2x.jpg')} style={{width:'100%'}}/>
                </MediaQuery>

                <div className={styles.mainContainer}>
                <div className={styles.container}>
                    <div className={styles.upperHeading}>
                        <h2 className={styles.mainHeading}>Paris Travel Diaries </h2>
                        <div className={styles.subHeading}> Discover all blogs around paris & get knowledge of experiences</div>
                    </div>
                    <MediaQuery minDeviceWidth={768}>
                        <div style={{display: 'flex'}}>
                            {this._renderBlogs()}
                        </div>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={768}>
                        {this._renderMobileBlogs()}
                        <div className={styles.linkBlog}>
                            <Link to="/blogs" className={styles.viewAll}><span className={styles.blogText}>View all Blogs </span></Link>
                        </div>
                    </MediaQuery>
                </div>
                </div>

            </div>
        )
    }
}

export default Cities