import React,{Component} from 'react'
import styles from './ShortList.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';
import classNames from "classnames";

class ShortList extends Component {

    render() {
        return (
            <div className={styles.mainContainer}>
                <div className={'ncontainer'}>
                    <div className={styles.shortFlex}>
                        <div style={{marginRight:'7px'}}>
                            <div className={styles.shortText}>Short Listed</div>
                            <div className={styles.num}>Showing 4/4</div>
                        </div>

                        <div className={styles.bgWhite}>
                            <div className={styles.pharmaFlex}>
                                <div>
                                    <img src={require('../../assets/img/factory/ManufacturersList/logo.png')}
                                         className={styles.logo}/>
                                </div>
                                <div className={styles.listing}>
                                    <div className={styles.ahar}>AHAR PHARMA(BADDI)</div>
                                    <div className={styles.title}>Paracetamol(500MG)</div>
                                    <div className={styles.loc}><span className={styles.price}>Rs 2.00-3.00</span> /
                                        tablet
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.bgWhite}>
                            <div className={styles.pharmaFlex}>
                                <div>
                                    <img src={require('../../assets/img/factory/ManufacturersList/logo.png')}
                                         className={styles.logo}/>
                                </div>
                                <div className={styles.listing}>
                                    <div className={styles.ahar}>AHAR PHARMA(BADDI)</div>
                                    <div className={styles.title}>Paracetamol(500MG)</div>
                                    <div className={styles.loc}><span className={styles.price}>Rs 2.00-3.00</span> /
                                        tablet
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.bgWhite}>
                            <div className={styles.pharmaFlex}>
                                <div>
                                    <img src={require('../../assets/img/factory/ManufacturersList/logo.png')}
                                         className={styles.logo}/>
                                </div>
                                <div className={styles.listing}>
                                    <div className={styles.ahar}>AHAR PHARMA(BADDI)</div>
                                    <div className={styles.title}>Paracetamol(500MG)</div>
                                    <div className={styles.loc}><span className={styles.price}>Rs 2.00-3.00</span> /
                                        tablet
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.bgGrey}>
                            <div className={styles.addFlex}>
                                <img src={require('../../assets/img/factory/plus_add.png')} height={20}/>
                                <div className={styles.add}>
                                    Add upto 4 manufactures <br/>
                                    to get a free quote from
                                </div>
                            </div>
                        </div>

                        <div>
                            <ButtonBase className={styles.quote} type={'submit'}>
                                Get a quote
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortList