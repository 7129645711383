import React, {Component} from 'react';
import styles from '../Home.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';
import ManufacturersO from "./ManufacturersO.view";

function SecuredO(){
    return(
        <div>
            <div className={styles.buildFlex} style={{marginTop: '10px'}}>
                <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                <div className={styles.nego}>Easy access to verified manufacturers directly. </div>
            </div>
            <div className={styles.buildFlex}>
                <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                <div className={styles.nego}>Regular update for your order and milestones. </div>
            </div>
            <div className={styles.buildFlex}>
                <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                <div className={styles.nego}>Quality Control & Assurance with FactoryGrid Product Predict. </div>
            </div>
            <div className={styles.buildFlex}>
                <img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>
                <div className={styles.nego}>Get best prices with complete industry insights & expert opinion. </div>
            </div>
        </div>
    )
}

class OutSource extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icon: false
        };

    }

    render() {
        return (
            <div>
                <div className={styles.interestingFlex}>
                    <div className={styles.desc} style={{marginRight:'10px', position: 'relative'}}>
                        <div className={styles.manufacturers}>
                            <p>Get orders directly with 100% payment guarantee.</p>
                        </div>
                            <div className={styles.leftOverlay}>
                                <ManufacturersO/>
                            </div>


                        <div className={styles.greybg}>
                            <div className={styles.small}>
                                <b>I'm looking for manufacturers</b>
                            </div>
                            <div>
                                <ButtonBase className={styles.learnMore}>
                                    learn more
                                </ButtonBase>
                            </div>
                        </div>
                    </div>


                    <div className={styles.desc} style={{marginLeft:'10px', position: 'relative'}}>
                        <div className={styles.more}>
                            <p>Get your products manufactured & delivered seamlessly. </p>
                        </div>
                        <div className={styles.leftOverlay}>
                            <SecuredO/>
                        </div>
                        <div className={styles.greybg}>
                            <div className={styles.small}>
                                <b>I'm a Manufacturer</b>
                            </div>
                            <div>
                                <ButtonBase className={styles.learnMore}>
                                    learn more
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OutSource

{/*<div className={styles.outsource}>Outsource manufacturing with ease and trust</div>*/}
{/*<div className={styles.buildFlex} style={{marginTop:'10px'}}>*/}
{/*<img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
{/*<div className={styles.nego}>Best deal Assurance with best price, quality and time</div>*/}
{/*</div>*/}
{/*<div className={styles.buildFlex}>*/}
{/*<img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
{/*<div className={styles.nego}>Coordination for complete project until delivery.</div>*/}
{/*</div>*/}
{/*<div className={styles.buildFlex}>*/}
{/*<img src={require('../../../assets/img/factory/plus_icon.png')} height={'28'}/>*/}
{/*<div className={styles.nego}>Recurring requests management</div>*/}
{/*</div>*/}