import React , {Component} from 'react'
import styles from './About.module.css'
import {Star} from '@material-ui/icons';
import ButtonBase from '@material-ui/core/ButtonBase';

class About extends Component {
    constructor(props){
        super(props);
        this.state = {}
        this._handleProfile = this._handleProfile.bind(this);
    }
    _handleProfile() {
        // const {data, vehicle} = this.props;
        // this.props.history.push(RouteNames.driver_profile+'/'+data.id, {
        //     data: data
        // });
    }
    render(){
        const {data, vehicle} = this.props;
        if(data)
        return(
            <div>
                <div className={styles.box}>
                    <div className={styles.cont}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <img className={styles.driverImage} src={data.image}/>
                        </div>

                        <div style={{marginLeft:'15px'}}>
                            <div >
                                <div className={styles.text}>{data.name}</div>
                                <div className={styles.language}>{data.first_name} can speak {data.languages}</div>
                                <div className={styles.icons}>
                                    <Star fontSize="small" className={styles.fontSizeSmall}/>
                                    <span style={{color: 'white'}}>4.6</span>
                                </div>
                            </div>
                            <hr/>
                            <div style={{marginTop:'10px'}}>
                                <div className={styles.descriptionFlex}>
                                    <div style={{color:'grey',fontWeight:'500'}}> Overview </div>
                                    <div className={styles.driverdesc}>{data.overview}</div>
                                </div>

                                {/*<div className={styles.descriptionFlex}>*/}
                                    {/*<div style={{color:'grey',fontWeight:'500'}}> Education </div>*/}
                                    {/*<div className={styles.driverdesc}>{data.education}</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/*Vehicle : <span style={{fontWeight:'600'}}>{data.name} </span>*/}
                        <ButtonBase className={styles.bookButton} onClick={this._handleProfile}>
                            <span style={{ fontSize: '10px', color: 'white', fontWeight: '500' }}>View Profile</span>
                        </ButtonBase>
                    </div>
                </div>
            </div>
        )
    }
}

export default About