import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './TourImage.module.css'

class TourImages extends Component {
    constructor (props) {
        super (props);
        this.slider = null;
    }

    _renderCarousel(){
        const { images} = this.props;
        return images.map((val, index) => {
            return (
                <div key={'images_'+index} className={styles.noBorder}>
                    <div style={{ backgroundImage: "url('"+val+"')" }} className={styles.bannerImage} >
                    </div>
                </div>
            )
        });

    }

    _renderVideo(){
        const {video} = this.props;
        if(video) {
            return (
                <>
                    {/*<video  controls="true" autoPlay loop height="400" muted className="tuxiVideo">*/}
                    {/*<source src={require('../../../../assets/img/tuxi/tuxiwebsite_update_1_2.mp4')}*/}
                            {/*type="video/mp4"/>*/}
                {/*</video>*/}
                </>
            )
        }
    }

    render(){
        const settings = {
            dots: false,
            infinite: true,
            nav: true,
            accessibility: false,
            arrows: false,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            touchThreshold: 10,
        }
        return(
            <div className={'tourSlider'}>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderVideo()}
                    {this._renderCarousel()}
                </Slider>
            </div>
        )
    }
}

export default TourImages

