import React, {Component} from 'react';
import {ButtonBase} from '@material-ui/core';
import Slider from 'rc-slider';
import styles from './style.module.css';
import PriceFilter from "../PriceFilter/PriceFilter.component";
import EventEmitter from '../../../libs/Events.utils';

class PriceFilterPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: { min: 0, max: 5000 },
            is_set: false
        };
        this._handleChange = this._handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            is_set: true
        })
    }

    _handleChange (type, data) {
        this.setState({
            data: data
        })
    }

    _renderPriceFilter() {
        if(this.state.is_set) {
            return (
                <PriceFilter
                    data={this.state.data}
                    handleChange={this._handleChange}
                />
            )
        } return null;
    }
    render() {
        return (
            <div>
                <div className={styles.outerFlex}>
                    <div className={styles.innFlex}>
                        <div style={{}}>
                            Select Price Range
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <ButtonBase onClick={() => {
                            this.props.handleClear('price');
                        }}>
              <span className={styles.rightBtn}>
                          Clear
              </span>
                        </ButtonBase>
                        <div className={styles.rightBtn} style={{padding: '5px 0px'}}>
                            |
                        </div>
                        <ButtonBase onClick={(e) => {
                            this.props.handleChange('price', this.state.data);
                        }}>
              <span className={styles.rightBtn}>
                          Apply
              </span>
                        </ButtonBase>
                    </div>
                </div>
                {/* Filter Content */}
                <div className={styles.contentContainer}>
                    {this._renderPriceFilter()}

                    {/*<Slider*/}
                    {/*  value={this.state.value}*/}
                    {/*  onChange={value => this.setState({ value })}*/}
                    {/*/>*/}
                    {/*<div>{this.state.value}</div>*/}
                </div>
            </div>
        );
    }
}

export default PriceFilterPopup;
