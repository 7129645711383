/**
 * Created by charnjeetelectrovese@gmail.com on 5/27/2020.
 */
import React, { Component } from 'react';
import styles from './style.module.css';
import { ButtonBase, withStyles } from "@material-ui/core";
import DateComponent from '../../../../components/DateComponent/Date.component';

class DateHeader extends Component{

    constructor(props) {
        super(props);

    }


    render() {
        const { classes } = this.props;
        return (
            <div className={styles.dateContainer}>
                <h4 >Enter your dates to find available activities</h4>
             <div className={styles.mobileFlex}>
                <div className={styles.inputCont}>
                <DateComponent
                    name={'from'}
                    label={'From'}
                />
                </div>
                <div className={styles.inputCont}>
                <DateComponent
                    name={'to'}
                    label={'To'}
                />
                </div>
             </div>
                <ButtonBase className={classes.btnGradient} onClick={() => {}}>
                    Check Availability
                </ButtonBase>
            </div>
        );
    }

}

const useStyles = (theme) => ({
   btnGradient: {
       backgroundImage: theme.buttonGradient,
       width: '100%',
       padding: '10px',
       borderRadius: '5px',
       color: 'white',
       marginTop: '10px',
       // fontSize: '1.1rem',
       fontFamily:'OpenSans-SemiBold'
   }
});

export default withStyles(useStyles)(DateHeader);
