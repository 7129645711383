/**
 * Created by charnjeetelectrovese@gmail.com on 5/27/2020.
 */
import React, {Component} from 'react';
import { Paper, withStyles } from "@material-ui/core";
import styles from './Style.module.css';
import classNames from 'classnames';
import { KeyboardArrowDown as DownIcon, KeyboardArrowUp as UpIcon,ArrowDropDown,ArrowDropUp } from '@material-ui/icons';

class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount(){
      if(this.props.initial){
          this.setState({
              open: false
          })
      }
      else {
          this.setState({
              open: true
          })
      }
    }

    _handleClick() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classNames(styles.panelGroup, classes.highlightColor)}>
                <div  onClick={this._handleClick} className={styles.panelHeader}>
                    <span className={styles.title}>
                        {this.props.title}
                        </span>
                    <span className={styles.iconContainer}>
                        {!this.state.open ? (<ArrowDropDown/>) : (<ArrowDropUp/>)}
                    </span>
                </div>
                <div className={classNames(styles.panelCollapse, (this.state.open ? styles.panelOpen : styles.panelClose))}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

const useStyles = (theme) => ({
    highlightColor: {
        // borderColor: theme.highlightColor
    }
});

export default withStyles(useStyles)(Collapse);
