import React, {Component} from 'react';
import styles from './Otp.module.css'
import {Link} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import {ButtonBase} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import {renderOutlinedTextField,renderTextField} from "../../libs/redux-material.utils";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const validate = (values) => {
    const errors = {};
    const requiredFields = ['otp'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });

    return errors;
};

class OtpDialog extends Component{
    constructor(props){
        super(props);

        this.state={

        }
        this._handleSubmit = this._handleSubmit.bind(this)
    }

    _handleSubmit(data) {

    }

    render(){
        const {handleSubmit} = this.props
        return(
            <div>
                <Dialog
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >

                    <div className={styles.mainContainer}>
                        <div className={styles.headingOtp}>
                            Enter the OTP sent to your Email Address to <br/>
                            verify your email now
                        </div>

                        <form onSubmit={handleSubmit(this._handleSubmit)}>
                            <div className={styles.formContainer}>
                                <Field
                                    fullWidth={true}
                                    name="otp"
                                    component={renderTextField}
                                    margin={"dense"}
                                    label={"Enter Your OTP"}
                                />
                            </div>

                            <div style={{textAlign:'center',marginTop:'25px'}}>
                                <ButtonBase className={styles.verifyEmail} type={'submit'}>
                                    Verify Email
                                </ButtonBase>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        )
    }
}

const otp = reduxForm({
    form:'user',
    validate,
})(OtpDialog);

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(otp)