import React, { Component } from 'react';
import styles from './Support.module.css';
import BookingCard from "../MyBooking/component/BookingCard.component";
import {connect} from "react-redux";
import {withTheme} from "@material-ui/core";
import {bindActionCreators} from "redux";
import Collapse from "../../components/Collapse/Collapse.component";
import Accordion from "../../components/Accordion/Accordion.component";
import CustomerForm from './components/CustomerForm'
import BusinessForm from './components/BusinessForm'
import TourOperator from './components/TourOperatorForm'

class Support extends Component{
    constructor(props) {
        super(props);
        // this.supportHeadings = ['Customer Support', 'Partnership/Business Query', 'Tour Operator'];
    }

    _renderCollapse(){
        return(
                <div>
                    <Collapse title={'Customer Support'} initial="hide">
                        <CustomerForm/>
                    </Collapse>
                    <Collapse title={'Partnership/Business Query'} initial="hide">
                        <BusinessForm/>
                    </Collapse>
                    <Collapse title={'Tour Operator'} initial="hide">
                        <TourOperator/>
                    </Collapse>
                </div>
            )

    }

    _renderFAQ(){
        return(
            [1,2,3,4,5].map(()=> {
                return(
                    <div>
                        <Accordion title={'Payment and refunds'} main={"Paying with a gift or promo code"} initial="hide">
                            After selecting “Add to cart”, you’ll start the checkout process.
                            Underneath your chosen experience,
                            you'll find a link that says: “Enter gift or promo code.“ Click it and add your 12-digit code. Then click “Redeem
                        </Accordion>
                        <hr/>
                    </div>
                )
            })
        )
    }

    render(){
        const { user_details } = this.props;
        return(
            <div>
                <div style={{marginTop:'70px'}}></div>
                <div className={styles.containerS}>
                    <div className={styles.thanksTxt}>How We Can Help You?</div>
                </div>

                <div className={'container'}>
                    <div className={styles.innerContainer}>
                        <div className={styles.heading}>How Can we Help You</div>
                        {this._renderCollapse()}
                        <br/>
                        <br/>
                        <div className={styles.heading}>Still Confused ? Check Our FAQ Section</div>
                        <hr/>
                        {this._renderFAQ()}
                        <br/>
                        <br/>
                        <div className={styles.heading}>Still Need Help?</div><hr/>
                        <div>
                            <div>
                                Send an email at <span className={styles.contact}><a className={styles.num} href="mailto: contactus@letstuxi.com">contactus@letstuxi.com</a></span><br/>
                                or<br/>
                                Call us at <span className={styles.contact}><a className={styles.num} href="tel:+351-12345678">+351-12345678</a></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    });
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        user_details: state.auth.user_details,
        cart: state.cart,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Support));
