/**
 * Created by charnjeetelectrovese@gmail.com on 2/3/2020.
 */
export  function setParams(data) {
    const searchParams = new URLSearchParams();
    Object.keys(data).forEach((name) => {
        const value = data[name];
        searchParams.set(name, value);
    });
    return searchParams.toString();
}
