import React, {Component} from 'react'
import styles from '../../../views/Home/Home.module.css';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {PriceFilter, VehicleFilter, LanguageFilter} from '../../Filter/PopupFilters/Index';
import classnames from 'classnames';
import PopOver from '../../Filter/Popover.component';
import Filter from '../../Filter/Filter.component'

// import EventEmitter from '../../../libs/Events.utils';

class FilterView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialog_open: false,
            open: false,
            x_axis: 10,
        };
        this.nodes = [];

        this._handleClick = this._handleClick.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleApply = this._handleApply.bind(this);
        this._handleClearValue = this._handleClearValue.bind(this)
    }

    componentDidMount() {
        // EventEmitter.subscribe(EventEmitter.FILTER_CHANGE, this._handleChange);
    }

    componentWillUnmount() {
        // EventEmitter.unsubscribe(EventEmitter.FILTER_CHANGE);
    }

    _handleApply(data) {
        this.props.handleChanges(data);
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }


    _handleClose() {
        this.setState({
            dialog_open: !this.state.dialog_open
        })
    }

    _handleChange(type, data) {
        this.props.handleChanges({[type]: data});
        this.setState({
            open: false,
        })
    }

    _handleClearValue(type){
        this.props.handleClear(type);
        this.setState({
            open: false,
            dialog_open: false,
        })
    }

    _handleClick(index = null, type = '') {
        console.log(index);
        if (index != null) {
            const dom = ReactDOM.findDOMNode(this.nodes[index]);
            const position = dom.getBoundingClientRect();
            this.setState({
                open: true,
                x_axis: position.x,
                selectedIndex: index,
            });
        } else {
            this.setState({
                open: false,
            })
        }
    }

    _renderPopOverContent() {
        const {filters, filterList} = this.props;
        switch (this.state.selectedIndex) {
            case 0: {
                return (<PriceFilter
                    data={filters.price}
                    handleChange={this._handleChange}
                    handleClick={this._handleClick}
                    handleClear={this._handleClearValue}
                />);
            }
            case 1: {
                return (<VehicleFilter
                    data={filters.type_category}
                    handleChange={this._handleChange}
                    handleClick={this._handleClick}
                    list_data={filterList.list_data.type_categories}
                    handleClear={this._handleClearValue}
                />);
            }
            case 2: {
                return (<LanguageFilter
                    data={filters.language}
                    handleChange={this._handleChange}
                    handleClick={this._handleClick}
                    list_data={filterList.list_data.languages}
                    handleClear={this._handleClearValue}
                />);
            }
            default : {
                return null;
            }

        }
    }

    _renderDialogFilter() {
        const { filters, filterList } = this.props;
        // if (this.state.dialog_open) {
        if (!filterList.is_fetching) {
            return (
                <Filter
                    filters={this.props.filters}
                    open={this.state.dialog_open}
                    handleApply={this._handleApply}
                    handleClose={this._handleClose}
                    tour_types={filterList.list_data.tour_types}
                    type_categories={filterList.list_data.type_categories}
                    categories={filterList.list_data.categories}
                    languages={filterList.list_data.languages}
                    handleClear={this._handleClearValue}
                />
            )
        }
        // }
    }
    render() {
        return (
            <div>
                <div>
                    <div className={styles.bottomFlex}>
                        <div
                            className={classnames(styles.price, 'divPrice')}
                            ref={node => this.nodes[0] = node}
                            onClick={this._handleClick.bind(this, 0)}
                        >
                            <img src={require('../../../assets/img/coins.png')} className={styles.images}/>
                            <div className={styles.filter}>
                                Price
                            </div>
                        </div>

                        <div
                            className={classnames(styles.price, 'divPrice')}
                            ref={node => this.nodes[1] = node}
                            onClick={this._handleClick.bind(this, 1)}
                        >
                            <img src={require('../../../assets/img/car-compact@3x.png')} className={styles.images}/>
                            <div className={styles.filter}>
                                Vehicle
                            </div>
                        </div>

                        <div
                            className={classnames(styles.price, 'divPrice')}
                            ref={node => this.nodes[2] = node}
                            onClick={this._handleClick.bind(this, 2)}
                        >
                            <img src={require('../../../assets/img/language@3x.png')} className={styles.images}/>
                            <div className={styles.filter}>
                                Language
                            </div>
                        </div>

                        <div className={styles.price} onClick={this._handleClose}>
                            <img src={require('../../../assets/img/filter@3x.png')} className={styles.images}/>
                            <div className={styles.filter}>
                                Filters
                            </div>
                        </div>

                    </div>
                </div>
                <PopOver handleClick={this._handleClick} x_axis={this.state.x_axis} open={this.state.open}>
                    {this._renderPopOverContent()}
                </PopOver>
                {this._renderDialogFilter()}
                <div style={{height: '65px'}}>
                </div>
            </div>
        )
    }
}

FilterView.propTypes = {
    handleChanges: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};

export default FilterView
