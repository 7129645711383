import React, {Component} from 'react'
import styles from './Banner.module.css'
import ButtonBase from '@material-ui/core/ButtonBase'
import DateComponent from '../../components/DateComponent/Date.component';
import MediaQuery from 'react-responsive';
import classNames from "classnames";


class Banner extends Component{
    constructor(props) {
        super(props);

        this.state = {

        };
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick() {
        this.props.handleClick();
    }


    render(){
        const {pharma} = this.props
        return (
           <div>
               <div className={pharma ? styles.pharmaContainer :styles.backgroundContainer}>
                   <div className={classNames(styles.innerContainer,styles.mainContainer)}>
                       <div className={styles.mainFlex}>
                           <h2 className={styles.connect}>India's Largest Portal for<br/>manufacturers</h2>
                           <span className={styles.verified}>We connect 100+ verified manufacturies and PCD's seeking for <br/> hassle free business association</span>
                           <br/>
                           <span>What are you looking to get manufactured?</span>
                           <div style={{display:'flex',marginTop:'10px',width:'500px'}}>
                               <input type="text" className={styles.inputCity} placeholder={"Salt-Combinaion-Medicine"} />
                               <ButtonBase className={styles.quote} type={'submit'} onClick={this._handleClick}>
                                   <span className={styles.getquote}>GET QUOTE</span>
                               </ButtonBase>
                           </div>

                           <div style={{marginTop:'10px',fontSize:'14px'}}>
                               Popular Search: <span className={styles.popular} onClick={this._handleClick}>FaceMask</span>
                               <span className={styles.popular} onClick={this._handleClick}>Paracetamol</span>
                               <span className={styles.popular} onClick={this._handleClick}>Chips</span>
                               <span className={styles.popular} onClick={this._handleClick}>Monocartons</span>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Banner
