import React, {Component} from 'react';
import styles from './TabsDetails.module.css'
import Account from '@material-ui/icons/AccountBalanceWallet'
import Watch from '@material-ui/icons/WatchLater'
import Timer from '@material-ui/icons/Timer'
import People from '@material-ui/icons/People'
import LocalTaxi from '@material-ui/icons/LocalTaxi'
import Translate from '@material-ui/icons/Translate'


class TabsDetails extends Component {
    render() {
        const {info} = this.props;
        return (
            <div>
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Account className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>{info.price}</span>
                        </div>
                        <br/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Watch className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>{'Starts in 10 min'}</span></div>
                        <br/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Timer className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>{info.duration} Min.</span></div>
                        <br/>
                    </div>
                    <div style={{flex: 1}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <People className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>Up to {info.vehicle.capacity} people</span></div>
                        <br/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <LocalTaxi className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>{info.type_category}</span></div>
                        <br/>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Translate className={styles.fontSizeIcon} fontSize={'medium'}/>
                            <span style={{paddingLeft: '5px'}}>{info.driver.languages_shortcode}</span></div>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabsDetails
