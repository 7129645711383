import React,{Component} from 'react'
import styles from './Brands.module.css';
import {actionLoginUser} from "../../../../actions/Auth.action";
import {withStyles} from "@material-ui/core/index";
import {bindActionCreators} from "redux";
import {MenuItem,ButtonBase} from '@material-ui/core';
import {connect} from "react-redux";
import {Field,reduxForm} from "redux-form";
import {renderFileField, renderOutlinedTextField} from "../../../../libs/redux-material.utils";

class Brands extends Component{
    constructor(props){
        super(props);
        this.state={

        }
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleSubmit(data) {

    }

    _renderBrands(){
        const {handleSubmit, classes} = this.props;
        return(
            <div>
                <form onSubmit={handleSubmit(this._handleSubmit)} className={'addBrand'}>
                    <div className={'newForm'}>
                        <Field
                            fullWidth={true}
                            name="name"
                            component={renderOutlinedTextField}
                            margin={"dense"}
                            label={"Brand Name"}
                        />
                    </div>
                    <div className={'newForm'}>
                        <Field
                            max_size={1024 * 1024 * 5}
                            type={['jpg', 'png', 'pdf', 'jpeg']}
                            fullWidth={true}
                            name="upload"
                            component={renderFileField}
                            accept={'application/pdf, image/*'}
                            label="Attachment"
                            // link={data ? data.company_proof : null}
                        />
                    </div>

                    <div className={styles.auth}>
                        <div>Authority Letter</div>
                        <div><a href={'/'}>Download Template</a></div>
                    </div>

                    <div style={{textAlign:'center'}}>
                    <ButtonBase className={styles.save} type={'submit'}>
                        Add
                    </ButtonBase>
                    </div>
                </form>
            </div>
        )
    }

    render(){
        return(
            <div className={styles.brandFlex}>
                <div>
                    <div className={styles.brandName}>Brands(6)</div>

                    <div className={styles.brandContainer}>
                        <div className={styles.attach}>
                        <div className={styles.brandName}>Brand Name</div>
                            <div style={{marginRight:'15px'}}>
                            <img src={require('../../../../assets/img/factory/varified_icon.png')} height={15}/>
                            </div>
                        </div>
                        <div className={styles.attach}>
                            <div className={styles.added}>
                                <div>Attachment</div>
                                <div>Added on</div>
                            </div>
                            <div className={styles.added}><a href={'/'}>Revoke</a></div>
                        </div>
                    </div>
                </div>

                <div style={{flex:'0.5'}}>
                    <div className={styles.addContainer}>
                        <div className={styles.addText}>Add New Brand</div>
                        {this._renderBrands()}
                    </div>
                </div>
            </div>
        )
    }
}

Brands = reduxForm({
    form: 'brand',  // a unique identifier for this form
    // validate,
    // onSubmitFail: errors => {
    //     EventEmitter.dispatch(EventEmitter.THROW_ERROR, {error: 'Please Enter Your Login Credentials', type: 'none'});
    //
    // }
})(Brands);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}


export default connect(null, mapDispatchToProps)(Brands);