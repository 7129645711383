/**
 * Created by charnjeetelectrovese@gmail.com on 3/23/2020.
 */
import React ,{Component} from 'react'
import Calendar from 'react-calendar';
import classnames from 'classnames';
import styles from './DateSelector.module.css'
import ButtonBase from '@material-ui/core/ButtonBase';
import {Navbar} from '../../../components/index.component'
import data from '../../../data'
import RouteNames from '../../../routes/Route.name';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import NextBtn from '@material-ui/icons/ArrowForward'
import 'react-calendar/dist/Calendar.css';
import moment from "moment";

class DateSelector extends Component {
    constructor (props) {
        super (props);
        const tempDate = new Date();
        const hours = tempDate.getHours();
        this.state = {
            date: new Date(),
            calendar_date: new Date(),
            time: `${hours.length < 2 ? '0'+hours : hours}:00`,
        }
        this._onChange = this._onChange.bind(this);
        this._handleTime = this._handleTime.bind(this);
        this._handleNext = this._handleNext.bind(this);
    }

    _onChange = (date) =>  {
        const temp  = moment(date).format('MM-DD-YYYY');
        this.setState({
            date: temp,
            calendar_date: date,
        })
    }


    _handleTime(e) {
        this.setState({
            time: e.target.value,
        });
    }

    _renderTimeOptions() {
        const tempOptions = [];
        for (let i = 0; i < 24; i++) {
            const stamp = `${i.length < 2 ? '0'+i : i}:00`;
            tempOptions.push(<option value={stamp} selected={stamp==this.state.time}>{stamp}</option>)
        }
        return tempOptions;
    }

    _handleNext() {
        const {date, time} = this.state;
        this.props.handleNext(date, time);
    }

    render () {
        return (
            <div>
                <div className={styles.outerFlex}>
                    <div className={styles.innFlex}>
                        <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <div style={{fontWeight: '700'}}>
                            Select Date
                        </div>
                    </div>
                    <div style={{marginRight: '10px'}}>

                    </div>
                </div>
                <div className={styles.calenderContainer}>

                <Calendar
                    className={styles.reactCalendar}
                    onChange={this._onChange}
                    minDate={new Date()}
                    value={this.state.calendar_date}
                />
                    <br/>
                    <h2 className={styles.timeText}>What Time?</h2>
                    <div className={styles.textCenter}>
                    <div className={classnames(styles.kirk_timePicker, styles.sc_cHGsZl, styles.timeCont)} aria-disabled="false">
                        <time>{this.state.time}</time>
                        <select name="time" onChange={this._handleTime}>
                            {this._renderTimeOptions()}
                        </select></div>
                    </div>
                </div>
                <div className={styles.nextBtnCont} >
                    <ButtonBase className={styles.nextBtn} onClick={this._handleNext}>
                        <div >
                            <NextBtn />
                        </div>
                    </ButtonBase>
                </div>
            </div>
        )
    }
}

export default DateSelector;
