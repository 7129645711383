import React, {Component} from 'react';

import {FavoriteBorder as Favorite, Favorite as FavoriteFilled} from '@material-ui/icons';
import ButtonBase from '@material-ui/core/ButtonBase'
import Rating from '@material-ui/lab/Rating';
import ImageSlider from "../ImageSlider/ImageSlider.view";
import Activity from "../Activity/Activity.component";
import Inclusion from "../../../../components/Inclusion/Inclusion.component";
import Email from "../../../../components/Email/Email.component";
import styles from './Style.module.css';
import RecentReview from "../../../../components/RecentReview/RecentReview.component";
import RouteComponent from "../Route/Route.component";
import RouteList from "../../../../components/RouteComponent/Routes.component";
import classNames from "classnames";
import MediaQuery from 'react-responsive';
import TourImages from "../mobile/TourImages.view";
import ToursCard from "../ToursCard/ToursCard.component";

class TourDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false,
            fullDescription: false,
            routeMap: false,
        };
        this._handleReadDescription = this._handleReadDescription.bind(this);
        this._handleRouteType = this._handleRouteType.bind(this);
        this._handleBookNow = this._handleBookNow.bind(this);
    }

    _renderFavourite() {
        if (this.state.is_filled)
            return (
                <FavoriteFilled fontSize={'2rem'} className={styles.icons}/>
            );
        else
            return <Favorite fontSize={'2rem'} className={styles.icons}/>

    }

    _handleReadDescription() {
        this.setState({
            fullDescription: !this.state.fullDescription,
        });
    }

    _handleRouteType() {
        this.setState({
            routeMap: !this.state.routeMap
        })
    }

    _handleBookNow() {
        this.props.handleBookNow();
    }
    _getDescription() {
        const {data} = this.props;
        const {fullDescription} = this.state;
        if (fullDescription) {
            return data.description;
        } else {
            return data.description.substring(0, 200);
        }
    }

    _renderTourCardsBelow(){
        return(
        [1,2,3,4].map(val => <ToursCard/>)
        )
    }

    _renderRouteComponent() {
        const {data} = this.props;
        const { routeMap } = this.state;
        if (!routeMap) {
            return (
                <RouteComponent routes={data.route_list}/>
            )
        }
        return (
            <RouteList data={{routes: data.routes}}></RouteList>
        );
    }

    _renderEcoImage() {
        const { data } = this.props;
        const  { eco_friendly } = data;
        let totalPoints = 0;
        Object.keys(eco_friendly).forEach((val) => {
            if (eco_friendly[val]) {
                totalPoints++;
            }
        });
        return (<img src={require('../../../../assets/img/eco/ic_eco'+totalPoints+'.png')} className={styles.ecoIcon}/>);
    }

    renderPersonalizedTours(){
        return(
            <div className={styles.personaliseContainer}>
                <div className={styles.personalizedHeading}>Personalised Tours Features</div>
                <div className={styles.chooseText}>You can Choose Your</div>
                <div className={styles.persFlex}>
                    <div className={styles.innerFlex}>
                        <img src={require('../../../../assets/img/tuxi/personalized/interface.png')} className={styles.perImage}/>
                        <div className={styles.perText}>Custom Time</div>
                    </div>
                    <div className={styles.innerFlex}>
                        <img src={require('../../../../assets/img/tuxi/personalized/maps-and-location.png')} className={styles.perImage}/>
                        <div className={styles.perText}>Pickup Location</div>
                    </div>
                </div>
                <div className={styles.persFlex} style={{marginTop:'20px',paddingBottom:'20px'}}>
                    <div className={styles.innerFlex}>
                        <img src={require('../../../../assets/img/tuxi/personalized/burger.png')} className={styles.perImage}/>
                        <div className={styles.perText}>Meal Preference</div>
                    </div>
                    <div className={styles.innerFlex}>
                        <img src={require('../../../../assets/img/tuxi/personalized/ic_tour_preference.png')} className={styles.perImage}/>
                        <div className={styles.perText}>Tour Preference</div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {data} = this.props;
        const {fullDescription, routeMap} = this.state;
        return (
            <div>
                <MediaQuery minDeviceWidth={768}>
                    <div className={'container'}>
                        <div style={{height:'75px'}}></div>
                        <div className={styles.outerFlex}>
                            <div>
                                {this._renderEcoImage()}

                            </div>
                            <div className={styles.textContainer}>
                                <h1 className={styles.tourHeading}>
                                    {data.name}
                                </h1>
                                <div>
                                    <Rating value={data.rating} readOnly className={styles.starRating}/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <ImageSlider images={data.images} video={data.video}/>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={768}>
                    <TourImages images={data.images} video={data.video}/>

                    <div className={classNames('container',styles.outerFlex)}>
                        <div className={styles.mainFlex}>
                            <div>
                                {this._renderEcoImage()}

                            </div>
                            <div className={styles.textContainer}>
                                <h1 className={styles.mobileHeading}>
                                    {data.name}
                                </h1>
                                <div>
                                    <Rating value={data.rating} readOnly className={styles.starRating}/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.lowerFlex}>
                            <div className={styles.textInside}>From</div>
                            <div>
                                <span className={styles.price}>{data.currency_shortcode}{data.starting_price} </span>
                                <span className={styles.textInside}>Per Mem.</span>
                            </div>
                        </div>
                    </div>

                    <div className={'container'}>
                        <div className={styles.buttonContainer}>
                            <ButtonBase
                                onClick={this._handleBookNow}
                                className={styles.book}>
                                {data.is_personalized ? 'Personalize Tour' : 'Book Now'}
                            </ButtonBase>
                        </div>
                        <hr/>
                        {data.is_personalized ? this.renderPersonalizedTours() : ''}
                    </div>
                </MediaQuery>

                <div className={classNames('container',styles.tourFlex)}>
                    <div className={styles.descriptionFlex}>
                        <h3 className={styles.tourDetailHeading}>Description</h3>
                        <p className={styles.tourDescription}>
                            {this._getDescription()}
                        </p>
                        <div className={styles.readButton}>
                            {data.description.length > 200 ? (
                            <ButtonBase
                                onClick={this._handleReadDescription}
                                className={styles.readMore}>
                                {fullDescription ? "Show Less" : "Read More"}
                            </ButtonBase>
                                ) : ''}
                        </div>
                        <hr/>

                        <h3  className={styles.tourDetailHeading}>Activity Highlights</h3>
                        <div>
                            <Activity data={data}/>
                            <hr/>
                        </div>
                        <div className={styles.routeFlex}>
                            <div>
                        <h3 className={styles.tourDetailHeading}>Itinenary</h3>
                            </div>
                            <div>
                                <ButtonBase
                                    onClick={this._handleRouteType}
                                    className={styles.readMore}>
                                    {!routeMap ? "View On Map" : "Show List"}

                                </ButtonBase>
                            </div>
                        </div>
                        <div>
                            {this._renderRouteComponent()}
                            <hr/>
                        </div>
                        <h3 className={styles.tourDetailHeading}>Inclusions / Exclusions</h3>
                        <div>
                            <Inclusion data={data.included} included title="Inclusions"/>
                            <hr/>
                            <Inclusion data={data.included} title="Exclusions"/>
                        </div>
                        {/*<h3>You might also like</h3>*/}
                        {/*<div>*/}
                        {/*    {this._renderTourCard()}*/}
                        {/*</div>*/}
                    </div>

                    <MediaQuery minDeviceWidth={768}>
                    <div className={styles.priceFlex}>
                        <div className={styles.boxContainer}>
                            <div className={styles.upperFlex}>
                                <div className={styles.category}>
                                    Bestseller
                                </div>
                                <div>
                                    {this._renderFavourite()}
                                </div>
                            </div>
                            <div className={styles.lowerFlex}>
                                <div className={styles.textInside}>From</div>
                                <div>
                                    <span className={styles.price}>{data.currency_shortcode}{data.starting_price} </span>
                                    <span className={styles.textInside}>Per Member</span>
                                </div>
                            </div>
                            <div className={styles.buttonContainer}>
                                <ButtonBase
                                    onClick={this._handleBookNow}
                                    className={styles.book}>
                                    {data.is_personalized ? 'Personalize Tour' : 'Book Now'}
                                </ButtonBase>
                            </div>
                        </div>
                        {data.is_personalized ? this.renderPersonalizedTours() : ''}

                        <div className={styles.reviewContainer}>
                            <RecentReview/>
                        </div>
                    </div>
                    </MediaQuery>
                </div>

                <MediaQuery maxDeviceWidth={768}>
                    <div>
                    <Email/>
                    </div>
                </MediaQuery>
                <MediaQuery minDeviceWidth={768}>
                    <div className={'container'}>
                        <h3 className={styles.tourDetailHeading}>You might also like...</h3>
                        <div style={{display: 'flex'}}>
                            {this._renderTourCardsBelow()}
                        </div>
                        <Email/>
                    </div>
                </MediaQuery>

            </div>
        )
    }
}

export default TourDetail
