/**
 * Created by charnjeetelectrovese@gmail.com on 9/19/2017.
 */
import React from 'react';
import ReactDOM from 'react-dom';

import File from "../components/FileComponent/FileComponent.component";
import {CheckBox} from '@material-ui/icons'
// import { RadioButton, RadioButtonGroup } from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import SelectField from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {Input,IconButton} from '@material-ui/core';
import ReduxCountryContact from '../components/country/ReduxCountryContact.component';
import TimePicker from '../components/ReduxDatePicker/ReduxTimePicker.component';
import InputAdornment from '@material-ui/core/InputAdornment';
import  { Visibility, VisibilityOff } from '@material-ui/icons';
import AccountCircle from '@material-ui/icons/AccountCircle';

export const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField
        error={(touched && error) ? true : false}
        {...input}
        {...custom}
        label={label}
        helperText={((touched && error) ? true : false) ? (custom.errorText ? custom.errorText : error )  : ''}
    />
    // <TextField
    //     {...input}
    //     {...custom}
    //     hintText={label}
    //     floatingLabelText={label}
    //     errorText={touched && error}
    // />
);

export const renderOutlinedTextField = ({input,icon, label, meta: {touched, error}, ...custom}) => (
    <TextField
        error={(touched && error) ? true : false}
        helperText={((touched && error) ? true : false) ? (custom.errorText ? custom.errorText : error )  : ''}
        {...input}
        {...custom}
        label={label}
        InputProps={{
            startAdornment: icon ? (
                <InputAdornment position="start">
                    {icon ? <img className={'fieldIcon'} src={icon}/> : '' }
                </InputAdornment>
            ):'',
        }}
        // helperText={touched && error}
        variant={'outlined'}
    />
);


export const renderCheckbox = (props) => {
    const {input, label} = props;
    return (
        <FormControlLabel
            control={<Checkbox
                // checked={input.value ? true : false}
                onChange={input.onChange}
            />}
            label={label}
        />);
}


// export const renderRadioGroup = ({ input, ...rest }) =>
//     (<RadioButtonGroup
//         {...input}
//         {...rest}
//         valueSelected={input.value}
//         onChange={(event, value) => input.onChange(value)}
//     />);

export const renderSelectField = ({input, label, meta: {touched, error}, children, ...custom}) => (
    <SelectField
        floatingLabelText={label}
        errorText={touched && error}
        {...input}
        onChange={(event, index, value) => input.onChange(value)}
        children={children}
        {...custom}
    />
);

export const renderNewSelectField = ({input, label, meta: {touched, error}, children, ...custom}) => (
    <FormControl fullWidth error={touched && error}>
        <InputLabel htmlFor={custom.inputId}>{label}</InputLabel>
        <SelectField
            {...input}
            {...custom}
            inputProps={{
                id: custom.inputId,
            }}
        >
            {children}
        </SelectField>
    </FormControl>
)


export class renderOutlinedSelectField extends React.Component {
    state = {
        labelWidth: 0,
    };

    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }

    render() {
        const { input, label,icon, margin, meta: { touched, error }, children, ...custom } = this.props;
        return (
            <FormControl fullWidth variant="outlined" margin={margin} error={touched && error}>
                <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor={custom.inputId}

                >
                    {label}
                </InputLabel>

                <div style={{position: 'relative', display: 'inline-block'}}>
                    {icon ? <img className={'selectIcon'} src={icon}/> : '' }
                <SelectField
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            labelWidth={this.state.labelWidth}
                            id={custom.inputId}
                        />
                    }

                >
                    {children}
                </SelectField>
                </div>
                <FormHelperText>{((touched && error) ? true : false) ? (custom.errorText ? custom.errorText : error) : ''}</FormHelperText>
            </FormControl>
        )
    }
}

export const renderFileField = (props) => {
    const {input, label, type, max_size, multiple,setImage, meta: {touched, error}, ...custom} = props;
    // console.log(input, label, type, max_size, multiple,custom,props.meta,setImage)
    return (
        <File
            error={(touched && error) ? true : false}
            multiple={multiple ? multiple : false}
            {...input}
            {...custom}
            // file={this.state.files['company_logo']}
            max_size={max_size ? max_size : 20000}
            name={label}
            type={type ? type : []}
            // handleChange={this._handleFileChange}
            placeholder={label}
        />
    );
}

export const renderCountryContact = ({input, label, country_code, maxLimit, meta: {touched, error}, ...custom}) => {
    console.log('renderCountryContact', touched, error);
    return (
        <ReduxCountryContact
            error={(touched && error) ? true : false}
            helperText={((touched && error) ? true : false) ? (custom.errorText ? custom.errorText : error) : ''}
            input={input}
            {...input}
            {...custom}
            label={label}
            country={country_code}
            // helperText={touched && error}
            variant={'outlined'}
        />
    );
}

export const renderTimePicker = (props) => {
    const {input, label, ampm, type, minDate, max_size, multiple, meta: {touched, error}, ...custom} = props;
    return (
        <TimePicker
            error={(touched && error) ? true : false}
            {...input}
            {...custom}
            // file={this.state.files['company_logo']}
            name={label}
            ampm={ampm}
            minDate={minDate}
            // handleChange={this._handleFileChange}
            label={label}
        />
    );
}

export class renderPasswordField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };
        this.input = React.createRef();
        this._handleClickShowPassword = this._handleClickShowPassword.bind(this);
        this._handleMouseDownPassword = this._handleMouseDownPassword.bind(this);
    }

    _handleClickShowPassword = () => {
        this.input.current.children[0].blur()
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword
        });
    };

    _handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render() {
        const { showPassword } = this.state;
        const {input, multiple, label, limitTags, margin, meta: {touched, error}, dataObj, data, children, ...custom} = this.props;
        return (<FormControl margin={'dense'} fullWidth error={touched && error}>
            <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
            <Input
                error={(touched && error) ? true : false}
                helperText={((touched && error) ? true : false) ? (custom.errorText ? custom.errorText : error) : ''}
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                // variant={'outlined'}
                fullWidth={true}
                {...input}
                {...custom}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={this._handleClickShowPassword}
                            onMouseDown={this._handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility fontSize={'small'}/> : <VisibilityOff fontSize={'small'}/>}
                        </IconButton>
                    </InputAdornment>
                }
                innerRef={this.input}
            />
        </FormControl>);
    }
}