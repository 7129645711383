/**
 * Created by charanjeetelectrovese@gmail.com on system AakritiS. on 22/03/19.
 */

import {serviceSyncWishlist, serviceToggleWishlist} from "../services/Wishlist.service";
import store from '../store';

export const WISHLIST_SET = 'WISHLIST_SET';
export const WISHLIST_RESET = 'WISHLIST_RESET';
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST';
export const SYNC_WISHLIST = 'SYNC_WISHLIST';

export function actionWishlistReset(data) {
    return (dispatch) => {
        dispatch({ type: WISHLIST_RESET, payload: data });

    }
}

export function actionSetWishlist(list) {
    return (dispatch) => {
        dispatch({ type: WISHLIST_SET, payload: list });
    }
}

export function actionToggleWishlist(id) {
    const authState = store.getState().auth;
    if (authState.is_authenticated) {
        const req = serviceToggleWishlist({product_id: id});
        req.then((data) => {

        });
    }
    return (dispatch) => {
        dispatch({ type: TOGGLE_WISHLIST, payload: id});

    }
}


export function actionSyncWishlist() {
    const data = store.getState().wishlist;
    const req = serviceSyncWishlist({ list: data.list });
    return (dispatch) => {
        req.then((data) => {
            if (!data.is_error) {
                dispatch({type: SYNC_WISHLIST, payload: data.data.wishlist});
            } else {
                dispatch({type: SYNC_WISHLIST, payload: []});
            }
        });
    }
}
