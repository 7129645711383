import React, {Component} from 'react';
import styles from './PortraitCard.module.css'
import {FavoriteBorder as Favorite, Favorite as FavoriteFilled} from '@material-ui/icons';
import history from "../../../libs/history.util";
import RouteNames from "../../../routes/Route.name";

class PortraitCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            is_filled: false
        };
        this._handleClick = this._handleClick.bind(this);
    }

    _renderFavourite() {
        if (this.state.is_filled)
            return (
                <FavoriteFilled fontSize={'2rem'} className={styles.icons}/>
            );
        else
            return <Favorite fontSize={'2rem'} className={styles.fontIcon}/>

    }

    _handleClick () {
        history.push(RouteNames.cities);
    }


    render(){
        return(
            <div style={{marginRight:'10px'}} className={styles.mainContainer}  onClick={this._handleClick}>
                <div className={styles.cardContainer}>
                    <div className={styles.backgroundImage} style={{
                        backgroundImage: 'url(' + require('../../../assets/img/tuxi/City.png') + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition:'center'
                    }}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div></div>
                            <div className={styles.favorite}>
                                {this._renderFavourite()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottomText}>
                    <div>
                    <div className={styles.name}>
                        Lisbon
                    </div>
                    <div className={styles.totalCount}>
                        1900 Tours and Activities
                    </div>
                    </div>
                    <div>
                        <div className={styles.fromText}>
                            From
                        </div>
                        <div className={styles.price}>
                            $40
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PortraitCard