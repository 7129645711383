import React, {Component} from 'react';
import styles from './ManufactureCard.module.css';
import {ArrowRightAlt,TurnedIn} from '@material-ui/icons'
import classNames from 'classnames';
import ButtonBase from "@material-ui/core/ButtonBase";
import history from '../../../libs/history.util';
import RouteNames from "../../../routes/Route.name";
import data from '../../../data'

class ManufactureCard extends Component{
    constructor(props) {
        super(props);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick () {
        history.push(RouteNames.manufacturer);
    }

    _renderTopProducts(){
        return (
            data.manufacture.map((val)=> {
                return (
                    <div className={styles.mainContainer} onClick={this._handleClick}>
                        <div className={styles.turned}><TurnedIn className={styles.bookmark}/></div>
                        <div className={styles.seller}>Top<br/>Seller</div>
                        <div className={styles.indusCont}><img src={val.company} className={styles.industryIcons}/></div>
                        <div className={styles.outline}>
                            <div className={styles.badgesFlex}>
                                <img src={val.badgeA} className={styles.badgeIcon}/>
                                <img src={val.badgeB} className={styles.badgeIcon}/>
                                <img src={val.badgeC} className={styles.badgeIcon}/>
                                <img src={val.badgeD} className={styles.badgeIcon}/>
                                <img src={val.badgeE} className={styles.badgeIcon}/>
                            </div>
                            <div className={styles.title}>
                                {val.title}
                            </div>
                            <div className={styles.min}>Pharmaceuticals</div>
                            <div className={styles.description}>
                                {val.desc}
                            </div>
                            <div className={styles.innerCard}>
                                <ButtonBase className={styles.inquire}>
                                    inquire now <ArrowRightAlt/>
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    render(){
        return(
            <div className={classNames(styles.wrapper,styles.prodFlex)}>
                {this._renderTopProducts()}
            </div>
        )
    }
}

export default ManufactureCard
