/**
 * Created by charnjeetelectrovese@gmail.com on 1/8/2020.
 */
import {applyMiddleware, compose, createStore} from "redux";
import reducer from "./reducers/index.reducer";
import thunk from "redux-thunk";
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
    key: 'root',
    storage: storage,
    version: 5.5,
    blacklist: ['offers', 'filter','form', 'wishlist']
    // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};


const pReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];
// middlewares.push(logger);


const store = createStore(pReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;

export const persistor = persistStore(store);
