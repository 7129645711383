import React, {Component} from 'react';
import styles from './Chat.module.css';
import data from '../../data'

class Chats extends Component{
    render(){
        return(
            <div>
                <div style={{height:'65px'}}>
                </div>

                {data.chats.map((val)=> {
                    return (
                        <div className={styles.chatContainer}>
                            <div className={styles.chatInner}>
                                    <img src={val.image} className={styles.driverIcon}/>
                                <div className={styles.driverName}>
                                    <div className={styles.name}>{val.name}</div>
                                </div>
                            </div>
                        </div>
                    )
                })}


            </div>
        )
    }
}

export default Chats
