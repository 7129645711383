/**
 * Created by charnjeetelectrovese@gmail.com on 11/12/2019.
 */
import store from '../store';
import {actionFetchTours} from './Tours.action';
import constants from "../config/constants";
import {setParams} from "../libs/URL.utils";
import history from "../libs/history.util";

export const FILTER_UPDATE = 'FILTER_UPDATE';
export const CLEAR_FILTER = 'CLEAR_FILTER';


function updateUrl() {
    const tempState = store.getState().filter;
    const temp = {};
    Object.keys(tempState).forEach((key) => {
        const val = tempState[key];
        if (key == 'price') {
            temp['min_price'] = val.min;
            temp['max_price'] = val.max;
        } else {
            if (val && (val != '' || (Array.isArray(val) && val.length > 0))) {
                temp[key] = Array.isArray(val) ? val.join(',') : val;
            }
        }
    });
    console.log('FILTER_UPDATE', temp);
    const url = setParams(temp);
    console.log('filter url', encodeURIComponent(url));
    history.push(`?${(url)}`);
}

export function actionUpdateFilter(data, shouldUrlUpdate = true, shouldFetchTours = true) {
    console.log(data)
    return (dispatch) => {
        dispatch({type: FILTER_UPDATE, payload: data});

        if (shouldFetchTours) {
            store.dispatch(actionFetchTours());
        }

        if (shouldUrlUpdate) {
            updateUrl();
        }
    };
    // return ({type: AUTH_USER, payload: data});
}


export function actionCleanFilter(p, shouldUrlUpdate = true, shouldFetchTours = true) {
    return (dispatch) => {
        dispatch({type: CLEAR_FILTER, payload: p});
        if (shouldFetchTours) {
            store.dispatch(actionFetchTours());
        }
        if (shouldUrlUpdate) {
            updateUrl();
        }
    };
}

export function actionHydrateFilters(location) {
    console.log('actionHydrateFilters', location, constants.initialFilterState);
    store.dispatch(actionCleanFilter('ALL', false, false));
    const params = new URLSearchParams(location);
    const tempFilter = {...JSON.parse(JSON.stringify(constants.initialFilterState)), min_price: 0, max_price: 0};
    const temp = {};
    Object.keys(tempFilter).forEach((key) => {
        const val = tempFilter[key];
        const linkVal = params.get(key);
        if (linkVal) {
            if (Array.isArray(val)) {
                temp[key] = linkVal.split(',');
            } else if (key == 'min_price') {
                if (!('price' in temp)) {
                    temp['price'] = {min: 0, max: 0};
                }
                temp['price']['min'] = linkVal;
            } else if (key == 'max_price') {
                if (!('price' in temp)) {
                    temp['price'] = {min: 0, max: 0};
                }
                temp['price']['max'] = linkVal;
            } else {
                temp[key] = linkVal
            }
        }
    });

    console.log('actionHydrateFilters', temp);
    return (dispatch) => {
        store.dispatch(actionUpdateFilter(temp, false));
    };
}
