import React, {Component} from 'react';
import styles from './Cards.module.css'
import ButtonBase from '@material-ui/core/ButtonBase'

import CitiesCard from '../../components/Paper/Cities/Cities.component'
import PortraitCard from "../../components/Paper/Portrait/PortraitCard.component";
import LandscapeCard from "../../components/Paper/LandScape/LandscapeCard.component";
import Mini from '../../components/Paper/Mini/Mini.component'
import AttractionCard from "../../components/Paper/AttractionCard/AttractionCard.component";
import TourCard from "../../components/Paper/TourCard/TourCard.component";

import Banner from '../../views/Banner/Banner.view'
import TopBar from "../../components/TopBar/TopBar.component";
import FooterView from "../../views/Footer/Footer.view";

class Cards extends Component{

    _renderTours(){
        return(
            [1,2,3].map(val => <TourCard/>)
        )
    }

    _renderMiniCards(){
        return(
            [1,2,3,4,5,6].map(val => <Mini/>)
        )
    }

    _renderPortraitCards(){
        return (
            [1,2,3].map(val => <PortraitCard/>)
        )
    }

    _renderCities(){
        return(
            [1,2,3,4,5,6,7,8,9,10,11,12].map(val => <CitiesCard/>)
        )
    }

    render(){
        return(
            <div>
                <Banner/>
                <div className={styles.pilotRating}>
                    <div className={styles.mainContainer} style={{display:'flex',alignItems:'center'}}>
                        <img src={require('../../assets/img/tuxi/ic_trust_pilot.png')} style={{height:'35px'}}/>
                        <span className={styles.ratings}>4.9 out of 5 Reviews</span>
                    </div>
                </div>

                <div className={styles.mainContainer}>
                    <div>
                        <div className={styles.upperHeading}>
                            <h2 className={styles.mainHeading}>Popular Tours </h2>
                            <div className={styles.subHeading}> Discover popular tours around you</div>
                        </div>
                        {this._renderTours()}


                        <div className={styles.videoFlex}>
                            <div className={styles.videoTuxi}>
                                {/*<video className={styles.tuxivideo} controls="false" autoPlay loop muted>*/}
                                    {/*<source src={require('../../assets/img/tuxi/tuxiwebsite_update_1_2.mp4')}*/}
                                            {/*type="video/mp4"/>*/}
                                {/*</video>*/}
                            </div>
                            <div className={styles.cashbackContainer}>
                                <div>
                                    <img src={require('../../assets/img/tuxi/cashback.png')}
                                         className={styles.cashback}/>
                                </div>
                                <div className={styles.textContainer}>
                                    <div className={styles.waitingText}>What are you Waiting for?</div>
                                    <p className={styles.signupText}>Signup and explore specially curated
                                        experiences.More amazing offers awaiting when you
                                        register.So signup today and start exploring</p>
                                    <ButtonBase
                                        className={styles.signUp}>
                                        Signup
                                    </ButtonBase>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.companiesContainer}>
                    <div className={styles.mainContainer}>
                        <div className={styles.companyFlex}>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo1.png')} className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo3.png')} className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo2.png')}className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo1.png')}className={styles.companyLogo}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/tuxi/logo4.png')} className={styles.companyLogo}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.mainContainer}>
                    <div>
                        <div className={styles.upperHeading}>
                        <h2 className={styles.mainHeading}>Top Attractions </h2>
                        <div className={styles.subHeading}> Discover top attrations around you and Get 100% fun</div>
                         </div>

                          <div style={{display: 'flex'}}>
                            <AttractionCard/>
                             <div className={styles.miniFlex}>
                            {this._renderMiniCards()}
                            </div>
                            </div>
                        <div className={styles.listButton}>
                            <ButtonBase
                                className={styles.signUp}>
                                See all tours and activities in Lisbon
                            </ButtonBase>
                        </div>
                    </div>

                    <div className={styles.container}>
                    <div className={styles.upperHeading}>
                        <h2 className={styles.mainHeading}>Top Cities </h2>
                        <div className={styles.subHeading}> Discover top cities around you & roam with fun</div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div>
                        <PortraitCard/>
                        </div>
                        <div>
                        <LandscapeCard/>
                        </div>
                        <div className={styles.dottedImage}></div>
                    </div>
                    <div style={{display: 'flex'}}>
                        {this._renderPortraitCards()}
                    </div>
                    </div>
                    <br/>
                    <br/>
                    <br/><br/>


                    <div style={{display: 'flex'}}>
                        <LandscapeCard/>
                        <PortraitCard/>
                    </div>
                    <div style={{display: 'flex'}}>
                        {this._renderPortraitCards()}
                    </div>

                    <div className={styles.emailFlex}>
                        <div className={styles.outerFlex}>
                            <img src={require('../../assets/img/tuxi/auto.png')} className={styles.autoImage}/>
                        </div>
                        <div className={styles.innerFlex}>
                            <div className={styles.emailContainer}>
                                <div className={styles.latestText}>
                                    Subscribe to Get Latest Updates
                                </div>
                                <p className={styles.signUpEmail}>
                                    Sign up for our newsletter and discover travel experiences you'll really
                                    want to try
                                </p>
                                <div className={styles.buttonFlex}>
                                    <div className={styles.emailBox}>
                                        Email
                                    </div>
                                    <div className={styles.subscribeButton}>
                                        <ButtonBase
                                            className={styles.signUp}>
                                           Subscribe
                                        </ButtonBase>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.infoText}>
                        By signing up , you agree to receive promotional emails. You can unsubscribe at any time.
                        For more information , read our <span style={{color:'#2CB5A5',fontWeight:'500'}}>privacy statement</span>
                    </div>

                    <div className={styles.container}>
                    <div className={styles.upperHeading}>
                        <h2 className={styles.mainHeading}>All Cities </h2>
                        <div className={styles.subHeading}> Discover all cities around you & experience with fun</div>
                    </div>
                    <div className={styles.citiesFlex}>
                        {this._renderCities()}
                    </div>
                        <div className={styles.listButton}>
                            <ButtonBase
                                className={styles.view}>
                                View More
                            </ButtonBase>
                        </div>
                    </div>
                </div>

                <div className={styles.footerContainer}>
                    <div className={styles.mainContainer}>


                    </div>
                </div>

            </div>

        )
    }
}

export default Cards
