/**
 * Created by charnjeetelectrovese@gmail.com on 5/27/2020.
 */
import React, { Component } from 'react';
import { withTheme } from "@material-ui/core";
import styles from './styles.module.css';
import Collapse from "../../../../components/Collapse/Collapse.component";
import RatingFilter from "./RatingFilter.view";

class Chip extends Component{
    constructor(props){
        super(props);
        this.state={
        };
        this._handleClick = this._handleClick.bind(this)
    }
    _handleClick(){
        // this.props.handleClick(this.props.data.value);
    }

    render() {
        const {data, theme} = this.props;
        if (data.id) {
            return (
               <div>
                   <div className={styles.title}>{data.title}</div>
                   <ul className={styles.parent}>
                       {data.child ? data.child.map((val) => <li className={styles.child}>{val}</li>):''}
                   </ul>
               </div>
            )
        } else if(data.type){
            return (
                <RatingFilter/>
            )
        } else {
            return (
                <div className={styles.chipContainer} style={this.props.checked ? {color: theme.highlightColor} : {}}
                     onClick={this._handleClick}>
                    {/*<input type="checkbox" name={"v1"} value={"v1"} checked={this.props.checked} style={this.props.checked ? {backgroundColor: theme.highlightColor}: {}}  />*/}
                    <span className={styles.chipTitle}>
                    <img className={styles.certify} src={data.image}/>{data.title}
                    </span>
                </div>

            )
        }
    }
}


class CheckboxFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
        };
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        // this.setState({
        //     filter: this.props.filters
        // });
    }

    componentWillReceiveProps(nextProps){
        // if( (JSON.stringify(nextProps.filters) !== JSON.stringify(this.props.filters))){
        //     this.setState({
        //         filter: nextProps.filters
        //     })
        // }
    }

    _handleClick (value) {
        // const {filter} = this.state;
        const { type, filter } = this.props;
        const index  = filter.indexOf(value);
        const tempVehicles = filter;

        if (index >= 0) {
            tempVehicles.splice(index, 1);
        } else {
            tempVehicles.push(value);
        }

        // this.setState({
        //     filter: tempVehicles,
        // });

        this.props.handleChange(type, tempVehicles);
    }


    _renderList() {
        const { list_data,theme } = this.props;
        const { filter } = this.props;
        return list_data.map((val) => {
            const isThere =  filter.indexOf(val.value) >= 0;
            return <Chip
                theme={theme}
                checked={isThere}
                data={val}
                handleClick={this._handleClick}
            />
        })
    }

    render() {
        const { label } = this.props;
        return (
            <Collapse title={label}>
                {this._renderList()}
            </Collapse>
        );
    }

}

export default withTheme(CheckboxFilter);
