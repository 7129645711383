/**
 * Created by charnjeetelectrovese@gmail.com on 1/8/2020.
 */
import {serviceGetTours} from "../services/Tours.service";
import store from "../store";


export const TOURS_INIT = 'TOURS_INIT';
export const TOURS_DONE = 'TOURS_DONE';
export const SELECT_TOUR = 'SELECT_TOUR';

export function actionFetchTours(params = null) {
    const tempState = store.getState().filter;
    console.log('actionFetchTours', tempState);
    const tempQuery = {page: 1};
    Object.keys(tempState).forEach((key) => {
        const val = tempState[key];
        if (val && (val != '' || (Array.isArray(val) && val.length > 0))) {
            tempQuery[key] = val;
            console.log(tempQuery[key])
        }

    });
    const request = serviceGetTours(tempQuery);
    return (dispatch) => {
        dispatch({type: TOURS_INIT, payload: null});
        request.then((data) => {
            if (!data.error) {
                dispatch({type: TOURS_DONE, payload: data.data});
            }
        })
        //     .catch((err) => {
        //         //     console.log(err)
        //         // })
    };
    // return ({type: AUTH_USER, payload: data});
}


export function  actionSelectTour(data) {
    return (dispatch) => {
        dispatch({type: SELECT_TOUR, payload: data});
    }
}
