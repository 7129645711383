/**
 * Created by charnjeetelectrovese@gmail.com on 2/11/2020.
 */
import React, {Component} from 'react';
import {ButtonBase, CircularProgress} from '@material-ui/core';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import styles from './Cart.module.css';
import classStyles from '../Payment/Payment.module.css';
import RouteNames from '../../routes/Route.name';
import {actionUpdateBoardType} from "../../actions/Common.action";
import {actionApplyCoupon, actionRemoveCoupon} from "../../actions/Cart.action";

class PromoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
        this._handleApply = this._handleApply.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRemove = this._handleRemove.bind(this);
    }

    _handleApply() {
        this.props.actionApplyCoupon(this.state.value);
    }

    _handleRemove() {
        this.props.actionRemoveCoupon();
    }

    _handleChange(e) {
        this.setState({
            value: e.target.value,
        });
    }

    _renderApplyBtn() {
        const {coupon_applying} = this.props;
        if (coupon_applying) {
            return (
                <CircularProgress size={30}></CircularProgress>
            )
        } else {
            return (
                <ButtonBase onClick={this._handleApply}>
                    <div style={{color: '#2196F3', fontWeight: '500', fontSize: '16px', padding: '8px',}}>
                        APPLY
                    </div>
                </ButtonBase>
            )
        }
    }
    render() {
        const { coupon_applied, coupon_applying, coupon } = this.props;
        if (!coupon_applied) {
            return (
                <div className={styles.container}>
                    <div className={styles.innerFlex}>
                        <div>
                            <img src={require('../../assets/img/ic_promo_code@3x.png')} style={{height: '28px'}}/>
                        </div>
                        <div style={{flex: '1'}}>
                            <input
                                className={styles.promo}
                                name="promo"
                                value={this.state.value}
                                onChange={this._handleChange}
                                type="text"
                                placeholder="Enter Promo Code"
                            />
                        </div>

                        <div>
                            {this._renderApplyBtn()}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={styles.container}>
                    <div className={styles.innerFlex}>
                        <div>
                            <img src={require('../../assets/img/mark.png')} style={{height: '28px'}}/>
                        </div>
                        <div style={{flex: '1'}}>
                            <div style={{ color: '#2196F3', fontWeight: '500', marginLeft: '10px' }}
                            >{coupon.name} {' '}<span style={{ color: '#2196F3', fontWeight: '300', fontSize: '14px' }}>{' '} - Promo code Applied </span>
                            </div>
                            <div style={{fontSize: '14px', color: 'grey', marginLeft: '10px'}}>
                                {coupon.discount} {coupon.discount_type} Off On this order
                            </div>
                        </div>
                        <div>
                            <ButtonBase onClick={this._handleRemove}>
                                <div style={{ color: 'red', fontWeight: '500', fontSize: '16px', padding: '8px' }}>
                                    Remove
                                </div>
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionApplyCoupon: actionApplyCoupon,
        actionRemoveCoupon: actionRemoveCoupon,
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        coupon_applied: state.cart.coupon_applied,
        coupon_applying: state.cart.coupon_applying,
        coupon: state.cart.coupon
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(PromoComponent);
