import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styles from './style.module.css'


class RatingSlider extends Component{

    _renderSlider(){
        return [1,2,3].map((val)=> {

            return (
                <div>
                    <div className={styles.containers}>
                        <div className={styles.fle}>
                        <img src={require('../../../../assets/img/tuxi/stars-5.svg')} className={styles.fiveStar}/>
                        <div>2 hours ago</div>
                        </div>
                        <p className={styles.lorem}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industrys
                        </p>
                        <div className={styles.title}>Jonathan Walker</div>
                    </div>
                </div>
            )
        })
    }

    handleNext() {
        if (this.slider) {
            this.slider.slickNext();
        }
    }

    handlePrev() {
        if (this.slider) {
            this.slider.slickPrev();
        }
    }

    render(){
        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     nav: false,
        //     accessibility: false,
        //     arrows: true,
        //     speed: 500,
        //     slidesToShow: 1,
        //     // centerPadding: '50px',
        //     // centerMode: true,
        //     slidesToScroll: 1,
        //     variableWidth: true,
        //     touchThreshold:10
        //     // autoplay: true
        // }
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold: 10
        }

        return(
            <div>
                <Slider ref={c => { this.slider = c; }} {...settings}>
                    {this._renderSlider()}
                </Slider>
            </div>
        )
    }
}

export default RatingSlider