import React,{Component} from 'react';
import styles from './Confirm.module.css';
import Card from '../RideDetail/Card/Card.component';
import ButtonBase from '@material-ui/core/ButtonBase';
import RouteNames from '../../../routes/Route.name';

class Confirm extends Component {
    constructor(props){
        super(props);

        this.state = {

        }
        this._handleClick = this._handleClick.bind(this)
    }

    _handleClick(){
        this.props.history.push(RouteNames.cart);
    }

    render(){
        return(
            <div>
                <div style={{
                    backgroundImage: 'url(' + require('../../../assets/img/map.png') + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height:'100vh'
                }}>

            <div className={styles.confirm}>
                <div className={styles.heading}>
                    Confirm Your Ride
                </div>
                <div>
                    <Card board_type={this.props.board_type}/>
                </div>

                <div className={styles.spacing}>
                    <div>
                        <ButtonBase classes={{ root: styles.bookbtn}} onClick={this._handleClick}>
                            <span style={{fontSize: '18px',color:'white',fontWeight:'500'}}>Confirm Pickup</span>
                        </ButtonBase>
                    </div>
                </div>

                <div style={{height:'40px'}}>

                </div>
            </div>
            </div>
            </div>
        )
    }
}

export default Confirm
