import React,{Component} from 'react'
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import Categories from './Category.component'

import imgData from '../../../../data'

class CategoryCard extends Component{

    _renderCategory(){
        return this.props.data.map((val)=> {

            return <Categories val={val}/>
        })
    }
    render(){
        const settings = {
            dots: false,
            infinite: true,
            nav: false,
            accessibility: false,
            arrows: false,
            speed: 500,
            slidesToShow: 1,

            // centerPadding: '50px',
            // centerMode: true,
            slidesToScroll: 1,
            variableWidth: true,
            touchThreshold:10
            // autoplay: true
        }

        return(
            <div>
                <Slider {...settings}>
                    {this._renderCategory()}
                    {/*<Categories/>*/}
                    {/*<Categories/>*/}
                    {/*<Categories/>*/}
                </Slider>
            </div>
        )
    }
}

export default CategoryCard