import React, {Component} from 'react'
import styles from './Reviews.module.css'
import {Star,FavoriteBorder as Favorite } from '@material-ui/icons'

class Reviews extends Component{
    render(){
        return(
            <div className={styles.card}>
                <div className={styles.cont}>
                    <div style={{display:'flex'}}>
                    <img src={require('../../assets/img/Profile.png')} className={styles.imgS}/>
                        <div className={styles.heading}>
                        <div>Catherine</div>
                        <div className={styles.icons}>
                            <Star fontSize ={'small'} className={styles.fontSizeSmall}/>
                            <span className={styles.rating}>4.6</span>
                        </div>
                        </div>
                    </div>
                    <div className={styles.summary}>
                    <span className={styles.date}>On 2nd August 2019</span>
                        <div className={styles.description}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Reviews;