/**
 * Created by charnjeetelectrovese@gmail.com on 5/30/2020.
 */
import React, {Component} from 'react';
import styles from "./Style.module.css";
import Addition from "../../../../components/Addition/Addition.component";

class Passengers extends Component {
    constructor(props) {
        super(props);
        this._handlePriceChange = this._handlePriceChange.bind(this);
        this.state = {
            data: {},
            is_set: false
        }
    }

    componentDidMount() {
        const {prices, initialData} = this.props;
        console.log('Passengers', initialData);
        const tempPassengers = {};
        initialData.forEach((val) => {
            tempPassengers[val.title] = val;
        });
        const data = {};
        prices.forEach((val, index) => {
            data[val.title] = {
                type: val.title,
                price: val.price,
                number: tempPassengers[val.title] ? tempPassengers[val.title].number : index === 0 ? 1 : 0
            };
        });
        this.setState({
            data: data,
            is_set: true
        }, () => {
            this.props.handlePassengerChange(this._processPrices(data));
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    _processPrices(data) {
        const tempData = [];
        Object.keys(data).forEach((key) => {
            const val = data[key];
            if (val.number > 0) {
                tempData.push({
                    title: val.type,
                    price: val.price,
                    number: val.number,
                    total: val.price * val.number,
                });
            }
        });
        return tempData;
    }

    _handlePriceChange(type, total) {
        const {data} = this.state;
        data[type]['number'] = total;
        this.setState({
            data: data,
        }, () => {
            this.props.handlePassengerChange(this._processPrices(data));
        });
    }

    _renderPrice() {
        const {prices, inventory} = this.props;
        const {data} = this.state;
        let totalNumber = 0;
        Object.keys(data).forEach((key) => {
            totalNumber += data[key].number;
        });
        return prices.map((val, index) => {
            return (
                <div key={'price_' + index} className={styles.passengerCont}>
                    <div>
                        <div className={styles.heading}>
                            {val.title}
                        </div>
                        <div className={styles.maxSeats}>
                            ({val.age})
                        </div>
                    </div>
                    <div className={styles.addCounter}>
                        <Addition
                            totalPassengers={totalNumber}
                            max={inventory}
                            min={index === 0 ? 1 : 0}
                            handleChange={this._handlePriceChange}
                            data={data[val.title]}
                        />
                    </div>
                </div>
            );
        })

    }

    render() {
        const {prices, tour_schedule} = this.props;
        if (this.state.is_set) {
            return (
                <div className={styles.flexCont}>
                    {this._renderPrice()}
                </div>
            );
        }
        return null;
    }

}

export default Passengers;
