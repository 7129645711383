import React, {Component} from 'react';
import Banner from '../../views/Banner/Banner.view';
import {ButtonBase,MenuItem} from '@material-ui/core';
import {Link} from 'react-router-dom';
import styles from './Pharma.module.css';
import classNames from "classnames";
import SearchCard from "../Searches/component/SearchCard.component";
import data from "../../data";
import Portfolio from "../Portfolio/Portfolio.view";
import ProdSlider from "../Products/components/ProdSlider/ProdSlider.component";
import ManufactureList from "../Manufacture/components/ManufactureList/ManufactureList.view";
import ManufactureCard from "../Manufacture/components/ManufactureCard.view";
import QuoteForm from "../Discover/QuoteForm/QuoteForm.view";
import OutSource from "../Home/OutSource/OutSource.view";
import BulkSlider from "../Bulk/components/BulkSlider/BulkSlider.component";

class PharmaCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: false,
        }
        this._toggleHover = this._toggleHover.bind(this);
    }

    _toggleHover() {
        this.setState({
            icon: !this.state.icon
        })
    }

    render() {
        const { hoverImage, blankImage, content } = this.props;
        return ( <div className={styles.bgContainer} onMouseEnter={this._toggleHover} onMouseLeave={this._toggleHover} >
            <div className={styles.quotesflex}>
                <img src={this.state.icon ? hoverImage : blankImage}
                     className={styles.quoteIcon}/>
                <div className={styles.closedeal}>
                    {content}
                </div>
            </div>
        </div>);
    }
}

class Pharma extends Component {
    constructor(props) {
        super(props);

        this.state = {
            icon:false,
        };
        this.toggleHover = this.toggleHover.bind(this)
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    toggleHover() {
        this.setState({
            icon: !this.state.icon
        })
    }

    _renderTopSearches(){
        return ['Paracetamol','Face Mask','Paracetamol Liquid','Paracetamol','Face Mask'].map((val)=> {
            return <SearchCard data={val}/>
        })
    }

    _renderSearch(){
        return ['Paracetamol Liquid','Paracetamol','Face Mask'].map((val)=> {
            return <SearchCard data={val}/>
        })
    }

    _renderCategories(){
        return(
            data.categories.map((val)=>{
                return (
                    <div className={styles.industryContainer}>
                        <div className={styles.indusCont}><img src={val.image} className={styles.industryIcon}/></div>
                        <div className={styles.title}>{val.text}</div>
                        <div className={styles.vendor}>{val.vendors}</div>
                    </div>
                )
            })
        )
    }

    _renderTopProducts(){
        return(
            <div>
                <ProdSlider/>
            </div>
        )
    }

    _renderTopManu(){
        return (
            [1,2,3,4].map(val => <ManufactureList/>)
        )

    }

    _renderUspIcons(){
        return(
            <div className={styles.uspFlex}>
                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U1.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Order Protection and <br/>payment security</div>
                </div>

                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U2.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Quality delivery with <br/>realtime monitoring</div>
                </div>

                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U3.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Easy Click & Order <br/>hassle free</div>
                </div>

                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U4.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Easy Finance/EMI option <br/>for making payments</div>
                </div>

                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U5.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Assured competitive and<br/>transparent prices</div>
                </div>
                <div className={styles.indUsp}>
                    <div className={styles.uspIcon}>
                        <img src={require('../../assets/img/factory/U4.png')} height={40}/>
                    </div>
                    <div className={styles.uspTxt}>Easy Finance/EMI option <br/>for making payments</div>
                </div>
            </div>
        )
    }

    _renderPlants(){
        return(
            <div className={styles.plantFlex}>
                <div className={styles.cerFlex}>
                    <figure><img src={require('../../assets/img/factory/who.png')} className={styles.who}/></figure>
                    <div className={styles.name}>World health Organisation</div>
                    <div className={styles.ven}>12 Vendors</div>
                </div>

                <div className={styles.cerFlex}>
                    <figure><img src={require('../../assets/img/factory/gmp.png')} className={styles.who}/></figure>
                    <div className={styles.name}>Good manufacturing practices</div>
                    <div className={styles.ven}>12 Vendors</div>
                </div>

                <div className={styles.cerFlex}>
                    <figure><img src={require('../../assets/img/factory/fda.png')} className={styles.who}/></figure>
                    <div className={styles.name}>Food and Drug Administration</div>
                    <div className={styles.ven}>12 Vendors</div>
                </div>

                <div className={styles.cerFlex}>
                    <figure><img src={require('../../assets/img/factory/eu_gmp.png')} className={styles.who}/></figure>
                    <div className={styles.name}>eu gimp slovenle</div>
                    <div className={styles.ven}>12 Vendors</div>
                </div>

                <div className={styles.cerFlex}>
                    <figure><img src={require('../../assets/img/factory/eu_gmp.png')} className={styles.who}/></figure>
                    <div className={styles.name}>eu gmp(cdsco)</div>
                    <div className={styles.ven}>12 Vendors</div>
                </div>

            </div>
        )
    }
    _renderFacIcons(){
        return(
            <div className={styles.quoteFlex}>
                <PharmaCard
                    hoverImage={require('../../assets/img/factory/click_hover.png')}
                    blankImage={require('../../assets/img/factory/click.png')}
                    content={(<>Close deal in<br/>
                        one click</>)}
                />

                <PharmaCard
                    hoverImage={require('../../assets/img/factory/Transparency_hover.png')}
                    blankImage={require('../../assets/img/factory/Transparency.png')}
                    content={(<>Full<br/>
                        Transparency</>)}
                />

                <PharmaCard
                    hoverImage={require('../../assets/img/factory/Protection_hover.png')}
                    blankImage={require('../../assets/img/factory/Protection.png')}
                    content={(<>Order<br/>
                        Protection</>)}
                />

                <PharmaCard
                    hoverImage={require('../../assets/img/factory/Financing_hover.png')}
                    blankImage={require('../../assets/img/factory/Financing.png')}
                    content={(<>Financing<br/>
                        Options</>)}
                />

                <PharmaCard
                    hoverImage={require('../../assets/img/factory/Quality_hover.png')}
                    blankImage={require('../../assets/img/factory/QualityNew.png')}
                    content={(<>Quality<br/>
                        and Trust</>)}
                />

            </div>
        )
    }

    _renderBulk(){
        return(
            <BulkSlider/>
        )
    }

    render() {
        return (
            <div>
                <Banner pharma={true}/>

                <div className={classNames(styles.bgSearch)}>
                    <div className={'ncontainer'}>
                        <h2 className={styles.top}>Top Searches</h2>
                        <div className={styles.searchFlex}>
                            {this._renderTopSearches()}
                        </div>
                    </div>
                </div>

                <div className={styles.margin}></div>
                <div className={'ncontainer'}>
                    <h2>Top Categories</h2>
                    <div className={styles.industyFlex}>
                        {this._renderCategories()}
                    </div>
                </div>

                <Portfolio products/>

                <div className={'ncontainer'}>
                    <div className={styles.request}>
                    An easy way to send <br/> requests to manufactures & get best quotes
                    </div>
                    <div className={styles.dummy}>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque</div>
                    <div className={styles.lapFlex}>
                        <div >
                            {this._renderFacIcons()}
                        </div>
                        <div>
                            <img src={require('../../assets/img/factory/laptop.png')} height={280}/>
                        </div>
                    </div>

                    <div>
                        <div className={'viewFlex'}>
                            <h2>Top Seller Products</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderTopProducts()}
                    </div>

                    <div>
                        <div className={styles.mainFlex}>
                            <div className={styles.innerFlex}>
                                <div className={'viewFlex'}>
                                    <h2>Top Manufacturers</h2>
                                    <Link className={'offerViewBtn'} to="/">view all</Link>
                                </div>

                                <div className={styles.bgcolor}>
                                {this._renderTopManu()}
                                </div>
                            </div>

                            <div className={styles.innerFlex} style={{marginLeft:'15px'}}>
                                <div className={'viewFlex'}>
                                    <h2>Heatmap with Hotspots</h2>
                                    <Link className={'offerViewBtn'} to="/">view hubs</Link>
                                </div>

                                <div>
                                    <img src={require('../../assets/img/factory/map.jpg')} className={styles.map}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={styles.usp}>
                    <div className={styles.uspSection}>
                        <div className={styles.totalUsp}>6 USPs of Factory Grid</div>
                        <div className={styles.break}>Factorygrid is a breakthrough connecting you with verified and trusted manufacturers and making your
                        <br/>manufacturing orders hassle free.</div>
                        <div className={'ncontainer'}>
                            {this._renderUspIcons()}
                        </div>
                    </div>
                </div>

                <div className={'ncontainer'}>
                    <div className={styles.certified}>
                        Looking for certified plants ?
                    </div>
                    <div className={styles.dummy}>
                        FactoryGrid is a breakthrough in connecting you with verified and trusted manufactures and making your<br/>
                        manufacturing orders hassle free
                    </div>

                    <div>
                        {this._renderPlants()}
                    </div>

                    <div className={styles.requestFlex}>
                        <div className={styles.innerFlex} >
                            <QuoteForm/>
                        </div>
                        <div className={styles.innerFlex} style={{marginLeft:'25px'}}>
                            <div>
                                <img src={require('../../assets/img/factory/hireus.png')} className={styles.hire}/>
                            </div>
                            <div className={styles.pos}>
                                    <ButtonBase className={styles.looking}>
                                        Go Premium
                                    </ButtonBase>
                            </div>

                        </div>
                    </div>

                    <div>
                        <div className={'viewFlex'}>
                            <h2>Bulk Deals</h2>
                            <Link className={'offerViewBtn'} to="/">view all</Link>
                        </div>
                        {this._renderBulk()}
                    </div>
                    <br/>
                    <img src={require('../../assets/img/factory/easymob.png')} width={'100%'}/>

                    <div className={styles.out}>
                        <OutSource/>
                    </div>

                </div>
                <div className={classNames(styles.bgSearch)}>
                    <div className={'ncontainer'}>
                        <h2 className={styles.top}>Top Searches</h2>

                        <div className={styles.searchFlex}>
                            {this._renderSearch()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pharma