import React, {Component} from 'react';
import {ButtonBase, CircularProgress, withTheme} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {renderOutlinedSelectField, renderOutlinedTextField, renderTimePicker} from '../../libs/redux-material.utils'
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Cart.module.css';
import Rating from '@material-ui/lab/Rating';
import classStyles from '../Payment/Payment.module.css';
import {TotalSidebar, WaitingComponent} from '../../components/index.component';
import {actionCheckout} from "../../actions/Cart.action";
import RouteNames from '../../routes/Route.name';
import Promocode from './Promo.component';
import {getSocketInstance} from "../../libs/socket.utils";
import DateComponent from "../../components/DateComponent/Date.component";
import Addition from '../../components/Addition/Addition.component'
import {actionCartUpdate} from '../../actions/Cart.action';
import PassengerCount from './components/PassengersCount/Passengers.component';
import {serviceGetTourAvailability, serviceGetTours, serviceGetTourSlots} from "../../services/Tours.service";
import DateUtils from '../../libs/DateUtils.lib';
import { TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PickupCard from "../../components/Cart/component/PickupCard.component";
import EventEmitter from "../../libs/Events.utils";
import RoutesList from "../../components/RouteComponent/Routes.component";
import MediaQuery from 'react-responsive';
import classNames from "classnames";

const validate = (values) => {
    const errors = {};
    const requiredFields = ['language', 'time_slot', 'pickup_id'];

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passengerPrices: [],
            selectedDate: new Date(),
            is_set: false,
            isCalling: true,
            availableDates: [],
            slotCalling: false,
            schedules: null,
            timezone: 0,
            inventory: 0,
            defaultDate: new Date(),
            open:false
        };
        this._handleSubmit = this._handleSubmit.bind(this);
        this._handlePassengerChange = this._handlePassengerChange.bind(this);
        this._handleDateChange = this._handleDateChange.bind(this);
        this._handleSlotChange = this._handleSlotChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this._handleBack = this._handleBack.bind(this);
        this._handleMapClick = this._handleMapClick.bind(this);
        this._handleClose = this._handleClose.bind(this)
    }

    async componentDidMount() {
        const {cart, selected_tour} = this.props;
        const res = await serviceGetTourAvailability({id: selected_tour._id});
        if (!res.error) {
            const data = res.data;
            this.setState({
                isCalling: false,
                availableDates: data.dates,
            })
        }
        if (cart.is_set) {
            this.props.change('language', cart.language);
            this.props.change('time_slot', cart.time_slot);
            this.props.change('pickup', cart.pickup);
            this.setState({
                selectedDate: cart.selectedDate,
                passengerPrices: cart.passengerPrices,
                is_set: true
            }, () => {
                this._handleDateChange(cart.selectedDate)
            });
        } else {
            this.setState({
                is_set: true,
            })
        }
    }

    _handleSubmit(tData) {
        if (tData.time_slot) {
            const {passengerPrices, selectedDate, timezone} = this.state;
            const {selected_tour: tour} = this.props;
            const tempDate = DateUtils.changeTimeStamp(selectedDate, "YYYY-MM-DD");
            console.log(tData, DateUtils.formattedTimeZone(tempDate, tData.time_slot, timezone));
            let routeIndex = null;
            tour.routes.some((val, index) => {
                if (val._id == tData.pickup_id) {
                    routeIndex = index;
                    return true;
                }
            });
            const route = tour.routes[routeIndex];
            this.props.actionCartUpdate({
                ...tData,
                passengerPrices: passengerPrices,
                tour_id: tour._id,
                tour_name: tour.name,
                selectedDate: selectedDate,
                tour_date: DateUtils.formattedTimeZone(tempDate, tData.time_slot, timezone),
                pickup: route.name,
                selected_point: route,
                tour_timezone: timezone,
                currency_shortcode: tour.currency_shortcode,
            });
            this.props.history.push(RouteNames.payment);
        } else {
            EventEmitter.dispatch(EventEmitter.THROW_ERROR, {
                error: 'Please Select Time Slot',
                type: 'error'
            });
        }
    }

    _renderLanguageMenu() {
        const {selected_tour: tour} = this.props;
        return tour.languages.split(', ').map((val) => {
            return (
                <MenuItem value={val}>{val}</MenuItem>
            )
        });
    }

    _handlePassengerChange(data) {
        console.log('_handlePassengerChange', data);
        this.setState({
            passengerPrices: data,
        });
    }

    _handleSlotChange(e) {
        const {schedules} = this.state;
        const selectedTime = e.target.value;
        let tempIndex = null;
        schedules.slots.some((val, index) => {
            if (val.slot_time == selectedTime) {
                tempIndex = index;
                return true
            }
        });
        if (tempIndex != null) {
            const inventory = schedules.slots[tempIndex];
            this.setState({
                inventory: inventory.inventory,
            });
        }
    }

    handleDateChange(data){
        this.setState({defaultDate: data})
    }

    _renderSlotField() {
        const {selected_tour: tour} = this.props;
        const {tour_schedule} = tour;
        const {schedules} = this.state;
        console.log('_renderSlotField', schedules);
        const tourSchedule = schedules ? (schedules.slots.length > 0 ? schedules : null) : null;
        if (tourSchedule) {
            const tempMenu = tourSchedule.slots.map((val, index) => {
                return (<MenuItem value={val.slot_time} key={'slot_' + index}>{val.slot_time}</MenuItem>);
            });
            return (
                <div>
                <div className={styles.formContainer}>
                    <Field
                        fullWidth={true}
                        name="time_slot"
                        component={renderOutlinedSelectField}
                        margin={"dense"}
                        onChange={this._handleSlotChange}
                        label={"Choose a slot"}>
                        {tempMenu}
                    </Field>
                </div>
                    {tour.is_personalized && (<div className={styles.timeSection}>
                    <span className={styles.orText}>OR</span>
                    <div style={{marginLeft: '15px'}}>
                        <div className={styles.subText} style={{marginBottom:'5px'}}>Enter custom time between 9:00hrs to 12:00hrs</div>
                        <Field fullWidth={true}
                               okLabel
                               name="time"
                               component={renderTimePicker}
                               margin={'dense'}
                               label="Enter Custom Time"
                               ampm={false}
                               inputId={'time'}
                               minDate={new Date()}
                        />
                    </div>
                </div>) }
                </div>

        )
        } else {
            return (
                <span className={styles.noslot}>No Slot For The Tour</span>
            )
        }
    }

    async _handleDateChange(date) {
        const {selected_tour} = this.props;
        this.setState({
            selectedDate: date,
            slotsCalling: true
        });
        const tempDate = DateUtils.changeTimeStamp(date, "YYYY-MM-DD");
        const req = await serviceGetTourSlots({id: selected_tour._id, date: tempDate});
        console.log('_handleDateChange', req);
        if (!req.error) {
            const data = req.data;
            this.setState({
                schedules: data.schedules,
                timezone: data.timezone,
                slotsCalling: false,
            })
        } else {
            this.setState({
                slotsCalling: false,
            })
        }
    }

    _renderPickupField() {
        const {selected_tour: tour} = this.props;
        const {routes} = tour;
        const tempMenu = [];
        routes.forEach((val, index) => {
            if (index == 0) {
                tempMenu.push(<MenuItem value={val._id} key={'route_' + index}>{val.name}</MenuItem>);
            }
        });
        return (
            <div className={styles.formContainer}>
                <Field
                    fullWidth={true}
                    name="pickup_id"
                    component={renderOutlinedSelectField}
                    margin={"dense"}
                    label={"Choose Pickup Point"}>
                    {tempMenu}
                </Field>
            </div>
        )
    }

    _handleBack() {
        this.props.history.goBack();
    }

    _renderBack() {
        const {theme} = this.props;
        return (
            <div className={styles.arrowFlex}>

                <ButtonBase onClick={this._handleBack}>
                    <div className={styles.backText}>
                        <ArrowBack/>
                        <span style={{color: theme.linkColor}}>Go back to Details</span>
                    </div>
                </ButtonBase>
            </div>
        )
    }

    _renderPickupCard(){
        return [1,2,3,4,5,6,7,8].map(val => <PickupCard/>)
    }

    _renderSlotsAndCount() {
        const {selected_tour: tour} = this.props;
        const {passengerPrices, slotsCalling, inventory} = this.state;
        if (slotsCalling) {
            return (
                <div>
                    <CircularProgress/>
                </div>
            );
        } else {
            return (
                <>

                    <div className={styles.bottomFlex}>
                        <div className={styles.formContainer}>
                            <div>
                                <div className={styles.heading}>
                                    Available Time Slots
                                </div>
                                <div className={styles.duration}>
                                    Duration: 90 Mins
                                </div>
                            </div>
                            {this._renderSlotField()}
                        </div>
                        <div className={styles.formContainer}>

                        </div>
                    </div>
                    <div>
                        <PassengerCount
                            inventory={inventory}
                            initialData={passengerPrices}
                            handlePassengerChange={this._handlePassengerChange}
                            prices={tour.prices}
                        ></PassengerCount>
                    </div>
                </>
            )
        }
    }

    _renderEcoImage() {
        const eco_friendly = this.props.eco_friendly;
        let totalPoints = 0;
        Object.keys(eco_friendly).forEach((val) => {
            if (eco_friendly[val]) {
                totalPoints++;
            }
        });
        return (<img src={require('../../assets/img/eco/ic_eco'+totalPoints+'.png')} className={styles.ecoIcon}/>);
    }

    _handleClose() {
        this.setState({open: false});
    }

    _handleMapClick(){
        this.setState({
            open: !this.state.open
        })
    }

    _handleMapView(){
        return(
            <Dialog
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this._handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <RoutesList data={{routes: this.props.routes}}>
                </RoutesList>
            </Dialog>
        )
    }

    render() {
        const {handleSubmit, theme, selected_tour: tour} = this.props;
        const {passengerPrices, selectedDate, is_set, availableDates} = this.state;
        if (is_set) {
            return (
                <div className={'container'}>
                    <MediaQuery minDeviceWidth={768}>
                        <div style={{marginTop:'80px'}}></div>
                    {this._renderBack()}
                    </MediaQuery>

                    <MediaQuery maxDeviceWidth={768}>
                        <div style={{marginTop:'70px'}}></div>
                        <div className={styles.centerHeading}>
                            Your Cart
                        </div>
                    </MediaQuery>

                    <MediaQuery minDeviceWidth={768}>

                    <div className={styles.outerFlex}>
                        <div>
                            {/*<img src={require('../../assets/img/tuxi/ic_eco5.png')} className={styles.ecoIcon}/>*/}
                            {this._renderEcoImage()}
                        </div>
                        <div className={styles.textContainer}>
                            <div className={styles.tourHeading}>
                                {tour.name}
                            </div>
                            <div>
                                <Rating value={4} readOnly className={styles.starRating}/>
                            </div>
                        </div>
                    </div>
                    </MediaQuery>

                    <form onSubmit={handleSubmit(this._handleSubmit)}>
                        <div className={styles.flexContainer}>
                            <div className={styles.tourFlex}>
                                <div className={styles.cardContainer}>
                                    We are listed as best seller of this tour.Enjoy your trips with lots of fun &
                                    activities
                                </div>

                                <MediaQuery maxDeviceWidth={768}>
                                    <div className={styles.outerFlex}>
                                        <div>
                                            {/*<img src={require('../../assets/img/tuxi/ic_eco5.png')} className={styles.ecoIcon}/>*/}
                                            {this._renderEcoImage()}
                                        </div>
                                        <div className={styles.textContainer}>
                                            <div>
                                                <Rating value={4} readOnly className={styles.starRating}/>
                                            </div>
                                            <div className={styles.tourHeading}>
                                                {tour.name}
                                            </div>
                                        </div>
                                    </div>
                                </MediaQuery>

                                <div>
                                    <h3>Plan Your Tour</h3>
                                    <div className={styles.bottomFlex}>
                                        <div className={styles.formContainer}>
                                            <div className={styles.heading}>
                                                Date Of Tour
                                            </div>
                                            <div className={styles.dateCont}>
                                                <DateComponent
                                                    color={'#B9B9B9'}
                                                    name={'DateOfTour'}
                                                    label={'Date of Tour'}
                                                    handleChange={this._handleDateChange}
                                                    initialValue={selectedDate}
                                                    options={{disable: [], enable: availableDates}}
                                                >
                                                </DateComponent>
                                            </div>
                                        </div>
                                        <div className={classNames(styles.formContainer,styles.newContainer)}>
                                            <div className={styles.heading}>
                                                Language
                                            </div>
                                            <div>
                                                <div className={styles.formContainer}>
                                                    <Field
                                                        fullWidth={true}
                                                        name="language"
                                                        component={renderOutlinedSelectField}
                                                        margin={"dense"}
                                                        label={"Language"}>
                                                        {this._renderLanguageMenu()}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br/>

                                    {this._renderSlotsAndCount()}

                                    <br/>

                                    <div>
                                            <div>
                                                <div className={styles.heading}>
                                                    Pickup Point
                                                </div>
                                                <div className={styles.innerText}>
                                                    Not Sure ? Dont worry you can change with tour provider later
                                                </div>
                                            </div>
                                        <div className={styles.bottomFlex}>
                                            {this._renderPickupField()}
                                            <MediaQuery minDeviceWidth={768}>
                                            <div className={styles.formContainer}>
                                                <ButtonBase className={styles.mapButton} onClick={this._handleMapClick}>
                                                    See on Map
                                                </ButtonBase>
                                            </div>
                                            </MediaQuery>
                                        </div>
                                        {tour.is_personalized && (<div className={styles.timeSectionM}>
                                            <span className={styles.orText}>OR</span>
                                            <div style={{marginLeft: '15px',flex:'1'}}>
                                                <div className={styles.subText}>Mention your custom location for pickup</div>
                                                <Field fullWidth={true}
                                                       name="location"
                                                       component={renderOutlinedTextField}
                                                       margin={'dense'}
                                                       label="Enter Location"

                                                />
                                            </div>
                                        </div>)}
                                        {tour.is_personalized && (<><div>
                                            <div className={styles.heading}>
                                                Meal Option
                                            </div>
                                            <div className={styles.innerText}>
                                                Not Sure ? Dont worry you can change with tour provider later
                                            </div>
                                        </div>

                                        <div className={styles.timeSectionM} >
                                            <span className={styles.orText}>OR</span>
                                            <div style={{marginLeft: '15px',flex:'1'}}>
                                                <Field fullWidth={true}
                                                       name="meal"
                                                       component={renderOutlinedSelectField}
                                                       margin={'dense'}
                                                       label="Choose Your Meal Preference"

                                                >
                                                    <MenuItem value={'MEAL'}>MEAL</MenuItem>
                                                </Field>
                                            </div>
                                        </div></>)}
                                        {tour.is_personalized && (<><div>
                                            <div className={styles.heading}>
                                               Pick what you want to see
                                            </div>
                                            <div className={styles.innerText}>
                                                Not Sure ? Dont worry you can change with tour provider later
                                            </div>
                                        </div>
                                        <div className={styles.pickupFlex}>
                                        {this._renderPickupCard()}
                                        </div></>)}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.totalFlex}>
                                <TotalSidebar currency={tour.currency_shortcode} passengers={passengerPrices}/>
                                <div className={styles.bottomText}>
                                    Zero Cancellation fee up to 24 hours
                                    subject to acceptance by provider
                                </div>
                            </div>
                        </div>
                    </form>
                    {this._handleMapView()}
                </div>
            );
        } else {
            return (<WaitingComponent/>)
        }
    }
}

const cart = reduxForm({
    form: 'cart',
    validate,
})(Cart);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        actionCartUpdate: actionCartUpdate
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        is_authenticated: state.auth.is_authenticated,
        category: state.common.category,
        cart: state.cart,
        selected_tour: state.tours.selected_tour,
        eco_friendly: state.tours.selected_tour.eco_friendly,
        routes:  state.tours.selected_tour.routes,
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(cart));
