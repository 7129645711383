import React from 'react'
import styles from './Driver.module.css'
import ButtonBase from "@material-ui/core/ButtonBase";
import Record from '@material-ui/icons/RecordVoiceOver'
import {Places, WaitingComponent} from '../../components/index.component';
import Keyboard from '@material-ui/icons/KeyboardArrowLeft'


class Driver extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
        this._handleBack = this._handleBack.bind(this);
    }

    componentDidMount() {
        const {data} = this.props.location.state;
        if (data) {
            this.setState({
                data: data,
            });
        }
    }

    _handleBack() {
        this.props.history.goBack();
    }

    _renderLanguages() {
        const {data} = this.state;
        const tempArr = data.languages.split(',');
        if (tempArr.length > 0) {
            const temp = ['Speaks'];
            tempArr.forEach((val, index) => {
                if (tempArr.length - 1 == index && tempArr.length > 1) {
                    temp.push(' and');
                }
                temp.push((<span className={styles.language}> {val} </span>));
                if (index < tempArr.length - 2 && tempArr.length > 1) {
                    temp.push(', ');
                }
            });
            return temp;
        }
        return null;
    }

    render() {
        const {data} = this.state;
        if (data) {
            return (
                <div className={styles.mainContainer}>
                    <div className={styles.container}>
                        <div className={styles.back}>
                            <ButtonBase onClick={this._handleBack}>
                                <Keyboard fontSize={'large'}/>
                            </ButtonBase>
                        </div>
                        <div className={styles.profile}>
                            <img src={data.image} className={styles.image}/>

                            <div className={styles.text}>{data.name}</div>


                            <div className={styles.trips}>
                                <div>
                                    2000
                                    <div className={styles.category}>Trips</div>
                                </div>

                                <div>
                                    4.9
                                    <div className={styles.category}>Rating</div>
                                </div>
                                <div>
                                    186
                                    <div className={styles.category}>Compliments</div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={styles.cont}>
                        <div className={styles.mainCont}>
                            <Record style={{color: '#1D8BF1'}}/>
                            <div style={{marginLeft: '10px'}}>{this._renderLanguages()}</div>
                        </div>
                        <hr/>

                        <div className={styles.heading}>Overview</div>
                        <div className={styles.description}>
                            {data.overview.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </div>
                        <hr/>

                        <div className={styles.heading}>Education Details</div>
                        <div className={styles.description}>
                            {data.education.split('\n').map(i => {
                                return <div>{i}</div>
                            })}
                        </div>
                        <hr/>

                        <div className={styles.heading}>Rider Compliments</div>
                        <div className={styles.icons}>
                            <div>
                                <img src={require('../../assets/img/star.png')}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/star2.png')}/>
                            </div>
                            <div>
                                <img src={require('../../assets/img/star3.png')}/>
                            </div>
                        </div>
                        <hr/>

                        <div className={styles.heading}>More Tours from {data.name}</div>
                        <Places/>
                    </div>

                </div>
            )
        } else {
            return (<WaitingComponent/>)
        }
    }
}

export default Driver
