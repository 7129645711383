import React from 'react';

const data = {
    images: [
        require('./assets/img/heading.png'),
        require('./assets/img/heading.png'),
    ],
    compliments: [
        {
            image:require('./assets/img/star.png'),
            text:'Performance'
        },
        {
            image:require('./assets/img/star2.png'),
            text:'Cool Car'
        },
        {
            image:require('./assets/img/star3.png'),
            text:'Funny'
        },
    ],
    cardsData: [
        {
            id: 1,
            image: require('./assets/img/Lisbon.png'),
            category: 'Historical',
            text: 'Libson Castle and medivieal buildings',
            icon: require('./assets/img/ic_profile.png'),
            like: require('./assets/img/ic_non_fav.png'),
            ratings: require('./assets/img/ic_rating.png'),
            rating: 4.6,
            amount: 20,
            people: 'Upto 6',
            time: '2hrs',
            language: 'English',
            speak: require('./assets/img/speak.png'),
        },
        {
            id: 2,
            image: require('./assets/img/Safari.png'),
            category: 'Outdoor & Adventures',
            text: 'Jeep Safari In Sintra , Fun outside Lisbon',
            icon: require('./assets/img/ic_profile.png'),
            rating: 4.6,
            amount: 30,
            people: 'Upto 7',
            time: '4 hrs',
            language: 'English',
            speak: require('./assets/img/speak.png'),
        },
        {
            id: 3,
            image: require('./assets/img/Lisbon.png'),
            category: 'Lobda',
            text: 'Libson Castle and medivielal buildings',
            icon: require('./assets/img/ic_profile.png'),
            rating: 4.6,
            amount: 20,
            people: 'Upto 8',
            time: '2 hrs',
            language: 'English',
            speak: require('./assets/img/speak.png'),
        },
        {
            id: 4,
            image: require('./assets/img/Safari.png'),
            category: 'Konda',
            text: 'Libson Castle and medivielal buildings',
            rating: 4.6,
            amount: 20,
            time: '2 hrs',
            language: 'English',
            speak: require('./assets/img/speak.png'),
        },
    ],
    grid: [
        {
            image: require('./assets/img/1.png'),
            cols: 1,
        },
        {
            image: require('./assets/img/2.png'),
            cols: 0.5,
        },
        {
            image: require('./assets/img/3.png'),
            cols: 0.5,
        },
        // {
        //     image: require('./assets/img/4.png'),
        //     rows:2,
        //     cols:0.5
        // }
    ],
    coupons: [
        {
            coupon: require('./assets/img/c1.png'),
        },
        {
            coupon: require('./assets/img/c2.png'),
        },
    ],
    categories: [
        {
            image: require('./assets/img/factory/categories/Prescription-Medicine.png'),
            text: 'Prescription Medicine',
            vendors: '20+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/OTC-Products.png'),
            text: 'OTC Products',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Ayurveda.png'),
            text: 'Ayurveda',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Homeopathy.png'),
            text: 'Homeopathy',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Nutraceuticals.png'),
            text: 'Nutraceuticals',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Veterinary.png'),
            text: 'Veterinary',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Wellness.png'),
            text: 'Wellness',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Lifestyle.png'),
            text: 'Lifestyle',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Cosmetics.png'),
            text: 'Cosmetics',
            vendors: '30+ Vendors',
        },
        {
            image: require('./assets/img/factory/categories/Healthcare-Devices.png'),
            text: 'Healthcare Devices',
            vendors: '30+ Vendors',
        },

    ],
    bulk: [
        {
            image: require('./assets/img/factory/Bulk Deals/1.png'),
            title: 'My PAPERCLIP Limited Edition',
            price: '97',
            min: '50'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/5.png'),
            title: '198 pack of mulicolor injections',
            price: '07',
            min: '500'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/3.png'),
            title: 'Conventional medicine templates',
            price: '3',
            min: '50'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/1.png'),
            title: 'My PAPERCLIP Limited Edition',
            price: '97',
            min: '50'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/5.png'),
            title: '198 pack of mulicolor injections',
            price: '07',
            min: '500'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/3.png'),
            title: 'Conventional medicine templates',
            price: '3',
            min: '50'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/1.png'),
            title: 'My PAPERCLIP Limited Edition',
            price: '97',
            min: '50'
        },
        {
            image: require('./assets/img/factory/Bulk Deals/5.png'),
            title: '198 pack of mulicolor injections',
            price: '07',
            min: '500'
        }
    ],
    industries: [
        {
            image: require('./assets/img/factory/industries/Pharmaceuticals.png'),
            text: 'Pharmaceuticals',
            vendors: '20+ Vendors',
        },
        {
            image: require('./assets/img/factory/industries/FMCG.png'),
            text: 'FMCG',
            vendors: '100+ Vendors',
        },
        {
            image: require('./assets/img/factory/industries/Printing _ Packaging.png'),
            text: 'Printing & Packaging',
            vendors: '100+ Vendors',
        },
        {
            image: require('./assets/img/factory/industries/Apparels.png'),
            text: 'Apparels',
            vendors: '100+ Vendors',
        },
        {
            image: require('./assets/img/factory/industries/Consumer Durables.png'),
            text: 'Consumer Durables',
            vendors: '100+ Vendors',
        },
        {
            image: require('./assets/img/factory/industries/Furniture.png'),
            text: 'Furniture',
            vendors: 'Coming Soon!',
        },
        {
            image: require('./assets/img/factory/industries/Electronics.png'),
            text: 'Electronics',
            vendors: 'Coming Soon!',
        },
        {
            image: require('./assets/img/factory/industries/Vehicles _ Accessories.png'),
            text: 'Vehicle & Accessories',
            vendors: 'Coming Soon!',
        },
        {
            image: require('./assets/img/factory/industries/Footwear.png'),
            text: 'Footwear',
            vendors: 'Coming Soon!',
        },
        {
            image: require('./assets/img/factory/industries/Sports _ Toys.png'),
            text: 'Sports & Toys',
            vendors: 'Coming Soon!',
        },

    ],
    all_industries:[
        {
            title:'Apparels',
            total:'72982',
            data: ['Apparel Design Services','Processing Services','Apparel Stock','Boys clothing','Costumes','Ehnic Clothing','Garment Accessories','Infant & Toddler Clothing','Maternity Clothing','Sewing Supplies','Stage & Dance Wear','Uniforms','Wedding Apparels & Accessories','Mannequins','Mens Clothing','Sports Wear','Tag Guns','Used Clothes']
        },
        {
            title: 'Fashion Accessories',
            total:'82982',
            data: ['Belt Accessories','Belt Buckles','Scarves & Shawls','Fashion Accessories Design','Belt Chains','Genuine Leather Belts','Gloves & Mittens','Suspenders','Ties & Accessories','Hair Accessories','Belts','PU Belts','Wedding Apparel & Accessories','Fashion Accessories Processing','Metal Belts','Leather Gloves & Mittens','Scarf,Hat & Glove Sets','']
        },
        {
            title: 'Timepieces,Jewelery,Eyewears',
            total:'52982',
            data: ['Eyewear','Jewelry Sets','Sports Eyewear','Watches','Loose Beads','Bracelets & Bangles','Sunglasses','Rings','Cuff links & Tie Clips','Body Jewellery','Necklaces','Jewelry Boxes','Brooches','Jewelry','Jewelry Tools & Equipment','Earrings','Eyeglasses Frames','Loose Gemstone']
        },

    ],
    products: [
        {
            image: require('./assets/img/factory/Top/product_1.png'),
            title: 'MYPAPERCLIP LIMITED EDITION',
            desc: '100 GSM Munken Polar Rough from Arctic Paper',
            price: '97',
            min: '50',
            supplier: '20+',
            per: 'edition'
        },
        {
            image: require('./assets/img/factory/Top/product_2.png'),
            title: 'NEEDCLOX-CV',
            desc: 'Pharmaneeds offers full range of pharmaceuticals',
            price: '7',
            min: '500',
            supplier: '20+',
            per: 'strip'
        },
        {
            image: require('./assets/img/factory/Top/product_3.png'),
            title: 'I95 PACK OF 7 BLACK 4-LAYER',
            desc: 'Airmesh Washable Reusable Anti-pollution Outdoor Masks',
            price: '47',
            min: '50',
            supplier: '20+',
            per: 'mask'
        },
        {
            image: require('./assets/img/factory/Top/product_2.png'),
            title: 'NEEDCLOX-CV',
            desc: 'Pharmaneeds offers full range of pharmaceuticals',
            price: '7',
            min: '500',
            supplier: '20+',
            per: 'strip'
        },
        {
            image: require('./assets/img/factory/Top/product_2.png'),
            title: 'NEEDCLOX-CV',
            desc: 'Pharmaneeds offers full range of pharmaceuticals',
            price: '7',
            min: '500',
            supplier: '20+',
            per: 'strip'
        },
    ],
    manufacture: [
        {
            company: require('./assets/img/factory/Manufacture/company_logo1.png'),
            badgeA: require('./assets/img/factory/Manufacture/badge1.png'),
            badgeB: require('./assets/img/factory/Manufacture/badge2.png'),
            badgeC: require('./assets/img/factory/Manufacture/badge3.png'),
            badgeD: require('./assets/img/factory/Manufacture/badge4.png'),
            badgeE: require('./assets/img/factory/Manufacture/badge5.png'),
            title: 'AHAR PHARMA',
            desc: 'We provide Pharmaneeds offers full range of pharmaceutical..'
        },
        {
            company: require('./assets/img/factory/Manufacture/company_logo2.png'),
            badgeA: require('./assets/img/factory/Manufacture/badge1.png'),
            badgeB: require('./assets/img/factory/Manufacture/badge2.png'),
            badgeC: require('./assets/img/factory/Manufacture/badge3.png'),
            badgeD: require('./assets/img/factory/Manufacture/badge4.png'),
            title: 'INDIAN PHARMA NETWORK',
            desc: 'We provide Pharmaneeds offers full range of pharmaceutical..'
        },
        {
            company: require('./assets/img/factory/Manufacture/company_logo3.png'),
            badgeA: require('./assets/img/factory/Manufacture/badge1.png'),
            badgeB: require('./assets/img/factory/Manufacture/badge2.png'),
            badgeC: require('./assets/img/factory/Manufacture/badge3.png'),
            badgeD: require('./assets/img/factory/Manufacture/badge4.png'),
            title: 'MARLINZ PHARMA',
            desc: 'We provide Pharmaneeds offers full range of pharmaceutical..'
        },
        {
            company: require('./assets/img/factory/Manufacture/company_logo4.png'),
            badgeA: require('./assets/img/factory/Manufacture/badge1.png'),
            badgeB: require('./assets/img/factory/Manufacture/badge2.png'),
            badgeC: require('./assets/img/factory/Manufacture/badge3.png'),
            title: 'SHIFT PHARMACEUTICALS',
            desc: 'We provide Pharmaneeds offers full range of pharmaceutical..'
        }
    ],
    chats: [
        {
            image: require('./assets/img/ProfillePic.png'),
            name:'Maddie Castro'
        },
        {
            image: require('./assets/img/ProfillePic.png'),
            name:'Maddie Castro'
        },
        {
            image: require('./assets/img/ProfillePic.png'),
            name:'Maddie Castro'
        },

    ],
    filters: {
        arrival: {
            title: 'Estimated Time of Arrival',
            data: [
                {
                    title: '0-5',
                    value: '0-5'
                },
                {
                    title: '5-10',
                    value: '5-10'
                },
                {
                    title: '10-15',
                    value: '10-15'
                },

            ],
        },
        tour_type: {
          title: 'Tour Type',
            data: [
                {
                    title: 'Home',
                    value: 'Home',
                },
                {
                    title: 'Bussiness',
                    value: 'Bussiness',
                },
            ],
        },
        language: {
            title: 'Language',
            data: [
                {
                    title: 'Spanish',
                    value: 'Spanish',
                },
                {
                    title: 'English',
                    value: 'English',
                },
                {
                    title: 'German',
                    value: 'German',
                },
                {
                    title: 'French',
                    value: 'French',
                },
                {
                    title: 'Hindi',
                    value: 'Hindi',
                },
            ],
        },
        category: {
            title: 'Category Tours',
            data: [
                {
                    title: 'Historical',
                    value: 'Historical',
                },
                {
                    title: 'Hunting',
                    value: 'Hunting',
                },
                {
                    title: 'Adventure',
                    value: 'Adventure',
                },
                {
                    title: 'Outdoor',
                    value: 'Outdoor',
                },
            ]
        },
        type: {
            title: 'Category',
            data: [
                {
                    title: 'Land',
                    value: 'Land',
                },
                {
                    title: 'Air',
                    value: 'Air',
                },
                {
                    title: 'Water',
                    value: 'Water',
                },
                {
                    title: 'Walking',
                    value: 'Walking',
                },
            ]
        },
        duration: {
            title: 'DURATION',
            data: [
                {
                    title: '2 Hours',
                    value: '2 Hours',
                },
                {
                    title: '3 Hours',
                    value: '3 Hours',
                },
                {
                    title: '4 Hours',
                    value: '4 Hours',
                },
                {
                    title: '5 Hours',
                    value: '5 Hours',
                },
            ]
        },
        rating: {
            title: 'Rating',
            data:[
            {
                title: '2+',
            },
            {
                title: '3+',
            },
            {
                title: '4+',
            },
            // {
            //   title: '4.5',
            // },
        ]},
        vehicle: {
            title: 'Vehicle',
            data: [
            {
                blueIcon: require('./assets/img/ic_convertable_blue.png'),
                greyIcon: require('./assets/img/convertible.png'),
                title: 'Sports',
                value: 'Sports',
            },
            {
                blueIcon: require('./assets/img/ic_tuktuk_blue.png'),
                greyIcon: require('./assets/img/tuktukk_black.png'),
                title: 'TukTuk',
                value: 'TukTuk',
            },
            {
                blueIcon: require('./assets/img/ic_mvp_blue.png'),
                greyIcon: require('./assets/img/MVP.png'),
                title: 'MVP',
                value: 'MVP',
            },
            {
                blueIcon: require('./assets/img/ic_sedan_blue.png'),
                greyIcon: require('./assets/img/sedan.png'),
                title: 'Sedan',
                value: 'Sedan',
            },
            {
                blueIcon: require('./assets/img/ic_4_4_blue.png'),
                greyIcon: require('./assets/img/4_4.png'),
                title: '4*4',
                value: '4*4',
            },
            {
                blueIcon: require('./assets/img/ic_hatch_blue.png'),
                greyIcon: require('./assets/img/hatchback.png'),
                title: 'Hatchback',
                value: 'Hatchback',
            },
        ]},
    },
    driver: [
        {
            d1: require('./assets/img/driver1.png'),
        },
        {
            d1: require('./assets/img/driver2.png'),
        },
        {
            d1: require('./assets/img/driver3.png'),
        },
        {
            d1: require('./assets/img/driver4.png'),
        },
        {
            d1: require('./assets/img/driver5.png'),
        },

    ],
    translate: [
        {
            img: require('./assets/img/f1.png'),
            name: 'Portugal',
            short: 'EU',
            c_code: '+351',
        },
        {
            img: require('./assets/img/f2.png'),
            name: 'United States',
            short: 'US',
            c_code: '+1',
        },
        {
            img: require('./assets/img/f3.png'),
            name: 'States',
            short: 'U',
            c_code: '+35',
        },
        {
            img: require('./assets/img/f4.png'),
            name: 'Portugal',
            short: 'EU',
            c_code: '+123',
        },
        {
            img: require('./assets/img/f1.png'),
            name: 'Portugal',
            short: 'EU',
            c_code: '+351',
        },
        {
            img: require('./assets/img/f2.png'),
            name: 'United States',
            short: 'US',
            c_code: '+1',
        },
        {
            img: require('./assets/img/f3.png'),
            name: 'States',
            short: 'U',
            c_code: '+35',
        },
        {
            img: require('./assets/img/f4.png'),
            name: 'Portugal',
            short: 'EU',
            c_code: '+123',
        },
        {
            img: require('./assets/img/f2.png'),
            name: 'United States',
            short: 'US',
            c_code: '+1',
        },
        {
            img: require('./assets/img/f3.png'),
            name: 'States',
            short: 'U',
            c_code: '+35',
        },

    ],
    cities: [
        {
            greyCity: require('./assets/img/cities/ic_lisbon_unselected@3x.png'),
            blueCity: require('./assets/img/cities/ic_lisbon_selected@3x.png'),
            city: 'Lisbon',
        },
        {
            greyCity: require('./assets/img/cities/ic_porto_unselected@3x.png'),
            blueCity: require('./assets/img/cities/ic_porto_selected@3x.png'),
            city: 'Porto',
        },
        {
            greyCity: require('./assets/img/cities/ic_braga_unselected@3x.png'),
            blueCity: require('./assets/img/cities/ic_braga_selected@3x.png'),
            city: 'Braga',
        },
        {
            greyCity: require('./assets/img/cities/ic_sintra_unselected@3x.png'),
            blueCity: require('./assets/img/cities/ic_sintra_selected@3x.png'),
            city: 'Sintra',
        },
        {
            greyCity: require('./assets/img/cities/ic_cascais_unseletecd@3x.png'),
            blueCity: require('./assets/img/cities/ic_cascais_selected@3x.png'),
            city: 'Cascais',
        },
        {
            greyCity: require('./assets/img/cities/ic_coimbra_unselected@3x.png'),
            blueCity: require('./assets/img/cities/ic_coimbra_selected@3x.png'),
            city: 'Coimbra',
        },
    ],
    driverReview: [
        {
            name: 'Catherine',
            rating: '4',
            image: require('./assets/img/ProfillePic.png'),
            description: 'Overall Rating',
            date: 'On 2nd August 2019',
            reviews: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
        },
        {
            name: 'Harry',
            rating: '4',
            image: require('./assets/img/driverA.png'),
            description: 'Overall Rating',
            date: 'On 2nd August 2019',
            reviews: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',

        },
        {
            name: 'Marinda Gomes',
            rating: '4',
            image: require('./assets/img/ic_profile.png'),
            description: 'Overall Rating',
            date: 'On 2nd August 2019',
            reviews: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',

        },
    ],
    drawer: [
        {
            icon: require('./assets/img/navbar/ic_cities_unselected.png'),
            d1: 'Cities',
            path: '/cities'
        },
        {
            icon: require('./assets/img/navbar/ic_blogs_unselected.png'),
            d1: 'Blogs',
            path: '/blogs'
        },
        {
            icon: require('./assets/img/navbar/ic_wishlist_unselected.png'),
            d1: 'Cart',
            path: '/cart'
        },

    ],
    categoriesData:[
        {
            image: require('./assets/img/historical.jpg'),
            title:'Historical',
            tours:1270
        },
        {
            image: require('./assets/img/art&culture.jpg'),
            title:'Art & Culture',
            tours:620
        },
        {
            image: require('./assets/img/Places.jpg'),
            title:'Popular Places',
            tours:200
        },
    ],
    citiesData:[
        {
            image: require('./assets/img/Porto.jpg'),
            title:'Porto',
            tours:1270
        },
        {
            image: require('./assets/img/almada.jpg'),
            title:'Almada',
            tours:620
        },
        {
            image: require('./assets/img/Braga.jpg'),
            title:'Braga',
            tours:200
        }
    ],
    providers: [
        {
            image: require('./assets/img/desktop/provider1.png'),
            heading: 'Individual Driver',
            description: 'Start delivering great tours right now'
        },
        {
            image: require('./assets/img/desktop/provider2.png'),
            heading:'Tour Company',
            description:'Add your drivers and tours in minutes and reach global audience'
        }
    ]
};

export default data;
