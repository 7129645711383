import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from "react-redux";
import moment from 'moment';
import {bindActionCreators} from 'redux';
import styles from './Industry.module.css';
import data from '../../data';
import Portfolio from "../Portfolio/Portfolio.view";
import OutSource from "../Home/OutSource/OutSource.view";

const apparels = ['Apparel Design Services','Processing Services','Apparel Stock']

class Industry extends Component {
    constructor(props) {
        super(props);
        this.filterCategories = ['Apparels','Fashion Accessories','Timepieces,Jewelery,Eyewear']
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderIndustries(){
        return(
           data.industries.map((val)=>{
               return(
                   <div className={styles.textContainer}>
                       <div className={styles.heading}>{val.text}</div>
                   </div>
               )
           })
        )
    }

    _renderFilterCategories(){
        return(
            data.all_industries.map((val)=> {
                return(
                    <div className={styles.industryContainer}>
                        <div className={styles.title}>{val.title}({val.total})</div>
                        <div className={styles.indusFlex}>
                            {val.data.map((v)=>{
                                return(
                                    <a href={'/'} className={styles.category}>
                                        {v}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        return (
            <div>
                <div style={{height:'30px'}}></div>
                <div className={classNames('container', styles.desktopSearch)}>
                    <section className={styles.filterSidebar}>
                        <div className={styles.all}>All Industries</div>
                        <div>
                            {this.renderIndustries()}
                        </div>
                    </section>

                    <section className={styles.resultActivities}>
                        {this._renderFilterCategories()}
                    </section>
                </div>
                <br/>
                <Portfolio/>
                <div className={'ncontainer'}>
                <OutSource/>
                </div>
            </div>
        )
    }
}

export default Industry