import React,{Component} from 'react'
import styles from './Mini.module.css'


class Mini extends Component{
    constructor(props){
        super(props);
        this.state={
            value: []
        }
        this._handleFilter = this._handleFilter.bind(this)
    }
    _handleFilter(){
        // console.log(this.props.category,this.props.val.value)
        this.props.handleClick(this.props.category,this.props.val.value)
    }
    render(){
            const {val} = this.props
        return(

            <li className={styles.card} style={this.props.checked ? {backgroundColor: '#55B953',color:'white'}: {}} onClick={this._handleFilter}>
                <div style={{textAlign:'center'}}>{this.props.val.title}</div>
            </li>

        )
    }
}

export default Mini
