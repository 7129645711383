import React, {Component} from 'react';
import {ButtonBase} from '@material-ui/core';
import styles from './Compliments.module.css'

class Compliments extends Component {
    constructor(props){
        super(props);
        this.state = {
            selected:false,
            compliments:[]
        }
        this._handleSelect = this._handleSelect.bind(this)
    }

    _handleSelect () {
        this.setState({
            selected: !this.state.selected
        })
    }

    _renderCompliments() {
        console.log(this.props)
            return (
                <ButtonBase onClick={this._handleSelect}>
                    <div style={{marginRight:'10px'}}>
                      <img src={this.props.data.image} className={this.state.selected ? styles.imageBlur : styles.imageSelect}/>
                        <div>{this.props.data.text}</div>
                    </div>
                </ButtonBase>

            )
    }
    render(){
        return (
            <div>
                {this._renderCompliments()}
            </div>

        )
    }
}

export default Compliments