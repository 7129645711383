import React, {Component} from 'react';
import styles from './ProdCard.module.css';
import {ButtonBase,MenuItem} from '@material-ui/core';
import {ArrowRightAlt} from '@material-ui/icons'
import classNames from 'classnames';
import {Star,TurnedIn} from '@material-ui/icons'
import Rating from '@material-ui/lab/Rating';
import WishlistComponent from "../../../components/Wishlist/Wishlist.component";
// import ManufactureCard from "../ManufactureCard.view";

class ProdCard extends Component{
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(data){
        this.props.addToTemp(data)
    }

    _renderFavourite() {
        const {data, val} = this.props;
        if (data) {
            return (<WishlistComponent productId={data} handleClick={this.handleClick}/>)
        } else {
            return (<WishlistComponent productId={data.id}/>)
        }
    }

    render(){
        return(
            <div className={classNames(styles.wrapper)}>
                <div className={styles.mainContainer}>
                    <div className={styles.turned}><TurnedIn className={styles.bookmark}/></div>
                    <div className={styles.seller}>Top<br/>Seller</div>
                    <div>
                        <div>
                            {this._renderFavourite()}
                            {/*<Star className={styles.starRating}/>*/}
                        </div>
                        <div className={styles.pharma}>
                            <img src={require('../../../assets/img/factory/ManufacturersList/logo.png')} className={styles.logo}/>
                            {/*<div className={styles.rating}>4.5</div>*/}
                        </div>
                        <div className={styles.company}>
                            <div className={styles.title}>PARACETAMOL(500MG)</div>
                            <div className={styles.loc}><span className={styles.price}>Rs 2.00-3.00</span> / tablet</div>
                            <div className={styles.spec}>Minimum Order Quantity: <span className={styles.price}>120 Box</span></div>
                            <div className={styles.spec}>Estimated delivery time 3 weeks</div>
                            <div className={styles.logosFlex}>
                                <img src={require('../../../assets/img/factory/ManufacturersList/b1.png')} className={styles.badge}/>
                                <img src={require('../../../assets/img/factory/ManufacturersList/b2.png')} className={styles.badge}/>
                                <img src={require('../../../assets/img/factory/ManufacturersList/b3.png')} className={styles.badge}/>
                                <img src={require('../../../assets/img/factory/ManufacturersList/b4.png')} className={styles.badge}/>
                                <img src={require('../../../assets/img/factory/ManufacturersList/b5.png')} className={styles.badge}/>
                            </div>

                            <div className={styles.viewFlex}>
                                <ButtonBase className={styles.works}>
                                    view profile
                                </ButtonBase>

                                <ButtonBase className={styles.works} style={{marginLeft:'15px'}}>
                                    place order
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                    {/*<div>*/}
                        {/*<ButtonBase className={styles.inquire}>*/}
                            {/*inquire now*/}
                        {/*</ButtonBase>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default ProdCard
