import React, { Component } from 'react';
import styles from './TicketList.module.css';
import ButtonBase from '@material-ui/core/ButtonBase';
import Ticket from '../../components/Ticket/Ticket.component'

class TicketView extends Component {
    render(){
        return(
            <div style={{ backgroundColor: '#f6f6f6', height: '100vh'}}>
                <div style={{height:'65px'}}>
                </div>

                <div className={styles.mainContainer}>
                <div className={styles.upperFlex}>
                    <div style={{color:'grey',fontSize:'12px'}}>Sun,6 Aug 2019</div>

                    <Ticket/>
                </div>
            </div>
            </div>
        )
    }
}

export default TicketView
