/**
 * Created by charanjeetelectrovese@gmail.com on system AakritiS. on 22/03/19.
 */
import {
    LOCATION_UPDATE,
    SELECT_INSURANCE,
    UPDATE_ROUTE,
    UPDATE_BOARD_TYPE,
    UPDATE_CATEGORY,
    FILTER_LIST_DONE,
    FILTER_LIST_INIT,
    SET_COUNTRY_CODE,
    SET_LOCATION,
    CLEAR_LOCATION,
    UPDATE_DATETIME,
    ADD_WISHLIST,
    REMOVE_WISHLIST
} from '../actions/Common.action';

const initialState = {
    location: null,
    redirect_route: '/',
    board_type: 'board_now',
    category: 'tour',
    booking: '',
    country_code: 'IN',
    filter_list: {
        list_data: null,
        is_fetching: false,
    },
    from_location: null,
    wishlist:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_DATETIME: {
            return {
                ...state,
                date: action.payload.date,
                time: action.payload.time
            }
        }
        case LOCATION_UPDATE: {
            localStorage.setItem('location', action.payload);
            return {...state, location_updated: true, location: action.payload};
        }
        case SELECT_INSURANCE: {
            return {...state, selected_insurance: action.payload};
        }
        case UPDATE_ROUTE: {
            return {...state, redirect_route: action.payload};
        }
        case UPDATE_BOARD_TYPE: {
            localStorage.setItem('board_type', action.payload);
            return {...state, board_type: action.payload};
        }
        case UPDATE_CATEGORY: {
            localStorage.setItem('category', action.payload);
            return {...state, category: action.payload};
        }
        case FILTER_LIST_INIT: {
            const tempData = {...state.filter_list, is_fetching: true};
            return {...state, filter_list: tempData};
        }
        case FILTER_LIST_DONE: {
            const temp = {
                is_fetching: false,
                list_data: action.payload
            };
            return {
                ...state,
                filter_list: temp
            }
        }
        case SET_COUNTRY_CODE: {
            return {
                ...state,
                country_code: action.payload
            };
        }
        case SET_LOCATION: {
            return {
                ...state,
                from_location: action.payload,
            };
        }
        case CLEAR_LOCATION: {
            return {
                ...state,
                from_location: null,
            };
        }
        case ADD_WISHLIST: {
            const temp = state.wishlist ? state.wishlist : [];
            const newCart = temp.filter((val)=> val.id != action.payload.id)
            newCart.push(action.payload);
            return {
                ...state,
                wishlist: newCart
            }
        }
        case REMOVE_WISHLIST: {
            return {
                ...state
            }
        }
        default: {
            return state;
        }
    }
}
